import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SpeedDial } from 'primereact/speeddial';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { Toast } from 'primereact/toast';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import ConfigServices from "../../core/services/ConfigServices";
import { ToastHelper } from "../../helper/ToastHelper";
import EBreadCrumb from "../../components/EBreadCrumb";
import UserServices from "../../core/services/UserServices";
import { cloneDeep } from 'lodash'
import { PERMISSION, USER_PERMISSION, USER_PERMISSIONS } from "../../utils/constants";
import { useSelector } from 'react-redux';
import ConfigDetail from "./ConfigDetail";
import { checkPermission } from "../../utils";
import { ProgressBar } from "primereact/progressbar";

const screenPermission = "configs"

function Config(props) {
    const { t } = useTranslation()

    const { userInfo, groupPermission } = useSelector((state) => state.userReducer)

    const [lazyParams, setLazyParams] = useState({
        keyword: "",
        first: 0,
        page: 0,
        size: 10,
        total: 0,
        status: 1
    })

    const [meta, setMeta] = useState(lazyParams)

    const toast = useRef()
    const refDetail = useRef()
    const [data, setData] = useState([])

    const [loadingList, setLoadingList] = useState(false)

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getList(lazyParams)
        }, 200)
        return () => clearTimeout(delayDebounceFn)
    }, [lazyParams])


    const permission = useMemo(() => {
        return parseInt(userInfo?.permission ?? "-1")
    }, [userInfo])

    const getList = (_lazyParams) => {
        _lazyParams = cloneDeep(_lazyParams ? _lazyParams : lazyParams)
        _lazyParams.page = _lazyParams.page + 1
        setLoadingList(true)
        ConfigServices.get(_lazyParams).then((res) => {
            setLoadingList(false)
            if (res) {
                setData(res)
            }
        })
    }

    const createData = () => {
        refDetail.current.create()
    }

    const editData = (_data) => {
        refDetail.current.edit(_data)
    }

    const deleteData = (id) => {
        confirmDialog({
            message: t("company.confirm_content"),
            header: t("company.confirm"),
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            rejectLabel: t("company.cancel"),
            acceptLabel: t("company.confirm"),
            accept: () => {
                ConfigServices.delete(id).then((res) => {
                    if (res) {
                        ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
                        getList()
                    }
                })
            }
            ,
            reject: () => { }
        });

    }
    const afterSubmit = () => {
        getList()
        ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
    }

    const startContent = (
        <React.Fragment>
            <Button
                icon="pi pi-plus"
                className="mr-2"
                label={t("common.add")}
                outlined
                size="small"
                onClick={() => createData()}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.CREATE)}
            />
        </React.Fragment>
    );

    const onChangeKeyword = (e) => {
        let _filter = cloneDeep(lazyParams)
        _filter.keyword = e.target.value
        setLazyParams(_filter)
    }

    const endContent = (
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText placeholder={t("company.search")} value={lazyParams?.keyword} onChange={onChangeKeyword} />
        </span>
    );

    const renderColEnd = (row) => {
        return <span className="flex">
            {/* <Button icon="pi pi-eye" rounded text onClick={() => viewData(row.id)} /> */}
            <Button icon="pi pi-pencil" rounded text onClick={() => editData(row)}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.UPDATE)}
            />
            <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" onClick={() => deleteData(row.key)}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.DELETE)}
            />
        </span>
    }

    if (!checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)) {
        return null
    }

    return (
        <div className="event-layout">
            <div className="event-layout-center overflow-hidden">
                <div className="p-card">
                    <div className="p-card-body">
                        <div className="grid">
                            <div className="col-12">
                                <Toolbar start={startContent} end={endContent} />
                            </div>
                            <div className="col-12">
                                <div className="e-progress-bar">
                                    {loadingList
                                        ? <ProgressBar className="e-progress-bar" mode="indeterminate"></ProgressBar>
                                        : null
                                    }
                                </div>
                                <DataTable
                                    value={data}
                                    dataKey="id"
                                    className="p-datatable-gridlines border-all"
                                    resizableColumns
                                    columnResizeMode="expand"
                                    emptyMessage={t("company.no_data")}
                                    scrollable
                                    scrollDirection="both"
                                    scrollHeight="flex"
                                    lazy
                                // paginator
                                // first={meta?.first || 0}
                                // rows={meta?.size || 20}
                                // totalRecords={meta.total}
                                // rowsPerPageOptions={[10, 20, 25, 50, 100]}
                                // paginatorTemplate="RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink"
                                // currentPageReportTemplate="{first} - {last} of {totalRecords}"
                                // onPage={onPage}
                                // reorderableColumns
                                // reorderableRows
                                >
                                    <Column
                                        style={{ width: 100 }}
                                        field="STT"
                                        body={(index, value) => value?.rowIndex + 1}
                                        header={t("common.no")}
                                    />
                                    <Column
                                        header={t("config.key")}
                                        style={{ minWidth: 250 }}
                                        field="key"
                                    />

                                    <Column
                                        columnKey="action"
                                        alignFrozen="right"
                                        frozen
                                        header={t("common.action")}
                                        style={{ minWidth: 180, width: 180 }}
                                        className="col-frozen-table-end py-1"
                                        body={renderColEnd}
                                    />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <ConfigDetail ref={refDetail} afterSubmit={afterSubmit} />
                </div>
            </div>
        </div>
    )
}

export default Config;
