import i18n from "../i18n"

export const API_STATUS = {
    API_200: 200,
    API_400: 400,
    API_401: 401,
    API_500: 500,
}

export const CANVAS_TYPE = {
    IMAGE: 'image',
    RECT: 'rect',
    CIRCLE: 'circle',
    TRIANGLE: 'triangle',
    POLIGON: 'poligon',
    NODE: "node",
    NODE_DIRECTION: "node_direction"
}

export const ACTION = {
    VIEW: "view",
    UPDATE: "update",
    CREATE: "create"
}

export const EnumEventModule = {
    SESSION: "SESSION",
    COMPANY: "COMPANY",
    PRODUCT: "PRODUCT",
    INDUSTRY: "INDUSTRY",
    POSITION: "POSITION",
    AGENDA: "AGENDA",
    COMPANIES_PRODUCT: "COMPANIES_PRODUCT",
    COMPANIES_INDUSTRY: "COMPANIES_INDUSTRY",
    USER: "USER",
    EVENT: "EVENT"
}

export const UPLOAD_STATUS = {
    NEW: "NEW",
    UPLOADING: "UPLOADING",
    FAIL: "FAIL",
    UPLOADED: "UPLOADED"
}

export const ROUTE = {
    dashboard: '/',
    // user: "/user",
    // cate: "/cate",
    maps: "/maps",
    login: "/login",
    // accounts: "/list-accounts",
    users: "/users",
    ranks: "/ranks",
    company_type: "/company-type",
    faqs: "/faqs",
    group: "/group",
    groupInfo: "/group/:groupId",
    companies: "/list-company",
    events: "/list-events",
    eventInfo: "/event/:eventId",
    eventInfoScreen: "/event/:eventId/:screenId",
    eventCompanyInfo: "/event/:eventId/company/:refId",
    eventCompanyInfoScreen: "/event/:eventId/company/:refId/:screenId",
    // offerAttributes: "/event/:eventId/:screenId/:refType",
    eventSessionInfo: "/event/:eventId/session/:refId",
    eventSessionInfoScreen: "/event/:eventId/session/:refId/:screenId",
    eventProductInfo: "/event/:eventId/product/:refId",
    eventProductInfoScreen: "/event/:eventId/product/:refId/:screenId",
    eventUserInfo: "/event/:eventId/user/:refId",
    eventUserInfoScreen: "/event/:eventId/user/:refId/:screenId",
    configs: "/configs",
}
export const ROUTE_PERMISSION = [
    {
        key: '0',
        code: "dashboard",
        name: "route.dashboard",
        children: []
    },
    {
        key: '1',
        code: "event",
        name: "route.event",
        children: [
            {
                key: '1-0',
                code: "eventInfo",
                name: "route.eventInfo",
                children: []
            },
            {
                key: '1-1',
                code: "eventInfoScreen",
                name: "route.eventInfoScreen",
                children: [
                    {
                        key: '1-1-0',
                        code: "eventInfoScreen_session",
                        name: "route.eventInfoScreen_session",
                        children: [
                            {
                                key: '1-1-0-0',
                                code: "eventInfoScreen_session_info",
                                name: "route.eventInfoScreen_session_info",
                                children: [
                                    {
                                        key: '1-1-0-0-0',
                                        code: "eventInfoScreen_session_speakers",
                                        name: "route.eventInfoScreen_session_speakers",
                                        children: [

                                        ]
                                    },
                                    {
                                        key: '1-1-0-0-0',
                                        code: "eventInfoScreen_session_locations",
                                        name: "route.eventInfoScreen_session_locations",
                                        children: [

                                        ]
                                    },
                                    {
                                        key: '1-1-0-0-0',
                                        code: "eventInfoScreen_session_offer-attributes",
                                        name: "route.eventInfoScreen_session_offer-attributes",
                                        children: [

                                        ]
                                    },
                                    {
                                        key: '1-1-0-0-0',
                                        code: "eventInfoScreen_session_permalinks",
                                        name: "route.eventInfoScreen_session_permalinks",
                                        children: [

                                        ]
                                    },
                                    {
                                        key: '1-1-0-0-5',
                                        code: "eventInfoScreen_session_reference",
                                        name: "route.eventInfoScreen_session_reference",
                                        children: [

                                        ]
                                    },

                                ]
                            },
                        ]
                    },
                    {
                        key: '1-1-1',
                        code: "eventInfoScreen_banners",
                        name: "route.eventInfoScreen_banners",
                        children: [

                        ]
                    },
                    {
                        key: '1-1-2',
                        code: "eventInfoScreen_maps",
                        name: "route.eventInfoScreen_maps",
                        children: [

                        ]
                    },
                    {
                        key: '1-1-3',
                        code: "eventInfoScreen_permalink",
                        name: "route.eventInfoScreen_permalink",
                        children: [

                        ]
                    },
                    {
                        key: '1-1-3.5',
                        code: "eventInfoScreen_user_type",
                        name: "route.eventInfoScreen_user_type",
                        children: [

                        ]
                    },
                    
                    {
                        key: '1-1-4',
                        code: "eventInfoScreen_companies",
                        name: "route.eventInfoScreen_companies",
                        children: [
                            {
                                key: '1-1-4-0',
                                code: "eventInfoScreen_companies_locations",
                                name: "route.eventInfoScreen_companies_locations",
                                children: [

                                ]
                            },
                            {
                                key: '1-1-4-1',
                                code: "eventInfoScreen_companies_files",
                                name: "route.eventInfoScreen_companies_files",
                                children: [

                                ]
                            },
                            {
                                key: '1-1-4-2',
                                code: "eventInfoScreen_companies_users",
                                name: "route.eventInfoScreen_companies_users",
                                children: [

                                ]
                            },
                            {
                                key: '1-1-4-3',
                                code: "eventInfoScreen_companies_cms-users",
                                name: "route.eventInfoScreen_companies_cms-users",
                                children: [

                                ]
                            },
                            {
                                key: '1-1-4-4',
                                code: "eventInfoScreen_companies_offer-attributes",
                                name: "route.eventInfoScreen_companies_offer-attributes",
                                children: [

                                ]
                            },
                        ]
                    },
                    {
                        key: '1-1-5',
                        code: "eventInfoScreen_user",
                        name: "route.eventInfoScreen_user",
                        children: [
                            {
                                key: '1-1-5-0',
                                code: "eventInfoScreen_user_offer-attributes",
                                name: "route.eventInfoScreen_user_offer-attributes",
                                children: [

                                ]
                            },
                        ]
                    },
                    {
                        key: '1-1-6',
                        code: "eventInfoScreen_products",
                        name: "route.eventInfoScreen_products",
                        children: [

                            {
                                key: '1-1-6-0',
                                code: "eventInfoScreen_products_offer-attributes",
                                name: "route.eventInfoScreen_products_offer-attributes",
                                children: [

                                ]
                            },
                            {
                                key: '1-1-6-1',
                                code: "eventInfoScreen_products_files",
                                name: "route.eventInfoScreen_products_files",
                                children: [

                                ]
                            },
                        ]
                    },
                    {
                        key: '1-1-7',
                        code: "eventInfoScreen_notification",
                        name: "route.eventInfoScreen_notification",
                        children: [

                        ]
                    },
                    {
                        key: '1-1-8',
                        code: "offer-attribute",
                        name: "route.offer-attribute",
                        children: [

                        ]
                    },
                    {
                        key: '1-1-9',
                        code: "route.eventInfoScreen_business-matching",
                        name: "common.route.event_info.business-matching",
                        children: [

                        ]
                    },
                    {
                        key: '1-1-9',
                        code: "route.eventInfoScreen_sort",
                        name: "common.route.event_info.sort",
                        children: [

                        ]
                    },
                ]
            }
        ]
    },
    {
        key: '2',
        code: "company",
        name: "route.company",
        children: [
        ]
    },
    {
        key: '3',
        code: "users",
        name: "route.users",
        children: [
        ]
    },
    {
        key: '4',
        code: "ranks",
        name: "route.ranks",
        children: [
        ]
    },
    {
        key: '5',
        code: "company-type",
        name: "route.company-type",
        children: [
        ]
    },
    // {
    //     key: '6',
    //     code: "offer-attribute",
    //     name: "route.offer-attribute",
    //     children: [
    //     ]
    // },
    // {
    //     key: '7',
    //     code: "maps",
    //     name: "route.maps",
    //     children: [
    //     ]
    // },
    {
        key: '8',
        code: "faqs",
        name: "route.faqs",
        children: [
        ]
    },
    {
        key: '9',
        code: "configs",
        name: "route.configs",
        children: [
        ]
    }
]

export const ROUTE_SCREEN = {
    eventInfoScreen: {
        "companies": "common.route.event_info.companies",
        "sessions": "common.route.event_info.sessions",
        "products": "common.route.event_info.products",
        "banners": "common.route.event_info.banners",
        "maps": "common.route.event_info.maps",
        "permalink": "common.route.event_info.permalink",
        "user": "common.route.event_info.user",
        "notification": "common.route.event_info.notification",
        "offer-attribute-industry": "common.route.event_info.offer-attribute-industry",
        "offer-attribute-product": "common.route.event_info.offer-attribute-product",
        "offer-attribute-position": "common.route.event_info.offer-attribute-position",
        "offer-attribute-agenda": "common.route.event_info.offer-attribute-agenda",
        "user-type": "common.route.event_info.user_type",
        "business-matching": "common.route.event_info.business-matching",
        "sort": "common.route.event_info.sort",
    },
    eventCompanyInfoScreen: {
        "locations": "common.route.event_company_info.locations",
        "files": "common.route.event_company_info.files",
        "users": "common.route.event_company_info.users",
        "cms-users": "common.route.event_company_info.cms-users",
        "offer-attribute-industry": "common.route.event_info.offer-attribute-industry",
        "offer-attribute-product": "common.route.event_info.offer-attribute-product"
    },
    eventSessionInfoScreen: {
        "speakers": "common.route.event_session_info.speakers",
        "locations": "common.route.event_session_info.locations",
        // "offer-attributes": "common.route.event_session_info.offer-attributes",
        "permalinks": "common.route.event_info.permalink",
        "offer-attribute-industry": "common.route.event_info.offer-attribute-industry",
        "offer-attribute-product": "common.route.event_info.offer-attribute-product",
        "offer-attribute-agenda": "common.route.event_info.offer-attribute-agenda"
    },
    eventProductInfoScreen: {
        "offer-attributes": "common.route.event_product_info.offer-attributes",
        "files": "common.route.event_product_info.files",
        "locations": "common.route.event_product_info.locations",
        "offer-attribute-product": "common.route.event_info.offer-attribute-product"
    },
    eventUserInfoScreen: {
        "offer-attribute-industry": "common.route.event_info.offer-attribute-industry",
        "offer-attribute-product": "common.route.event_info.offer-attribute-product"
    }
}

export const FILE_TYPE = {
    IMAGE: 'image/*',
    PNG: 'image/png'
}

export const USER_PERMISSION = {
    ADMIN: 1,
    ORG: 2,
    COMPANY: 3,
}

export const USER_TYPE = {
    COMPANY: "COMPANY",
    USER_COMPANY: "USER_COMPANY",
    GUEST: "GUEST",
    ORG: "ORG",
    SPEAKER: "SPEAKER"
}

export const USER_TYPES = [
    {
        id: "GUEST",
        name: "common.user_type.guest"
    },
    {
        id: "USER_COMPANY",
        name: "common.user_type.user_company"
    },
]

export const USER_COMPANY_PERMISSIONS = [
    {
        name: "common.user_permission.company",
        id: USER_TYPE.COMPANY
    },
    {
        name: "common.user_permission.staff",
        id: USER_TYPE.USER_COMPANY
    },
]

export const USER_TYPES_TAB = [
    {
        name: "common.user_type.org",
        value: USER_TYPE.ORG
    },
    {
        name: "common.user_type.guest",
        value: USER_TYPE.GUEST
    },
    {
        name: "common.user_type.user_company",
        value: USER_TYPE.USER_COMPANY //[USER_TYPE.COMPANY, USER_TYPE.USER_COMPANY, USER_TYPE.SPEAKER]
    },
]

export const EXCEL_SHEET = {
    VISISTOR: "Visistor",
    BUYERS: "Buyers",
    ORGANISERS: "Organisers",
    EXHIBITORS: "Exhibitors",
    SPEAKERS: "Speakers",
    BUSINESS_MATCHING: "Business Matching"
}
export const EXCEL_CONFIG = [
    {
        name: "Visistor",
        type: USER_TYPE.GUEST
    },
    {
        name: "Buyers",
        type: USER_TYPE.GUEST
    },
    {
        name: "Organisers",
        type: USER_TYPE.ORG
    },
    {
        name: "Exhibitors",
        type: USER_TYPE.COMPANY
    },
    {
        name: "Speakers",
        type: USER_TYPE.SPEAKER
    },
]

export const EXCEL_BUSINESS_MATCHING_CONFIG_FIELD = {
    no: i18n.t("business_matching.excel.no"),
    date: i18n.t("business_matching.excel.date"),
    to: i18n.t("business_matching.excel.to"),
    industry: i18n.t("business_matching.excel.industry"),
    website: i18n.t("business_matching.excel.website"),
    location: i18n.t("business_matching.excel.location"),
    status: i18n.t("business_matching.excel.status"),
}

export const EXCEL_CONFIG_FIELD = {
    passCode: "Pass Code",

    companyImport: "Company Name",

    companyWebsite: "Company URL",

    companyDescription: "Company Description",

    userPhone: "Phone",

    userEmail: "Email",

    userFullName: "Full Name",

    userPosition: "Job Title",

    mapsImport: "Meeting Location",

    locationIdsImport: "Meeting Room",

    countryImport: "Country",

    userIndustry: "Industry",

    userProduct: "Product Offer",

    userIndustryEn: "Industry / EN",

    userProductEn: "Product Offer / EN",

    companyRank: "Company Type",

    companyVideo: "Video URL",

    sessionImport: "Session",

    userSessionImport: "Quote",

    companyPhone: "Company Phone",

    companyEmail: "Company Email",

    companyCode: "Company Code",

    companyIndustry: "Company Industry",

    companyIndustryEn: "Company Industry / EN",

    companyProduct: "Company Product Offer",

    companyProductEn: "Company Product Offer / EN",

    // for company
    companyImportEn: "Company Name / EN",
    companyImportVi: "Company Name / VI",
    companyDescriptionEn: "Company Description / EN",
    companyDescriptionVi: "Company Description / VI",
}

export const USER_PERMISSIONS = [
    {
        id: USER_PERMISSION.ORG,
        name: "common.permissions.org"
    },
    {
        id: USER_PERMISSION.COMPANY,
        name: "common.permissions.company"
    }
]

export const EVENT_STATUS_TYPE = {
    CREATE: "CREATE",
    LAUNCHPAD: "LAUNCHPAD",
    ENGAGEMENT: "ENGAGEMENT",
    LAST_MILE: "LASTMILE",
    DURING_EVENT: "DURINGEVENT",
    COMPLETE: "COMPLETE",
}


export const EVENT_STATUS = [
    {
        id: EVENT_STATUS_TYPE.CREATE,
        name: "dashboard.steps.create"
    },
    {
        id: EVENT_STATUS_TYPE.LAUNCHPAD,
        name: "dashboard.steps.launchpad"
    },
    {
        id: EVENT_STATUS_TYPE.ENGAGEMENT,
        name: "dashboard.steps.engagement"
    },
    {
        id: EVENT_STATUS_TYPE.LAST_MILE,
        name: "dashboard.steps.last_mile"
    },
    {
        id: EVENT_STATUS_TYPE.DURING_EVENT,
        name: "dashboard.steps.during_event"
    },
    {
        id: EVENT_STATUS_TYPE.COMPLETE,
        name: "dashboard.steps.complete"
    }
]

export const PERMALINK_TYPE = {
    AGENDA: "AGENDA",
    COMPANY: "COMPANY",
    PRODUCT: "PRODUCT",
    SPONSER: "SPONSER",
    SPEAKER: "SPEAKER",
    EXTERNAL: "EXTERNAL"
}
export const PERMALINK_TYPES = [{
    id: PERMALINK_TYPE.AGENDA,
    name: "permalink.types.agenda"
},
{
    id: PERMALINK_TYPE.COMPANY,
    name: "permalink.types.company"
},
{
    id: PERMALINK_TYPE.PRODUCT,
    name: "permalink.types.product"
},
{
    id: PERMALINK_TYPE.SPONSER,
    name: "permalink.types.sponser"
},
{
    id: PERMALINK_TYPE.SPEAKER,
    name: "permalink.types.speaker"
},
{
    id: PERMALINK_TYPE.EXTERNAL,
    name: "permalink.types.external"
}]

export const PERMALINK_APPLICATION = {
    WEB: "WEB",
    MOBILE: "MOBILE",
    ANDROID: "ANDROID",
    IOS: "IOS"
}

export const APPLICATIONS = [{
    id: PERMALINK_APPLICATION.WEB,
    name: "permalink.web"
},
{
    id: PERMALINK_APPLICATION.MOBILE,
    name: "permalink.mobile"
}
]

export const PERMALINK_APPLICATIONS = [{
    id: PERMALINK_APPLICATION.WEB,
    name: "permalink.web"
},
{
    id: PERMALINK_APPLICATION.MOBILE,
    name: "permalink.mobile"
},
{
    id: PERMALINK_APPLICATION.ANDROID,
    name: "permalink.android"
},
{
    id: PERMALINK_APPLICATION.IOS,
    name: "permalink.ios"
}]

export const FAQ_TYPE = [
    "REGISTER",
    "FAQ"
]

export const EVENT_SETTING = {
    REGISTER: "REGISTER",
    AGENDA: "AGENDA",
    MEET: "MEET",
    MAPS: "MAPS",
    COLOR: "COLOR"
}

export const DATA_TYPE = {
    BOOLEAN: "BOOLEAN",
    STRING: "STRING",
    COLOR: "COLOR"
}

export const EVENT_SETTINGS = [
    {
        id: EVENT_SETTING.COLOR,
        name: "event.settings.color",
        dataType: DATA_TYPE.COLOR,
        value: "#ff0000"
    },
    {
        id: EVENT_SETTING.REGISTER,
        name: "event.settings.register",
        dataType: DATA_TYPE.BOOLEAN,
        value: true
    },
    {
        id: EVENT_SETTING.AGENDA,
        name: "event.settings.agenda",
        dataType: DATA_TYPE.BOOLEAN,
        value: true
    },
    {
        id: EVENT_SETTING.MEET,
        name: "event.settings.meet",
        dataType: DATA_TYPE.BOOLEAN,
        value: true
    },
    {
        id: EVENT_SETTING.MAPS,
        name: "event.settings.maps",
        dataType: DATA_TYPE.BOOLEAN,
        value: true
    }
]

export const PERMISSION = {
    VIEW: "view",
    CREATE: "create",
    UPDATE: "update",
    DELETE: "delete",
}

export const PERMISSIONS = [
    {
        id: PERMISSION.VIEW,
        name: "common.view"
    },
    {
        id: PERMISSION.CREATE,
        name: "common.create"
    },
    {
        id: PERMISSION.UPDATE,
        name: "common.update"
    },
    {
        id: PERMISSION.DELETE,
        name: "common.delete"
    },
]


export const NOTIFICATION_TYPE = {
    list: [
        {
            id: "SESSION",
            name: "common.notify_type.session"
        },
    ],
    SESSION: "SESSION"
}

export const MEET_OPTION =
{
    ONSITE: "ONSITE",
    ONLINE_WITH_VIDEO: "ONLINE_WITH_VIDEO",
    ONLINE_OR_OFFLINE: "ONLINE_OR_OFFLINE",
    CHAT: "CHAT"
}

export const MEET_OPTIONS = [
    {
        id: MEET_OPTION.ONSITE,
        name: "common.onsite"
    },
    {
        id: MEET_OPTION.ONLINE_WITH_VIDEO,
        name: "common.online"
    },
    {
        id: MEET_OPTION.ONLINE_OR_OFFLINE,
        name: "common.onsite_or_online"
    }
]

export const MEET_STATUS = {
    list: [
        {
            id: null,
            name: "common.meeting.none"
        },
        {
            id: 1,
            name: "common.meeting.accept"
        },
        {
            id: 2,
            name: "common.meeting.waiting"
        },
        {
            id: 4,
            name: "common.meeting.reject"
        }
    ],
    NONE: null,
    ACCEPT: 1,
    WAITING: 2,
    REJECT: 4
}

export const REFERENCE_TYPE = {
    list: [
        {
            id: EnumEventModule.PRODUCT,
            name: "references.product"
        },
        {
            id: EnumEventModule.USER,
            name: "references.user"
        },
    ],
}

export const SORT_TYPE = {
    list: [
        {
            id: "ALL",
            name: "common.all"
        },
        {
            id: "INDUSTRY",
            name: "common.industry"
        },
    ],
    ALL: "ALL",
    INDUSTRY: "INDUSTRY"
}


export const BUSINESS_MATCHING_ROLE = {
    list: [
        {
            id: "SEND",
            name: "common.send"
        },
        {
            id: "RECEIVE",
            name: "common.receive"
        }
    ],
    SEND: "SEND",
    RECEIVE: "RECEIVE"
}

export const USER_TYPE_NEW = {
    
    ORG: "ORG",
    GUEST: "GUEST",
    COMPANY: "COMPANY"
}

