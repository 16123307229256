import axiosClient from "./axiosClient";

const ImportServices = {

    create: (id, payload) => {
        return axiosClient.post(`/import/${id}/user`, payload);
    },
    companyCreate: (id, payload) => {
        return axiosClient.post(`/import/${id}/company`, payload);
    },
}

export default ImportServices