import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SpeedDial } from 'primereact/speeddial';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import RankDetail from "./components/RankDetail";
import RanksServices from "../../core/services/RanksServices";
import { Badge } from "react-bootstrap";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { confirmDialog } from "primereact/confirmdialog";
import { ToastHelper } from "../../helper/ToastHelper";
import _, { find, cloneDeep } from "lodash";
import EBreadCrumb from "../../components/EBreadCrumb";
import { PERMISSION, USER_PERMISSION } from "../../utils/constants";
// import ListAccountsDetail from "./ListAccountsDetail";
import { useSelector } from 'react-redux';
import { checkPermission, findAttribute } from "../../utils";
import { ProgressBar } from "primereact/progressbar";

function Ranks({ isDisplay, screenPermission }) {
    const { t } = useTranslation()

    const { userInfo, groupPermission, language } = useSelector((state) => state.userReducer)

    const [lazyParams, setLazyParams] = useState({
        keyword: ""
    })
    const [loading, setLoading] = useState(false);
    const refDetail = useRef()
    const [ranks, setRanks] = useState(null)

    const [loadingList, setLoadingList] = useState(false)

    useEffect(() => {
        prepareRanks()
    }, [isDisplay])

    const permission = useMemo(() => {
        return parseInt(userInfo?.permission ?? "-1")
    }, [userInfo])

    const prepareRanks = async () => {
        setLoadingList(true)
        await RanksServices.get().then(res => {
            setLoadingList(false)
            if (res) {
                setRanks(_.filter(res, { isDisplay }))
            }
        })
    }

    const createRank = () => {
        refDetail.current.create()
    }

    const EditRank = (id) => {
        refDetail.current.edit(id)
    }

    const ViewRank = () => {
        refDetail.current.view()
    }

    const deleteRank = (id) => {
        confirmDialog({
            message: t("common.confirm_content"),
            header: t("common.confirm"),
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            rejectLabel: t("common.cancel"),
            acceptLabel: t("common.confirm"),
            accept: () => {
                try {
                    RanksServices.delete(id).then(res => {
                        if (res) {
                            prepareRanks()
                            ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: 'Success' });
                        }
                    })
                } catch (error) {
                    ToastHelper.show({ severity: 'error', summary: t("common.error"), detail: 'Error' });
                }
            },
            reject: () => { }
        });
    }

    const renderColEnd = (row) => {
        return <span>
            <Button icon="pi pi-pencil" rounded text aria-label="Filter" onClick={() => EditRank(row.id)}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.UPDATE)}
            />
            <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" onClick={() => deleteRank(row.id)}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.DELETE)}
            />
        </span>
    }

    const startContent = (
        <React.Fragment>
            <Button
                icon="pi pi-plus"
                className="mr-2"
                label={t("common.add")}
                outlined
                size="small"
                onClick={createRank}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.CREATE)}
            />
        </React.Fragment>
    );

    const onChangeKeyword = (e) => {
        let _filter = cloneDeep(lazyParams)
        _filter.keyword = e.target.value
        setLazyParams(_filter)
    }

    const endContent = (
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText placeholder={t("company.search")} value={lazyParams?.keyword} onChange={onChangeKeyword} />
        </span>
    );

    const renderCol0 = (d, value) => {
        let _info = find(d.languages, { languageCode: language })
        return (
            <span>
                {_info?.name}
            </span>
        )
    }

    const previewList = useMemo(() => {
        return findAttribute(ranks, lazyParams.keyword, [], "languages")
    }, [lazyParams, ranks])

    if (!checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)) {
        return null
    }

    return (
        <div className="event-layout">
            <div className="event-layout-center overflow-hidden">
                <div className="p-card">
                    <div className="p-card-body">
                        <div className="grid">
                            <div className="col-12">
                                <Toolbar start={startContent} end={endContent} />
                            </div>
                            <div className="col-12">
                                <div className="e-progress-bar">
                                    {loadingList
                                        ? <ProgressBar className="e-progress-bar" mode="indeterminate"></ProgressBar>
                                        : null
                                    }
                                </div>
                                <DataTable
                                    value={previewList}
                                    // paginator
                                    showGridlines
                                    rows={10}
                                    loading={loading}
                                    dataKey="id"
                                    // filters={filters}
                                    // globalFilterFields={['name', 'country.name', 'representative.name', 'balance', 'status']}
                                    emptyMessage="No customers found."
                                >
                                    <Column
                                        style={{ width: 100 }}
                                        field="STT"
                                        body={(index, value) => value?.rowIndex + 1}
                                        header={t("common.no_")}
                                    />
                                    <Column
                                        showFilterMenu={false}
                                        showClearButton={false}
                                        header={t("common.name")}
                                        style={{ flex: "1 0 180px" }}
                                        body={renderCol0}
                                    />
                                    {isDisplay
                                        ? <Column
                                            header={t("common.color")}
                                            style={{ minWidth: 250 }}
                                            field="color"
                                            body={d => <div className="p-3 border-round-md" style={{ backgroundColor: `${d.color}` }}></div>}
                                        />
                                        : null
                                    }
                                    <Column
                                        header={t("ranks.sortOrder")}
                                        style={{ minWidth: 250 }}
                                        field="sortOrder"
                                    />

                                    <Column
                                        className="py-1"
                                        header={t("common.action")}
                                        style={{ minWidth: 180, width: 180 }}
                                        body={d => renderColEnd(d)} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <RankDetail ref={refDetail} setRanks={setRanks} isDisplay={isDisplay}></RankDetail>
                </div>
            </div>
        </div>
    )
}

export default Ranks;
