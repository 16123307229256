import axiosClient from "../core/services/axiosClient";


const AuthServices = {
  login: (params) => {
    return axiosClient.post(`/auth/login?`, params);
  },
  register: (params) => {
    return axiosClient.post(`/auth/register?module=WEB`, params);
  },
  postEvent: (params) => {
    return axiosClient.post(`/auth/event?`, params);
  },
}

export default AuthServices