import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SpeedDial } from 'primereact/speeddial';
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import "./styles.scss"
import { ColorPicker } from "primereact/colorpicker";
import EDialog from "../../../../components/EDialog";
import RanksServices from "../../../../core/services/RanksServices";
import _ from "lodash";
import { ToastHelper } from "../../../../helper/ToastHelper";
import { FAQ_TYPE } from "../../../../utils/constants";
import FaqsServices from "../../../../core/services/FaqsServices";
import { TabPanel, TabView } from "primereact/tabview";
import { useSelector } from "react-redux";
import { classNames } from "primereact/utils";
import { v4 as uuidv4 } from 'uuid'

function FaqsDetail(props, ref) {
    const { t } = useTranslation()

    const languages = useSelector((state) => state.userReducer.languages)
    const [activeIndex, setActiveIndex] = useState(0);

    const { afterSubmit } = props;
    const refDialog = useRef(null)
    const refEditMode = useRef(null);
    useImperativeHandle(ref, () => ({
        create: () => {
            refEditMode.current = "create"
            // setFaq(emptyFaq)
            let _data = _.cloneDeep(emptyFaq)
            _data.answers.push({
                rowIndex: uuidv4(),
                attributes: []
            })
            languages?.map((item) => {
                _data.attributes.push({
                    languageCode: item.code,
                    languagesname: item.name,
                    name: "",
                    isNew: true
                })
                _data.answers[0].attributes.push({
                    languageCode: item.code,
                    languagesname: item.name,
                    name: "",
                    isNew: true
                })
            })

            setFaq(_data)
            setFaqValid(emptyFaqValidate)
            refDialog.current.create()
        },
        edit: (data) => {
            refEditMode.current = "update"
            let _data = _.cloneDeep(data)
            _data.attributes = []
            // _data.answers.push({
            //     rowIndex: uuidv4(),
            //     attributes: []
            // })
            languages?.map((item) => {
                let lan = _data.languages.find(o => item.code == o.languageCode)
                _data.attributes.push({
                    languageCode: item.code,
                    languagesname: item.name,
                    name: lan?.name ?? "",
                })
            })
            let _answers = []
            _data.answers.map(o => {
                _answers.push({
                    id: o.id,
                    rowIndex: uuidv4(),
                    attributes: []
                })
                languages?.map((item) => {
                    let lan = o.languages.find(o => item.code == o.languageCode)
                    _answers[_answers.length - 1].attributes.push({
                        languageCode: item.code,
                        languagesname: item.name,
                        name: lan?.name ?? "",
                    })
                })
            })
            _data.answers = _answers

            setFaq(_data)
            // setFaqValid(emptyFaqValidate)
            validEdit(data)
            refDialog.current.create()
        },
        view: () => {
            refEditMode.current = "view"
            refDialog.current.create()
        }
    }));
    // const getbyID = async (id) => {
    //     await RanksServices.getById(id).then(res => {
    //         if (res) {
    //             setFaq(res)
    //         }
    //     })
    // }

    const validEdit = (data) => {
        let _valid = emptyFaqValidate;
        let _answers = []
        data?.answers.map((item) => {
            _answers.push({ "title": null })
        })
        _valid.answers = _answers;
        setFaqValid(_valid)
    }
    let emptyFaqValidate = {
        "type": null,
        "question": null,
        "answers": [
            {
                "title": null
            },
        ]
    };

    let emptyFaq = {
        type: null,
        attributes: [],
        answers: []
    }

    const [faq, setFaq] = useState(emptyFaq)
    const [faqValid, setFaqValid] = useState(emptyFaqValidate)

    const cancel = () => {
        refDialog.current.close();
    }


    const save = async () => {
        let isValid = performValidate([]);

        if (isValid.isValid) {
            // call api
            try {
                if (refEditMode.current == "create") {

                    await FaqsServices.create(faq).then(res => {
                        refDialog.current.close();
                        ToastHelper.show({ severity: 'success', summary: t("common.success") });
                        afterSubmit()
                    })

                } else {
                    await FaqsServices.update(faq.id, faq).then(res => {
                        // loadRanks()
                        refDialog.current.close();
                        ToastHelper.show({ severity: 'success', summary: t("common.success") });
                        afterSubmit()
                    })

                }

            } catch (error) {
                ToastHelper.show({ severity: 'error', summary: t("common.error") });
            }
        } else {
            ToastHelper.show({ severity: 'error', summary: t("common.error"), detail: t("error.required_full") });
        }
    }

    const applyChange = (prop, value, propArr, rowIndex, languageCode) => {
        let _current = _.cloneDeep(faq);

        switch (prop) {
            case "attributes":
                    const alangIndex = _.findIndex(_current[prop], {languageCode})
                    if (alangIndex != -1) {
                        _current[prop][alangIndex][propArr] = value;
                    }
            break
            case "answers":
                const index = _.findIndex(_current[prop], {rowIndex})
                if (index != -1) {
                    const langIndex = _.findIndex(_current[prop][index].attributes, {languageCode})
                    if (langIndex != -1) {
                        _current[prop][index].attributes[langIndex][propArr] = value;
                    }
                }
                break
            default:
                _current[prop] = value
                break;
        }

        setFaq(_current);
        performValidate([prop], _current)
    }
    const performValidate = (props, _checkObject, propArr, index) => {
        let result = { ...faqValid }, isValid = true, _errors = [];
        let _object = _checkObject ? _checkObject : faq;
        // validate all props
        if (props.length === 0) {
            for (const property in result) {
                props.push(property);
            }
        }

        // validate props
        props.forEach(prop => {
            switch (prop) {
                case 'type':
                    result[prop] = _object.type ? null : t("message.validate_required");
                    break;
                
                default:
                    break;
            }
        });

        // set state
        setFaqValid(result);


        for (const property in result) {
            if (result[property]) {
                if (property != "answers") {
                    isValid = false;
                    _errors.push(result[property]);
                } else {
                    result[property].map((i, index) => {
                        // if (result[property][index].title != null || result[property][index].languageCode != null) {
                        if (result[property][index].title != null) {
                            isValid = false;
                        }
                    })
                }
                break;
            }
        }

        return { isValid: isValid, errors: _errors };
    }

    const loadFaqs = async () => {
        await RanksServices.get().then(res => {
            if (res) {
                setFaq(res)
            }
        })
    }

    const header = () => {
        let headerMode = refEditMode.current
        if (headerMode === 'create') {
            return t('common.create') + " "  + (t("common.faqs").toLowerCase())
        }
        if (headerMode === 'update') {
            return t('common.update') + " "  + (t("common.faqs").toLowerCase())
        }
        if (headerMode === 'view') {
            return t('common.view') + " "  + (t("common.faqs").toLowerCase())
        }
    }

    const addAttributes = () => {
        let _emptyAttributes = {
            rowIndex: uuidv4(),
            attributes: []
        }
        languages?.map((item) => {
            _emptyAttributes.attributes.push({
                languageCode: item.code,
                languagesname: item.name,
                name: "",
                isNew: true
            })
        })
        let _emptyValidAttributes = {
            "answers": null
        }
        let _data = structuredClone(faq)
        let _valid = structuredClone(faqValid)
        _data.answers.push(_emptyAttributes)
        _valid.answers.push(_emptyValidAttributes)
        setFaq(_data)
        setFaqValid(_valid)
    }

    const removeAttributes = (index) => {
        let _data = structuredClone(faq)
        let _valid = structuredClone(faqValid)
        if (_data.answers.length > 1) {
            _data.answers.splice(index, 1)
            _valid.answers.splice(index, 1)
            setFaq(_data)
            // setValid(_valid)
        }
    }

    const renderAnswer = (item, index) => {
        return <div className="col-12" key={item?.rowIndex}>
            <div className="grid">
                <div className="col-12 mb-3 mt-3">
                    {/* <span className="">{index}</span> */}
                    <Toolbar
                        className="px-3 py-1"
                        start={<React.Fragment>
                            <span className="font-bold">{t("faqs.answers_number", { num: index + 1 })}</span>
                        </React.Fragment>
                        }
                        end={<React.Fragment>
                            <Button
                                icon="pi pi-plus"
                                rounded text
                                severity="success"
                                aria-label="Cancel"
                                disabled={refEditMode.current == "view"}
                                onClick={addAttributes}
                            />
                            <Button
                                icon="pi pi-times"
                                rounded text
                                severity="danger"
                                aria-label="Cancel"
                                disabled={refEditMode.current == "view"}
                                onClick={() => removeAttributes(item.rowIndex)}
                            />
                        </React.Fragment>
                        } />
                </div>
                {item?.attributes?.map(renderAnswerItem(item.rowIndex))}
            </div>

        </div>

    }

    const renderAnswerItem = (rowIndex) => (item) => {
        return (
            <div className="col-12 field" key={item?.languagesname}>
                <label>{item?.languagesname}</label>
                <InputText
                    className="w-full"
                    value={item?.name}
                    disabled={refEditMode.current == "view"}
                    onChange={(e) => { applyChange("answers", e.target.value, "name", rowIndex, item?.languageCode) }}
                />
            </div>
        )
    }


    return (
        <EDialog
            ref={refDialog}
            title={header()}
            closable={true}
            hidebtnFooter={false}
            btnFooter={[
                {
                    label: t("common.cancel"),
                    onClick: () => cancel(),
                    text: true
                },
                {
                    label: t("common.save"),
                    onClick: () => save(),
                }
            ]}
        >
            <div className="container">
                <div class="formgrid grid">
                    {/* <div class="field col-6">
                        <label htmlFor="question" className="require">{t("faqs.question")}: </label>
                        <InputText
                            value={faq?.question}
                            className="w-full"
                            onChange={(e) => applyChange("question", e.target.value)}
                        ></InputText>
                        <span className='event-color-primary-500 text-sm'>{faqValid.question || ""}</span>
                    </div> */}
                    <div class="field col-6">
                        <label htmlFor="type" className="require">{t("faqs.type")}: </label>
                        <Dropdown
                            value={faq?.type}
                            onChange={(e) => applyChange("type", e.target.value)}
                            options={FAQ_TYPE}
                            disabled={refEditMode.current == "view"}
                            className="w-full"
                        />
                        <span className='event-color-primary-500 text-sm'>{faqValid.type || ""}</span>
                    </div>
                    <div className="col-12 flex flex-column">
                        <b className="mb-2">{t("event.attributes")}</b>
                        <div className="w-full">
                            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                {faq?.attributes?.map((item) => {
                                    return (
                                        <TabPanel header={item?.languagesname}>
                                            <div className="grid">
                                                <div className="col-12 field">
                                                    <label>{t("event.name")}</label>
                                                    <InputText
                                                        value={item?.name}
                                                        onChange={(e) => { applyChange("attributes", e.target.value, "name", null, item.languageCode) }}
                                                        className={classNames({ "w-full": true })}
                                                    />
                                                </div>
                                            </div>
                                        </TabPanel>
                                    )
                                })}
                            </TabView>
                        </div>
                    </div>
                    {/* <div className="field col-12">
                    <Toolbar start={startContent} end={endContent} />
                    </div> */}
                    {faq?.answers.map(renderAnswer)}
                    {/* <div className="field col-12">
                        {faq?.answers.map(renderAnswer)} */}

                    {/* <DataTable
                            value={faq?.answers}
                            dataKey="id"
                            className="p-datatable-gridlines border-all"
                            resizableColumns
                            columnResizeMode="expand"
                            emptyMessage={t("common.no_data")}
                            cellClassName="p-1"
                            scrollable
                            scrollDirection="both"
                            scrollHeight="flex"
                            lazy
                        >

                            <Column
                                header={t("faqs.answers")}
                                field="answers"
                                body={(e, row) => {
                                    return (
                                        <div className="relative">

                                            <InputText
                                                // disabled={action === "view"}
                                                className="w-full shadow-none border-0"
                                                value={e?.title}
                                                disabled={refEditMode.current == "view"}
                                                onChange={(e) => { applyChange("answers", e.target.value, "title", row.rowIndex) }}
                                            // className={classNames({ "w-full": true, "p-invalid": error["languageCode"] })}
                                            ></InputText>
                                            <div>
                                                <span className='event-color-primary-500 text-sm'>{faqValid.answers[row.rowIndex]?.title || ""}</span>
                                            </div>
                                        </div>
                                    )
                                }}
                            />

                            <Column
                                columnKey="action"
                                alignFrozen="right"
                                frozen
                                header={() => {
                                    return (
                                        <>
                                            <Button
                                                icon="pi pi-plus"
                                                rounded text
                                                severity="success"
                                                aria-label="Cancel"
                                                disabled={refEditMode.current == "view"}
                                                onClick={addAttributes}
                                            // onClick={() => deleteRank(row.id)}
                                            />
                                        </>)
                                }}
                                headerClassName="flex align-items-center justify-content-center"
                                // style={{ minWidth: 180, width: 180 }}
                                className="col-frozen-table-end"
                                body={(d, row) => {
                                    return (
                                        <div className="w-full h-full flex align-items-center justify-content-center">
                                            <Button
                                                icon="pi pi-times"
                                                rounded text
                                                severity="danger"
                                                aria-label="Cancel"
                                                disabled={refEditMode.current == "view"}
                                                onClick={() => removeAttributes(row.rowIndex)}
                                            />
                                        </div>
                                    )
                                }}
                            />
                        </DataTable> */}
                    {/* </div> */}

                </div>

            </div>
        </EDialog>

    )
}
FaqsDetail = forwardRef(FaqsDetail)
export default FaqsDetail;
