import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from "primereact/dropdown";
import _ from "lodash";
import { Calendar } from "primereact/calendar";
import EDialog from "../../../../components/EDialog";
import { ToastHelper } from "../../../../helper/ToastHelper";
import { useSelector } from "react-redux";
import EventsServices from "../../../../core/services/EventsServices";
import { FILE_TYPE, NOTIFICATION_TYPE, UPLOAD_STATUS } from "../../../../utils/constants";
import S3FileUpload from "../../../../components/S3FileUpload";
import moment from "moment";
import { Editor } from 'primereact/editor';
import { TabPanel, TabView } from "primereact/tabview";
import { classNames } from "primereact/utils";
import CKEditorDocumentEditor from "../../../../components/CKEditorDocumentEditor";
import { cloneDeep } from 'lodash'
import { InputTextarea } from "primereact/inputtextarea";
import UserServices from "../../../../core/services/UserServices";

function EventNotificationEmailDetail(props, ref) {
    const { t } = useTranslation()

    const [saveLoading, setSaveLoading] = useState(false)
    const { event, sessions, afterSubmit } = props;
    let emptyValidate = {
        title: "",
        message: "",
    };
    let emptyData = {
        title: null,
        message: null
    };

    const [data, setData] = useState(emptyData)
    const [valid, setValid] = useState(emptyValidate)

    const s3FileUploadRef = useRef()

    const { languages } = useSelector((state) => state.userReducer)
    const refDialog = useRef(null)
    const refEditMode = useRef(null);
    const [currentId, setCurrentId] = useState(null)
    const [disabledInput, setDisabledInput] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0);
    const [types, setTypes] = useState([]);
    const [refIds, setRefIds] = useState([]);

    useImperativeHandle(ref, () => ({
        create: () => {
            refEditMode.current = "create"
            let _data = cloneDeep(emptyData)

            setData(_data)
            refDialog.current.create()
        },
        edit: (data) => {
            refEditMode.current = "update"
            getbyID(data.id)
            setCurrentId(data.id)
        },
        view: (id) => {
            refEditMode.current = "view"
            getbyID(id)
            setDisabledInput(true)
        }
    }));


    const [companies, setCompanies] = useState(null)
    const [maps, setMaps] = useState(null)

    useEffect(() => {
        setTypes(NOTIFICATION_TYPE.list.map(o => ({
            ...o,
            name: t(o.name)
        })))
    }, [])

    const onShow = () => {
        if (data?.image) {
            s3FileUploadRef.current.setFiles([data.image.name], FILE_TYPE.IMAGE)
        }
    }

    const getbyID = async (id) => {
        await EventsServices.notification.getById(id).then(res => {
            if (res) {
                setData(res)

                refDialog.current.create()
            }
        })

    }

    const cancel = () => {
        refDialog.current.close();
        setData(emptyData)
        setValid(emptyValidate)
        setActiveIndex(0)
    }
    const save = async () => {
        let isValid = performValidate([]);
        if (isValid.isValid) {
            // call api
            try {
                if (refEditMode.current == "create") {
                    setSaveLoading(true)
                    let to = await UserServices.searchUserEvent({
                        code: event.eventCode
                    }, {
                        first: 0,
                        page: 0,
                        size: 9999,
                    })

                    await EventsServices.notification.postGuest({
                        ...data,
                        to: to.data.map(o => o.email)
                    }).then(res => {
                        if (res) {
                            refDialog.current.close();
                            ToastHelper.show({ severity: 'success', summary: t("common.success") });
                            if (afterSubmit) {
                                afterSubmit()
                            }
                        }

                        setSaveLoading(false)
                    })

                } else {
                    // setSaveLoading(true)
                    // await EventsServices.notification.put(currentId, {
                    //     ...data,
                    //     image: data?.image?.name,
                    //     eventId: eventId,
                    //     publishDate: data.publishDate ? moment.utc(data.publishDate).format("HH:mm DD/MM/YYYY") : null
                    // }).then(res => {
                    //     refDialog.current.close();
                    //     ToastHelper.show({ severity: 'success', summary: t("common.success") });
                    //     afterSubmit()
                    //     setSaveLoading(false)
                    // })

                }

            } catch (error) {
                setSaveLoading(false)
                // ToastHelper.show({ severity: 'error', summary: t("common.error"), detail: 'Error' });
            }
        } else {
            setSaveLoading(false)
            ToastHelper.show({ severity: 'error', summary: t("common.error"), detail: t("error.required_full") });
        }
    }

    const applyChange = (prop, value, propArr, index) => {
        let _current = _.cloneDeep(data);
        switch (prop) {

            default:
                _current[prop] = value
                break;
        }

        setData(_current);
        performValidate([prop], _current, propArr, index)
    }
    const performValidate = (props, _checkObject, propArr, index) => {
        let result = { ...valid }, isValid = true, _errors = [];
        let _object = _checkObject ? _checkObject : data;
        // validate all props
        if (props.length === 0) {
            for (const property in result) {
                props.push(property);
            }
        }

        // validate props
        props.forEach(prop => {
            switch (prop) {
                case "title":
                case "message":
                    result[prop] = ""
                    if (!_object[prop]) {
                        result[prop] = t("message.validate_required")
                    }
                    break;
                default:
                    break;
            }
        });

        // set state
        setValid(result);
        for (const property in result) {
            if (result[property]) {
                if (property != "languages") {
                    isValid = false;
                    _errors.push(result[property]);
                }
                break;
            }
        }
        return { isValid: isValid, errors: _errors };
    }


    const header = () => {
        let headerMode = refEditMode.current
        if (headerMode === 'create') {
            return t('common.create') + " " + (t("event.notifications").toLowerCase())
        }
        if (headerMode === 'update') {
            return t('common.update') + " " + (t("event.notifications").toLowerCase())
        }
        if (headerMode === 'view') {
            return t('common.view') + " " + (t("event.notifications").toLowerCase())
        }
    }

    return (
        <EDialog
            ref={refDialog}
            title={header()}
            closable={true}
            style={{ width: '90vw' }}
            hidebtnFooter={false}
            onShow={onShow}
            btnFooter={
                refEditMode.current == "view" ?
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        }
                    ] :
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        },
                        {
                            label: t("common.save"),
                            onClick: () => save(),
                            loading: saveLoading
                        }
                    ]
            }
        >
            <div className="container px-4 py-3">
                <div class="formgrid grid">
                    <div className="col-6 field">
                        <label className="require">{t("common.name")}</label>
                        <InputText
                            disabled={refEditMode.current === "view"}
                            value={data?.title}
                            onChange={(e) => { applyChange("title", e.target.value) }}
                            className={classNames({ "w-full": true })}
                        />
                        <span className='event-color-primary-500 text-sm'>{valid.title || ""}</span>
                    </div>
                    <div className="col-12 field">
                        <label className="require">{t("common.description")}</label>
                        <InputTextarea
                            className="w-full"
                            value={data?.message}
                            onChange={(e) => { applyChange("message", e.target.value) }}
                            rows={5}
                            cols={30} />
                        <span className='event-color-primary-500 text-sm'>{valid.message || ""}</span>
                    </div>

                </div>

            </div>
        </EDialog>

    )
}
EventNotificationEmailDetail = forwardRef(EventNotificationEmailDetail)
export default EventNotificationEmailDetail;
