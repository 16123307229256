import axiosClient from "./axiosClient";

const ConfigServices = {
    get: (payload) => {
        return axiosClient.get(`/config`, {
            params: payload
        });
    },

    create: (payload) => {
        return axiosClient.post(`/config`, payload);
    },

    update: (payload) => {
        return axiosClient.put(`/config/${payload.key}`, payload);
    },

    delete: (id) => {
        return axiosClient.delete(`/config/${id}`);
    },
}

export default ConfigServices;