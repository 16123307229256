/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss"
import { useTranslation } from "react-i18next";
import { Card } from "primereact/card";
import { Chart } from "primereact/chart";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import EventsServices from "../../../core/services/EventsServices";
import { filter, find, remove, findIndex } from 'lodash'
import { EVENT_SETTING, EVENT_STATUS, USER_PERMISSION } from "../../../utils/constants";
import moment from "moment";
import { Steps } from "primereact/steps";
import ProgressEvent from "../../../components/ProgressEvent";
import { ToastHelper } from "../../../helper/ToastHelper";
import { TabMenu } from 'primereact/tabmenu';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import _ from 'lodash'

function Sessions(props, ref) {

    const { t } = useTranslation()
    const { eventID } = props;

    const { events, permission, eventCode, language } = useSelector((state) => state.userReducer)

    const dispatch = useDispatch();

    const [list, setList] = useState([])
    const [listDate, setListDate] = useState([]);
    const [report, setReport] = useState([])
    const [dataFavorite, setDataFavorite] = useState([]);
    const [dataClick, setDataClick] = useState([]);
    // const [chartData, setChartData] = useState({});

    const [chartOptions, setChartOptions] = useState({});

    const [chartData, setChartData] = useState({});

    const loadSessions = async (type) => {

       await EventsServices.report.getSession(eventID).then((res) => {
            if (res) {
                setReport(res)
            }
        })
    }

    useEffect(() => {
        if (eventID) {
            loadSessions()
        }
    }, [eventID])

    useEffect(() => {
        let _temp = _.cloneDeep(report)
        let _list = _.concat(_temp?.favorite, _temp?.click);
        // console.log(_temp?.favorite)
        const sortedList = _.orderBy(_list, [(item) => {
            if (item?.title) {
                const [day, month, year] = item?.title.split('/');
                return new Date(year, month - 1, day);
            }
        }], 'asc');
        const listDate = sortedList?.map(item => item?.title)
        const newlistDate = _.uniq(listDate);
        setListDate(newlistDate);
        // const isContainDate = _.some(temp, { 'title': '15/04/2024' });
        setDataFavorite(getValueArray(newlistDate, _temp?.favorite))
        setDataClick(getValueArray(newlistDate, _temp?.click))
    }, [report])


    const getValueArray = (date, data) => {
        const sortedList = _.orderBy(data, [(item) => {
            if (item?.title) {
                const [day, month, year] = item?.title.split('/');
                return new Date(year, month - 1, day);
            }
        }], 'asc');
        let _array = [];

        let _index = 0;
        date.map((item) => {
            if (!_.some(sortedList, { 'title': item })) {
                _array.push({ title: item, total: 0})
            } else {
                if (sortedList.length >= _index) {
                    _array.push(sortedList[_index])
                    _index++;

                }

            }
        }
        )
        const listTotal = _array?.map(item => item?.total)
        return listTotal;
    }

    
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: listDate,
            datasets: [
                {
                    label: t("chart.visit"),
                    data: dataClick,
                    fill: false,
                    // stepped: 'middle',
                    tension: 0.9,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                },
                {
                    label: t("chart.favourite"),
                    data: dataFavorite,
                    fill: false,
                    // stepped: 'middle',
                    // borderDash: [5, 5],
                    tension: 0.9,
                    borderColor: documentStyle.getPropertyValue('--pink-500'),
                }
            ]
        };
        const options = {
            hoverBorderJoinStyle: "round",
            tension: 0,
            stepped: true

            // maintainAspectRatio: false,
            // aspectRatio: 0.6,
            // plugins: {
            //     legend: {
            //         labels: {
            //             color: textColor
            //         }
            //     }
            // },
            // scales: {
            //     x: {
            //         ticks: {
            //             color: textColorSecondary
            //         },
            //         grid: {
            //             color: surfaceBorder
            //         }
            //     },
            //     y: {
            //         ticks: {
            //             color: textColorSecondary
            //         },
            //         grid: {
            //             color: surfaceBorder
            //         }
            //     }
            // }
        };

        setChartOptions(options);
        setChartData(data);
    }, [listDate,dataClick,dataFavorite]);



    return (

        <>
            <div className="col-12">
                <Card className="border-round-2xl shadow-2">
                    <div className="flex justify-content-center">
                        <div className="text-center mt-2 mb-4">
                            <div className="text-5xl font-bold text-900">{t("chart.sessions")}</div>
                        </div>
                    </div>
                    <Chart type="line" data={chartData} options={chartOptions} />

                </Card>
            </div>
            <div className="col-12 lg:col-6">
                <Card className="border-round-2xl shadow-2">
                    <div className="text-center text-900 ">
                        <div className="font-bold text-7xl ">{_.sum(dataClick)??0}</div>   
                        <p className="font-bold text-lg">{t("chart.visit")}</p>
                        <p className="text-lg">{t("chart.total_accessing")}</p>
                    </div>

                </Card>
            </div>
            <div className="col-12 lg:col-6">
                <Card className="border-round-2xl shadow-2">
                    <div className="text-center text-900 ">
                        <div className="font-bold text-7xl ">{_.sum(dataFavorite)??0}</div>
                        <p className="font-bold text-lg">{t("chart.favourite")}</p>
                        <p className="text-lg">{t("chart.total_attention")}</p>
                    </div>

                </Card>
            </div>

        </>

    )
}
Sessions = forwardRef(Sessions)
export default Sessions;

