import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import EventsServices from "../../../core/services/EventsServices";
import OfferAttributeDetail from "./OfferAttributeDetail";
import { ToastHelper } from "../../../helper/ToastHelper";
import { confirmDialog } from "primereact/confirmdialog";
import { Link, useParams } from "react-router-dom";
import { EnumEventModule, PERMISSION } from "../../../utils/constants";
import { useSelector } from "react-redux";
import { checkPermission } from "../../../utils";
import { filter } from 'lodash'
import { ProgressBar } from "primereact/progressbar";

function OfferAttribute({ type, refType, screenPermission }) {
    const { t } = useTranslation();

    const { groupPermission, language } = useSelector((state) => state.userReducer)

    const [dictionary, setDictionary] = useState(null);
    const { refId, eventId } = useParams()
    const refDetail = useRef();

    const [data, setData] = useState([])

    const [loadingList, setLoadingList] = useState(false)

    useEffect(() => {
        if (refId) {
            loadList()
            loadDictionary()
        }
    }, [])

    const loadList = () => {
        setLoadingList(true)
        EventsServices.searchOfferAttribute(type, refId).then((res) => {
            setLoadingList(false)
            if (res) {
                setData(filter(res, { refType }))
            }
        })
    }

    /**
     * load dictionary
     */
    const loadDictionary = async () => {
        let [offer] =
            await Promise.all([
                EventsServices.offerAttribute.filter(eventId, { page: 0, size: 9999 }),
            ]);
        setDictionary({
            offer: filter(offer.data, { refType }).map(o => {
                o.name = o.languages.find(x => x.languageCode == language)?.name
                return o
            })
        });
    };


    const createData = () => {
        refDetail.current.create()
    }

    const editData = (item) => {
        refDetail.current.edit(item)
    }

    const deleteData = (id) => {
        confirmDialog({
            message: t("common.confirm_content"),
            header: t("common.confirm"),
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            rejectLabel: t("common.cancel"),
            acceptLabel: t("common.confirm"),
            accept: () => {
                EventsServices.deleteOfferAttribute(
                    type, refId, id

                ).then((res) => {
                    if (res) {
                        ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
                        loadList()
                    }
                })
            }
            ,
            reject: () => { }
        });

    }

    const headerTable = () => {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-plus"
                    className="mr-2"
                    label={t("common.add")}
                    outlined
                    size="small"
                    onClick={() => createData()}
                    disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.CREATE)}

                />
            </React.Fragment>
        )
    }

    const renderColEnd = (row) => {
        return <span>
            <Button icon="pi pi-pencil" rounded text aria-label="Filter" onClick={() => editData(row)}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.UPDATE)}
            />
            <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" onClick={() => deleteData(row.id)}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.DELETE)}
            />
        </span>
    }

    if (!checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)) {
        return null
    }

    return (
        <div className="container-fluid">
            <div className="grid">
                <div className="col-12">
                    <div className="e-progress-bar">
                        {loadingList
                            ? <ProgressBar className="e-progress-bar" mode="indeterminate"></ProgressBar>
                            : null
                        }
                    </div>
                    <DataTable
                        value={data}
                        header={headerTable}
                        dataKey="id"
                        className="p-datatable-gridlines border-all"
                        resizableColumns
                        columnResizeMode="expand"
                        emptyMessage={t("common.no_data")}
                        scrollable
                        scrollDirection="both"
                        scrollHeight="flex"
                        lazy
                    >
                        <Column
                            style={{ width: 100 }}
                            field="STT"
                            body={(index, value) => value?.rowIndex + 1}
                            header={t("common.no")}
                        />
                        {/* <Column
                            header={t("event.type")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {d?.type}
                            </div>}
                        /> */}
                        {/* <Column
                            header={t("event.offer_attribute")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {dictionary?.offer?.find((item) => item.id ===  d?.offerAttributeId)?.name}
                            </div>}
                        /> */}
                        <Column
                            header={t("event.tags")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {d?.tags}
                            </div>}
                        />
                        <Column
                            columnKey="action"
                            alignFrozen="right"
                            frozen
                            header={t("company.action")}
                            className="col-frozen-table-end py-1"
                            style={{ minWidth: 180, width: 180 }}
                            body={renderColEnd}
                        />
                    </DataTable>
                </div>
            </div>
            <OfferAttributeDetail
                refId={refId}
                dictionary={dictionary}
                type={type}
                afterSubmit={loadList}
                ref={refDetail}
                refType={refType}
            />
        </div>
    )
}

export default OfferAttribute;
