import axiosClient from "./axiosClient";

const MasterDataServices = {
    getLanguage: () => {
        return axiosClient.get(`/masterdata/language`);
    },
    getCountries: () => {
        return axiosClient.get(`/masterdata/country`);
    },
}

export default MasterDataServices