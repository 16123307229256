import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { useDispatch, useSelector } from "react-redux";
import EDialog from "../../../../components/EDialog";
import CompanyServices from "../../../../core/services/CompanyServices";
import { InputNumber } from 'primereact/inputnumber';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "react-bootstrap";
import { ACTION, APPLICATIONS, FILE_TYPE, PERMALINK_APPLICATIONS, UPLOAD_STATUS } from "../../../../utils/constants";
import MapsServices from "../../../../core/services/MapsServices";
import { cloneDeep } from 'lodash'
import EventsServices from "../../../../core/services/EventsServices";
import { MultiSelect } from "primereact/multiselect";
import S3FileUpload from "../../../../components/S3FileUpload";

function BannerDetail({ afterSubmit, eventId }, ref) {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const languages = useSelector((state) => state.userReducer.languages)

    const s3FileUploadRef = useRef()

    const defaultData = {
        image: null,
        router: null,
        languageCode: null
    }
    const defaultError = {
        image: null,
        router: null,
        languageCode: null
    }

    const [data, setData] = useState(defaultData)

    const [error, setError] = useState(defaultError)

    const [action, setAction] = useState(ACTION.CREATE)

    const [applications, setApplications] = useState(ACTION.CREATE)

    const refDialog = useRef(null)

    useEffect(() => {
        setApplications(APPLICATIONS.map(o => ({
            ...o,
            name: t(o.name)
        })))
    }, [])

    useImperativeHandle(ref, () => ({
        create: () => {
            refDialog.current.create();
            setAction(ACTION.CREATE)
        },
        edit: (id) => {
            setAction(ACTION.UPDATE);
            EventsServices.getBannerById(id, {
                eventId
            }).then((res) => {
                if (res) {
                    setData({
                        ...res,
                        image: res?.image ? {
                            name: res?.image,
                            status: UPLOAD_STATUS.UPLOADED
                        } : null
                    })
                    refDialog.current.create();
                }
            })
        },
        view: (id) => {
            refDialog.current.create();
            setAction(ACTION.VIEW)
            // CompanyServices.getById(id).then((res) => {
            //     if (res) {
            //         setData(res)
            //     }
            // })
        }
    }));

    const onShow = () => {
        if (data?.image) {
            s3FileUploadRef.current.setFiles([data.image.name], FILE_TYPE.IMAGE)
        }
    }

    const onChangeData = (prop, value) => {
        let _data = cloneDeep(data)

        _data[prop] = value
        validateData([prop], _data)
        setData(_data)
    }

    const validateData = (prop, _data) => {
        let _error = cloneDeep(error)
        let requiredFields = prop.length > 0 ? prop : Object.keys(_data);
        let _prop = requiredFields
        for (const field of _prop) {
            switch (field) {
                case "image":
                    _error[field] = ""
                    if (!_data[field] || _data[field].status != UPLOAD_STATUS.UPLOADED) {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                case "languageCode":
                case "router":
                    _error[field] = ""
                    if (!_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;

                default:
                    break;
            }
        }

        setError(_error)
        let count = 0;
        for (const key in _error) {
            if (_error[key]) {
                count++;
            }
        }
        return count;
    }

    const onSubmit = () => {
        let validate = validateData([], data)
        if (validate) return
        switch (action) {
            case ACTION.CREATE:
                EventsServices.createBanner({
                    ...data,
                    image: data?.image?.name,
                    eventId
                }).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            case ACTION.UPDATE:
                EventsServices.updateBanner(data.id, {
                    ...data,
                    image: data?.image?.name,
                    eventId
                }).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            default:
                break;
        }

    }

    const renderError = (field) => {
        if (error[field]) {
            return <span className="text-red-500 text-xs">{error[field]}</span>
        }
    }

    const cancel = () => {
        refDialog.current.close()
        setData(defaultData)
        setError(defaultError)
    }

    const onUploadedImage = useCallback((val) => {
        onChangeData("image", val)
    }, [data])

    return (
        <EDialog
            ref={refDialog}
            style={{ width: '70vw' }}
            title={t("event_info.tab.banner")}
            closable={false}
            hidebtnFooter={false}
            onShow={onShow}
            btnFooter={
                action === ACTION.VIEW ?
                    [
                        {
                            text: true,
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        }
                    ] :
                    [
                        {
                            text: true,
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        },
                        {
                            label: t("common.save"),
                            onClick: () => onSubmit(),
                            loading
                        }
                    ]
            }
        >
            <div className="container">
                <div className="grid">
                    <div className="col-6 field">
                        <label className="require">{t("permalink.application")}</label>
                        <Dropdown
                            filter
                            disabled={action === "view"}
                            options={applications}
                            optionLabel="name"
                            optionValue="id"
                            value={data?.application}
                            onChange={(e) => { onChangeData("application", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["application"] })}
                        ></Dropdown>
                        {renderError("application")}
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("event.banner.router")}</label>
                        <InputText
                            className="w-full"
                            value={data?.router}
                            onChange={(e) => onChangeData("router", e.target.value)}
                        />
                        {renderError("router")}
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("event.languages")}</label>
                        <Dropdown
                            filter
                            disabled={action === "view"}
                            options={languages}
                            optionLabel="name"
                            optionValue="code"
                            className="w-full"
                            value={data?.languageCode}
                            onChange={(e) => { onChangeData("languageCode", e.value) }}
                        />
                        {renderError("languageCode")}
                    </div>
                    <div className="col-12 field">
                        <label className="require">{t("event.banner.image")}</label>
                        <S3FileUpload
                            ref={s3FileUploadRef}
                            onChange={onUploadedImage}
                            accept="image/*"
                            onLoading={setLoading}
                        />
                        {/* {renderError("image")} */}
                    </div>

                    {/* <div className="col-6 field">
                        <label className="require">{t("event.banner.image")}</label>
                        <InputText
                            className="w-full"
                            value={data?.image}
                            onChange={(e) => onChangeData("image", e.target.value)}
                        />

                        {renderError("image")}
                    </div> */}
                </div>
            </div>
        </EDialog>
    )
}
BannerDetail = forwardRef(BannerDetail)
export default BannerDetail;
