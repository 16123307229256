import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SpeedDial } from 'primereact/speeddial';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ListAccountsDetail from "./ListAccountsDetail";
import EBreadCrumb from "../../../components/EBreadCrumb";
import { USER_PERMISSION } from "../../../utils/constants";
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash'

function ListAccounts(props) {
    const { t } = useTranslation()
    const [filter, setFilter] = useState({})
    const { userInfo } = useSelector((state) => state.userReducer)

    const refDetail = useRef()

    const [data, setData] = useState({
        pageSize: 20,
        page: 0,
        total: 576,
        content: [
            {
                userName: "chienth",
                fullName: "Test",
                roleName: "admin"
            },
            {
                userName: "chiendh",
                fullName: "Chu Đình Test",
                roleName: "user"
            },

        ]
    })

    useEffect(() => {
        let _filter = cloneDeep(filter)
        _filter.page = data.page;
        _filter.first = data.page * data.pageSize;
        _filter.size = data.pageSize;
        _filter.total = data.total;
        setFilter(_filter)
    }, [])

    const permission = useMemo(() => {
        return parseInt(userInfo?.permission ?? "-1")
    }, [userInfo])

    const createData = () => {
        refDetail.current.create()
    }

    const editData = () => {
        refDetail.current.edit()
    }

    const viewData = () => {
        refDetail.current.view()
    }

    const startContent = (
        <React.Fragment>
            <Button
                icon="pi pi-plus"
                className="mr-2"
                label={t("common.add")}
                outlined
                size="small"
                onClick={() => createData()}
            />
        </React.Fragment>
    );

    const endContent = (
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText placeholder="Search" />
        </span>
    );

    return (
        <div className="event-layout">
            <div className="event-layout-center overflow-hidden">
                <div className="p-card">
                    <div className="p-card-body">
                        <div className="grid">
                            <div className="col-12">
                                <Toolbar start={startContent} end={endContent} />
                            </div>
                            <div className="col-12">
                                <DataTable
                                    value={data.content}
                                    dataKey="id"
                                    className="p-datatable-gridlines border-all"
                                    resizableColumns
                                    columnResizeMode="expand"
                                    emptyMessage={t("không có dữ liệu")}
                                    scrollable
                                    scrollDirection="both"
                                    scrollHeight="flex"
                                    lazy
                                    paginator
                                    first={filter?.first || 0}
                                    rows={filter?.size || 20}
                                    totalRecords={filter.total}
                                    rowsPerPageOptions={[20, 25, 50, 100]}
                                    // onPage={onPage}
                                    paginatorTemplate="RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink"
                                    currentPageReportTemplate="{first} - {last} of {totalRecords}"
                                    reorderableColumns
                                    reorderableRows
                                >
                                    <Column
                                        style={{ width: 100 }}
                                        field="STT"
                                        body={(index, value) => value?.rowIndex + 1}
                                        header={t("STT")}
                                    />
                                    <Column
                                        showFilterMenu={false}
                                        showClearButton={false}
                                        header={t("Username")}
                                        style={{ flex: "1 0 180px" }}
                                        field="userName"
                                    />
                                    <Column
                                        header={t("Tên tài khoản")}
                                        style={{ minWidth: 250 }}
                                        field="fullName"
                                    />
                                    <Column
                                        header={t("Vai trò")}
                                        style={{ minWidth: 250 }}
                                        field="roleName"
                                    />
                                    <Column
                                        columnKey="action"
                                        alignFrozen="right"
                                        frozen
                                        header={t("Tác động")}
                                        style={{ minWidth: 180, width: 180 }}
                                        className="col-frozen-table-end py-1"
                                        body={(d) => {
                                            return (
                                                <div>
                                                    <Button icon="pi pi-eye" rounded text onClick={() => viewData()} />
                                                    <Button icon="pi pi-pencil" rounded text onClick={() => editData()} />
                                                </div>
                                            )
                                        }}
                                    />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <ListAccountsDetail ref={refDetail}></ListAccountsDetail>
                </div>
            </div>
        </div>
    )
}

export default ListAccounts;
