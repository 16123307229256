import { put, call, takeEvery, all, fork } from "redux-saga/effects";
import MasterDataServices from "../services/MasterDataServices";
import { REQUEST_GET_COUNTRIES, REQUEST_GET_COUNTRIES_SUCCESS, REQUEST_GET_MASTERDATA_LANGUAGE, REQUEST_GET_MASTERDATA_LANGUAGE_SUCCESS } from "../actions/ActionMasterData";



function* getMasterDataLanguage(action) {
  try {
    const data = yield call(MasterDataServices.getLanguage, action.params)
    yield put({
      type: REQUEST_GET_MASTERDATA_LANGUAGE_SUCCESS,
      params: data
    })
    if (action.callBack) {
      action.callBack(data)
    }
  } catch (error) {
    console.log(error)
  }
}

function* getCountries(action) {
  try {
    const data = yield call(MasterDataServices.getCountries)
    yield put({
      type: REQUEST_GET_COUNTRIES_SUCCESS,
      params: data
    })
    if (action.callBack) {
      action.callBack(data)
    }
  } catch (error) {
    console.log(error)
  }
}


function* watchMaster() {
  yield takeEvery(REQUEST_GET_MASTERDATA_LANGUAGE, getMasterDataLanguage);
  yield takeEvery(REQUEST_GET_COUNTRIES, getCountries);
}

export default function* MasterDataSaga() {
  yield all([fork(watchMaster)]);
}
