import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TabView, TabPanel } from 'primereact/tabview';
import "./styles.scss"
import Locations from "../../Events/Locations";
import { EnumEventModule } from "../../../utils/constants";
import EventFile from "../../Events/EventFile";
import EBreadCrumb from "../../../components/EBreadCrumb";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import UserCompany from "../../Events/UserCompany";
import OfferAttribute from "../../Events/OfferAttribute";

export default function CompanyInfo(props) {
    const { t } = useTranslation()

    const { eventId, refId, screenId } = useParams()

    const history = useNavigate()

    const activeIndex = useMemo(() => {
        switch (screenId) {
            case "locations":
                return 0
            case "files":
                return 1
            case "users":
                return 2
            case "cms-users":
                return 3
            case "offer-attributes":
                return 4
            default:
                return 0
        }
    }, [screenId])


    const tabHeaderTemplate = (name, link) => ({ onClick }) => {
        return <div className="p-3 font-bold flex cursor-pointer text-primary" onClick={onLink(link, onClick)}>
            {name}
        </div>
    }

    const onLink = (link, onClick) => () => {
        onClick()
        history(link)
    }

    return (
        <div className="event-layout">
            <div className="event-layout-center overflow-hidden">
                <div className="p-card">
                    <div className="p-card-body">
                        <div className="grid">
                            <div className="col-12">
                                <TabView scrollable activeIndex={activeIndex} panelContainerClassName="px-0">

                                    <TabPanel headerTemplate={tabHeaderTemplate(t("company.location"), `/event/${eventId}/company/${refId}/locations`)}>
                                        <Locations
                                            type={EnumEventModule.COMPANY}
                                            screenPermission={"eventInfoScreen_companies_locations"}
                                        />
                                    </TabPanel>
                                    <TabPanel headerTemplate={tabHeaderTemplate(t("event.file"), `/event/${eventId}/company/${refId}/files`)}>
                                        <EventFile
                                            type={EnumEventModule.COMPANY}
                                            screenPermission={"eventInfoScreen_companies_files"}
                                        />
                                    </TabPanel>
                                    <TabPanel headerTemplate={tabHeaderTemplate(t("company.user"), `/event/${eventId}/company/${refId}/users`)}>
                                        <UserCompany
                                            isAdmin={false}
                                            screenPermission={"eventInfoScreen_companies_users"}
                                        />
                                    </TabPanel>
                                    <TabPanel headerTemplate={tabHeaderTemplate(t("company.cms_user"), `/event/${eventId}/company/${refId}/cms-users`)}>
                                        <UserCompany
                                            isAdmin={true}
                                            screenPermission={"eventInfoScreen_companies_cms-users"}
                                        />
                                    </TabPanel>
                                    {/* <TabPanel headerTemplate={tabHeaderTemplate(t("event.offer_attribute"), `/event/${eventId}/company/${refId}/offer-attributes`)}>
                                        <OfferAttribute
                                        type={EnumEventModule.COMPANY}
                                        screenPermission={"eventInfoScreen_companies_offer-attributes"}
                                        
                                        />
                                    </TabPanel> */}
                                    <TabPanel headerTemplate={tabHeaderTemplate(t("common.offer_attribute_industry"), `/event/${eventId}/company/${refId}/offer-attribute-industry`)}>
                                        <OfferAttribute
                                            type={EnumEventModule.COMPANY}
                                            refType={EnumEventModule.INDUSTRY}
                                            screenPermission="eventInfoScreen_companies_offer-attributes"
                                        />
                                    </TabPanel>

                                    <TabPanel headerTemplate={tabHeaderTemplate(t("common.offer_attribute_product"), `/event/${eventId}/company/${refId}/offer-attribute-product`)}>
                                        <OfferAttribute
                                            type={EnumEventModule.COMPANY}
                                            refType={EnumEventModule.PRODUCT}
                                            screenPermission="eventInfoScreen_companies_offer-attributes"
                                        />
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
