import { put, call, takeEvery, all, fork } from "redux-saga/effects";
import { REQUEST_GET_EVENTS, REQUEST_POST_EVENTS, REQUEST_PUT_EVENTS, REQUEST_DELETE_EVENTS, REQUEST_GET_EVENTS_ID } from "../actions/ActionEvents";
import EventsServices from "../services/EventsServices";


// Comment here
function* getSearch(action) {
  try {
    const data = yield call(EventsServices.search)
    if (action.callBack) {
      action.callBack(data)
    }
  } catch (error) {
    console.log(error)
  }
}
function* createEvents(action) {
    try {
      const data = yield call(EventsServices.create, action.params)
      if (action.callBack) {
        action.callBack(data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  function* updateEvents(action) {
    try {
      const data = yield call(EventsServices.update, action.params)
      if (action.callBack) {
        action.callBack(data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  function* deleteEvents(action) {
    try {
      const data = yield call(EventsServices.delete, action.params)
      if (action.callBack) {
        action.callBack(data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  function* getEventsById(action) {
    try {
      const data = yield call(EventsServices.getById, action.params)
      if (action.callBack) {
        action.callBack(data)
      }
    } catch (error) {
      console.log(error)
    }
  }

function* watchEvents() {
  yield takeEvery(REQUEST_GET_EVENTS, getSearch);
  yield takeEvery(REQUEST_POST_EVENTS, createEvents);
  yield takeEvery(REQUEST_PUT_EVENTS, updateEvents);
  yield takeEvery(REQUEST_DELETE_EVENTS, deleteEvents);
  yield takeEvery(REQUEST_GET_EVENTS_ID, getEventsById);
}

export default function* EventsSaga() {
  yield all([fork(watchEvents)]);
}
