import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import EventsServices from "../../../core/services/EventsServices";
import EventCompanyDetail from "./EventCompanyDetail";
import { ToastHelper } from "../../../helper/ToastHelper";
import { confirmDialog } from "primereact/confirmdialog";
import { Link, useParams } from "react-router-dom";
import CompanyServices from "../../../core/services/CompanyServices";
import RanksServices from "../../../core/services/RanksServices";
import { useSelector } from "react-redux";
import MasterData from "../../../core/services/MasterService";
import { find, orderBy, filter, cloneDeep } from 'lodash'
import { checkPermission, convertCompanyExcelData, convertExcelData, findAttribute } from "../../../utils";
import { PERMISSION } from "../../../utils/constants";
import { TabPanel, TabView } from "primereact/tabview";
import { FileUpload } from "primereact/fileupload";
import { ProgressSpinner } from "primereact/progressspinner";
import ImportDetailDetail from "./ImportDetailDetail";
import * as XLSX from 'xlsx';
import ImportServices from "../../../core/services/ImportServices";
import { ProgressBar } from "primereact/progressbar";

const screenPermission = "eventInfoScreen_companies"

function EventCompanies({ rankId }) {
    const { t } = useTranslation();
    const { language, groupPermission } = useSelector((state) => state.userReducer)

    const { eventId } = useParams()
    const [companies, setCompanies] = useState([]);
    const [ranks, setRanks] = useState([]);
    const uploadRef = useRef()
    const refImportDetail = useRef()

    const [loading, setLoading] = useState(false)

    const [lazyParams, setLazyParams] = useState({
        keyword: ""
    })

    const refDetail = useRef();

    const [loadingList, setLoadingList] = useState(false)

    const [data, setData] = useState([])
    useEffect(() => {
        if (eventId) {
            getList()
            loadDictionary()
        }
    }, [])

    /**
     * load dictionary
     */
    const loadDictionary = async () => {
        CompanyServices.search().then(res => {
            setCompanies(res)
        })
        RanksServices.get().then(res => {
            if (res) {
                setRanks(orderBy(res.map(o => {
                    const lang = find(o.languages, { languageCode: language })
                    const name = lang ? lang?.name : ""
                    return {
                        ...o,
                        name
                    }
                }), ['isDisplay', 'sortOrder'], ['desc', 'asc']))
            }

        })
    };

    const createData = () => {
        refDetail.current.create()
    }

    const editData = (id) => {
        refDetail.current.edit(id)
    }

    const viewData = (id) => {
        refDetail.current.view(id)
    }

    const deleteData = (id) => {
        confirmDialog({
            message: t("company.confirm_content"),
            header: t("company.confirm"),
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            rejectLabel: t("company.cancel"),
            acceptLabel: t("company.confirm"),
            accept: () => {
                EventsServices.deleteCompany(eventId, id).then((res) => {
                    if (res) {
                        ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
                        getList()
                    }
                })
            }
            ,
            reject: () => { }
        });

    }

    const onChangeKeyword = (e) => {
        let _filter = cloneDeep(lazyParams)
        _filter.keyword = e.target.value
        setLazyParams(_filter)
    }

    const onSelectFile = (e) => {
        if (e.files?.length) {
            upload(e.files[0])
        }
    }

    const upload = (f) => {
        console.log(f)
        var name = f.name;
        const reader = new FileReader();
        reader.onload = async (evt) => { // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            let ds = []
            wb.SheetNames.map(wsname => {
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                var XL_row_object = XLSX.utils.sheet_to_row_object_array(ws);
                ds.push({
                    name: wsname,
                    data: XL_row_object
                })
            })
            // console.log(ds);
            
            const maxNo = await EventsServices.getCompanyNo(eventId)
            const params = convertCompanyExcelData(ds, (maxNo?.eventCompany ?? 0))
            console.log(params)
            setLoading(true)
            ImportServices.companyCreate(eventId, params).then(res => {
                setLoading(false)
                if (res && res?.length == params.eventCompanies.length) {
                    ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
                } else {
                    refImportDetail.current.create(res, ds)
                }
                getList()

            })

        };
        reader.readAsBinaryString(f);
    }

    const onClear = () => {
        uploadRef.current.clear()
    }

    const headerTable = () => {
        return (
            <div className="flex justify-content-between">
                <div className="flex align-items-center">
                    <Button
                        icon="pi pi-plus"
                        className="mr-2"
                        label={t("common.add")}
                        outlined
                        // size="small"
                        onClick={() => createData()}
                        disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.CREATE)}

                    />
                    <FileUpload
                        ref={uploadRef}
                        mode="basic"
                        accept="*"
                        chooseLabel={t("common.import")}
                        maxFileSize={1000000}
                        onSelect={onSelectFile}
                        disabled={loading || !checkPermission(groupPermission, screenPermission, PERMISSION.CREATE)}
                        className="mr-2"
                        customUpload
                        uploadHandler={onClear}
                    />
                    {loading
                        ? <ProgressSpinner style={{ width: '40px', height: '40px' }} />
                        : null
                    }
                </div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText placeholder={t("company.search")} value={lazyParams?.keyword} onChange={onChangeKeyword} />
                </span>
            </div>
        )
    }

    const getList = () => {
        setLoadingList(true)
        EventsServices.searchCompany(eventId).then((res) => {
            setLoadingList(false)
            if (res) {
                setData(res)
            }
        })
    }

    const afterSubmit = () => {
        getList()
        ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
    }

    const renderCol0 = (d, value) => {
        let _info = find(d.attributes, { languageCode: language })
        return (
            <Link
                to={`/event/${eventId}/company/${d.id}`}
                className="text-primary underline"
            >
                {_info?.name}
            </Link>
        )
    }

    const previewData = useMemo(() => {
        let rank = ranks?.length ? find(ranks, { id: rankId }) : null
        let _list = filter(data, o => o.rank?.id == rank?.id)
        return findAttribute(_list, lazyParams.keyword)
    }, [data, ranks, rankId, lazyParams])

    if (!checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)) {
        return null
    }

    return (
        <div className="container-fluid">
            <div className="grid">
                <div className="col-12">
                    <div className="e-progress-bar">
                        {loadingList
                            ? <ProgressBar className="e-progress-bar" mode="indeterminate"></ProgressBar>
                            : null
                        }
                    </div>
                    <DataTable
                        value={previewData}
                        header={headerTable}
                        dataKey="id"
                        className="p-datatable-gridlines border-all"
                        resizableColumns
                        columnResizeMode="expand"
                        emptyMessage={t("common.no_data")}
                        scrollable
                        scrollDirection="both"
                        scrollHeight="flex"
                        lazy
                    >
                        <Column
                            style={{ width: 100 }}
                            field="STT"
                            body={(index, value) => value?.rowIndex + 1}
                            header={t("common.no")}
                        />

                        <Column
                            header={t("common.code")}
                            style={{ minWidth: 100 }}
                            field="code"
                        />
                        <Column
                            header={t("common.name")}
                            style={{ minWidth: 250 }}
                            field="location"
                            body={renderCol0}
                        />
                        <Column
                            header={t("common.email")}
                            style={{ minWidth: 250 }}
                            field="email"
                        />
                        <Column
                            header={t("common.phonenumber")}
                            style={{ minWidth: 100 }}
                            field="phone"
                        />
                        <Column
                            columnKey="action"
                            alignFrozen="right"
                            frozen
                            header={t("company.action")}
                            className="col-frozen-table-end py-1"
                            style={{ minWidth: 180, width: 180 }}
                            body={(d) => {
                                return (
                                    <div>
                                        <Button icon="pi pi-eye" rounded text aria-label="Filter" onClick={() => viewData(d.id)} />
                                        <Button icon="pi pi-pencil" rounded text aria-label="Filter" onClick={() => editData(d.id)}
                                            disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.UPDATE)}
                                        />
                                        <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" onClick={() => deleteData(d.id)}
                                            disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.DELETE)}
                                        />
                                    </div>
                                )
                            }}
                        />
                    </DataTable>
                </div>
            </div>
            <EventCompanyDetail
                eventId={eventId}
                companies={companies}
                ranks={ranks}
                ref={refDetail}
                afterSubmit={afterSubmit}
            />
            <ImportDetailDetail ref={refImportDetail} />
        </div>
    )
}

export default EventCompanies;
