export const REQUEST_GET_EVENTS = `REQUEST_GET_EVENTS`
export const REQUEST_GET_EVENTS_SUCCESS = `REQUEST_GET_EVENTS_SUCCESS`
export function actionEventsList(callBack) {
    return {
        type: REQUEST_GET_EVENTS,
        callBack
    };
}
export const REQUEST_POST_EVENTS = `REQUEST_POST_EVENTS`
export const REQUEST_POST_EVENTS_SUCCESS = `REQUEST_POST_EVENTS_SUCCESS`
export function actionEventsCreate(params, callBack) {
    return {
        type: REQUEST_POST_EVENTS,
        params,
        callBack
    };
}
export const REQUEST_PUT_EVENTS = `REQUEST_PUT_EVENTS`
export const REQUEST_PUT_EVENTS_SUCCESS = `REQUEST_PUT_EVENTS_SUCCESS`
export function actionEventsEdit(params, callBack) {
    return {
        type: REQUEST_PUT_EVENTS,
        params,
        callBack
    };
}
export const REQUEST_DELETE_EVENTS = `REQUEST_DELETE_EVENTS`
export const REQUEST_DELETE_EVENTS_SUCCESS = `REQUEST_DELETE_EVENTS_SUCCESS`
export function actionEventsDelete(params, callBack) {
    return {
        type: REQUEST_DELETE_EVENTS,
        params,
        callBack
    };
}
export const REQUEST_GET_EVENTS_ID = `REQUEST_GET_EVENTS_ID`
export const REQUEST_GET_EVENTS_SUCCESS_ID = `REQUEST_GET_EVENTS_SUCCESS_ID`
export function actionEventsById(params, callBack) {
    return {
        type: REQUEST_GET_EVENTS_ID,
        params,
        callBack
    };
}
