import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import OfferAttributeDetail from "./OfferAttributeDetail";
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { ToastHelper } from "../../helper/ToastHelper";
import { Dropdown } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';
import EBreadCrumb from "../../components/EBreadCrumb";
import { cloneDeep, find } from 'lodash'
import { useSelector } from "react-redux";
import { checkPermission } from "../../utils";
import { PERMISSION } from "../../utils/constants";
import EventsServices from "../../core/services/EventsServices";
import { useParams } from "react-router";
import { ProgressBar } from "primereact/progressbar";

const screenPermission = "offer-attribute"

function OfferAttributeList({ refType }) {
    const { t } = useTranslation()

    const { eventId } = useParams()

    const { groupPermission, language } = useSelector((state) => state.userReducer)

    const defaultFilter = {
        type: "",
        keyword: "",
        page: 0,
        size: 10,
        total: 0,
        first: 0
    }

    const optionRefType = [
        {
            name: "PRODUCT",
            dataType: ""
        },
        {
            name: "INDUSTRY",
            dataType: ""
        },
        {
            name: "POSITION",
            dataType: ""
        },
        {
            name: "AGENDA",
            dataType: ""
        }
    ]
    const [filter, setFilter] = useState(defaultFilter)

    const [meta, setMeta] = useState(filter)

    const refDetail = useRef()
    const op = useRef()
    const [data, setData] = useState([])

    const [loadingList, setLoadingList] = useState(false)

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getList(filter)
        }, 200)
        return () => clearTimeout(delayDebounceFn)
    }, [filter, refType])

    const getList = (_filter) => {
        if (refType) {
            _filter = cloneDeep(_filter ? _filter : filter)
            _filter.page = _filter.page + 1
            setLoadingList(true)
            EventsServices.offerAttribute.filter(eventId, {
                ..._filter,
                type: refType
            }).then((res) => {
                setLoadingList(false)
                if (res) {
                    setData(res.data)
                    setMeta({
                        ...meta,
                        first: (res.pageNumber - 1) * res.pageSize,
                        total: res.totalRecord
                    })
                }
            })
        }
    }

    const onChangeFilter = (prop, value) => {
        let _filter = cloneDeep(filter)
        _filter[prop] = value
        setFilter(_filter)
    }

    const createData = () => {
        refDetail.current.create()
    }

    const editData = (item) => {
        refDetail.current.edit(item)
    }

    const viewData = (item) => {
        refDetail.current.view(item)
    }

    const deleteData = (id) => {
        confirmDialog({
            message: t("common.confirm_content"),
            header: t("common.confirm"),
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            rejectLabel: t("common.cancel"),
            acceptLabel: t("common.confirm"),
            accept: () => {
                EventsServices.offerAttribute.delete(id, eventId).then((res) => {
                    if (res) {
                        ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
                        getList()
                    }
                })
            }
            ,
            reject: () => { }
        });

    }
    const afterSubmit = () => {
        getList()
        ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
    }

    const onPage = (event) => {
        let _filter = cloneDeep(filter)
        _filter.size = event.rows;
        _filter.page = event.page;
        setFilter(_filter)
    };

    const startContent = (
        <React.Fragment>
            <Button
                icon="pi pi-plus"
                className="mr-2"
                label={t("common.add")}
                outlined
                size="small"
                onClick={() => createData()}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.CREATE)}
            />
        </React.Fragment>
    );

    const endContent = (
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText placeholder={t("company.search")} value={filter.keyword} onChange={(e) => { onChangeFilter("keyword", e.target.value) }} />
        </span>
    );

    const renderCol0 = (d, value) => {
        let _info = find(d.languages, { languageCode: language })
        return (
            <span>
                {_info?.name}
            </span>
        )
    }

    if (!checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)) {
        return null
    }

    return (
        <div className="event-layout">
            <div className="event-layout-center overflow-hidden">

                <div className="grid">
                    <div className="col-12 ">
                        <Toolbar start={startContent} end={endContent} />
                    </div>
                    <div className="col-12">
                        <div className="e-progress-bar">
                            {loadingList
                                ? <ProgressBar className="e-progress-bar" mode="indeterminate"></ProgressBar>
                                : null
                            }
                        </div>
                        <DataTable
                            value={data}
                            dataKey="id"
                            className="p-datatable-gridlines border-all"
                            resizableColumns
                            columnResizeMode="expand"
                            emptyMessage={t("common.no_data")}
                            scrollable
                            scrollDirection="both"
                            scrollHeight="flex"
                            lazy
                            paginator
                            first={meta?.first || 0}
                            rows={filter?.size || 20}
                            totalRecords={meta.total}
                            rowsPerPageOptions={[10, 20, 25, 50, 100]}
                            onPage={onPage}
                            paginatorTemplate="RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink"
                            currentPageReportTemplate="{first} - {last} of {totalRecords}"
                            reorderableColumns
                            reorderableRows
                        >
                            <Column
                                style={{ width: 100 }}
                                field="STT"
                                body={(index, value) => value?.rowIndex + 1}
                                header={t("common.no")}
                            />
                            <Column
                                header={t("offer_at.name")}
                                style={{ minWidth: 250 }}
                                field="name"
                                body={renderCol0}
                            />
                            <Column
                                columnKey="action"
                                alignFrozen="right"
                                frozen
                                header={t("common.action")}
                                className="col-frozen-table-end py-1"
                                style={{ minWidth: 180, width: 180 }}
                                body={(d) => {
                                    return (
                                        <div className="flex">
                                            {/* <Button icon="pi pi-eye" rounded text onClick={() => viewData(d)} /> */}
                                            <Button icon="pi pi-pencil" rounded text onClick={() => editData(d)}
                                                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.UPDATE)}
                                            />
                                            <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" onClick={() => deleteData(d.id)}
                                                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.DELETE)}
                                            />
                                        </div>
                                    )
                                }}
                            />
                        </DataTable>
                    </div>
                </div>
            </div>
            <OfferAttributeDetail optionRefType={optionRefType} refType={refType} eventId={eventId} ref={refDetail} afterSubmit={afterSubmit}></OfferAttributeDetail>

        </div>
    )
}

export default OfferAttributeList;
