import axiosClient from "./axiosClient";

const CompanyServices = {
    search: (payload) => {
        return axiosClient.get(`/company`, {
            params: payload
        });
    },

    getById: (id) => {
        return axiosClient.get(`/company/${id}`);
    },

    create: (payload) => {
        return axiosClient.post(`/company`, payload);
    },

    update: (payload) => {
        return axiosClient.put(`/company/${payload.id}`, payload);
    },

    delete: (id) => {
        return axiosClient.delete(`/company/${id}`);
    },
}

export default CompanyServices