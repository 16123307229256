import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import BusinessMatchingDetail from "./BusinessMatchingDetail";
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { ToastHelper } from "../../helper/ToastHelper";
import { Dropdown } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';
import EBreadCrumb from "../../components/EBreadCrumb";
import _, { cloneDeep, find, filter } from 'lodash'
import { useSelector } from "react-redux";
import { checkPermission, exportBusinessMatchingXlsx, exportDataBusinessMatchingXlsx, exportDoc } from "../../utils";
import { BUSINESS_MATCHING_ROLE, MEET_OPTIONS, MEET_STATUS, PERMISSION } from "../../utils/constants";
import EventsServices from "../../core/services/EventsServices";
import { useParams } from "react-router";
import { ProgressBar } from "primereact/progressbar";
import EventMeetingService from "../../core/services/EventMeetingService";
import { Tag } from "primereact/tag";
import BusinessMatchingExportDetail from "./BusinessMatchingExportDetail";
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';
import expressionParser from 'docxtemplater/expressions';

const screenPermission = "route.eventInfoScreen_business-matching"

function BusinessMatching({ }) {
    const { t } = useTranslation()

    const { eventId } = useParams()

    const [event, setEvent] = useState()

    const { groupPermission, language } = useSelector((state) => state.userReducer)

    const defaultFilter = {
        type: "",
        send: BUSINESS_MATCHING_ROLE.SEND,
        eventId: eventId,
        // keyword: "",
        page: 0,
        size: 10,
        total: 0,
        first: 0
    }
    const [filter, setFilter] = useState(defaultFilter)

    const [meta, setMeta] = useState(filter)

    const [lazyParams, setLazyParams] = useState()

    const refDetail = useRef()
    const filterRef = useRef()

    const refExportDetail = useRef()


    const op = useRef()
    const [data, setData] = useState([])
    const [all, setAll] = useState([])

    const [loadingList, setLoadingList] = useState(false)

    const [loadingExport, setLoadingExport] = useState(false)

    const [businessMatchingRole, setBusinessMatchingRole] = useState([])

    useEffect(() => {
        loadEvent()
        setBusinessMatchingRole(BUSINESS_MATCHING_ROLE.list.map(o => ({
            ...o,
            name: t(o.name)
        })))
    }, [])

    const onExport = () => {
        setLoadingExport(true)
        EventMeetingService.getAll({
            eventId: eventId,
        }, {
            'Content-Language': language
        }).then(res => {
            setLoadingExport(false)
            if (res) {
                setAll(res)
                refExportDetail.current.create()
            }
        })
    }

    const loadEvent = () => {
        EventsServices.getById(eventId).then(res => {
            if (res) {
                setEvent(res)
            }
        })
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getList(filter)
        }, 200)
        return () => clearTimeout(delayDebounceFn)
    }, [filter])

    const getList = (_filter) => {
        _filter = _filter ?? filter
        setLoadingList(true)
        EventMeetingService.get(eventId, {
            send: _filter.send == BUSINESS_MATCHING_ROLE.SEND ? true : _filter.send == BUSINESS_MATCHING_ROLE.RECEIVE ? false : null
        }).then((res) => {
            setLoadingList(false)
            if (res) {
                setData(res)
            }
        })
    }

    const onChangeFilter = (prop, value) => {
        let _filter = cloneDeep(filter)
        _filter[prop] = value
        setFilter(_filter)
    }

    const createData = () => {
        refDetail.current.create()
    }

    const editData = (item) => {
        EventMeetingService.getByCompany(eventId, item.id).then((res) => {
            refDetail.current.edit(_.filter(res, o => o.fromCompany.id == item.id))
        })

    }

    const onDetail = (item) => () => {
        EventMeetingService.getByCompany(eventId, item.id).then((res) => {
            refDetail.current.view(res, item.id)
        })
    }


    const onDoc = (item) => async () => {
        const res = await EventsServices.searchCompany(eventId)

        let _companies = res.map(o => {
            let _com = _.find(o.attributes, { languageCode: language })
            return {
                ...o,
                name: _com?.name,
                description: _com?.description
            }
        })
        EventMeetingService.getByCompany(eventId, item.id).then((res) => {
            let _items = _.filter(res, o => o.fromCompany.id == item.id)
            _items.map(o => {
                let _comTo = _.find(_companies, { id: o.toCompany.id })
                let _comFrom = _.find(_companies, { id: o.fromCompany.id })
                o.toCompany.industry = _comTo?.industry ?? []
                o.toCompany.location = _comTo.location
                o.fromCompany.industry = _comFrom?.industry ?? []
                o.fromCompany.location = _comFrom.location
            })
            let name = _.find(event.attributes, { languageCode: language })?.name
            exportDoc(_items, name, language)
        })
    }

    const onExcel = (item) => async () => {
        const res = await EventsServices.searchCompany(eventId)

        let _companies = res.map(o => {
            let _com = _.find(o.attributes, { languageCode: language })
            return {
                ...o,
                name: _com?.name,
                description: _com?.description
            }
        })
        EventMeetingService.getByCompany(eventId, item.id).then((res) => {
            let _items = _.filter(res, o => o.fromCompany.id == item.id)
            _items.map(o => {
                let _comTo = _.find(_companies, { id: o.toCompany.id })
                let _comFrom = _.find(_companies, { id: o.fromCompany.id })
                o.toCompany.industry = _comTo?.industry ?? []
                o.toCompany.location = _comTo.location
                o.fromCompany.industry = _comFrom?.industry ?? []
                o.fromCompany.location = _comFrom.location
            })
            exportBusinessMatchingXlsx(_items, language)
        })
    }

    const viewData = (item) => {
        refDetail.current.view(item)
    }

    const deleteData = (id) => {
        confirmDialog({
            message: t("business_matching.confirm_content"),
            header: t("common.confirm"),
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            rejectLabel: t("common.cancel"),
            acceptLabel: t("common.confirm"),
            accept: () => {
                EventMeetingService.delete(id, {
                    code: event.eventCode
                }).then((res) => {
                    if (res) {
                        ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
                        getList()
                    }
                })
            }
            ,
            reject: () => { }
        });

    }
    const afterSubmit = () => {
        getList()
        ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
    }

    const onPage = (e) => {
        let _filter = cloneDeep(filter)
        _filter.size = e.rows;
        _filter.page = e.page;
        setFilter(_filter)
    };

    const startContent = (
        <React.Fragment>
            <Button
                icon="pi pi-plus"
                className="mr-2"
                label={t("common.add")}
                outlined
                size="small"
                onClick={() => createData()}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.CREATE)}
            />
            {/* <Button
                icon="pi pi-download"
                label={t("common.export")}
                outlined
                size="small"
                onClick={onExport}
                disabled={loadingExport || !checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)}
                loading={loadingExport}
            /> */}
        </React.Fragment>
    );

    const endContent = (<div className="flex gap-3">
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText placeholder={t("company.search")} value={filter.keyword} onChange={(e) => { onChangeFilter("keyword", e.target.value) }} />
        </span>
        <Dropdown
            placeholder={t("common.all")}
            value={filter.send}
            onChange={(e) => onChangeFilter("send", e.value)}
            options={businessMatchingRole}
            optionLabel="name"
            optionValue="id"
            className="w-full"
            showClear
        />
    </div>
    );

    const renderCol0 = (d, value) => {
        let com = find(d.attributes ?? [], { languageCode: language })
        return (
            <div className="text-primary underline cursor-pointer" onClick={onDetail(d)}>{com?.name}</div>
        )
    }

    const renderCol1 = (d, value) => {
        let _rank = find(d.rank?.languages ?? [], { languageCode: language })
        return (
            <div className="">{_rank?.name}</div>
        )
    }

    const renderCol3 = (d, value) => {
        // let status = find(MEET_STATUS.list, { id: d.eventMeeting.status })
        return (
            <span>
                {/* <Tag
                    severity={d.eventMeeting.status == MEET_STATUS.ACCEPT
                        ? 'success'
                        : d.eventMeeting.status == MEET_STATUS.NONE
                            ? 'info'
                            : d.eventMeeting.status == MEET_STATUS.WAITING
                                ? 'warning'
                                : 'danger'}
                >
                    {t(status?.name)}
                </Tag> */}

            </span>
        )
    }

    if (!checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)) {
        return null
    }

    return (
        <div className="event-layout">
            <div className="event-layout-center overflow-hidden">

                <div className="grid">
                    <div className="col-12 ">
                        <Toolbar start={startContent} end={endContent} />
                    </div>
                    <div className="col-12">
                        <div className="e-progress-bar">
                            {loadingList
                                ? <ProgressBar className="e-progress-bar" mode="indeterminate"></ProgressBar>
                                : null
                            }
                        </div>
                        <DataTable
                            value={data}
                            dataKey="id"
                            className="p-datatable-gridlines border-all"
                            resizableColumns
                            columnResizeMode="expand"
                            emptyMessage={t("common.no_data")}
                            scrollable
                            scrollDirection="both"
                            scrollHeight="flex"
                            lazy
                            paginator
                            first={meta?.first || 0}
                            rows={filter?.size || 20}
                            totalRecords={meta.total}
                            rowsPerPageOptions={[10, 20, 25, 50, 100]}
                            onPage={onPage}
                            paginatorTemplate="RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink"
                            currentPageReportTemplate="{first} - {last} of {totalRecords}"
                            reorderableColumns
                            reorderableRows
                        >
                            <Column
                                style={{ width: 100 }}
                                field="STT"
                                body={(index, value) => value?.rowIndex + 1}
                                header={t("common.no")}
                            />
                            <Column
                                style={{ width: 100 }}
                                field="code"
                                header={t("common.code")}
                            />
                            <Column
                                header={t("common.company")}
                                style={{ minWidth: 300 }}
                                field="name"
                                body={renderCol0}
                            />
                            <Column
                                header={t("business_matching.type")}
                                style={{ minWidth: 300 }}
                                field="name"
                                body={renderCol1}
                            />
                            <Column
                                header={t("business_matching.count")}
                                style={{ minWidth: 250 }}
                                field="totalMeeting"
                            />
                            <Column
                                columnKey="action"
                                alignFrozen="right"
                                frozen
                                header={t("common.action")}
                                className="col-frozen-table-end py-1"
                                style={{ minWidth: 150, width: 150 }}
                                body={(d) => {
                                    return (
                                        <div className="flex">
                                            {/* <Button icon="pi pi-eye" rounded text onClick={() => viewData(d)} /> */}
                                            <Button icon="pi pi-pencil" rounded text onClick={() => editData(d)}
                                                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.UPDATE)}
                                                size='small'
                                            />
                                            <Button icon="pi pi-file" rounded text onClick={onDoc(d)}
                                                size='small'
                                                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.UPDATE) || d?.status == MEET_STATUS.REJECT || d?.status == MEET_STATUS.ACCEPT}
                                            />
                                            <Button icon="pi pi-file-excel" rounded text onClick={onExcel(d)}
                                                size='small'
                                                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.UPDATE) || d?.status == MEET_STATUS.REJECT || d?.status == MEET_STATUS.ACCEPT}
                                            />
                                        </div>
                                    )
                                }}
                            />
                        </DataTable>
                    </div>
                </div>
            </div>
            <BusinessMatchingDetail
                eventId={eventId}
                event={event}
                ref={refDetail}
                afterSubmit={afterSubmit}
            />
            <BusinessMatchingExportDetail
                data={all}
                ref={refExportDetail}
            />
        </div>
    )
}

export default BusinessMatching;
