export const BASE_URL = process.env.REACT_APP_BASE_URL
// export const BASE_URL = "https://localhost:7186/api/cms/"
export const BASE_SHARE_URL = process.env.REACT_APP_BASE_SHARE_URL
// export const BASE_S3_URL = "http://dev-apis3.xdp.vn/expolander/"
export const BASE_S3_URL = process.env.REACT_APP_BASE_S3_URL

export const API_METHOD = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete'
}