import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SpeedDial } from 'primereact/speeddial';
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import "./ListLocation.scss"
import { classNames } from "primereact/utils";
import EDialog from "../../components/EDialog";
import Maps from "../Maps";
import MapsServices from "../../core/services/MapsServices";
import { ACTION, CANVAS_TYPE, FILE_TYPE, UPLOAD_STATUS } from "../../utils/constants";
import { filter, remove } from 'lodash'
import S3FileUpload from "../../components/S3FileUpload";
import { BASE_S3_URL } from "../../config";

function ListLocationDetail({ reload, eventId }, ref) {
    const { t } = useTranslation()
    const refDialog = useRef(null)

    const [loading, setLoading] = useState(false)

    const [loadingSave, setLoadingSave] = useState(false)

    const s3FileUploadRef = useRef()

    const defaultData = {
        name: "",
        image: null
    }
    const defaultError = {
        name: null,
    }
    const [data, setData] = useState(defaultData)
    const [error, setError] = useState(defaultError)
    const [action, setAction] = useState(ACTION.CREATE)

    const mapRef = useRef()

    useImperativeHandle(ref, () => ({
        create: () => {
            setData(defaultData)
            setAction(ACTION.CREATE)
            refDialog.current.create()
        },
        edit: (id) => {
            MapsServices.getById(id, eventId).then(res => {
                if (res) {
                    setData({
                        ...res,
                        image: res?.image ? {
                            name: res?.image,
                            status: UPLOAD_STATUS.UPLOADED
                        } : null
                    })
                    
                    setAction(ACTION.UPDATE)
                    refDialog.current.create()
                }
            })
        },
        view: () => {
            setAction(ACTION.VIEW)
            refDialog.current.create()
        }
    }));

    const onShow = () => {
        if (data?.image) {
            s3FileUploadRef.current.setFiles([data.image.name], FILE_TYPE.IMAGE)
        }
    }

    const save = () => {
        const invalid = validateData([], data)
        if (!invalid) {
            const json = mapRef.current.onSave()
            // debugger
            // remove(json.objects, {_type: "image"})

            switch (action) {
                case ACTION.CREATE:
                    setLoadingSave(true)
                    MapsServices.create(eventId, {
                        ...data,
                        image: data?.image?.name,
                        json: JSON.stringify(json)
                    }).then(res => {
                        if (res) {
                            let locations = filter(json.objects, { _type: CANVAS_TYPE.NODE })
                            MapsServices.createLocations(res.id, eventId, {
                                items: locations.map(o => ({
                                    parentId: res.id,
                                    name: o._title,
                                    refId: o.id
                                }))
                            }).then(res1 => {
                                if (res1) {
                                    refDialog.current.close()
                                    if (reload) {
                                        reload()
                                    }
                                }
                                setLoadingSave(false)
                            })
                        } else {
                            setLoadingSave(false)
                        }
                    })
                    break
                case ACTION.UPDATE:
                    setLoadingSave(true)
                    MapsServices.update(eventId, {
                        ...data,
                        image: data?.image?.name,
                        json: JSON.stringify(json)
                    }).then(res => {
                        if (res) {
                            let locations = filter(json.objects, { _type: CANVAS_TYPE.NODE })
                            MapsServices.createLocations(res.id, eventId, {
                                items: locations.map(o => ({
                                    parentId: res.id,
                                    name: o._title,
                                    refId: o.id
                                }))
                            }).then(res1 => {
                                if (res1) {
                                    refDialog.current.close()
                                    if (reload) {
                                        reload()
                                    }
                                }
                                setLoadingSave(false)
                            })
                        } else {
                            setLoadingSave(false)
                        }
                    })
                    break
            }
        }
    }

    const onChangeData = (prop, value) => {
        let _data = structuredClone(data)
        _data[prop] = value
        switch(prop) {
            case "image":
                mapRef.current.addImage(value ? `${BASE_S3_URL}${value.name}` : null)
            break
        }
        validateData([prop], _data)
        setData(_data)
    }

    const validateData = (prop, _data) => {
        let _error = structuredClone(error)
        let requiredFields = prop.length > 0 ? prop : Object.keys(_data);
        let _prop = requiredFields
        for (const field of _prop) {
            switch (field) {
                case "name":
                    _error[field] = ""
                    if (!_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                case "image":
                    _error[field] = ""
                    if (!_data[field] || _data[field].status != UPLOAD_STATUS.UPLOADED) {
                        _error[field] = t("common.validate_content")
                    }
                    break;

                default:
                    break;
            }
        }

        setError(_error)
        let count = 0;
        for (const key in _error) {
            if (_error[key]) {
                count++;
            }
        }
        return count;
    }

    const renderError = (field) => {
        if (error[field]) {
            return <span className="text-red-500 text-xs">{error[field]}</span>
        }
    }

    const cancel = () => {
        refDialog.current.close()
    }

    const onUploadedImage = useCallback((val) => {
        onChangeData("image", val)
    }, [data])

    return (
        <EDialog
            ref={refDialog}
            title={t("location.maps")}
            closable={true}
            hidebtnFooter={false}
            style={{ width: '100vw' }}
            btnFooter={[
                {
                    label: t("common.cancel"),
                    onClick: () => cancel(),
                    text: true
                },
                {
                    label: t("common.save"),
                    onClick: () => save(),
                    loading: loading || loadingSave,
                    disabled: loading || loadingSave
                }
            ]}
            onShow={onShow}
        >


            <div className="p-2">
                <div className="grid">
                    <div className="col-6 field">
                        <label className="require">Name</label>
                        <InputText
                            value={data?.name}
                            onChange={(e) => { onChangeData("name", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["name"] })}
                        />
                        {renderError("name")}
                    </div>
                    <div className="col-12 field">
                        <label className="require">{t("event.banner.image")}</label>
                        <S3FileUpload
                            ref={s3FileUploadRef}
                            onChange={onUploadedImage}
                            accept="image/*"
                            onLoading={setLoading}
                            clearActive={true}
                        />
                        {renderError("image")}
                    </div>
                    <div className="col-12">
                        <Maps ref={mapRef} json={data?.json} />
                    </div>
                </div>
            </div>
        </EDialog>
    )
}
ListLocationDetail = forwardRef(ListLocationDetail)
export default ListLocationDetail;
