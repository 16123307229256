import axiosClient from "./axiosClient";

const UserServices = {
    searchUserCompany: (headers, payload) => {
        return axiosClient.get(`/user/company`, {
            headers: headers,
            params: payload
        });
    },

    addUserCompany: (id, params, headers) => {
        console.log(headers)
        return axiosClient.post(`/user/company/${id}`, params,  { headers: headers });
    },
    deleteUserCompany: (id, params, headers) => {
        console.log(headers)
        return axiosClient.delete(`/user/company/${id}`,  { headers: headers, data: params });
    },
    event: {
        getById: (headers, id) => {
            return axiosClient.get(`/user/event/user/${id}`, {
                headers
            });
        },
        create: (payload) => {
            return axiosClient.post(`/user/event`, payload);
        },
    
        update: (payload) => {
            return axiosClient.put(`/user/event/${payload.id}`, payload);
        },
    },

    searchUserEvent: (headers, payload) => {
        return axiosClient.get(`/user/event`,{
            headers: headers,
            params: payload
        });
    },

    activeUserEvent: (id, params, headers) => {
        return axiosClient.put(`/user/event/active/${id}`, params,  { headers: headers });
    },

    get: (payload) => {
        return axiosClient.get(`/user`, {
            params: payload
        });
    },

    // getById: (id) => {
    //     return axiosClient.get(`/user/${id}`);
    // },

    create: (payload) => {
        return axiosClient.post(`/user`, payload);
    },

    update: (payload) => {
        return axiosClient.put(`/user/${payload.id}`, payload);
    },

    delete: (id) => {
        return axiosClient.delete(`/user/${id}`);
    },
}

export default UserServices;