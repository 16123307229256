import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import EDialog from "../../../../components/EDialog";
import { Button } from "primereact/button";
import { ACTION, UPLOAD_STATUS } from "../../../../utils/constants";
import { cloneDeep } from 'lodash'
import EventsServices from "../../../../core/services/EventsServices";
import S3FileUpload from "../../../../components/S3FileUpload";

function EventFileDetail({ afterSubmit, type, refId }, ref) {
    const { t } = useTranslation()
    const defaultData = {
        fileType: "",
        title: "",
        url: ""
    }
    const defaultError = {
        fileType: "",
        title: "",
        url: ""
    }

    const [loading, setLoading] = useState(false)

    const s3FileUploadRef = useRef()

    const [data, setData] = useState(defaultData)

    const [error, setError] = useState(defaultError)

    const [action, setAction] = useState()

    const refDialog = useRef(null)


    useImperativeHandle(ref, () => ({
        create: () => {
            refDialog.current.create();
            setAction(ACTION.CREATE)
        },
        edit: (item) => {
            refDialog.current.create();
            setAction(ACTION.UPDATE);
            setData({
                ...item,
                url: item?.url ? {
                    name: item?.url,
                    type: item?.fileType,
                    status: UPLOAD_STATUS.UPLOADED
                } : null
            })
        },
    }));

    const onShow = () => {
        if (data.url) {
            s3FileUploadRef?.current?.setFiles([data?.url?.name], data?.url?.type)
        }
    }

    const onChangeData = (prop, value) => {
        let _data = cloneDeep(data)
        _data[prop] = value
        validateData([prop], _data)
        setData(_data)
    }

    const validateData = (prop, _data) => {
        let _error = cloneDeep(error)
        let requiredFields = prop.length > 0 ? prop : Object.keys(_data);
        let _prop = requiredFields
        for (const field of _prop) {
            switch (field) {
                case "title":
                    _error[field] = ""
                    if (!_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                case "url":
                    _error[field] = ""
                    if (!_data[field] || _data[field].status != UPLOAD_STATUS.UPLOADED) {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                default:
                    break;
            }
        }

        setError(_error)
        let count = 0;
        for (const key in _error) {
            if (_error[key]) {
                count++;
            }
        }
        return count;
    }

    const onSubmit = () => {
        let validate = validateData([], data)
        if (validate) return
        switch (action) {
            case ACTION.CREATE:
                EventsServices.createFile(type, refId, {
                    ...data,
                    url: data?.url?.name,
                    fileType: data?.url?.type,
                }).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            case ACTION.UPDATE:
                EventsServices.updateFile(type, refId, {
                    ...data,
                    url: data?.url?.name,
                    fileType: data?.url?.type,
                }).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            default:
                break;
        }

    }

    const renderError = (field) => {
        if (error[field]) {
            return <span className="text-red-500 text-xs">{error[field]}</span>
        }
    }

    const cancel = () => {
        refDialog.current.close()
        setData(defaultData)
        setError(defaultError)
    }

    const onUploadedUrl = useCallback((val) => {
        onChangeData("url", val)
    }, [data])

    return (
        <EDialog
            ref={refDialog}
            style={{ width: '70vw' }}
            title={t("event.file_information")}
            closable={false}
            onShow={onShow}
            hidebtnFooter={false}
            btnFooter={
                action === ACTION.VIEW ?
                    [
                        {
                            text: true,
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        }
                    ] :
                    [
                        {
                            text: true,
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        },
                        {
                            label: t("common.save"),
                            onClick: () => onSubmit(),
                            loading
                        }
                    ]
            }
        >
            <div className="container">
                <div className="grid">
                    <div className="col-6 field">
                        <label>{t("event.title")}</label>
                        <InputText
                            disabled={action === "view"}
                            className="w-full"
                            onChange={(e) => { onChangeData("title", e.target.value) }}
                            value={data?.title}
                        ></InputText>
                        {renderError("title")}
                    </div>
                    {/* <div className="col-6 field">
                        <label>{t("event.url")}</label>
                        <InputText
                            disabled={action === "view"}
                            className="w-full"
                            onChange={(e) => { onChangeData("url", e.target.value) }}
                            value={data?.url}
                        ></InputText>
                        {renderError("url")}
                    </div> */}
                    <div className="col-12 field">
                        <label className="require">{t("event.url")}</label>
                        <S3FileUpload
                            ref={s3FileUploadRef}
                            onChange={onUploadedUrl}
                            onLoading={setLoading}
                            accept=".pdf"
                        />
                        {renderError("url")}
                    </div>
                    <div className="col-6 field">
                        <label>{t("event.file_type")}</label>
                        <InputText
                            disabled={true}
                            className="w-full"
                            onChange={(e) => { onChangeData("fileType", e.target.value) }}
                            value={data?.url?.type}
                        ></InputText>
                        {renderError("fileType")}
                    </div>
                </div>
            </div>
        </EDialog>
    )
}
EventFileDetail = forwardRef(EventFileDetail)
export default EventFileDetail;
