import { put, call, takeEvery, all, fork } from "redux-saga/effects";
import { REQUEST_DELETE_EVENT_USER_TYPE, REQUEST_DELETE_EVENT_USER_TYPE_SUCCESS, REQUEST_GET_EVENT_USER_TYPE, REQUEST_GET_EVENT_USER_TYPE_SUCCESS, REQUEST_POST_EVENT, REQUEST_POST_EVENT_SUCCESS, REQUEST_POST_EVENT_USER_TYPE, REQUEST_POST_EVENT_USER_TYPE_SUCCESS, REQUEST_POST_LOGIN, REQUEST_POST_LOGIN_SUCCESS, REQUEST_PUT_EVENT_USER_TYPE, REQUEST_PUT_EVENT_USER_TYPE_SUCCESS } from "../actions/ActionUser";
import AuthServices from "../../services/AuthService";
import EventsServices from "../services/EventsServices";


// Comment here
function* postLogin(action) {
  try {
    const data = yield call(AuthServices.login, action.params)
    if (data) {
      yield put({
        type: REQUEST_POST_LOGIN_SUCCESS,
        params: data
      })
    }
    if (action.callBack) {
      action.callBack(data)
    }
  } catch (error) {
    console.log(error)
  }
}

function* postEvent(action) {
  try {
    const data = yield call(AuthServices.postEvent, action.params)
    if (data) {
      yield put({
        type: REQUEST_POST_EVENT_SUCCESS,
        params: data
      })
    }
    if (action.callBack) {
      action.callBack(data)
    }
  } catch (error) {
    console.log(error)
  }
}

function* getEventUserType(action) {
  try {
    const data = yield call(EventsServices.userType.get, action.params)
    if (data) {
      yield put({
        type: REQUEST_GET_EVENT_USER_TYPE_SUCCESS,
        params: data
      })
    }
    if (action.callBack) {
      action.callBack(data)
    }
  } catch (error) {
    console.log(error)
  }
}

function* postEventUserType(action) {
  try {
    const data = yield call(EventsServices.userType.create, action.params)
    if (data) {
      yield put({
        type: REQUEST_POST_EVENT_USER_TYPE_SUCCESS,
        params: data
      })
    }
    if (action.callBack) {
      action.callBack(data)
    }
  } catch (error) {
    console.log(error)
  }
}

function* putEventUserType(action) {
  try {
    const data = yield call(EventsServices.userType.update, action.params)
    if (data) {
      yield put({
        type: REQUEST_PUT_EVENT_USER_TYPE_SUCCESS,
        params: data
      })
    }
    if (action.callBack) {
      action.callBack(data)
    }
  } catch (error) {
    console.log(error)
  }
}

function* deleteEventUserType(action) {
  try {
    const data = yield call(EventsServices.userType.delete, action.params)
    if (data) {
      yield put({
        type: REQUEST_DELETE_EVENT_USER_TYPE_SUCCESS,
        params: data
      })
    }
    if (action.callBack) {
      action.callBack(data)
    }
  } catch (error) {
    console.log(error)
  }
}

function* watchpostLogin() {
  yield takeEvery(REQUEST_POST_LOGIN, postLogin);
  yield takeEvery(REQUEST_POST_EVENT, postEvent);
  yield takeEvery(REQUEST_GET_EVENT_USER_TYPE, getEventUserType);
  yield takeEvery(REQUEST_POST_EVENT_USER_TYPE, postEventUserType);
  yield takeEvery(REQUEST_PUT_EVENT_USER_TYPE, putEventUserType);
  yield takeEvery(REQUEST_DELETE_EVENT_USER_TYPE, deleteEventUserType);
}

export default function* UserSaga() {
  yield all([fork(watchpostLogin)]);
}
