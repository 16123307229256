import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import EventsServices from "../../../core/services/EventsServices";
import EventSpeakerDetail from "./EventSpeakerDetail";
import { ToastHelper } from "../../../helper/ToastHelper";
import { confirmDialog } from "primereact/confirmdialog";
import { Link, useParams } from "react-router-dom";
import { EnumEventModule, PERMISSION } from "../../../utils/constants";
import UserServices from "../../../core/services/UserServices";
import { checkPermission, findKeyword } from "../../../utils";
import { useSelector } from "react-redux";
import { find, cloneDeep } from 'lodash'
import { ProgressBar } from "primereact/progressbar";

const screenPermission = "eventInfoScreen_session_speakers"

function EventSpeaker(props) {
    const { t } = useTranslation();

    const { groupPermission } = useSelector((state) => state.userReducer)

    const [lazyParams, setLazyParams] = useState({
        keyword: ""
    })

    const { refId, eventId } = useParams()
    const refDetail = useRef();
    const [dictionary, setDictionary] = useState()

    const [data, setData] = useState([])

    const [loadingList, setLoadingList] = useState(false)

    useEffect(() => {
        if (refId) {
            loadList()
            loadDictionary()
        }
    }, [])

    const loadList = () => {
        setLoadingList(true)
        EventsServices.searchEventSpeaker(refId, eventId).then((res) => {
            setLoadingList(false)
            if (res) {
                setData(res)
            }
        })
    }

    const loadDictionary = async () => {
        try {
            const _event = await EventsServices.getById(eventId);
            if (_event) {
                const userEventPromise = UserServices.searchUserEvent({
                    code: _event.eventCode
                },
                    {
                        first: 0,
                        page: 0,
                        size: 9999,
                    });
                const [userEventData] = await Promise.all([userEventPromise]);
                setDictionary({
                    userEvent: userEventData.data,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };


    const createData = () => {
        refDetail.current.create()
    }

    const editData = (item) => {
        refDetail.current.edit(item)
    }

    const deleteData = (id) => {
        confirmDialog({
            message: t("common.confirm_content"),
            header: t("common.confirm"),
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            rejectLabel: t("common.cancel"),
            acceptLabel: t("common.confirm"),
            accept: () => {
                EventsServices.deleteEventSpeaker(refId, id, eventId).then((res) => {
                    if (res) {
                        ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
                        loadList()
                    }
                })
            }
            ,
            reject: () => { }
        });

    }

    const onChangeKeyword = (e) => {
        let _filter = cloneDeep(lazyParams)
        _filter.keyword = e.target.value
        setLazyParams(_filter)
    }

    const headerTable = () => {
        return (
            <div className="flex justify-content-between">
                <Button
                    icon="pi pi-plus"
                    className="mr-2"
                    label={t("common.add")}
                    outlined
                    size="small"
                    onClick={() => createData()}
                    disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.CREATE)}
                />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText placeholder={t("company.search")} value={lazyParams?.keyword} onChange={onChangeKeyword} />
                </span>
            </div>
        )
    }

    const renderColEnd = (row) => {
        return <span>
            <Button icon="pi pi-pencil" rounded text aria-label="Filter" onClick={() => editData(row)}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.UPDATE)}
            />
            <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" onClick={() => deleteData(row.id)}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.DELETE)}
            />
        </span>
    }

    const previewList = useMemo(() => {
        return findKeyword(data, lazyParams.keyword, ["user"], ["fullName", "email"])
    }, [lazyParams, data])

    if (!checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)) {
        return null
    }

    return (
        <div className="container-fluid">
            <div className="grid">
                <div className="col-12">
                    <div className="e-progress-bar">
                        {loadingList
                            ? <ProgressBar className="e-progress-bar" mode="indeterminate"></ProgressBar>
                            : null
                        }
                    </div>
                    <DataTable
                        value={previewList}
                        header={headerTable}
                        dataKey="id"
                        className="p-datatable-gridlines border-all"
                        resizableColumns
                        columnResizeMode="expand"
                        emptyMessage={t("common.no_data")}
                        scrollable
                        scrollDirection="both"
                        scrollHeight="flex"
                        lazy
                    >
                        <Column
                            style={{ width: 100 }}
                            field="STT"
                            body={(index, value) => value?.rowIndex + 1}
                            header={t("common.no")}
                        />
                        <Column
                            header={t("common.fullname")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {d?.user?.fullName}
                            </div>}
                        />
                        <Column
                            header={t("event.companyname")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {d?.user?.companyName}
                            </div>}
                        />
                        <Column
                            header={t("common.phonenumber")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {d?.user?.phoneNumber}
                            </div>}
                        />
                        <Column
                            header={t("common.email")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {d?.user?.email}
                            </div>}
                        />
                        <Column
                            header={t("event.quote")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {d?.quote}
                            </div>}
                        />
                        <Column
                            columnKey="action"
                            alignFrozen="right"
                            frozen
                            header={t("company.action")}
                            className="col-frozen-table-end py-1"
                            style={{ minWidth: 180, width: 180 }}
                            body={renderColEnd}
                        />
                    </DataTable>
                </div>
            </div>
            <EventSpeakerDetail
                refId={refId}
                eventId={eventId}
                type={EnumEventModule.SESSION}
                afterSubmit={loadList}
                ref={refDetail}
                dictionary={dictionary}
            />
        </div>
    )
}

export default EventSpeaker;
