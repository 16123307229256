/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss"
import { useTranslation } from "react-i18next";
import { Card } from "primereact/card";
import { Chart } from "primereact/chart";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import EventsServices from "../../../core/services/EventsServices";
import { filter, find, remove, findIndex } from 'lodash'
import { EVENT_SETTING, EVENT_STATUS, USER_PERMISSION } from "../../../utils/constants";
import moment from "moment";
import { Steps } from "primereact/steps";
import ProgressEvent from "../../../components/ProgressEvent";
import { ToastHelper } from "../../../helper/ToastHelper";
import { TabMenu } from 'primereact/tabmenu';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import _ from 'lodash'

function Interactions(props, ref) {

    const { t } = useTranslation()

    const { eventID } = props;
    const { events, permission, eventCode, language } = useSelector((state) => state.userReducer)

    const dispatch = useDispatch();

    const [list, setList] = useState([])

    // const [chartData, setChartData] = useState({});

    const [chartOptions, setChartOptions] = useState({});
    const [listDate, setListDate] = useState([]);
    const [dataFavorite, setDataFavorite] = useState([]);
    const [dataClick, setDataClick] = useState([]);
    const [report, setReport] = useState(null)
    const [chartData, setChartData] = useState({});
    const [chartData1, setChartData1] = useState({});
    const [chartData2, setChartData2] = useState({});
    const [activeIndex, setActiveIndex] = useState(0);
    const loadInteractions = (type) => {

        EventsServices.report.getInteractive(eventID, type).then((res) => {
            if (res) {
                setReport(res)
            }
        })
    }

    useEffect(() => {
        let _type = null
        switch (activeIndex) {
            case 0:
                _type = "COMPANY"
                break;
            case 1:
                _type = "USER"
                break;
            case 2:
                _type = "PRODUCT"
                break;
            default:
                _type = "COMPANY"
                break;
        }
        if (eventID) {
            loadInteractions(_type)
        }
    }, [eventID, activeIndex])

    useEffect(() => {
        let _temp = _.cloneDeep(report)
        let _list = _.concat(_temp?.favorite, _temp?.click);
        // console.log(_temp?.favorite)
        const sortedList = _.orderBy(_list, [(item) => {
            if (item?.title) {
                const [day, month, year] = item?.title.split('/');
                return new Date(year, month - 1, day);
            }
        }], 'asc');
        const listDate = sortedList?.map(item => item?.title)
        const newlistDate = _.uniq(listDate);
        setListDate(newlistDate);
        // const isContainDate = _.some(temp, { 'date': '15/04/2024' });
        setDataFavorite(getValueArray(newlistDate, _temp?.favorite))
        setDataClick(getValueArray(newlistDate, _temp?.click))
    }, [report])

    const getValueArray = (date, data) => {
        const sortedList = _.orderBy(data, [(item) => {
            if (item?.title) {
                const [day, month, year] = item?.title.split('/');
                return new Date(year, month - 1, day);
            }
        }], 'asc');
        let _array = [];
        let _temp = sortedList ? sortedList[0]?.total : null
    
        if (_temp) {
            let _index = 0;
            date.map((item) => {
                if (!_.some(sortedList, { 'title': item })) {
                    // console.log(item, "không thuộc")
                    _array.push({ title: item, total: _temp})
                } else {
                    _temp=sortedList[_index]?.total
                    if (sortedList.length >= _index) {
                        _array.push(sortedList[_index])
                        _index++;
                        
                    }
                    
                }
                // console.log(_temp)
                // debugger
            }
            )
        }
        // console.log(data)
        // console.log(_array)
        const listTotal = _array?.map(item => item?.total)
        if(listTotal.length==0){
            date?.map((item)=>{
                listTotal.push(0)
            })
        }
        return listTotal;

    }
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: listDate,
            datasets: [
                {
                    label: t("chart.follow"),
                    data: dataFavorite,
                    fill: false,
                    stepped: 'middle',
                    tension: 0,
                    borderColor: documentStyle.getPropertyValue('--green-500')
                },
                {
                    label: t("chart.visit"),
                    data: dataClick,
                    fill: false,
                    stepped: 'middle',
                    // borderDash: [5, 5],
                    tension: 0,
                    borderColor: documentStyle.getPropertyValue('--blue-500')
                }
            ]
        };
        
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartOptions(options);
        setChartData(data);
    }, [listDate]);





    const items = [
        { label: t("chart.my_company") },
        { label: t("chart.my_profile") },
        { label: t("chart.my_product") }
    ];


    return (

        <>
            <div className="col-12">
                <Card className="border-round-2xl shadow-2">
                    <div className="flex justify-content-center">
                        <div className="text-center mt-2 mb-4">
                            <div className="text-5xl font-bold text-900">{t("chart.interact")}</div>
                            <div className="text-lg font-medium">{t("chart.understand_what")}</div>
                            {/* <TabMenu model={itemsChart1} /> */}
                            <div className="menu-tab-css flex justify-content-center">
                                <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                            </div>
                        </div>
                    </div>
                   <Chart type="line" data={chartData} options={chartOptions} />

                </Card>
            </div>
            <div className="col-12 lg:col-6">
                <Card className="border-round-2xl shadow-2">
                    <div className="text-center text-900 ">
                        <div className="font-bold text-7xl ">{_.last(dataClick)??0}</div>
                        <p className="font-bold text-lg">{t("chart.visit")}</p>
                        <p className="text-lg">{t("chart.total_number_accessing")}</p>
                    </div>

                </Card>
            </div>
            <div className="col-12 lg:col-6">
                <Card className="border-round-2xl shadow-2">
                    <div className="text-center text-900 ">
                        <div className="font-bold text-7xl ">{_.last(dataFavorite)??0}</div>
                        <p className="font-bold text-lg">{t("chart.follow")}</p>
                        <p className="text-lg">{t("chart.attention_on_information")}</p>
                    </div>

                </Card>
            </div>

        </>

    )
}
Interactions = forwardRef(Interactions)
export default Interactions;

