import axiosClient from "./axiosClient";

const ReferenceServices = {
    get: (eventId, type, resourceId) => {
        return axiosClient.get(`/event/${eventId}/reference/${type}/${resourceId}`);
    },

    create: (eventId, type, resourceId, payload) => {
        return axiosClient.post(`/event/${eventId}/reference/${type}/${resourceId}`, payload);
    },

    update: (id, eventId, type, resourceId, payload) => {
        return axiosClient.put(`/event/${eventId}/reference/${type}/${resourceId}/${id}`, payload);
    },

    delete: (id, eventId, type, resourceId) => {
        return axiosClient.delete(`/event/${eventId}/reference/${type}/${resourceId}/${id}`);
    },
}

export default ReferenceServices