import axiosClient from "./axiosClient";

const RanksServices = {
    get: (payload) => {
        return axiosClient.get(`/ranks`, {
            params: payload
        });
    },

    getById: (id) => {
        return axiosClient.get(`/ranks/${id}`);
    },

    create: (payload) => {
        return axiosClient.post(`/ranks`, payload);
    },

    update: (payload) => {
        return axiosClient.put(`/ranks/${payload.id}`, payload);
    },

    delete: (id) => {
        return axiosClient.delete(`/ranks/${id}`);
    },
}

export default RanksServices