import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import EventsServices from "../../../core/services/EventsServices";
import UserCompanyDetail from "./UserCompanyDetail.js";
import { confirmDialog } from "primereact/confirmdialog";
import { Link, useParams } from "react-router-dom";
import UserServices from "../../../core/services/UserServices";
import { Badge } from 'primereact/badge';
import { cloneDeep } from 'lodash'
import { useSelector } from "react-redux";
import { checkPermission } from "../../../utils";
import { PERMISSION } from "../../../utils/constants";
import { InputText } from "primereact/inputtext";
import { ProgressBar } from "primereact/progressbar";

function UserCompany({ isAdmin, screenPermission }) {
    const { t } = useTranslation();

    const { groupPermission } = useSelector((state) => state.userReducer)

    const { eventId, refId } = useParams()
    const refDetail = useRef();
    const [event, setEvent] = useState()
    const [lazyParams, setLazyParams] = useState({
        keyword: "",
        first: 0,
        page: 0,
        size: 10,
        total: 0,
        status: 1
    })
    const [meta, setMeta] = useState(lazyParams)

    const [data, setData] = useState([])
    const [dictionary, setDictionary] = useState(null);
    const [userEvent, setUserEvent] = useState(null);

    const [loadingList, setLoadingList] = useState(false)

    useEffect(() => {
        if (eventId && refId) {
            loadDictionary()
        }
    }, [])

    useEffect(() => {

        const delayDebounceFn = setTimeout(() => {
            if (eventId && refId) {
                loadList(lazyParams)
            }
        }, 200)
        return () => clearTimeout(delayDebounceFn)
    }, [lazyParams])

    const loadList = async (_lazyParams) => {
        _lazyParams = cloneDeep(_lazyParams ? _lazyParams : lazyParams)
        _lazyParams.page = _lazyParams.page + 1
        let _event = cloneDeep(event)
        setLoadingList(true)
        if (!_event) {
            _event = await EventsServices.getById(eventId)
            setEvent(_event)
        }
        if (_event) {
            UserServices.searchUserCompany({
                code: _event.eventCode
            }, {
                ..._lazyParams,
                companyId: refId,
                isAdmin
            }).then((res) => {
                setLoadingList(false)
                if (res) {
                    setData(res.data)
                    setMeta({
                        ...meta,
                        first: (res.pageNumber - 1) * res.pageSize,
                        total: res.totalRecord
                    })
                }
            })
        } else {
            setLoadingList(false)
        }

    }

    const loadDictionary = async () => {
        try {
            const _event = await EventsServices.getById(eventId);
            if (_event) {
                const userPromise = isAdmin ? UserServices.get({
                    first: 0,
                    page: 0,
                    size: 9999,
                    status: 1
                }) : UserServices.searchUserEvent({
                    code: _event.eventCode
                }, {
                    first: 0,
                    page: 0,
                    size: 9999,
                })
                const [userData] = await Promise.all([userPromise]);
                setDictionary({
                    userEvent: userData.data,
                    event: _event
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const createData = () => {
        refDetail.current.create()
    }

    const editData = (id) => {
        refDetail.current.edit(id)
    }

    const viewData = (id) => {
        refDetail.current.view(id)
    }

    const deleteData = (id) => {
        confirmDialog({
            message: t("common.confirm_content"),
            header: t("common.confirm"),
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            rejectLabel: t("common.cancel"),
            acceptLabel: t("common.confirm"),
            accept: () => {
                UserServices.deleteUserCompany(refId, [{ userId: id }], { code: dictionary?.event?.eventCode }).then(res => {
                    if (res) {
                        loadList(lazyParams)
                    }
                })
            }
            ,
            reject: () => { }
        });

    }

    const onChangeKeyword = (e) => {
        let _filter = cloneDeep(lazyParams)
        _filter.keyword = e.target.value
        setLazyParams(_filter)
    }

    const headerTable = () => {
        return (
            <div className="flex justify-content-between">
                <div>
                    <Button
                        icon="pi pi-plus"
                        className="mr-2"
                        label={t("common.add")}
                        outlined
                        size="small"
                        onClick={() => createData()}
                        disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.CREATE)}
                    />
                </div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText placeholder={t("company.search")} value={lazyParams?.keyword} onChange={onChangeKeyword} />
                </span>
            </div>
        )
    }

    const renderColEnd = (row) => {
        return <span>
            <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" onClick={() => deleteData(row.id)} />
        </span>
    }

    const onPage = (event) => {
        let _filter = cloneDeep(lazyParams)
        _filter.size = event.rows;
        _filter.page = event.page;
        setLazyParams(_filter)
    };

    if (!checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)) {
        return null
    }

    return (
        <div className="container-fluid">
            <div className="grid">
                <div className="col-12">
                    <div className="e-progress-bar">
                        {loadingList
                            ? <ProgressBar className="e-progress-bar" mode="indeterminate"></ProgressBar>
                            : null
                        }
                    </div>
                    <DataTable
                        value={data}
                        header={headerTable}
                        dataKey="id"
                        className="p-datatable-gridlines border-all"
                        resizableColumns
                        columnResizeMode="expand"
                        emptyMessage={t("common.no_data")}
                        scrollable
                        scrollDirection="both"
                        scrollHeight="flex"
                        lazy
                        paginator
                        first={meta?.first || 0}
                        rows={meta?.size || 20}
                        totalRecords={meta.total}
                        rowsPerPageOptions={[10, 20, 25, 50, 100]}
                        paginatorTemplate="RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink"
                        currentPageReportTemplate="{first} - {last} of {totalRecords}"
                        onPage={onPage}
                        reorderableColumns
                        reorderableRows
                    >
                        <Column
                            style={{ width: 100 }}
                            field="STT"
                            body={(index, value) => value?.rowIndex + 1}
                            header={t("common.no")}
                        />
                        <Column
                            header={t("common.fullname")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {d?.fullName}
                            </div>}
                        />
                        <Column
                            header={t("user.user_name")}
                            style={{ minWidth: 250 }}
                            field="userName"
                        />
                        <Column
                            header={t("common.email")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {d?.email}
                            </div>}
                        />
                        <Column
                            header={t("common.phonenumber")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {d?.phoneNumber}
                            </div>}
                        />
                        {/* <Column
                            header={t("event.companyname")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {d?.companyName}
                            </div>}
                        /> */}
                        <Column
                            header={t("common.status")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {d?.status == "1" ? (
                                    <Badge value={t("common.active")} size="small" severity="success"></Badge>
                                ) : (<Badge value={t("common.inactive")} size="small" severity="warning"></Badge>)}
                            </div>}
                        />
                        <Column
                            columnKey="action"
                            alignFrozen="right"
                            frozen
                            header={t("company.action")}
                            className="col-frozen-table-end py-1"
                            style={{ minWidth: 180, width: 180 }}
                            body={renderColEnd}
                        />
                    </DataTable>
                </div>
            </div>
            <UserCompanyDetail
                ref={refDetail}
                isAdmin={isAdmin}
                eventId={eventId}
                refId={refId}
                dictionary={dictionary}
                afterSubmit={loadList}
            />
        </div>
    )
}

export default UserCompany;
