import axiosClient from "./axiosClient";

const FaqsServices = {
    // get: (keyword, payload) => {
    //     return axiosClient.get(`/faqs`,{
    //         keyword: keyword,
    //         params: payload
    //     });
    // },

    get: (payload) => {
        return axiosClient.get(`/faqs`, {
            params: payload
        });
    },

    create: (payload) => {
        return axiosClient.post(`/faqs`, payload);
    },

    update: (id,payload) => {
        return axiosClient.put(`/faqs/${id}`, payload);
    },

    delete: (id) => {
        return axiosClient.delete(`/faqs/${id}`);
    },
}

export default FaqsServices