import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from "primereact/dropdown";
import _ from "lodash";
import { Calendar } from "primereact/calendar";
import EDialog from "../../../../components/EDialog";
import { ToastHelper } from "../../../../helper/ToastHelper";
import { useSelector } from "react-redux";
import EventsServices from "../../../../core/services/EventsServices";
import { FILE_TYPE, UPLOAD_STATUS } from "../../../../utils/constants";
import S3FileUpload from "../../../../components/S3FileUpload";
import moment from "moment";
import { Editor } from 'primereact/editor';
import { TabPanel, TabView } from "primereact/tabview";
import { classNames } from "primereact/utils";
import CKEditorDocumentEditor from "../../../../components/CKEditorDocumentEditor";
import { cloneDeep } from 'lodash'
import { MultiSelect } from "primereact/multiselect";
import { ECalendar } from "../../../../components/ECalendar";
import { InputTextarea } from "primereact/inputtextarea";

function EventSessionDetail(props, ref) {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(false)
    const { language } = useSelector((state) => state.userReducer)

    let emptyValidate = {
        "type": null,
        "startDate": null,
        "endDate": null,
        "image": null,
    };

    const typeOption = ["SESSION", "ONDEMAIN"]
    let emptyData = {
        "type": null,
        "startDate": null,
        "endDate": null,
        "image": null,
        "video": null,
        "attributes": [],
    };

    const [data, setData] = useState(emptyData)
    const [valid, setValid] = useState(emptyValidate)

    const s3FileUploadRef = useRef()
    const { eventId, setSessions, afterSumit, listCompanies } = props;
    const { languages } = useSelector((state) => state.userReducer)
    const refDialog = useRef(null)
    const refEditMode = useRef(null);
    const [currentId, setCurrentId] = useState(null)
    const [disabledInput, setDisabledInput] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0);

    useImperativeHandle(ref, () => ({
        create: () => {
            refEditMode.current = "create"
            let _data = cloneDeep(emptyData)
            languages?.map((item) => {
                _data.attributes.push({
                    languageCode: item.code,
                    languagesname: item.name,
                    name: "",
                    summary: "",
                    description: "",
                    isNew: true
                })
            })
            setData(_data)
            refDialog.current.create()
        },
        edit: (eventId, id) => {
            refEditMode.current = "update"
            getbyID(eventId, id)
            setCurrentId(id)
        },
        view: (eventId, id) => {
            refEditMode.current = "view"
            getbyID(eventId, id)
            setDisabledInput(true)
        }
    }));

    useEffect(() => {
        loadCompany()
    }, [])


    const [companies, setCompanies] = useState([])
    const [maps, setMaps] = useState(null)

    const onShow = () => {
        if (data?.image) {
            s3FileUploadRef.current.setFiles([data.image.name], FILE_TYPE.IMAGE)
        }
    }

    const loadCompany = async () => {
        EventsServices.searchCompany(eventId).then((res) => {
            if (res) {
                setCompanies(res.map(o => ({
                    ...o,
                    name: _.find(o.attributes, { languageCode: language })?.name
                })))
            }
        })
    }
    const getbyID = async (eventId, id) => {
        let _coms = _.cloneDeep(companies)
        if (!_coms?.length) {
            const resCom = await EventsServices.searchCompany(eventId)
            if (resCom) {
                _coms = resCom.map(o => ({
                    ...o,
                    name: _.find(o.attributes, { languageCode: language })?.name
                }))
            }
        }
        await EventsServices.session.getById(eventId, id).then(res => {
            if (res) {
                let end = new Date(res.endDate);
                let start = new Date(res.startDate);
                res.attributes.map((item) => {
                    item.languagesname = languages?.find((i) => i.code === item.languageCode)?.name
                })
                let _data = {
                    ...res,
                    endDate: end,
                    startDate: start,
                    image: res?.image ? {
                        name: res?.image,
                        status: UPLOAD_STATUS.UPLOADED
                    } : null,
                    attributes: [],
                    eventCompanyIds: res?.eventCompanies.map(o => {
                        return _.find(_coms, { id: o.id })
                    })
                }
                languages?.map((item) => {
                    let lan = res.attributes.find(o => item.code == o.languageCode)
                    _data.attributes.push({
                        languageCode: item?.code,
                        languagesname: item?.name,
                        name: lan?.name,
                        summary: lan?.summary,
                        description: lan?.description
                    })
                })
                setData(_data)

                refDialog.current.create()
            }
        })
    }

    const cancel = () => {
        refDialog.current.close();
        setData(emptyData)
        setValid(emptyValidate)
        setActiveIndex(0)
    }
    const save = async () => {
        let isValid = performValidate([]);
        if (isValid.isValid) {
            // call api
            try {
                if (refEditMode.current == "create") {
                    await EventsServices.session.create(eventId, {
                        ...data,
                        image: data?.image?.name,
                        startDate: data.startDate ? moment.utc(data.startDate).format("HH:mm DD/MM/YYYY") : null,
                        endDate: data.endDate ? moment.utc(data.endDate).format("HH:mm DD/MM/YYYY") : null,
                        eventCompanyIds: data?.eventCompanyIds?.map(o => o.id)
                    }).then(res => {
                        // loadRanks()
                        refDialog.current.close();
                        ToastHelper.show({ severity: 'success', summary: t("common.success") });
                        afterSumit()
                    })

                } else {
                    await EventsServices.session.update(eventId, currentId, {
                        ...data,
                        image: data?.image?.name,
                        startDate: data.startDate ? moment.utc(data.startDate).format("HH:mm DD/MM/YYYY") : null,
                        endDate: data.endDate ? moment.utc(data.endDate).format("HH:mm DD/MM/YYYY") : null,
                        eventCompanyIds: data?.eventCompanyIds?.map(o => o.id)
                    }).then(res => {
                        // loadRanks()
                        refDialog.current.close();
                        ToastHelper.show({ severity: 'success', summary: t("common.success") });
                        afterSumit()
                    })

                }

            } catch (error) {
                ToastHelper.show({ severity: 'error', summary: t("common.error") });
            }
        } else {
            ToastHelper.show({ severity: 'error', summary: t("common.error"), detail: t("error.required_full") });
        }
    }

    const applyChange = (prop, value, propArr, index) => {
        let _current = _.cloneDeep(data);
        switch (prop) {
            case "attributes":
                _current[prop][index][propArr] = value;
                break
            default:
                _current[prop] = value
                break;
        }

        setData(_current);
        performValidate([prop], _current, propArr, index)
    }
    const performValidate = (props, _checkObject, propArr, index) => {
        let result = { ...valid }, isValid = true, _errors = [];
        let _object = _checkObject ? _checkObject : data;
        // validate all props
        if (props.length === 0) {
            for (const property in result) {
                props.push(property);
            }
        }

        // validate props
        props.forEach(prop => {
            switch (prop) {
                case 'startDate':
                    if (data.endDate && (data?.endDate < _object.startDate)) {
                        result[prop] = t("The start date cannot be less than the end date");
                    }
                    else {
                        result[prop] = _object.startDate ? null : t("message.validate_required");
                        result["endDate"] = null;
                    }
                    break;
                case 'endDate':
                    if (data.startDate && (data?.startDate > _object.endDate)) {
                        result[prop] = t("The end date cannot be more than the end date");
                    }
                    else {
                        result[prop] = _object.endDate ? null : t("message.validate_required");
                        result["start"] = null;
                    }
                    break;
                case "image":
                    result[prop] = ""
                    if (!_object[prop] || _object[prop].status != UPLOAD_STATUS.UPLOADED) {
                        result[prop] = t("message.validate_required")
                    }
                    break;
                default:
                    break;
            }
        });

        // set state
        setValid(result);
        for (const property in result) {
            if (result[property]) {
                if (property != "attributes") {
                    isValid = false;
                    _errors.push(result[property]);
                }
                break;
            }
        }
        return { isValid: isValid, errors: _errors };
    }


    const header = () => {
        let headerMode = refEditMode.current
        if (headerMode === 'create') {
            return t('common.create') + " " + (t("events.session.session").toLowerCase())
        }
        if (headerMode === 'update') {
            return t('common.update') + " " + (t("events.session.session").toLowerCase())
        }
        if (headerMode === 'view') {
            return t('common.view') + " " + (t("events.session.session").toLowerCase())
        }
    }

    const onUploadedImage = useCallback((val) => {
        applyChange("image", val)
    }, [data])

    return (
        <EDialog
            ref={refDialog}
            title={header()}
            closable={true}
            style={{ width: '90vw' }}
            hidebtnFooter={false}
            onShow={onShow}
            btnFooter={
                refEditMode.current == "view" ?
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        }
                    ] :
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        },
                        {
                            label: t("common.save"),
                            onClick: () => save(),
                            loading
                        }
                    ]
            }
        >
            <div className="container px-4 py-3">
                <div class="formgrid grid">
                    <div class="field col-6">
                        <label htmlFor="name" className="require">{t("events.session.startDate")}: </label>

                        <ECalendar
                            disabled={refEditMode.current == "view"}
                            value={data.startDate}
                            onChange={(value) => applyChange("startDate", value)}
                            className="w-full"
                            showIcon
                            showDate
                            showTime
                            hourFormat="24"
                        />
                        <span className='event-color-primary-500 text-sm'>{valid.startDate || ""}</span>
                    </div>
                    <div class="field col-6">
                        <label htmlFor="name" className="require">{t("events.session.endDate")}: </label>

                        <ECalendar
                            disabled={refEditMode.current == "view"}
                            value={data.endDate}
                            onChange={(value) => applyChange("endDate", value)}
                            className="w-full"
                            showIcon
                            showDate
                            showTime
                            hourFormat="24"
                        />
                        <span className='event-color-primary-500 text-sm'>{valid.endDate || ""}</span>
                    </div>
                    <div class="field col-6">
                        <label htmlFor="name" className="require">{t("events.session.type")}: </label>
                        <Dropdown
                            value={data.type}
                            onChange={(e) => applyChange('type', e.value)}
                            options={typeOption}
                            disabled={refEditMode.current == "view"}
                            className="w-full"
                        />
                        <span className='event-color-primary-500 text-sm'>{valid.type || ""}</span>
                    </div>
                    <div class="field col-6">
                        <label htmlFor="name">{t("events.session.video")}: </label>
                        <InputText
                            value={data?.video}
                            disabled={refEditMode.current == "view"}

                            className="w-full"
                            onChange={(e) => applyChange("video", e.target.value)}
                        ></InputText>
                    </div>
                    {/* <div class="field col-6">
                        <label htmlFor="name" className="require">{t("events.session.image")}: </label>
                        <InputText
                            value={data?.image}
                            disabled={refEditMode.current == "view"}

                            className="w-full"
                            onChange={(e) => applyChange("image", e.target.value)}
                        ></InputText>
                        <span className='event-color-primary-500 text-sm'>{valid.image || ""}</span>
                    </div> */}
                    <div class="field col-6">
                        <label htmlFor="name">{t("events.session.present")}: </label>
                        <MultiSelect
                            value={data?.eventCompanyIds}
                            onChange={(e) => { applyChange("eventCompanyIds", e.value) }}
                            options={companies ?? []}
                            showClear
                            optionLabel="name"
                            className={classNames({ "w-full": true })}
                            filter
                        />
                    </div>
                    <div className="col-12 field">
                        <label className="require">{t("events.session.image")}</label>
                        <S3FileUpload
                            ref={s3FileUploadRef}
                            onChange={onUploadedImage}
                            accept="image/*"
                            onLoading={setLoading}
                        />
                        <span className='event-color-primary-500 text-sm'>{valid.image || ""}</span>
                    </div>
                    <div className="col-12 flex flex-column">
                        <b className="mb-2">{t("event.attributes")}</b>
                        <div className="w-full">
                            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                {data?.attributes?.map((item) => {
                                    return (
                                        <TabPanel header={item?.languagesname}>
                                            <div className="grid">
                                                <div className="col-12 field">
                                                    <label>{t("event.name")}</label>
                                                    <InputText
                                                        disabled={refEditMode.current === "view"}
                                                        value={item?.name}
                                                        onChange={(e) => { applyChange("attributes", e.target.value, "name", activeIndex) }}
                                                        className={classNames({ "w-full": true })}
                                                    />
                                                </div>
                                                <div className="col-12 field">
                                                    <label>{t("event.summary")}</label>
                                                    <InputTextarea
                                                        className="w-full"
                                                        value={item?.summary}
                                                        onChange={(e) => { applyChange("attributes", e.target.value, "summary", activeIndex) }}
                                                        rows={5}
                                                        cols={30} />
                                                </div>
                                                
                                                <div className="col-12 field pb-4">
                                                    <label>{t("event.description")}</label>
                                                    <CKEditorDocumentEditor
                                                        initialData={item?.description}
                                                        onDataChanged={(e) => {
                                                            setData(prevData => {
                                                                // Tạo một bản sao của prevData
                                                                const newData = { ...prevData };
                                                                // Kiểm tra xem phần tử có index có tồn tại không
                                                                if (newData.attributes && newData.attributes[activeIndex]) {
                                                                    // Nếu tồn tại, cập nhật thuộc tính 'description'
                                                                    newData.attributes[activeIndex] = {
                                                                        ...newData.attributes[activeIndex],  // Kế thừa các thuộc tính khác của phần tử 
                                                                        description: e   // Cập nhật mô tả mới
                                                                    };
                                                                }
                                                                return newData;  // Trả về newData đã được cập nhật
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </TabPanel>
                                    )
                                })}
                            </TabView>
                        </div>
                    </div>
                </div>

            </div>
        </EDialog>

    )
}
EventSessionDetail = forwardRef(EventSessionDetail)
export default EventSessionDetail;
