/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss"
import { useTranslation } from "react-i18next";
import { Card } from "primereact/card";
import { Chart } from "primereact/chart";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import EventsServices from "../../../core/services/EventsServices";
import { filter, find, remove, findIndex } from 'lodash'
import { EVENT_SETTING, EVENT_STATUS, USER_PERMISSION } from "../../../utils/constants";
import moment from "moment";
import { Steps } from "primereact/steps";
import ProgressEvent from "../../../components/ProgressEvent";
import { ToastHelper } from "../../../helper/ToastHelper";
import { TabMenu } from 'primereact/tabmenu';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Divider } from "primereact/divider";
import _ from "lodash";

function Scans(props, ref) {

    const { t } = useTranslation()
    const { eventID } = props;
    const { events, permission, eventCode, language } = useSelector((state) => state.userReducer)

    const dispatch = useDispatch();
    const [report, setReport] = useState(null)
    const [chartData, setChartData] = useState({});
    const [chartData1, setChartData1] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [activeIndex, setActiveIndex] = useState(0);
    const [listDate, setListDate] = useState([]);
    const [arrayData, setArrayData] = useState([]);
    const [totalDetail, setTotalDetail] = useState(null);
    const loadScans = (type) => {

        EventsServices.report.getScan(eventID, type).then((res) => {
            if (res) {
                setReport(res)
            }
        })
    }
    useEffect(() => {
        let _type = null
        switch (activeIndex) {
            case 0:
                _type = "COMPANY"
                break;
            case 1:
                _type = "USER"
                break;
            default:
                _type = "COMPANY"
                break;
        }
        if (eventID) {
            loadScans(_type)
        }
    }, [eventID, activeIndex])

    useEffect(() => {
        let _temp = _.cloneDeep(report)
        let _list = _temp
        const sortedList = _.orderBy(_list, [(item) => {
            if (item?.title) {
                const [day, month, year] = item?.title.split('/');
                return new Date(year, month - 1, day);
            }
        }], 'asc');
        sortedList.map((item) => {
            item.invalid = item?.total == 0 ? 1 : 0
        })
        let _totalDetail = {
            hot: _.sumBy(sortedList, "hot"),
            warm: _.sumBy(sortedList, "warm"),
            cold: _.sumBy(sortedList, "cold"),
            unknown: _.sumBy(sortedList, "unknown"),
            total: _.sumBy(sortedList, "total"),
            invalid: _.sumBy(sortedList, "invalid")
        };
        setTotalDetail(_totalDetail)
        const listDate = sortedList?.map(item => item?.title)
        const newlistDate = _.uniq(listDate);
        setListDate(newlistDate);
        const listTotal = sortedList?.map(item => item?.total)
        setArrayData(listTotal)
    }, [report])

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: listDate,
            datasets: [
                {
                    label: t("chart.scan"),
                    data: arrayData,
                    backgroundColor: [
                        'rgb(84, 112, 198)'
                    ],
                    borderColor: [
                        'rgb(84, 112, 198)'
                    ],
                    borderWidth: 1
                }
            ]
        };

        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.9,
            scales: {
                y: {
                    beginAtZero: true
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [listDate]);

    const items = [
        { label: t("chart.my_company") },
        { label: t("chart.my_profile") },
    ];
    return (

        <>
            <div className="col-12">
                <Card className="border-round-2xl shadow-2">
                    <div className="flex justify-content-center">
                        <div className="text-center mt-2 mb-4">
                            <div className="text-5xl font-bold text-900">{t("chart.scan")}</div>
                            <div className="text-lg font-medium">{t("chart.understand_what")}</div>
                            {/* <TabMenu model={itemsChart1} /> */}
                            <div className="menu-tab-css flex justify-content-center">
                                <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                            </div>
                        </div>
                    </div>

                    <Chart type="bar" data={chartData} options={chartOptions} />
                </Card>
            </div>
            <div className="col-12">
                <Card className="border-round-2xl shadow-2">
                    <div className="text-center text-900">
                        <div className="font-bold text-5xl ">{totalDetail?.total}</div>
                        <p className="font-medium text-lg font-bold my-2">{t("chart.scan")}</p>
                    </div>
                    <Divider />
                    <div className="flex">
                        <div className="text-900 text-center" style={{ width: '20%' }}>
                            <div className="font-bold text-3xl ">{totalDetail?.hot}</div>
                            <p className=" text-lg font-bold">{t("chart.hot")}</p>
                        </div>
                        <div className="text-900 text-center" style={{ width: '20%' }}>
                            <div className="font-bold text-3xl ">{totalDetail?.warm}</div>
                            <p className=" text-lg my-2">{t("chart.warm")}</p>
                        </div>
                        <div className="text-900 text-center" style={{ width: '20%' }}>
                            <div className="font-bold text-3xl ">{totalDetail?.cold}</div>
                            <p className=" text-lg my-2">{t("chart.cold")}</p>
                        </div>
                        <div className="text-900 text-center" style={{ width: '20%' }}>
                            <div className="font-bold text-3xl ">{totalDetail?.unknown}</div>
                            <p className=" text-lg my-2">{t("chart.not_graded")}</p>
                        </div>
                        <div className="text-900 text-center" style={{ width: '20%' }}>
                            <div className="font-bold text-3xl ">{totalDetail?.invalid}</div>
                            <p className=" text-lg my-2">{t("chart.illegal")}</p>
                        </div>
                    </div>

                </Card>
            </div>


        </>

    )
}
Scans = forwardRef(Scans)
export default Scans;

