import React, { useCallback, useEffect, useRef, useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, useLocation, useRoutes } from "react-router-dom";
import HeaderApp from './components/HeaderApp/HeaderApp';
import { Toast } from 'primereact/toast';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Dashboard from './pages/Dashboard';
import Login from './pages/Account/Login';
import SideNavBar from './components/Sidebar';
import { classNames } from 'primereact/utils';
import Category from './pages/Category';
import AppHelper, { AppHelperComponent } from './helper/AppHelper';
import Maps from './pages/Maps';
import ListAccounts from './pages/Account/AccountManagement/ListAccounts';
import Ranks from './pages/Ranks';
import Companies from './pages/Company';
import ListEvents from './pages/Events';
import EventInfo from './pages/Events/EventInfo';

import ListLocation from './pages/Location/ListLocation';
import { ToastHelper } from './helper/ToastHelper';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import OfferAttributeList from './pages/OfferAttribute/OfferAttributeList';
import CompanyInfo from './pages/Company/CompanyInfo';
import ProductInfo from './pages/Events/EventsProduct/ProductInfo';
import Banners from './pages/Events/Banners';
import SessionInfo from './pages/Events/EventsSession/SessionInfo';
import { ROUTE } from './utils/constants';
import UserInfo from './pages/Events/UserEvent/UserInfo';
import User from './pages/User';
import Config from './pages/Config';
import Faqs from './pages/Faqs';
import { useTranslation } from 'react-i18next';
import Group from './pages/Group';
import GroupInfo from './pages/Group/GroupInfo';
import { persistor } from './core/store/configureStore';
import { PersistGate } from 'redux-persist/integration/react';


function AppPrefix() {
	const { t, i18n } = useTranslation()
	const { language } = useSelector(state => state.userReducer)
	const initLoad = (res) => {
		i18n.changeLanguage(language)
	}
	return (
		<PersistGate persistor={persistor} onBeforeLift={initLoad}>
			<App />
		</PersistGate>
	)
}
export default AppPrefix;

function App() {

	const { t, i18n } = useTranslation()

	const { token } = useSelector((state) => state.userReducer)
	const [isToggle, setIsToggle] = useState(true)
	const { language } = useSelector(state => state.userReducer)

	const toast = useRef(null)

	const dispatch = useDispatch()

	const onToggleCallback = useCallback((_flg) => {
		setIsToggle(_flg)
	}, [])

	// useEffect(() => {
	// 	i18n.changeLanguage(language)
	// 	console.log(language)
	// }, [language])

	const setAppHelper = (ref) => {
		AppHelper.setAppHelper(ref)
	}

	const setToastHelper = (ref) => {
		ToastHelper.setRef(ref)
	}

	return (
		<BrowserRouter basename={process.env.REACT_APP_ROUTE_BASE_NAME}>
			<div className='App'>
				<AppHelperComponent
					ref={setAppHelper}
				/>
				<div className='event-layout'>

					<HeaderApp
						isToggle={isToggle}
					/>
					<div className='event-layout-center'>


						{token
							? <SideNavBar onToggleCallback={onToggleCallback} />
							: null
						}
						<ScrollToTop>
							<main className={classNames({ "e-menu-toggle p-2 h-full": true, "active": isToggle })}>
								{token
									? <Routes>
										<Route path={ROUTE.dashboard} element={<Dashboard />} />
										{/* <Route path={ROUTE.cate} element={<Category />} /> */}
										{/* <Route path={ROUTE.accounts} element={<ListAccounts />} /> */}
										<Route path={ROUTE.users} element={<User />} />
										<Route path={ROUTE.ranks} element={<Ranks isDisplay={1} screenPermission="ranks" />} />
										<Route path={ROUTE.company_type} element={<Ranks isDisplay={0} screenPermission="company-type" />} />
										<Route path={ROUTE.companies} element={<Companies />} />
										<Route path={ROUTE.events} element={<ListEvents />} />
										<Route path={ROUTE.eventInfo} element={<EventInfo />} />
										<Route path={ROUTE.eventInfoScreen} element={<EventInfo />} />
										{/* <Route path={ROUTE.offerAttributes} element={<EventInfo />} /> */}
										<Route path={ROUTE.eventCompanyInfo} element={<CompanyInfo />} />
										<Route path={ROUTE.eventCompanyInfoScreen} element={<CompanyInfo />} />
										<Route path={ROUTE.eventBanner} element={<Banners />} />
										<Route path={ROUTE.maps} element={<ListLocation />} />
										<Route path={ROUTE.eventSessionInfo} element={<SessionInfo />} />
										<Route path={ROUTE.eventSessionInfoScreen} element={<SessionInfo />} />
										<Route path={ROUTE.eventProductInfo} element={<ProductInfo />} />
										<Route path={ROUTE.eventProductInfoScreen} element={<ProductInfo />} />
										<Route path={ROUTE.eventUserInfo} element={<UserInfo />} />
										<Route path={ROUTE.eventUserInfoScreen} element={<UserInfo />} />
										<Route path={ROUTE.configs} element={<Config />} />
										<Route path={ROUTE.faqs} element={<Faqs />} />
										<Route path={ROUTE.group} element={<Group />} />
										<Route path={ROUTE.groupInfo} element={<GroupInfo />} />

									</Routes>
									: <Routes>
										<Route path={ROUTE.login} element={<Login />} />
									</Routes>
								}

							</main>
						</ScrollToTop>
					</div>
				</div>
			</div>
			<Toast ref={setToastHelper} />
			<ConfirmDialog />
		</BrowserRouter>
	);
}



const ScrollToTop = (props) => {
	const location = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	return <>{props.children}</>
};

