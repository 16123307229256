import axios from 'axios';
import moment from 'moment';
import { BASE_SHARE_URL, BASE_URL } from '../../config';
import store from '../store/configureStore';
import { CommonHelper } from '../../helper/CommonHelper';
import { ToastHelper } from '../../helper/ToastHelper';
import i18n from '../../i18n';

const axiosClient = axios.create({
    baseURL: BASE_URL,
});

export const axiosShareClient = axios.create({
    baseURL: BASE_SHARE_URL,
});

axiosShareClient.interceptors.request.use(async (config: any) => {
    // token
    // const state: any = store.getState()
    // if (state?.userReducer?.token) {
    //     config.headers.Authorization = `Bearer ${state?.userReducer?.token}`;
    // }
    // config.headers.code = CommonHelper.data?.eventCode
    // language
    config.headers['Content-Type'] = "multipart/form-data";
    // company id
    // convert data of date
    // if (config.data) {
    //     convertDate(config.data);
    // }
    let formData = new FormData();
    formData.append("file", config.data.file);
    config.data = formData

    return config;
})

// handle response
axiosShareClient.interceptors.response.use((response: any) => {
    var res = null;

    if (response && response.data) {
        if (response.data instanceof Blob) {
            // check if response file blob
            res = response.data;
        } else {
            // normal request
            if (response.data.isSuccess) {
                res = (response.data.data != null && response.data.data != undefined) ? response.data.data : response.data.message;
            } else {
                console.log("Response Error !!!", response)
                // CommonFunction.toastError(response.message ? response.message : response.data.message);
                makeError(response.message ? response.message : response.data.message)
            }
        }
    } else {
        console.log("Axios: No response", response);
        if (response.message) {
            // CommonFunction.toastError(response.message);
            makeError(response.message)
        }
    }
    return res;
}, error => {
    // Handle errors
    if (error.response && error.response.data) {
        // CommonFunction.toastError(error.response.data.message);
        makeError(error.response.data.message)
    } else {
        makeError(error.message)
        // CommonFunction.toastError(error.message);
    }
    return null;
});


/**
 * convet all property date into iso8601 format
 * @param {*} obj
 */
const convertDate = (obj: any) => {
    try {
        for (const key in obj) {
            if (Array.isArray(obj[key]) && obj[key].length > 0) {
                obj[key].forEach((el: any) => {
                    if (el !== null && typeof el === "object" || Array.isArray(el)) {
                        convertDate(el);
                    }
                });
            } else if (obj[key] !== null && typeof obj[key] === "object" && Object.keys(obj[key]).length > 0) {
                convertDate(obj[key]);
            } else if (obj[key] instanceof Date) {
                obj[key] = moment(obj[key]).format('YYYY-MM-DDTHH:mm:ssZZ');
            }
        }
    } catch (error) {
        console.log("axios convert date error", error)
    }
}

// handle request
axiosClient.interceptors.request.use(async (config: any) => {
    // token
    const state: any = store.getState()
    if (state?.userReducer?.token) {
        config.headers.Authorization = `Bearer ${state?.userReducer?.token}`;
    }
    if (!config.headers[`Content-Language`]) {
        config.headers[`Content-Language`] = state?.userReducer.language
    }
    // config.headers.code = CommonHelper.data?.eventCode
    // language
    // config.headers.common['Accept-Language'] = localStorage.getItem("I18N_LANGUAGE");
    // company id
    // convert data of date
    if (config.data) {
        convertDate(config.data);
    }

    return config;
})

// handle response
axiosClient.interceptors.response.use((response: any) => {
    var res = null;

    if (response && response.data) {
        if (response.data instanceof Blob) {
            // check if response file blob
            res = response.data;
        } else {
            // normal request
            if (response.data.isSuccess) {
                res = (response.data.data != null && response.data.data != undefined) ? response.data.data : response.data.message;
            } else {
                console.log("Response Error !!!", response)
                // CommonFunction.toastError(response.message ? response.message : response.data.message);
                makeError(response.message ? response.message : response.data.message)
            }
        }
    } else {
        console.log("Axios: No response", response);
        if (response.message) {
            // CommonFunction.toastError(response.message);
            makeError(response.message)
        }
    }
    return res;
}, error => {
    // Handle errors
    if (error.response && error.response.data) {
        // CommonFunction.toastError(error.response.data.message);
        makeError(error.response.data.message)
    } else {
        makeError(error.message)
        // CommonFunction.toastError(error.message);
    }
    return null;
});

export default axiosClient;

const makeError = (error: string) => {
    // store.dispatch(ActionToast.createErorr(error))
    ToastHelper.show({ severity: 'error', summary: i18n.t("common.error"), detail: error })
}