import React, { useEffect, useRef, useState } from 'react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import "./styles.scss";
import S3Services from '../../core/services/S3Services';
import { BASE_S3_URL } from '../../config';

const CKEditorDocumentEditor = ({ initialData, onDataChanged, disabled }) => {
  const editorRef = useRef(null);
  const [editorData, setEditorData] = useState(initialData || '');

  useEffect(() => {
    DecoupledEditor
      .create(editorRef.current, {
        // Thêm các tùy chọn CKEditor ở đây nếu cần
        disabled
      })
      .then(editor => {
        const toolbarContainer = document.querySelector('.toolbar-container');
        toolbarContainer.appendChild(editor.ui.view.toolbar.element);

        // Nghe sự kiện khi tệp ảnh được tải lên
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
          return {
            upload: () => {
              return new Promise((resolve, reject) => {
                loader.file.then(file => {
                  S3Services.upload({ file })
                    .then(res => {
                      // Trả về đường dẫn của ảnh
                      // thay đường dẫn bằng res
                      resolve({ default: `${BASE_S3_URL}${res.fileName}` });
                    })
                    .catch(error => {
                      console.error('Upload failed:', error);
                      reject(error);
                    });
                }).catch(e => {
                  reject(e);
                });
                // Gửi tệp đến API để tải lên và nhận lại đường dẫn

              });
            }
          }
        }

        if (initialData) {
          editor.setData(initialData);
        }

        // Gán sự kiện khi dữ liệu thay đổi
        editor.model.document.on('change:data', () => {
          const data = editor.getData();
          setEditorData(data);
          if (onDataChanged) {
            onDataChanged(data);
          }
        });
      })
      .catch(error => {
        console.error(error);
      });

    return () => {
      // Hủy bỏ trình soạn thảo khi component bị hủy
      if (editorRef.current) {
        editorRef.current.editorInstance?.destroy();
      }
    };
  }, []);

  // Hàm để thiết lập dữ liệu cho trình soạn thảo từ bên ngoài
  const setData = (data) => {
    if (editorRef.current) {
      editorRef.current.editorInstance.setData(data);
    }
  };

  return (
    <div>
      <div className="toolbar-container"></div>
      <div ref={editorRef}></div>
    </div>
  );
};

export default CKEditorDocumentEditor;
