import { put, call, takeEvery, all, fork } from "redux-saga/effects";
import { REQUEST_GET_COMPANY, REQUEST_POST_COMPANY, REQUEST_PUT_COMPANY, REQUEST_DELETE_COMPANY, REQUEST_GET_COMPANY_ID } from "../actions/ActionCompany";
import CompanyServices from "../services/CompanyServices";


// Comment here
function* getSearch(action) {
  try {
    const data = yield call(CompanyServices.search)
    if (action.callBack) {
      action.callBack(data)
    }
  } catch (error) {
    console.log(error)
  }
}
function* createCompany(action) {
    try {
      const data = yield call(CompanyServices.create, action.params)
      if (action.callBack) {
        action.callBack(data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  function* updateCompany(action) {
    try {
      const data = yield call(CompanyServices.update, action.params)
      if (action.callBack) {
        action.callBack(data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  function* deleteCompany(action) {
    try {
      const data = yield call(CompanyServices.delete, action.params)
      if (action.callBack) {
        action.callBack(data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  function* getCompanyById(action) {
    try {
      const data = yield call(CompanyServices.getById, action.params)
      if (action.callBack) {
        action.callBack(data)
      }
    } catch (error) {
      console.log(error)
    }
  }

function* watchCompany() {
  yield takeEvery(REQUEST_GET_COMPANY, getSearch);
  yield takeEvery(REQUEST_POST_COMPANY, createCompany);
  yield takeEvery(REQUEST_PUT_COMPANY, updateCompany);
  yield takeEvery(REQUEST_DELETE_COMPANY, deleteCompany);
  yield takeEvery(REQUEST_GET_COMPANY_ID, getCompanyById);
}

export default function* CompanySaga() {
  yield all([fork(watchCompany)]);
}
