import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
// import "./styles.scss"
import { classNames } from "primereact/utils";
import { useDispatch } from "react-redux";
import EDialog from "../../../../components/EDialog";
import { actionCompanyCreate, actionCompanyEdit, actionCompanyById } from "../../../../core/actions/ActionCompany";
import CompanyServices from "../../../../core/services/CompanyServices";
import S3FileUpload from "../../../../components/S3FileUpload";
import { FILE_TYPE, UPLOAD_STATUS, USER_PERMISSION, USER_PERMISSIONS, USER_TYPE, USER_TYPE_NEW, USER_TYPES } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import UserServices from "../../../../core/services/UserServices";
import CKEditorDocumentEditor from "../../../../components/CKEditorDocumentEditor";
import { cloneDeep, find, remove } from 'lodash'
import EventsServices from "../../../../core/services/EventsServices";

function UserEventDetail(props, ref) {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    const { countries = [], language } = useSelector((state) => state.userReducer)

    const [permissions] = useState(USER_PERMISSIONS.map(o => ({
        ...o,
        name: t(o.name)
    })))

    const [userTypes, setUserTypes] = useState([])

    const { afterSubmit, eventId, event } = props
    const dispatch = useDispatch();
    const defaultData = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        descriptions: "",
        companyId: null,
        position: "",
        confirmEmail: 0,
        companyName: "",
        userType: USER_TYPE.GUEST,
        country: null,
        eventId,
        userTypeId: null
    }
    const defaultError = {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        permissionType: null,
        companyId: null,
        userType: null,
        country: null
    }
    const [filter, setFilter] = useState({})
    const [data, setData] = useState(defaultData)
    const [error, setError] = useState(defaultError)
    const [action, setAction] = useState()
    const refDialog = useRef(null)

    const [companies, setCompanies] = useState([])

    useEffect(() => {
        loadCompany()
        // setUserTypes(USER_TYPES.map(o => ({
        //     ...o,
        //     name: t(o.name)
        // })))
        loadUserType()
    }, [])

    const loadUserType = () => {
        EventsServices.userType.get(eventId).then((res) => {
            if (res) {
                let _type = res.map(o => ({
                    ...o,
                    name: find(o.languages, { languageCode: language })?.name ?? ""
                }))
                remove(_type, {type: USER_TYPE_NEW.ORG})
                setUserTypes(_type)
            }
        })
    }

    const loadCompany = () => {
        EventsServices.searchCompany(eventId).then(res => {
            if (res) {
                setCompanies(res.map(o => ({
                    ...o,
                    companyName: find(o.attributes, { languageCode: language })?.name ?? ""
                })))
            }
        })
    }

    useImperativeHandle(ref, () => ({
        create: () => {
            refDialog.current.create();
            setAction("create")
        },
        edit: (_data) => {
            refDialog.current.create();
            UserServices.event.getById({
                code: event?.eventCode
            }, _data?.id).then(res => {
                if (res) {
                    setData({
                        ...res,
                        eventId,
                        position: res.jobTitle,
                        phone: res.phoneNumber,
                        country: res.country?.length ? res.country[0]?.key : null,
                        descriptions: res.description
                    })
                }

            })
            setAction("edit");

        },
        view: (id) => {
            refDialog.current.create();
            setAction("view")
        }
    }));

    const onShow = () => {

    }

    const onChangeData = (prop, value) => {
        let _data = cloneDeep(data)
        _data[prop] = value
        switch (prop) {
            case "companyId":
                const cpn = find(companies, { id: value })
                _data["companyName"] = find(cpn?.attributes ?? [], { languageCode: language })?.name
                break
        }
        validateData([prop], _data)
        setData(_data)
    }

    const validateData = (prop, _data) => {
        let _error = cloneDeep(error)
        let requiredFields = prop.length > 0 ? prop : Object.keys(_data);
        let _prop = requiredFields
        for (const field of _prop) {
            switch (field) {
                case "firstName":
                case "lastName":
                    // case "email":
                    // case "phone":

                    _error[field] = ""
                    if (!_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                default:
                    break;
            }
        }

        setError(_error)
        let count = 0;
        for (const key in _error) {
            if (_error[key]) {
                count++;
            }
        }
        return count;
    }

    const onSubmit = () => {
        let validate = validateData([], data)
        if (validate) return
        switch (action) {
            case "create":
                setLoading(true)
                EventsServices.getCompanyNo(eventId).then(maxNo => {
                    UserServices.event.create({
                        ...data,
                        no: (maxNo?.eventUser ?? 0) + 1
                    }).then((res) => {
                        setLoading(false)
                        if (res) {
                            afterSubmit()
                            cancel()
                        }
                    })
                })
                break;
            case "edit":
                setLoading(true)
                UserServices.event.update({
                    ...data
                }).then((res) => {
                    setLoading(false)
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            default:
                break;
        }

    }

    const renderError = (field) => {
        if (error[field]) {
            return <span className="text-red-500 text-xs">{error[field]}</span>
        }
    }

    const cancel = () => {
        refDialog.current.close()
        setData(defaultData)
        setError(defaultError)
    }

    const onUploadedImage = useCallback((val) => {
        onChangeData("avatar", val)
    }, [data])

    return (
        <EDialog
            ref={refDialog}
            style={{ width: '70vw' }}
            title={t("user.user_information")}
            closable={false}
            hidebtnFooter={false}
            onShow={onShow}
            btnFooter={
                action === "view" ?
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        }
                    ] :
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        },
                        {
                            label: t("common.save"),
                            onClick: () => onSubmit(),
                            loading,
                            disabled: loading
                        }
                    ]
            }
        >
            <div className="container">
                <div className="grid">
                    <div className="col-6 field">
                        <label>{t("user.user_type")}</label>
                        <Dropdown
                            filter
                            options={userTypes}
                            optionLabel="name"
                            optionValue="id"
                            className="w-full"
                            value={data?.userTypeId}
                            // disabled
                            onChange={(e) => { onChangeData("userTypeId", e.target.value) }}
                        />
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("user.first_name")}</label>
                        <InputText
                            disabled={action === "view"}
                            value={data?.firstName}

                            onChange={(e) => { onChangeData("firstName", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["firstName"] })}
                        />
                        {renderError("firstName")}
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("user.last_name")}</label>
                        <InputText
                            disabled={action === "view"}
                            value={data?.lastName}

                            onChange={(e) => { onChangeData("lastName", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["lastName"] })}
                        />
                        {renderError("lastName")}
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("user.email")}</label>
                        <InputText
                            disabled={action === "view"}
                            value={data?.email}

                            onChange={(e) => { onChangeData("email", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["email"] })}
                        />
                        {renderError("email")}
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("user.phone")}</label>
                        <InputText
                            disabled={action === "view"}
                            value={data?.phone}

                            onChange={(e) => { onChangeData("phone", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["phone"] })}
                        />
                        {renderError("phone")}
                    </div>
                    <div className="col-6 field">
                        <label>{t("user.company")}</label>
                        <Dropdown
                            filter
                            disabled={action === "view"}
                            options={companies}
                            optionLabel="companyName"
                            optionValue="id"
                            className="w-full"
                            value={data?.companyId}
                            onChange={(e) => { onChangeData("companyId", e.target.value) }}
                        />
                    </div>
                    {/* <div className="col-6 field">
                        <label>{t("user.company_name")}</label>
                        <InputText
                            disabled={action === "view"}
                            value={data?.companyName}
                            onChange={(e) => { onChangeData("companyName", e.target.value) }}
                            className={classNames({ "w-full": true })}
                        />
                    </div> */}

                    <div className="col-6 field">
                        <label className="require">{t("user.position")}</label>
                        <InputText
                            disabled={action === "view"}
                            value={data?.position}

                            onChange={(e) => { onChangeData("position", e.target.value) }}
                            className={classNames({ "w-full": true })}
                        />
                        {renderError("position")}
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("user.country")}</label>
                        <Dropdown
                            value={data?.country}
                            onChange={(e) => onChangeData('country', e.value)}
                            options={countries}
                            optionLabel="countryName"
                            optionValue="countryId"
                            className="w-full"
                            filter
                        />
                        {renderError("country")}
                    </div>



                    <div className="col-12 field pb-4">
                        <label>{t("event.description")}</label>
                        <CKEditorDocumentEditor
                            initialData={data?.descriptions}
                            onDataChanged={(e) => {
                                setData(prevData => {
                                    // Tạo một bản sao của prevData
                                    let newData = { ...prevData };
                                    newData = {
                                        ...newData,
                                        descriptions: e
                                    };
                                    return newData;  // Trả về newData đã được cập nhật
                                });
                            }}
                        />
                    </div>

                </div>
            </div>
        </EDialog>
    )
}
UserEventDetail = forwardRef(UserEventDetail)
export default UserEventDetail;
