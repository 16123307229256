import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SpeedDial } from 'primereact/speeddial';
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import "./styles.scss"
import { ColorPicker } from "primereact/colorpicker";
import EDialog from "../../../../components/EDialog";
import RanksServices from "../../../../core/services/RanksServices";
import _ from "lodash";
import { ToastHelper } from "../../../../helper/ToastHelper";
import { useSelector } from "react-redux";
import { TabPanel, TabView } from "primereact/tabview";
import { classNames } from "primereact/utils";
import EColorPicker from "../../../../components/EColorPicker";


function RankDetail(props, ref) {
    const { t } = useTranslation()
    const { setRanks, isDisplay } = props;

    const languages = useSelector((state) => state.userReducer.languages)

    const refDialog = useRef(null)
    const refEditMode = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);

    let emptyRankValidate = {
        "sortOrder": null
    };

    let emptyRank = {
        color: "#FF0000",
        sortOrder: isDisplay ? 1 : 1,
        attributes: [],
        isDisplay: isDisplay,
        isOrg: 0,
        isShowWeb: 0
    };

    useImperativeHandle(ref, () => ({
        create: () => {
            refEditMode.current = "create"
            let _data = _.cloneDeep(emptyRank)
            languages?.map((item) => {
                _data.attributes.push({
                    languageCode: item.code,
                    languagesname: item.name,
                    name: "",
                    description: "",

                })
            })
            setRank(_data)
            setRankValid(_.cloneDeep(emptyRankValidate))
            refDialog.current.create()
        },
        edit: (id) => {
            refEditMode.current = "update"
            setRankValid(_.cloneDeep(emptyRankValidate))
            getbyID(id)
            refDialog.current.create()
        },
        view: () => {
            refEditMode.current = "view"
            refDialog.current.create()
        }
    }));
    const getbyID = async (id) => {
        await RanksServices.getById(id).then(res => {
            if (res) {
                let _data = _.cloneDeep(res)
                _data.attributes = []
                languages?.map((item) => {
                    let lan = res.languages.find(o => item.code == o.languageCode)
                    _data.attributes.push({
                        languageCode: item?.code,
                        languagesname: item?.name,
                        name: lan?.name,
                        description: lan?.description
                    })
                })

                setRank(_data)
            }
        })
    }



    const [rank, setRank] = useState(emptyRank)
    const [rankValid, setRankValid] = useState(emptyRankValidate)

    const cancel = () => {
        refDialog.current.close();
    }
    const save = async () => {
        let isValid = performValidate([]);
        // console.log(isValid)
        if (isValid.isValid) {
            // call api
            try {
                if (refEditMode.current == "create") {
                    await RanksServices.create(rank).then(res => {
                        loadRanks()
                        refDialog.current.close();
                    })
                } else {
                    await RanksServices.update(rank).then(res => {
                        loadRanks()
                        refDialog.current.close();
                    })
                }
                ToastHelper.show({ severity: 'success', summary: t("common.success") });

            } catch (error) {
                ToastHelper.show({ severity: 'error', summary: t("common.error") });
            }
        } else {
            ToastHelper.show({ severity: 'error', summary: t("common.error"), detail: t("error.required_full") });
        }
    }
    const applyChange = (prop, value, propArr, index) => {
        let _current = _.cloneDeep(rank);
        switch (prop) {
            case "attributes":
                _current.attributes[index][propArr] = value;
                break;
            case "color":
                _current.color = "#" + value;
                break
            default:
                _current[prop] = value;
                break
        }
        console.log(_current.color)
        setRank(_current);
        performValidate([prop], _current)
    }
    const performValidate = (props, _checkObject) => {
        let result = { ...rankValid }, isValid = true, _errors = [];
        let _object = _checkObject ? _checkObject : rank;
        // validate all props
        if (props.length === 0) {
            for (const property in result) {
                props.push(property);
            }
        }

        // validate props
        props.forEach(prop => {
            switch (prop) {
                case 'sortOrder':
                    result[prop] = _object.sortOrder ? null : t("message.validate_required");
                    break;
                default:
                    break;
            }
        });

        // set state
        setRankValid(result);

        // check if object has error
        for (const property in result) {
            if (result[property]) {
                isValid = false;
                _errors.push(result[property]);
                break;
            }
        }

        return { isValid: isValid, errors: _errors };
    }

    const loadRanks = async () => {
        await RanksServices.get().then(res => {
            if (res) {
                setRanks(_.filter(res, { isDisplay }))
            }
        })
    }
    function getHexColor(colorStr) {
        var a = document.createElement('div');
        a.style.color = colorStr;
        var colors = window.getComputedStyle(document.body.appendChild(a)).color.match(/\d+/g).map(function (a) { return parseInt(a, 10); });
        document.body.removeChild(a);
        return (colors.length >= 3) ? '#' + (((1 << 24) + (colors[0] << 16) + (colors[1] << 8) + colors[2]).toString(16).substr(1)) : false;
    }

    const header = () => {
        let headerMode = refEditMode.current
        let _temp = (isDisplay == 1) ? t("common.ranks") : t("common.company_type")
        if (headerMode === 'create') {
            return t('common.create') + " " + (_temp.toLowerCase())
        }
        if (headerMode === 'update') {
            return t('common.update') + " " + (_temp.toLowerCase())
        }
        if (headerMode === 'view') {
            return t('common.view') + " " + (_temp.toLowerCase())
        }
    }
    return (
        <EDialog
            ref={refDialog}
            title={header()}
            closable={true}
            hidebtnFooter={false}
            btnFooter={[
                {
                    label: t("common.cancel"),
                    onClick: () => cancel(),
                    text: true
                },
                {
                    label: t("common.save"),
                    onClick: () => save(),
                }
            ]}
        >
            <div className="container">
                <div class="formgrid grid">
                    <div className="col-12 flex flex-column">
                        <b className="mb-2">{t("event.attributes")}</b>
                        <div className="w-full">
                            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                {rank?.attributes?.map((item) => {
                                    return (
                                        <TabPanel header={item?.languagesname}>
                                            <div className="grid">
                                                <div className="col-12 field">
                                                    <label>{t("event.name")}</label>
                                                    <InputText
                                                        value={item?.name}
                                                        onChange={(e) => { applyChange("attributes", e.target.value, "name", activeIndex) }}
                                                        className={classNames({ "w-full": true })}
                                                    />
                                                </div>
                                            </div>
                                        </TabPanel>
                                    )
                                })}
                            </TabView>
                        </div>
                    </div>
                    {/* <div class="field col-6">
                        <label htmlFor="name" className="require">{t("common.name")}: </label>
                        <InputText
                            value={rank?.name}
                            className="w-full"
                            onChange={(e) => applyChange("name", e.target.value)}
                        ></InputText>
                        <span className='event-color-primary-500 text-sm'>{rankValid.name || ""}</span>
                    </div> */}
                    <div class="field col-6">
                        <label htmlFor="sortOrder" className="require">{t("ranks.sortOrder")}: </label>
                        <InputText
                            type="number"
                            value={rank?.sortOrder}
                            className="w-full"
                            onChange={(e) => applyChange("sortOrder", e.target.value)}></InputText>
                        <span className='event-color-primary-500 text-sm'>{rankValid.sortOrder || ""}</span>
                    </div>
                    {isDisplay
                        ? <div class="field col-6">
                            <label htmlFor="color" className="require">{t("common.color")}: </label>
                            <div>
                                <EColorPicker
                                    value={rank?.color}
                                    className="color-picker-btn"
                                    onChange={(e) => applyChange("color", e.value)}
                                    applyColor={(val) => applyChange("color", val)}
                                />
                                <span className='event-color-primary-500 text-sm'>{rankValid.color || ""}</span>
                            </div>
                        </div>
                        : null
                    }

                    <div class="field col-6">
                        <label>{t("common.isShowWeb")}: </label>
                        <div>
                            <Checkbox onChange={e => applyChange("isShowWeb", e.checked ? 1 : 0)} checked={!!rank.isShowWeb}
                            />
                        </div>
                    </div>

                    {!isDisplay
                        ?
                        <div class="field col-6">
                            <label>{t("common.is_org")}: </label>
                            <div>
                                <Checkbox onChange={e => applyChange("isOrg", e.checked ? 1 : 0)} checked={!!rank.isOrg}
                                />
                            </div>

                        </div>

                        : null
                    }


                </div>

            </div>
        </EDialog>

    )
}
RankDetail = forwardRef(RankDetail)
export default RankDetail;
