import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import "./OfferAttribute.scss"
import { classNames } from "primereact/utils";
import EDialog from "../../components/EDialog";
import _, { cloneDeep, find } from 'lodash'
import EventsServices from "../../core/services/EventsServices";
import { TabPanel, TabView } from "primereact/tabview";
import { useSelector } from "react-redux";

function OfferAttributeDetail(props, ref) {
    const { t } = useTranslation()
    const { afterSubmit, optionRefType, refType, eventId } = props

    const languages = useSelector((state) => state.userReducer.languages)

    const defaultData = {
        refType: refType,
        attributes: [],
        tags: ""
    }
    const defaultError = {
        refType: "",
        name: "",
        tags: ""
    }

    const [data, setData] = useState(defaultData)
    const [error, setError] = useState(defaultError)
    const [action, setAction] = useState()
    const refDialog = useRef(null)
    const [activeIndex, setActiveIndex] = useState(0);

    useImperativeHandle(ref, () => ({
        create: () => {
            refDialog.current.create();
            let _data = _.cloneDeep(defaultData)
            languages?.map((item) => {
                _data.attributes.push({
                    languageCode: item.code,
                    languagesname: item.name,
                    name: ""
                })
            })
            setData(_data)
            setAction("create")
        },
        edit: (_data) => {
            refDialog.current.create();
            setAction("edit");
            _data.attributes = []
            languages?.map((item) => {
                let lan = _data.languages.find(o => item.code == o.languageCode)
                _data.attributes.push({
                    languageCode: item?.code,
                    languagesname: item?.name,
                    name: lan?.name
                })
            })
            setData(_data)
        },
        view: (item) => {
            refDialog.current.create();
            setAction("view")
            setData(item)
        }
    }));

    const onChangeData = (prop, value, propArr, index) => {
        let _data = cloneDeep(data)
        switch (prop) {
            case "attributes":
                _data.attributes[index][propArr] = value;
                break;
            default:
                _data[prop] = value;
                break
        }
        validateData([prop], _data)
        setData(_data)
    }

    const validateData = (prop, _data) => {
        let _error = cloneDeep(error)
        let requiredFields = prop.length > 0 ? prop : Object.keys(_data);
        let _prop = requiredFields
        for (const field of _prop) {
            switch (field) {
                case "refType":
                    _error[field] = ""
                    if (!_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                default:
                    break;
            }
        }

        setError(_error)
        let count = 0;
        for (const key in _error) {
            if (_error[key]) {
                count++;
            }
        }
        return count;
    }

    const onSubmit = () => {
        let _data = cloneDeep(data)
        _data.tags = _data.name
        let validate = validateData([], _data)
        if (validate) return
        switch (action) {
            case "create":
                EventsServices.offerAttribute.create(eventId, _data).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            case "edit":
                EventsServices.offerAttribute.update(_data?.id, eventId, _data).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            default:
                break;
        }

    }

    const renderError = (field) => {
        if (error[field]) {
            return <span className="text-red-500 text-xs">{error[field]}</span>
        }
    }

    const cancel = () => {
        refDialog.current.close()
        setData(defaultData)
        setError(defaultError)
    }

    return (
        <EDialog
            ref={refDialog}
            style={{ width: '70vw' }}
            title={t("offer_at.at_information")}
            closable={false}
            hidebtnFooter={false}
            btnFooter={
                action === "view" ?
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        }
                    ] :
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        },
                        {
                            label: t("common.save"),
                            onClick: () => onSubmit(),
                        }
                    ]
            }
        >
            <div className="container">
                <div className="grid">
                    {/* <div className="col-6 field">
                        <label className="require">{t("offer_at.name")}</label>
                        <InputText
                            disabled={action === "view"}
                            value={data?.name}
                            onChange={(e) => { onChangeData("name", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["name"] })}
                        ></InputText>
                        {renderError("name")}
                    </div> */}
                    <div className="col-6 field">
                        <label className="require">{t("offer_at.type")}</label>
                        <Dropdown
                            disabled={true}
                            options={optionRefType}
                            optionLabel="name"
                            optionValue="name"
                            className="w-full"
                            value={data?.refType}
                            onChange={(e) => { onChangeData("refType", e.target.value) }}
                        />
                        {renderError("refType")}
                    </div>
                    <div className="col-12 flex flex-column">
                        <b className="mb-2">{t("event.attributes")}</b>
                        <div className="w-full">
                            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                {data?.attributes?.map((item) => {
                                    return (
                                        <TabPanel header={item?.languagesname}>
                                            <div className="grid">
                                                <div className="col-12 field">
                                                    <label>{t("event.name")}</label>
                                                    <InputText
                                                        value={item?.name}
                                                        onChange={(e) => { onChangeData("attributes", e.target.value, "name", activeIndex) }}
                                                        className={classNames({ "w-full": true })}
                                                    />
                                                </div>
                                            </div>
                                        </TabPanel>
                                    )
                                })}
                            </TabView>
                        </div>
                    </div>
                </div>
            </div>
        </EDialog>
    )
}
OfferAttributeDetail = forwardRef(OfferAttributeDetail)
export default OfferAttributeDetail;
