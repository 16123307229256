import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import './styles.scss'
import _ from "lodash";

function ProgressEvent(props, ref) {
    const {
        model,
        activeIndex,
        onChangeState,
        disable
    } = props;

    useImperativeHandle(ref, () => ({

    }));

    const onChange = (item) => () => {
        if (!disable) {
            onChangeState(item)
        }
    }

    const renderItem = (item, index) => {
        console.log(activeIndex)
        return <div className='e-step-item-container relative'>
            <div className={`absolute flex align-items-center justify-content-center e-step-item border-gray-900 border-1 border-round-3xl cursor-pointer ${activeIndex >= index ? "bg-gray-900" : "bg-white"}`}
                onClick={onChange(item)}
            >
                <i className={`pi pi-check ${activeIndex >= index ? "text-white" : "text-black"}`} />
            </div>
            <div className='e-step-item-label absolute'>
                <label className={`text-nowrap ${activeIndex > index ? "text-400" : ""}`}>{item.name}</label>
            </div>
        </div>
    }

    return <>
        <div className='e-step relative pb-6 pt-8 px-8'>
            <div className='flex align-items-center justify-content-between relative w-full'>
                {model.map(renderItem)}
                <div className='e-step-line absolute w-full'></div>
            </div>
        </div>
    </>
}
ProgressEvent = forwardRef(ProgressEvent);
export default ProgressEvent