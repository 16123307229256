/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss"
import { useTranslation } from "react-i18next";
import { Card } from "primereact/card";
import { Chart } from "primereact/chart";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import EventsServices from "../../../core/services/EventsServices";
import { filter, find, remove, findIndex } from 'lodash'
import { EVENT_SETTING, EVENT_STATUS, USER_PERMISSION } from "../../../utils/constants";
import moment from "moment";
import { Steps } from "primereact/steps";
import ProgressEvent from "../../../components/ProgressEvent";
import { ToastHelper } from "../../../helper/ToastHelper";
import { TabMenu } from 'primereact/tabmenu';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import _ from "lodash"
import { FilterMatchMode } from "primereact/api";

function Audience(props, ref) {

    const { eventID } = props;
    const { t } = useTranslation()
    const [data, setData] = useState([])
    const [keyword, setKeyword] = useState([])
    const [dataTemp, setDataTemp] = useState([])
    const [filterData, setFilterData] = useState([])
    const [temp, setTemp] = useState({})
    const [filter, setFilter] = useState("company")
    const dispatch = useDispatch();


    const loadAudience = async () => {

        await EventsServices.report.getAudience(eventID).then((res) => {
            if (res) {
                setData(res.company)
                setTemp(res)
            }
        })
    }
    useEffect(() => {
        if (eventID) {
            loadAudience();
        }

    }, [eventID])
    useEffect(() => {
        switch (filter) {
            case "company":
                setData(temp.company)
                break;
            case "user":
                setData(temp.user)
                break;
            case "product":
                setData(temp.product)
                break;
            default:
                setData(temp.company)
                break;
        }
    }, [filter])

    useEffect(() => {
        setDataTemp(data)
        setFilterData(data)
        setKeyword("")
    }, [data])

    const option = [
        { code: "company", name: t("chart.company") },
        { code: "user", name: t("chart.user") },
        { code: "product", name: t("chart.product") },
    ];

    const searchKeywork = (value) => {
        let _data = _.cloneDeep(dataTemp)
        setKeyword(value)
        const result = _.filter(_data, (item) =>
            _.includes(item.name.toLowerCase(), value.toLowerCase())
        );
        setFilterData(result)
    }


    return (

        <>
            <div className="col-12">
                <Card className="border-round-2xl shadow-2">
                    <div className="w-full mb-3">
                        <div className="text-5xl font-bold text-900 text-center">{t("chart.audience")}</div>
                        <div className="text-lg font-medium text-center mb-3">{t("chart.understand_what_audience")}</div>
                        <Dropdown value={filter} onChange={(e) => setFilter(e.value)} options={option} optionLabel="name" optionValue="code"
                            placeholder="Select a option" className="w-3 mr-2" />
                        <InputText placeholder={t("common.search")} value={keyword} onChange={(e) => searchKeywork(e.target.value)} className="w-3" />
                    </div>
                    <DataTable
                        value={filterData}
                        dataKey="id"
                        className="p-datatable-gridlines border-all"
                        resizableColumns
                        columnResizeMode="expand"
                        emptyMessage={t("company.no_data")}
                        // scrollable
                        // scrollDirection="both"
                        // scrollHeight="flex"
                        // lazy
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginator
                    >
                        <Column
                            style={{ width: 100 }}
                            field="STT"
                            body={(index, value) => value?.rowIndex + 1}
                            header={t("company.no")}
                        />
                        <Column
                            header={t("company.name")}
                            // style={{ minWidth: 250 }}
                            field="name"
                        />
                        <Column
                            header={t("common.total")}
                            // style={{ minWidth: 50 }}
                            field="total"
                        />

                    </DataTable>
                </Card>
            </div>


        </>

    )
}
Audience = forwardRef(Audience)
export default Audience;

