import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
// import "./styles.scss"
import { classNames } from "primereact/utils";
import { useDispatch } from "react-redux";
import EDialog from "../../../components/EDialog";
import { actionCompanyCreate, actionCompanyEdit, actionCompanyById } from "../../../core/actions/ActionCompany";
import CompanyServices from "../../../core/services/CompanyServices";
import S3FileUpload from "../../../components/S3FileUpload";
import { FILE_TYPE, UPLOAD_STATUS, USER_PERMISSION, USER_PERMISSIONS } from "../../../utils/constants";
import { useSelector } from "react-redux";
import UserServices from "../../../core/services/UserServices";
import CKEditorDocumentEditor from "../../../components/CKEditorDocumentEditor";
import { cloneDeep } from 'lodash'
import { Password } from 'primereact/password';

function UserDetail(props, ref) {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    const { countries = [] } = useSelector((state) => state.userReducer)

    const [permissions] = useState(USER_PERMISSIONS.map(o => ({
        ...o,
        name: t(o.name)
    })))

    const s3FileUploadRef = useRef()

    const s3FileUploadBgRef = useRef()

    const { afterSubmit } = props
    const dispatch = useDispatch();
    const defaultData = {
        userName: "",
        password: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        avatar: "",
        description: "",
        permissionType: USER_PERMISSION.ORG,
        companyId: null,
        position: ""
    }
    const defaultError = {
        userName: null,
        password: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        permissionType: null,
        companyId: null
    }
    const [filter, setFilter] = useState({})
    const [data, setData] = useState(defaultData)
    const [error, setError] = useState(defaultError)
    const [action, setAction] = useState()
    const refDialog = useRef(null)

    const [companies, setCompanies] = useState([])

    useEffect(() => {
        loadCompany()
    }, [])

    const loadCompany = () => {
        CompanyServices.search().then(res => {
            if (res) {
                setCompanies(res)
            }
        })
    }

    useImperativeHandle(ref, () => ({
        create: () => {
            refDialog.current.create();
            setAction("create")
        },
        edit: (_data) => {
            setAction("edit");
            if (!_data.description) { _data.description = "" }
            if (_data.descriptions) {_data.description = _data.descriptions}
            setData({
                ..._data,
                permissionType: _data.permission,
                avatar: _data?.avatar ? {
                    name: _data?.avatar,
                    status: UPLOAD_STATUS.UPLOADED
                } : null,
                password: ""
            })
            refDialog.current.create();
        },
        view: (id) => {
            refDialog.current.create();
            setAction("view")
        }
    }));

    const onShow = () => {
        if (data?.avatar) {
            s3FileUploadRef.current.setFiles([data.avatar.name], FILE_TYPE.IMAGE)
        }
    }

    const onChangeData = (prop, value) => {
        let _data = cloneDeep(data)
        _data[prop] = value
        validateData([prop], _data)
        setData(_data)
    }

    const validateData = (prop, _data) => {
        let _error = cloneDeep(error)
        let requiredFields = prop.length > 0 ? prop : Object.keys(_data);
        let _prop = requiredFields
        for (const field of _prop) {
            switch (field) {
                case "userName":
                case "firstName":
                case "lastName":
                case "email":
                case "phone":
                case "permissionType":
                case "companyId":

                    _error[field] = ""
                    if (!_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;

                case "password":
                    _error[field] = ""
                    if (!_data[field] && action == "create") {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                case "avatar":
                    _error[field] = ""
                    if (_data[field] && _data[field].status != UPLOAD_STATUS.UPLOADED) {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                default:
                    break;
            }
        }

        setError(_error)
        let count = 0;
        for (const key in _error) {
            if (_error[key]) {
                count++;
            }
        }
        return count;
    }

    const onSubmit = () => {
        let validate = validateData([], data)
        if (validate) return
        switch (action) {
            case "create":
                UserServices.create({
                    ...data,
                    avatar: data?.avatar?.name
                }).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            case "edit":
                UserServices.update({
                    ...data,
                    avatar: data?.avatar?.name,
                    password: data.password ? data.password : null
                }).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            default:
                break;
        }

    }

    const renderError = (field) => {
        if (error[field]) {
            return <span className="text-red-500 text-xs">{error[field]}</span>
        }
    }

    const cancel = () => {
        refDialog.current.close()
        setData(defaultData)
        setError(defaultError)
    }

    const onUploadedImage = useCallback((val) => {
        onChangeData("avatar", val)
    }, [data])
    console.log(data?.password)

    return (
        <EDialog
            ref={refDialog}
            style={{ width: '70vw' }}
            title={t("user.user_information")}
            closable={false}
            hidebtnFooter={false}
            onShow={onShow}
            btnFooter={
                action === "view" ?
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        }
                    ] :
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        },
                        {
                            label: t("common.save"),
                            onClick: () => onSubmit(),
                            loading
                        }
                    ]
            }
        >
            <div className="container">
                <div className="grid">
                    <div className="col-6 field">
                        <label className="require">{t("user.user_name")}</label>
                        <InputText
                            disabled={action === "view"}
                            value={data?.userName}
                            onChange={(e) => { onChangeData("userName", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["userName"] })}
                        />
                        {renderError("userName")}
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("user.permission_type")}</label>
                        <Dropdown
                            filter
                            disabled={action === "view"}
                            options={permissions}
                            optionLabel="name"
                            optionValue="id"
                            className="w-full"
                            value={data?.permissionType}
                            onChange={(e) => { onChangeData("permissionType", e.target.value) }}
                        />
                        {renderError("phone")}
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("user.company")}</label>
                        <Dropdown
                            filter
                            disabled={action === "view"}
                            options={companies}
                            optionLabel="name"
                            optionValue="id"
                            className="w-full"
                            value={data?.companyId}
                            onChange={(e) => { onChangeData("companyId", e.target.value) }}
                        />
                        {renderError("companyId")}
                    </div>

                    <div className="col-6 field">
                        <label >{t("user.position")}</label>
                        <InputText
                            disabled={action === "view"}
                            value={data?.position}

                            onChange={(e) => { onChangeData("position", e.target.value) }}
                            className={classNames({ "w-full": true })}
                        />
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("user.password")}</label>
                        <form autocomplete="off">
                        <InputText
                            // disabled={action !== "create"}
                            value={data?.password}
                            type="password"
                            onChange={(e) => { onChangeData("password", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["password"] })}
                            security
                            autocomplete="off"
                            filled={true}
                        />
                        </form>
                        {/* <Password autoComplete="off" security="off" au value={data?.password} onChange={(e) => onChangeData("password", e.target.value)} feedback={false} tabIndex={1} /> */}
                        {renderError("password")}
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("user.first_name")}</label>
                        <InputText
                            disabled={action === "view"}
                            value={data?.firstName}

                            onChange={(e) => { onChangeData("firstName", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["firstName"] })}
                        />
                        {renderError("firstName")}
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("user.last_name")}</label>
                        <InputText
                            disabled={action === "view"}
                            value={data?.lastName}

                            onChange={(e) => { onChangeData("lastName", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["lastName"] })}
                        />
                        {renderError("lastName")}
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("user.email")}</label>
                        <InputText
                            disabled={action === "view"}
                            value={data?.email}

                            onChange={(e) => { onChangeData("email", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["email"] })}
                        />
                        {renderError("email")}
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("user.phone")}</label>
                        <InputText
                            disabled={action === "view"}
                            value={data?.phone}

                            onChange={(e) => { onChangeData("phone", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["phone"] })}
                        />
                        {renderError("phone")}
                    </div>
                    <div className="col-12 field">
                        <label className="">{t("user.avatar")}</label>
                        <S3FileUpload
                            ref={s3FileUploadRef}
                            onChange={onUploadedImage}
                            accept="image/*"
                            onLoading={setLoading}
                        />
                    </div>
                    <div className="col-12 field pb-4">
                        <label>{t("event.description")}</label>
                        <CKEditorDocumentEditor
                            initialData={data?.description}
                            onDataChanged={(e) => {
                                setData(prevData => {
                                    // Tạo một bản sao của prevData
                                    let newData = { ...prevData };
                                    newData = {
                                        ...newData,
                                        description: e
                                    };
                                    return newData;  // Trả về newData đã được cập nhật
                                });
                            }}
                        />
                    </div>

                </div>
            </div>
        </EDialog>
    )
}
UserDetail = forwardRef(UserDetail)
export default UserDetail;
