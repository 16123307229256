import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { useDispatch, useSelector } from "react-redux";
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import "./styles.scss";
import EventSpeaker from "../../EventSpeaker";
import { EnumEventModule, PERMISSION } from "../../../../utils/constants";
import Locations from "../../Locations";
import OfferAttribute from "../../OfferAttribute";
import EBreadCrumb from "../../../../components/EBreadCrumb";
import { useNavigate, useParams } from "react-router-dom";
import EventPermalink from "../../EventsPermalink";
import { checkPermission } from "../../../../utils";
import EventsReferences from "../../EventsReferences";

const screenPermission = "eventInfoScreen_session_info"

export default function SessionInfo(props) {
    const { t } = useTranslation()

    const { eventId, refId, screenId } = useParams()

    const { groupPermission } = useSelector((state) => state.userReducer)

    const history = useNavigate()

    const activeIndex = useMemo(() => {
        switch (screenId) {
            case "speakers":
                return 0
            case "locations":
                return 1
            case "permalinks":
                return 2
            case "offer-attribute-industry":
                return 3
            case "offer-attribute-agenda":
                return 4
            case "reference":
                return 5
            default:
                return 0
        }
    }, [screenId])


    const tabHeaderTemplate = (name, link) => ({ onClick }) => {
        return <div className="p-3 font-bold flex cursor-pointer text-primary" onClick={onLink(link, onClick)}>
            {name}
        </div>
    }

    const onLink = (link, onClick) => () => {
        onClick()
        history(link)
    }

    if (!checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)) {
        return null
    }

    return (
        <div className="event-layout">
            <div className="event-layout-center overflow-hidden">
                <div className="p-card">
                    <div className="p-card-body">
                        <div className="grid">
                            <div className="col-12">
                                <TabView scrollable activeIndex={activeIndex} panelContainerClassName="px-0">
                                    <TabPanel headerTemplate={tabHeaderTemplate(t("event.speaker"), `/event/${eventId}/session/${refId}/speakers`)}>
                                        <EventSpeaker />
                                    </TabPanel>
                                    <TabPanel headerTemplate={tabHeaderTemplate(t("company.location"), `/event/${eventId}/session/${refId}/locations`)}>
                                        <Locations
                                            type={EnumEventModule.SESSION}
                                            screenPermission="eventInfoScreen_session_locations"
                                        />
                                    </TabPanel>
                                    <TabPanel headerTemplate={tabHeaderTemplate(t("event.permalink"), `/event/${eventId}/session/${refId}/permalinks`)}>
                                        <EventPermalink
                                            type={EnumEventModule.SESSION}
                                            screenPermission="eventInfoScreen_session_permalinks"
                                        />
                                    </TabPanel>
                                    <TabPanel headerTemplate={tabHeaderTemplate(t("common.offer_attribute_industry"), `/event/${eventId}/session/${refId}/offer-attribute-industry`)}>
                                        <OfferAttribute
                                            type={EnumEventModule.SESSION}
                                            refType={EnumEventModule.INDUSTRY}
                                            screenPermission="eventInfoScreen_session_offer-attributes"
                                        />
                                    </TabPanel>
                                    <TabPanel headerTemplate={tabHeaderTemplate(t("common.route.event_info.offer-attribute-agenda"), `/event/${eventId}/session/${refId}/offer-attribute-agenda`)}>
                                        <OfferAttribute
                                            type={EnumEventModule.SESSION}
                                            refType={EnumEventModule.AGENDA}
                                            screenPermission="eventInfoScreen_session_offer-attributes"
                                        />
                                    </TabPanel>
                                    <TabPanel headerTemplate={tabHeaderTemplate(t("references.title"), `/event/${eventId}/session/${refId}/reference`)}>
                                        <EventsReferences
                                            eventId={eventId}
                                            type={EnumEventModule.SESSION}
                                            refId={refId}
                                            screenPermission="eventInfoScreen_session_reference"
                                        />
                                    </TabPanel>

                                    {/* <TabPanel headerTemplate={tabHeaderTemplate(t("common.offer_attribute_product"), `/event/${eventId}/session/${refId}/offer-attribute-product`)}>
                                        <OfferAttribute
                                            type={EnumEventModule.SESSION}
                                            refType={EnumEventModule.PRODUCT}
                                            screenPermission="eventInfoScreen_session_offer-attributes"
                                        />
                                    </TabPanel> */}
                                </TabView>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
