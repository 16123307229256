import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import EDialog from "../../../components/EDialog";
import _, { cloneDeep, find } from 'lodash'
import EventsServices from "../../../core/services/EventsServices";
import { TabPanel, TabView } from "primereact/tabview";
import { useSelector } from "react-redux";
import UserServices from "../../../core/services/UserServices";
import { MEET_OPTION, MEET_OPTIONS } from "../../../utils/constants";
import EventMeetingService from "../../../core/services/EventMeetingService";
import { InputTextarea } from "primereact/inputtextarea";
import { ECalendar } from "../../../components/ECalendar";
import moment from "moment";
import MapsServices from "../../../core/services/MapsServices";
import { exportBusinessMatchingXlsx } from "../../../utils";

function BusinessMatchingExportDetail(props, ref) {
    const { t } = useTranslation()
    const { data } = props

    const language = useSelector((state) => state.userReducer.language)

    const refDialog = useRef()

    const [company, setCompany] = useState()


    useImperativeHandle(ref, () => ({
        create: () => {
            refDialog.current.create()
        }
    }));


    const onSubmit = () => {
        if (company) {
            const ls = _.filter(data, o => o.fromCompany.id == company)
            exportBusinessMatchingXlsx(ls)
            
        }
    }

    const cancel = () => {
        refDialog.current.close()
    }

    const companies = useMemo(() => {
        return data.map(o => {
            let com = o.fromCompany
            const companyAttributes = find(com?.attributes, { languageCode: language })
            com.name = companyAttributes?.name
            return com
        })
    }, [data, language])

    return (
        <EDialog
            ref={refDialog}
            style={{ width: '70vw' }}
            title={t("business_matching.info")}
            closable={false}
            hidebtnFooter={false}
            btnFooter={
                [
                    {
                        label: t("common.cancel"),
                        onClick: () => cancel(),
                        text: true
                    },
                    {
                        label: t("common.export"),
                        onClick: () => onSubmit(),
                    }
                ]
            }
        >
            <div className="container">
                <div className="grid">

                    <div className="col-12 field">
                        <label className="require">{t("business_matching.from_company")}</label>
                        <Dropdown
                            filter
                            options={companies}
                            optionLabel="name"
                            optionValue="id"
                            className="w-full"
                            value={company}
                            onChange={(e) => { setCompany(e.target.value) }}
                        />
                        {!company && <span className="text-red-500 text-xs">{t("common.validate_content")}</span>}
                    </div>
                </div>
            </div>
        </EDialog>
    )
}
BusinessMatchingExportDetail = forwardRef(BusinessMatchingExportDetail)
export default BusinessMatchingExportDetail;
