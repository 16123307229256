import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { ToastHelper } from "../../helper/ToastHelper";
import { Dropdown } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';
import EBreadCrumb from "../../components/EBreadCrumb";
import { cloneDeep, find } from 'lodash'
import { useSelector } from "react-redux";
import { checkPermission, exportBusinessMatchingXlsx } from "../../utils";
import { EnumEventModule, MEET_OPTIONS, MEET_STATUS, PERMISSION, SORT_TYPE } from "../../utils/constants";
import EventsServices from "../../core/services/EventsServices";
import { useParams } from "react-router";
import { ProgressBar } from "primereact/progressbar";
import EventMeetingService from "../../core/services/EventMeetingService";
import TableSort from "./TableSort";
import { AutoComplete } from 'primereact/autocomplete';
import * as _ from 'lodash'
import RanksServices from "../../core/services/RanksServices";

const screenPermission = "route.eventInfoScreen_sort"

function Sort({ }) {
    const { t } = useTranslation()

    const { eventId } = useParams()

    const [event, setEvent] = useState()

    const { groupPermission, language } = useSelector((state) => state.userReducer)

    const defaultFilter = {
        type: SORT_TYPE.ALL,
        refId: null
    }
    const [filter, setFilter] = useState(defaultFilter)

    const [search, setSearch] = useState("")

    const autoRef = useRef()

    const refTableSort = useRef()

    const [editing, setEditing] = useState(false)


    const op = useRef()
    const [data, setData] = useState([])
    const [all, setAll] = useState([])
    const [industries, setIndustries] = useState([])
    const [ranks, setRanks] = useState([])


    const [loadingList, setLoadingList] = useState(false)

    const [searchList, setSearchList] = useState([])
    const [keyword, setKeyword] = useState("")

    const inputRef = useRef()

    useEffect(() => {
        loadEvent()
        loadIndustries()
        loadRanks()
    }, [])

    const loadRanks = () => {
        RanksServices.get().then(res => {
            setLoadingList(false)
            if (res) {
                setRanks(res.map(o => {
                    o.name = find(o.languages, { languageCode: language })?.name
                    return o
                }))
            }
        })
    }

    const loadIndustries = () => {
        EventsServices.offerAttribute.filter(eventId, {
            page: 0,
            size: 9999,
            type: EnumEventModule.INDUSTRY
        }).then((res) => {
            if (res) {
                setIndustries(res.data.map(o => {
                    o.name = find(o.languages, { languageCode: language })?.name
                    return o
                }))
            }
        })
    }

    const loadEvent = () => {
        EventsServices.getById(eventId).then(res => {
            if (res) {
                setEvent(res)
            }
        })
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getList(filter)
        }, 200)
        return () => clearTimeout(delayDebounceFn)
    }, [filter])

    const getList = async (_filter) => {
        refTableSort.current.reset()
        _filter = cloneDeep(_filter ? _filter : filter)
            setLoadingList(true)
            let coms = await EventsServices.searchCompany(eventId, {
                offerAttributeId: _filter.refId
            })
            if (coms) {
                coms = coms.map(o => {
                    o.name = find(o.attributes, { languageCode: language })?.name
                    return o
                })
                setSearchList(coms)
            }
            let items = await EventsServices.companyArrange.get(eventId, {
                type: filter.refId ? SORT_TYPE.INDUSTRY : SORT_TYPE.ALL,
                refId: filter.refId
            })
            items = items.map(o => {
                // o.eventCompany.name = find(o.eventCompany.attributes, { languageCode: language })?.name
                return find(coms, { id: o.eventCompany.id })
            })
            setData(items)
            refTableSort.current.init(items)

            setLoadingList(false)
    }

    const onChangeFilter = (prop, value) => {
        let _filter = cloneDeep(filter)
        switch (prop) {
            case "type":
                _filter["refId"] = null
                setEditing(false)
                break
        }
        _filter[prop] = value
        setFilter(_filter)
    }

    const onSave = () => {
        setLoadingList(true)
        let _items = refTableSort.current.get()
        EventsServices.companyArrange.create(eventId, {
            type: filter.refId ? SORT_TYPE.INDUSTRY : SORT_TYPE.ALL,
            refId: filter.refId,
            items: _items.map((o, index) => ({
                eventCompanyId: o.id,
                sortOrder: index
            }))
        }).then(res => {
            setLoadingList(false)
            if (res) {
                setEditing(false)
                ToastHelper.show({ severity: 'success', summary: t("common.success") })
            }
        })
    }

    const deleteData = (id) => {
        confirmDialog({
            message: t("business_matching.confirm_content"),
            header: t("common.confirm"),
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            rejectLabel: t("common.cancel"),
            acceptLabel: t("common.confirm"),
            accept: () => {
                EventMeetingService.inactive(id, {
                    code: event.eventCode
                }).then((res) => {
                    if (res) {
                        ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
                        getList()
                    }
                })
            }
            ,
            reject: () => { }
        });

    }

    const types = useMemo(() => {
        return SORT_TYPE.list.map(o => ({
            ...o,
            name: t(o.name)
        }))
    }, [])

    const onSearchChange = (e) => {
        if (e.value) {
            let _select = _.find(searchList, {id: e.value})
            let _data = _.cloneDeep(data)
            if (!find(_data, { id: _select.id })) {
                // _data.push(_select)
                // setData(_data)
                refTableSort.current.add(_select)
                setEditing(true)
            }
        }
    }

    const onClear = (e) => {
        setKeyword("")
    }

    const onSearch = (e) => {
        let keyw = e?.query?.trim()
        setKeyword(keyw)
    }

    const searchListSelection = useMemo(() => {
        if (!keyword) {
            return searchList
        } else {
            let _keyword = keyword.toLowerCase()
            return _.filter(searchList, o => !!_.find(o.attributes, x => x.name?.toLowerCase()?.indexOf(_keyword) != -1))
        }
    }, [searchList, keyword])

    const startContent = (
        <div className="flex gap-2">
            {/* <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText  placeholder={t("company.search")} value={search} onChange={(e) => { setSearch(e.target.value) }} />
            </span> */}
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                {/* <AutoComplete
                    ref={autoRef}
                    inputRef={inputRef}
                    placeholder={t("company.search")}
                    className='w-full'
                    field="name"
                    multiple
                    // value={keyword}
                    onClear={onClear}
                    completeMethod={onSearch}
                    suggestions={searchListSelection}
                    // onKeyPress={onSearch}
                    // onClear={() => console.log("ok")}
                    onChange={onSearchChange}
                    // dropdown
                    onFocus={() => autoRef.current.show()}
                /> */}
                <Dropdown
                    filter
                    options={searchListSelection}
                    optionLabel="name"
                    optionValue="id"
                    className="w-full"
                    value={null}
                    onChange={onSearchChange}
                    showClear
                />
            </span>
            {editing
                ?
                <Button
                    icon="pi pi-save"
                    label={t("common.save")}
                    // size="small"
                    onClick={onSave}
                    loading={loadingList}
                    disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.CREATE)}
                />
                : null
            }
        </div>
    );

    const endContent = (
        <div className="flex gap-2">
            <Dropdown
                placeholder={t("common.industry")}
                optionLabel='name'
                optionValue='id'
                options={industries}
                value={filter.refId}
                onChange={(e) => onChangeFilter("refId", e.value)}
                filter
                showClear
            />


        </div>
    );

    const column = useMemo(() => [
        {
            accessorKey: 'name',
            header: t("company.name"),
        },
        {
            // accessorKey: 'progress',
            header: t("common.ranks"),
            accessorFn: row => _.find(ranks, { id: row.rank?.id })?.name,
        },
    ], [ranks])

    if (!checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)) {
        return null
    }

    return (
        <div className="event-layout">
            <div className="event-layout-center overflow-hidden">

                <div className="grid">
                    <div className="col-12 ">
                        <Toolbar start={startContent} end={endContent} />
                    </div>
                    <div className="col-12">
                        <div className="e-progress-bar">
                            {loadingList
                                ? <ProgressBar className="e-progress-bar" mode="indeterminate"></ProgressBar>
                                : null
                            }
                        </div>
                        <TableSort
                            ref={refTableSort}
                            list={data}
                            column={column}
                            setEditing={setEditing}
                        />

                    </div>
                </div>
            </div>

        </div>
    )
}

export default Sort;