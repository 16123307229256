import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SpeedDial } from 'primereact/speeddial';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
// import UserDetail from "./UserDetail";
import { Toast } from 'primereact/toast';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { ToastHelper } from "../../../helper/ToastHelper";
import { cloneDeep, find } from 'lodash'
import { PERMISSION, USER_PERMISSION, USER_PERMISSIONS } from "../../../utils/constants";
import { useSelector } from 'react-redux';
import FaqsServices from "../../../core/services/FaqsServices";
import EventNotificationDetail from "./EventNotificationDetail";
import EventsServices from "../../../core/services/EventsServices";
import { useParams } from "react-router";
import { checkPermission } from "../../../utils";
import { ProgressBar } from "primereact/progressbar";
import EventNotificationGuestDetail from "./EventNotificationGuestDetail";
import EventNotificationEmailDetail from "./EventNotificationEmailDetail";

const screenPermission = "eventInfoScreen_notification"

function EventsNotification(props) {
    const { t } = useTranslation()

    const { userInfo, groupPermission, language } = useSelector((state) => state.userReducer)
    const { eventId } = useParams()
    const [lazyParams, setLazyParams] = useState({
        keyword: "",
        first: 0,
        page: 0,
        size: 10,
        total: 0,
        // total: 0,
        // status: 1
    })

    const [meta, setMeta] = useState(lazyParams)

    const toast = useRef()
    const refDetail = useRef()
    const refDetailGuest = useRef()
    const refDetailEmail = useRef()

    const [data, setData] = useState([])
    const [event, setEvent] = useState()
    const [loadingList, setLoadingList] = useState(false)

    const [sessions, setSessions] = useState([])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (eventId) {
                getList(lazyParams)
            }
        }, 200)
        return () => clearTimeout(delayDebounceFn)
    }, [lazyParams])

    useEffect(() => {
        loadSession()
        loadEvent()
    }, [])

    const loadEvent = () => {
        EventsServices.getById(eventId).then(res => {
            if (res) {
                setEvent(res)
            }
        })
    }

    const loadSession = () => {
        EventsServices.session.get(eventId).then(res => {
            if (res) {
                setSessions(res.map(o => ({
                    ...o,
                    name: find(o.attributes, { languageCode: language })?.name
                })))
            }
        })
    }

    const permission = useMemo(() => {
        return parseInt(userInfo?.permission ?? "-1")
    }, [userInfo])

    const getList = (_lazyParams) => {
        _lazyParams = cloneDeep(_lazyParams ? _lazyParams : lazyParams)
        _lazyParams.page = _lazyParams.page + 1
        _lazyParams.keyword = _lazyParams.keyword ? _lazyParams.keyword : null
        setLoadingList(true)
        EventsServices.notification.get(_lazyParams).then((res) => {
            setLoadingList(false)
            if (res) {
                setData(res.data)
                setMeta({
                    ...meta,
                    first: (res.pageNumber - 1) * res.pageSize,
                    total: res.totalRecord
                })
            }
        })
    }

    const createData = () => {
        refDetail.current.create()
    }
    const createDataGuest = () => {
        refDetailGuest.current.create()
    }
    const createDataEmail = () => {
        refDetailEmail.current.create()
    }

    const editData = (_data) => {
        refDetail.current.edit(_data)
    }

    const viewData = (id) => {
        refDetail.current.view(id)
    }

    const deleteData = (id) => {
        confirmDialog({
            message: t("company.confirm_content"),
            header: t("company.confirm"),
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            rejectLabel: t("company.cancel"),
            acceptLabel: t("company.confirm"),
            accept: () => {
                EventsServices.notification.delete(id).then((res) => {
                    if (res) {
                        ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
                        getList()
                    }
                })
            }
            ,
            reject: () => { }
        });

    }
    const afterSubmit = () => {
        getList()
        // ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
    }

    const startContent = (
        <React.Fragment>
            <Button
                icon="pi pi-plus"
                className="mr-2"
                label={t("common.add")}
                outlined
                size="small"
                onClick={createData}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.CREATE)}

            />
            <Button
                icon="pi pi-plus"
                className="mr-2"
                label={t("common.notify_guest")}
                outlined
                size="small"
                onClick={createDataGuest}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.CREATE)}

            />
            <Button
                icon="pi pi-plus"
                className="mr-2"
                label={t("common.email")}
                outlined
                size="small"
                onClick={createDataEmail}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.CREATE)}

            />
        </React.Fragment>
    );

    const onChangeKeyword = (e) => {
        let _filter = cloneDeep(lazyParams)
        _filter.keyword = e.target.value
        setLazyParams(_filter)
    }

    const endContent = (
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText placeholder={t("company.search")} value={lazyParams?.keyword} onChange={onChangeKeyword} />
        </span>
    );

    const onPage = (event) => {
        let _filter = cloneDeep(lazyParams)
        _filter.size = event.rows;
        _filter.page = event.page;
        setLazyParams(_filter)
    };

    const renderCol2 = (row) => {
        const per = USER_PERMISSIONS.find(o => o.id.toString() == row.permission)
        return <span>{per ? t(per.name) : null}</span>
    }

    const renderColEnd = (row) => {
        return <span className="flex">
            <Button icon="pi pi-eye" rounded text onClick={() => viewData(row.id)} />
            <Button icon="pi pi-pencil" rounded text onClick={() => editData(row)}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.UPDATE)}
            />
            <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" onClick={() => deleteData(row.id)}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.DELETE)}
            />
        </span>
    }
    const renderCol0 = (d, value) => {
        let _info = find(d.languages, { languageCode: language })
        return (
            <span>
                {_info?.name}
            </span>
        )
    }

    if (!checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)) {
        return null
    }

    return (
        <div className="event-layout">
            <div className="event-layout-center overflow-hidden">

                <div className="grid">
                    <div className="col-12">
                        <Toolbar start={startContent} end={endContent} />
                    </div>
                    <div className="col-12">
                        <div className="e-progress-bar">
                            {loadingList
                                ? <ProgressBar className="e-progress-bar" mode="indeterminate"></ProgressBar>
                                : null
                            }
                        </div>
                        <DataTable
                            value={data}
                            dataKey="id"
                            className="p-datatable-gridlines border-all"
                            resizableColumns
                            columnResizeMode="expand"
                            emptyMessage={t("company.no_data")}
                            scrollable
                            scrollDirection="both"
                            scrollHeight="flex"
                            lazy
                            paginator
                            first={meta?.first || 0}
                            rows={lazyParams?.size || 20}
                            totalRecords={meta.total}
                            rowsPerPageOptions={[10, 20, 25, 50, 100]}
                            paginatorTemplate="RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink"
                            currentPageReportTemplate="{first} - {last} of {totalRecords}"
                            onPage={onPage}
                            reorderableColumns
                            reorderableRows
                        >
                            <Column
                                style={{ width: 100 }}
                                field="STT"
                                body={(index, value) => value?.rowIndex + 1}
                                header={t("common.no")}
                            />
                            <Column
                                header={t("common.name")}
                                // style={{ width: 200 }}
                                className="flex-1"
                                field="type"

                                body={renderCol0}
                            />
                            {/* <Column
                                        header={t("user.permission_type")}
                                        style={{ minWidth: 250 }}
                                        body={renderCol2}
                                    /> */}

                            <Column
                                // columnKey="action"
                                // alignFrozen="right"
                                // frozen
                                // header={t("common.action")}
                                // className="col-frozen-table-end py-1"
                                // style={{ minWidth: 180, width: 180 }}
                                // body={(d) => {
                                //     return (
                                //         <span>
                                //             {/* <Button icon="pi pi-eye" rounded text onClick={() => viewData(d.id)} /> */}
                                //             <Button icon="pi pi-pencil" rounded text onClick={() => editData(d)} />
                                //             <Button icon="pi pi-times" rounded text severity="danger" onClick={() => deleteData(d.id)} />
                                //         </span>
                                //     )
                                // }}
                                columnKey="action"
                                alignFrozen="right"
                                frozen
                                header={t("common.action")}
                                style={{ minWidth: 180, width: 180 }}
                                className="col-frozen-table-end py-1"
                                body={renderColEnd}
                            />
                        </DataTable>
                    </div>
                </div>
            </div>
            <EventNotificationDetail
                eventId={eventId}
                ref={refDetail}
                afterSubmit={afterSubmit}
                sessions={sessions}
            />
            <EventNotificationGuestDetail
                eventId={eventId}
                ref={refDetailGuest}
            // afterSubmit={afterSubmit}
            />
            <EventNotificationEmailDetail
                event={event}
                ref={refDetailEmail}
            // afterSubmit={afterSubmit}
            />

        </div>
    )
}

export default EventsNotification;
