import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import EventsServices from "../../../core/services/EventsServices";
import EventsReferencesDetail from "./EventsReferencesDetail";
import { ToastHelper } from "../../../helper/ToastHelper";
import { confirmDialog } from "primereact/confirmdialog";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkPermission, findAttribute } from "../../../utils";
import { PERMISSION, REFERENCE_TYPE } from "../../../utils/constants";
import { find, cloneDeep } from 'lodash'
import { ProgressBar } from "primereact/progressbar";
import { Image } from "primereact/image";
import { BASE_S3_URL } from "../../../config";
import ReferenceServices from "../../../core/services/ReferenceServices";
import * as _ from 'lodash';

function EventsReferences(props) {
    const { eventId, refId, type, screenPermission } = props;
    const { t } = useTranslation();

    const { groupPermission, language } = useSelector((state) => state.userReducer)

    const [lazyParams, setLazyParams] = useState({
        keyword: ""
    })

    const [loadingList, setLoadingList] = useState(false)
    const refDetail = useRef();

    const [data, setData] = useState([])
    useEffect(() => {
        if (eventId) {
            getList()
        }
    }, [])

    const createData = () => {
        refDetail.current.create()
    }

    const editData = (d) => {
        // setSelectedData(d)
        refDetail.current.edit(d)
    }

    const viewData = (d) => {
        refDetail.current.view(d)
    }

    const deleteData = (id) => {
        confirmDialog({
            message: t("permalink.confirm_content"),
            header: t("permalink.confirm"),
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            rejectLabel: t("permalink.cancel"),
            acceptLabel: t("permalink.confirm"),
            accept: () => {
                ReferenceServices.delete(id, eventId, type, refId).then((res) => {
                    if (res) {
                        ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
                        getList()
                    }
                })
            }
            ,
            reject: () => { }
        });

    }

    const onChangeKeyword = (e) => {
        let _filter = cloneDeep(lazyParams)
        _filter.keyword = e.target.value
        setLazyParams(_filter)
    }

    const headerTable = () => {
        return (
            <div className="flex justify-content-between">
                <div>
                    <Button
                        icon="pi pi-plus"
                        className="mr-2"
                        label={t("common.add")}
                        outlined
                        size="small"
                        onClick={() => createData()}
                        disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.CREATE)}

                    />
                </div>
                {/* <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText placeholder={t("company.search")} value={lazyParams?.keyword} onChange={onChangeKeyword} />
                </span> */}
            </div>
        )
    }

    const getList = () => {
        setLoadingList(true)
        ReferenceServices.get(eventId, type, refId).then((res) => {
            setLoadingList(false)
            if (res) {
                setData(res)
            }
        })
    }

    const afterSubmit = () => {
        getList()
        ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
    }
    const renderCol0 = (d, value) => {
        const info = _.find(REFERENCE_TYPE.list, { id: d.refType })
        return <span>{t(info?.name)}</span>
    }

    const renderCol1 = (d, value) => {
        return <span>{d.sortOrder}</span>
    }

    const previewList = useMemo(() => {
        return findAttribute(data, lazyParams.keyword)
    }, [lazyParams, data])

    if (!checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)) {
        return null
    }

    return (
        <div className="container-fluid">
            <div className="grid">
                <div className="col-12">
                    <div className="e-progress-bar">
                        {loadingList
                            ? <ProgressBar className="e-progress-bar" mode="indeterminate"></ProgressBar>
                            : null
                        }
                    </div>
                    <DataTable
                        value={previewList}
                        header={headerTable}
                        dataKey="id"
                        className="p-datatable-gridlines border-all"
                        resizableColumns
                        columnResizeMode="expand"
                        emptyMessage={t("common.no_data")}
                        scrollable
                        scrollDirection="both"
                        scrollHeight="flex"
                        lazy
                    >
                        <Column
                            style={{ width: 100 }}
                            field="STT"
                            body={(index, value) => value?.rowIndex + 1}
                            header={t("common.no")}
                        />
                        <Column
                            header={t("permalink.application")}
                            style={{ flex: "1 0 250px" }}
                            body={renderCol0}
                        />

                        <Column
                            header={t("common.sortOrder")}
                            style={{ flex: "1 0 250px" }}
                            body={renderCol1}
                        />
                        <Column
                            columnKey="action"
                            alignFrozen="right"
                            frozen
                            header={t("company.action")}
                            className="col-frozen-table-end py-1"
                            style={{ minWidth: 180, width: 180 }}
                            body={(d) => {
                                return (
                                    <span>
                                        <Button icon="pi pi-eye" rounded text aria-label="Filter" onClick={() => viewData(d)} />
                                        <Button icon="pi pi-pencil" rounded text aria-label="Filter" onClick={() => editData(d)}
                                            disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.UPDATE)}
                                        />
                                        <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" onClick={() => deleteData(d.id)}
                                            disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.DELETE)}
                                        />
                                    </span>
                                )
                            }}
                        />
                    </DataTable>
                </div>
            </div>
            <EventsReferencesDetail
                eventId={eventId}
                type={type}
                refId={refId}
                ref={refDetail}
                afterSubmit={afterSubmit}
            />
        </div>
    )
}

export default EventsReferences;
