/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss"
import { useTranslation } from "react-i18next";
import { Card } from "primereact/card";
import { Chart } from "primereact/chart";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import EventsServices from "../../../core/services/EventsServices";
import { filter, find, remove, findIndex } from 'lodash'
import { EVENT_SETTING, EVENT_STATUS, MEET_STATUS, USER_PERMISSION } from "../../../utils/constants";
import moment from "moment";
import { Steps } from "primereact/steps";
import ProgressEvent from "../../../components/ProgressEvent";
import { ToastHelper } from "../../../helper/ToastHelper";
import { TabMenu } from 'primereact/tabmenu';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Divider } from "primereact/divider";
import _ from "lodash";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

function Conversations(props, ref) {

    const { t } = useTranslation()
    const { eventID, event } = props;
    const { events, permission, eventCode, language } = useSelector((state) => state.userReducer)

    const dispatch = useDispatch();

    const [chartData, setChartData] = useState({});
    const [chartData2, setChartData2] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [report, setReport] = useState([])
    const [selected, setSelected] = useState()
    const [meet, setMeet] = useState([])


    const loadSessions = (type) => {

        EventsServices.report.getMeeting(eventID, {
            code: event.eventCode

        }).then((res) => {
            if (res) {
                setReport(res)
            }
        })
    }
    const loadDetail = () => {

        EventsServices.report.getMeetingDetail(eventID, {
            code: event.eventCode
        }).then((res) => {
            if (res) {

                switch (selected) {
                    case t("chart.pending"):
                        setMeet(filter(res, { status: MEET_STATUS.WAITING }))
                        break
                    case t("chart.confirmed"):
                        setMeet(filter(res, { status: MEET_STATUS.ACCEPT }))
                        break
                    case t("chart.declined"):
                        setMeet(filter(res, { status: MEET_STATUS.REJECT }))
                        break
                }
            }
        })
    }


    useEffect(() => {
        if (event) {
            loadSessions()
        }
    }, [event])

    useEffect(() => {
        if (selected) {
            loadDetail()
        }

    }, [selected])


    const detail = useMemo(() => {
        let _data = _.cloneDeep(report);
        let _total = _data?.sent?.confirmed + _data?.sent?.declined + _data?.sent?.pending;
        let _sumSend = _.sum(_.values(_data?.sent))
        let _sent = _sumSend ? (((_data?.sent?.confirmed) / _sumSend) * 100).toFixed(2) : 0
        let _sumReceive = _.sum(_.values(_data?.received))
        let _received = _sumReceive ? (((_data?.received?.confirmed) / _sumReceive) * 100).toFixed(2) : 0
        let _output = { sent: _.values(_data?.sent), received: _.values(_data?.received), total: _total, percentSent: _sent, percentReceived: _received }
        return _output
    }, [report])
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: [t("chart.pending"), t("chart.confirmed"), t("chart.declined")],
            datasets: [
                {
                    data: detail?.received,
                    backgroundColor: [
                        documentStyle.getPropertyValue('--blue-500'),
                        documentStyle.getPropertyValue('--green-500'),
                        documentStyle.getPropertyValue('--yellow-500')
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--blue-400'),
                        documentStyle.getPropertyValue('--green-400'),
                        documentStyle.getPropertyValue('--yellow-400')
                    ]
                }
            ]
        };
        const data2 = {
            labels: [t("chart.pending"), t("chart.confirmed"), t("chart.declined")],
            datasets: [
                {
                    data: detail?.sent,
                    backgroundColor: [
                        documentStyle.getPropertyValue('--blue-500'),
                        documentStyle.getPropertyValue('--green-500'),
                        documentStyle.getPropertyValue('--yellow-500')
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--blue-400'),
                        documentStyle.getPropertyValue('--green-400'),
                        documentStyle.getPropertyValue('--yellow-400')
                    ]
                }
            ]
        };
        const options = {
            cutout: '15%',
            onClick: (event, elements, chart) => {

                if (elements[0]) {
                    const i = elements[0].index;
                    setSelected(chart.data.labels[i])
                    // alert(chart.data.labels[i] + ': ' + chart.data.datasets[0].data[i]);
                }
            }
        };

        setChartData(data);
        setChartData2(data2);
        setChartOptions(options);
    }, [detail]);

    const onBack = () => {
        if (selected) {
            setSelected()
        }
    }

    const startContent = (
        <React.Fragment>
            {(selected) && <Button
                icon="pi pi-chevron-left"
                text
                size="small"
                onClick={onBack}
            />}
            <span className="ml-3">{selected}</span>
        </React.Fragment>
    );

    const endContent = (
        <>
            {/* <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText placeholder={t("company.search")} value={lazyParams?.keyword} onChange={onChangeKeyword} />
                </span>
            </div> */}
        </>
    )
    return (

        <>
            <div className="col-12">
                <Card className="border-round-2xl shadow-2">
                    <div className="flex justify-content-center">
                        <div className="text-center mt-2 mb-4">
                            <div className="text-5xl font-bold text-900">{t("chart.meeting")}</div>
                            <div className="text-lg font-medium">{t("chart.understand_what_meeting")}</div>

                        </div>
                    </div>

                    <div className="grid">
                        {(selected) && <div className="col-12">
                            <Toolbar start={startContent} end={endContent} />
                        </div>
                        }
                        <div className="col-12">


                            {selected
                                ? <DataTable
                                    value={meet}
                                    dataKey="id"
                                    className="p-datatable-gridlines border-all"
                                    resizableColumns
                                    columnResizeMode="expand"
                                    emptyMessage={t("common.no_data")}
                                    // scrollable
                                    // scrollDirection="both"
                                    // scrollHeight="flex"
                                    // lazy
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    paginator
                                >
                                    <Column
                                        className="w-6rem"
                                        body={(index, value) => value?.rowIndex + 1}
                                        header={t("common.no")}
                                    />
                                    <Column
                                        header={t("business_matching.from_user")}
                                        style={{ minWidth: 250 }}
                                        field="senderName"
                                    />
                                    <Column
                                        header={t("business_matching.from_company")}
                                        style={{ minWidth: 250 }}
                                        field="senderCompany"
                                    />
                                    <Column
                                        header={t("business_matching.to_user")}
                                        style={{ minWidth: 250 }}
                                        field="receiverName"
                                    />
                                    <Column
                                        header={t("business_matching.to_company")}
                                        style={{ minWidth: 250 }}
                                        field="receiverCompany"
                                    />
                                    <Column
                                        header={t("common.status")}
                                        style={{ minWidth: 150 }}
                                        className="text-center"
                                        body={(d, value) => {
                                            let status = find(MEET_STATUS.list, { id: d.status })

                                            return <Tag
                                                severity={d.status == MEET_STATUS.ACCEPT
                                                    ? 'success'
                                                    : d.status == MEET_STATUS.NONE
                                                        ? 'info'
                                                        : d.status == MEET_STATUS.WAITING
                                                            ? 'warning'
                                                            : 'danger'}
                                            >
                                                {t(status?.name)}
                                            </Tag>
                                        }
                                        }
                                    />
                                    <Column
                                        header={t("business_matching.power_by_organizer")}
                                        style={{ minWidth: 100 }}
                                        className="text-center"
                                        body={(d, value) => <div>
                                            {d.businessMatching && <i className="pi pi-check"/>}
                                        </div>}
                                    />

                                </DataTable>
                                : <div className=" flex justify-content-center">
                                    <Chart type="doughnut" data={chartData} options={chartOptions} className="w-full md:w-30rem" />

                                </div>
                            }

                        </div>
                        {/* <div className="col-12 lg:col-6">
                            <div className="text-center mt-2 mb-4">
                                <div className="text-3xl font-bold text-900">{t("chart.request_sent")}</div>
                            </div>
                            <div className=" flex justify-content-center">
                                <Chart type="doughnut" data={chartData2} options={chartOptions} className="w-full md:w-30rem" />
                            </div>
                        </div> */}
                    </div>



                </Card>
            </div>
            <div className="col-12 lg:col-4">
                <Card className="border-round-2xl shadow-2 h-full">
                    <div className="text-center text-900 ">
                        <div className="font-bold text-5xl ">{detail?.total}</div>
                        <p className="font-bold text-lg m-2">{t("chart.conversations")}</p>
                        <p className="text-lg ">{t("chart.meeting_detail")}
                        </p>
                    </div>

                </Card>
            </div>
            <div className="col-12 lg:col-4">
                <Card className="border-round-2xl shadow-2 h-full">
                    <div className="text-center text-900 ">
                        <div className="font-bold text-5xl ">{detail?.percentReceived}%</div>
                        <p className="font-bold text-lg m-2">{t("chart.rcr_received")}</p>
                        <p className="text-lg">{t("chart.request_to")}
                        </p>
                    </div>

                </Card>
            </div>
            <div className="col-12 lg:col-4">
                <Card className="border-round-2xl shadow-2 h-full">
                    <div className="text-center text-900 ">
                        <div className="font-bold text-5xl ">{detail?.percentSent}%</div>
                        <p className="font-bold text-lg m-2">{t("chart.rcr_sent")}</p>
                        <p className="text-lg">{t("chart.request_to_others")}</p>
                    </div>
                </Card>
            </div>



        </>

    )
}
Conversations = forwardRef(Conversations)
export default Conversations;

