import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import './styles.scss'
import _ from "lodash";
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { Dialog, DialogProps } from 'primereact/dialog';

function EDialog(props, ref) {
    const {
        onShow,
        title,
        // defaultWidth,
        btnFooter,
        contentHeader,
        closable,
        hidebtnFooter,
        className,
        style,
        maximized
    } = props;

    const [showDetail, setShowDetail] = useState(false);

    const [loading, setLoading] = useState(false);

    useImperativeHandle(ref, () => ({
        create: () => {
            setShowDetail(true);
        },
        edit: async () => {
            setShowDetail(true);
        },
        close: () => {
            setShowDetail(false);
        },
        setLoading: (flg) => {
            setLoading(flg);
        },
    }));

    const cancel = () => {
        setShowDetail(false);
    };

    const footer = () => {
        return (
            <>
                {btnFooter?.map((item, index) => {
                    return (
                        <Button
                            key={index}
                            {...item}
                        />
                    );
                })}
            </>
        );
    };
    const header = () => {
        return (
            <div className='text-left'>
                {title}
                {contentHeader ? contentHeader : ""}
            </div>
        );
    };
    return <>
        <Dialog
            header={header()}
            visible={showDetail}
            modal
            closable={closable}
            // contentClassName="over"
            className={`event-p-dialog ${className}`}
            footer={hidebtnFooter ? null : footer()}
            contentClassName='border-1 border-200 border-x-none p-3 solid'
            headerClassName='text-center font-bold event-color-neutral-400 py-3 px-4'
            onHide={cancel}
            style={{
                width: '70vw',
                ...style
            }}
            onShow={onShow}
            maximized={maximized}
        >
            {props.children}
        </Dialog>
    </>
    // <Dialog
    //     {...props}
    //     className={`event-p-dialog ${className}`}
    //     contentClassName='border-1 event-border-light-300 border-x-none p-4 solid'
    //     headerClassName='text-center font-bold event-color-neutral-400 py-3 px-4'
    // />
}
EDialog = forwardRef(EDialog);
export default EDialog