import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import "./styles.scss"
import { classNames } from "primereact/utils";
import EDialog from "../../../components/EDialog";
import { useDispatch, useSelector } from "react-redux";
import { Calendar } from 'primereact/calendar';
import EventsServices from "../../../core/services/EventsServices";
import { FileUpload } from "primereact/fileupload";
import { uploadToS3, urlToFile } from "../../../utils";
import S3Services from "../../../core/services/S3Services";
import S3FileUpload from "../../../components/S3FileUpload";
import { DATA_TYPE, EVENT_SETTING, EVENT_SETTINGS, FILE_TYPE, UPLOAD_STATUS, USER_PERMISSION } from "../../../utils/constants";
import { BASE_S3_URL } from "../../../config";
import moment from "moment";
import { Editor } from 'primereact/editor';
import { TabPanel, TabView } from "primereact/tabview";
import UserServices from "../../../core/services/UserServices";
import { Dropdown } from "primereact/dropdown";
import CKEditorDocumentEditor from "../../../components/CKEditorDocumentEditor";
import { cloneDeep, find } from 'lodash'
import { ColorPicker } from "primereact/colorpicker";
import { Checkbox } from "primereact/checkbox";
import EColorPicker from "../../../components/EColorPicker";
import { ECalendar } from "../../../components/ECalendar";

function EventDetail(props, ref) {
    const languages = useSelector((state) => state.userReducer.languages)

    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    const { afterSubmit, permission } = props
    const user = cloneDeep(useSelector((state) => state.userReducer.userInfo))
    const s3FileUploadRef = useRef()
    const s3FileUploadRefLogo = useRef()
    const [users, setUsers] = useState([])

    const defaultData = {
        eventCode: "",
        startDate: "",
        endDate: "",
        video: "",
        maps: "",
        location: "",
        image: null,
        imageRouter: "/",
        logo: "",
        userId: null,
        attributes: [],
        verifyRegistration: 1,
        setting: EVENT_SETTINGS.map(o => ({
            ...o,
            name: t(o.name)
        }))
    }
    const defaultArrAttributes = {
        languageCode: "",
        name: "",
        description: null,
        isNew: true
    }
    const defaultError = {
        eventCode: "",
        startDate: "",
        endDate: "",
        video: "",
        maps: "",
        location: "",
        userId: "",
        image: null,
        imageRouter: null,
        color: null
    }
    const [filter, setFilter] = useState({})
    const [show, setShow] = useState(false)
    const [data, setData] = useState(defaultData)
    const [error, setError] = useState(defaultError)
    const [action, setAction] = useState()
    const [activeIndex, setActiveIndex] = useState(0);
    const refDialog = useRef(null)

    useEffect(() => {
        loadUser()
    }, [])

    const loadUser = () => {
        UserServices.get({
            first: 0,
            page: 0,
            size: 9999,
            status: 1
        }).then(res => {
            if (res) {
                setUsers(res.data)
            }
        })
    }

    useImperativeHandle(ref, () => ({
        create: () => {
            let _data = cloneDeep(defaultData)
            languages?.map((item) => {
                _data.attributes.push({
                    languageCode: item.code,
                    languagesname: item.name,
                    name: "",
                    description: "",
                    isNew: true
                })
            })
            setData(_data)
            refDialog.current.create();
            setAction("create")
        },
        edit: (id) => {
            setAction("edit");
            EventsServices.getById(id).then((res) => {
                if (res) {
                    res.attributes.map((item) => {
                        item.languagesname = languages?.find((i) => i.code === item.languageCode)?.name
                    })
                    let _data = {
                        ...res,
                        startDate: new Date(res.startDate),
                        endDate: new Date(res.endDate),
                        logo: res?.logo ? {
                            name: res?.logo,
                            status: UPLOAD_STATUS.UPLOADED
                        } : null,
                        image: res?.image ? {
                            name: res?.image,
                            status: UPLOAD_STATUS.UPLOADED
                        } : null,
                        attributes: []
                    }
                    languages?.map((item) => {
                        let lan = res.attributes.find(o => item.code == o.languageCode)
                        _data.attributes.push({
                            languageCode: item?.code,
                            languagesname: item?.name,
                            name: lan?.name,
                            description: lan?.description
                        })
                    })
                    let _settingJson = {}

                    try {
                        _settingJson = _data.setting ? JSON.parse(_data.setting) : {}
                    } catch (e) { }

                    _data.setting = EVENT_SETTINGS.map(o => ({
                        ...o,
                        name: t(o.name),
                        value: _settingJson[o.id] ?? o.value
                    }))
                    setData(_data)
                    refDialog.current.create();
                }
            })
        },
        view: (id) => {
            setAction("view")
            EventsServices.getById(id).then((res) => {
                if (res) {
                    res.attributes.map((item) => {
                        item.languagesname = languages?.find((i) => i.code === item.languageCode)?.name
                    })
                    let _settingJson = {}

                    try {
                        _settingJson = res.setting ? JSON.parse(res.setting) : {}
                    } catch (e) { }
                    res.setting = EVENT_SETTINGS.map(o => ({
                        ...o,
                        name: t(o.name),
                        value: _settingJson[o.id] ?? o.value
                    }))

                    setData({
                        ...res,
                        startDate: new Date(res.startDate),
                        endDate: new Date(res.endDate),
                        image: res?.image ? {
                            name: res?.image,
                            status: UPLOAD_STATUS.UPLOADED
                        } : null
                    })
                    refDialog.current.create();
                }
            })
        }
    }));

    const onShow = () => {
        if (data?.image) {
            s3FileUploadRef.current.setFiles([data.image.name], FILE_TYPE.IMAGE)
        }
        if (data?.logo) {
            s3FileUploadRef.current.setFiles([data.logo.name], FILE_TYPE.IMAGE)
        }
    }

    const onChangeData = (prop, value, propArr, index) => {
        let _data = cloneDeep(data)
        switch (prop) {
            case "attributes":
                _data.attributes[index][propArr] = value;
                break;
            case "setting":
                _data[prop][index][propArr] = value;
                break;
            default:
                _data[prop] = value
                break;
        }
        validateData([prop], _data, propArr, index)
        setData(_data)
    }

    const validateData = (prop, _data, propArr, index) => {
        let _error = cloneDeep(error)
        let requiredFields = prop.length > 0 ? prop : Object.keys(_error);
        for (const field of requiredFields) {
            switch (field) {
                case "userId":
                case "eventCode":
                    _error[field] = ""
                    if (!_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                case "location":
                    _error[field] = ""
                    if (!_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                case "startDate":
                    _error[field] = ""
                    if (!_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                case "endDate":
                    _error[field] = ""
                    if (!_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                case "video":
                    _error[field] = ""
                    if (!_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                case "logo":
                case "image":
                    _error[field] = ""
                    if (!_data[field] || _data[field].status != UPLOAD_STATUS.UPLOADED) {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                case "imageRouter":
                    _error[field] = ""
                    if (!_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                default:
                    break;
            }
        }
        setError(_error)
        let count = 0;
        for (const key in _error) {
            if (_error[key] && key !== "attributes") {
                count++;
            }
        }
        return count;
    }


    const onSubmit = () => {
        let validate = validateData([], data)
        console.log(moment.utc(data.startDate).format("HH:mm DD/MM/YYYY"))
        if (validate) return
        let setting = {}
        data.setting.map(o => {
            setting[o.id] = o.value
        })
        switch (action) {
            case "create":
                setLoading(true)
                EventsServices.create({
                    ...data,
                    image: data?.image?.name,
                    logo: data?.logo?.name,
                    startDate: data.startDate ? moment.utc(data.startDate).format("HH:mm DD/MM/YYYY") : null,
                    endDate: data.endDate ? moment.utc(data.endDate).format("HH:mm DD/MM/YYYY") : null,
                    setting: JSON.stringify(setting)
                }).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                    setLoading(false)
                })
                break;
            case "edit":
                setLoading(true)
                EventsServices.update({
                    ...data,
                    image: data?.image?.name,
                    logo: data?.logo?.name,
                    startDate: data.startDate ? moment.utc(data.startDate).format("HH:mm DD/MM/YYYY") : null,
                    endDate: data.endDate ? moment.utc(data.endDate).format("HH:mm DD/MM/YYYY") : null,
                    setting: JSON.stringify(setting)
                }).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                    setLoading(false)
                })
                break;
            default:
                break;
        }
    }

    const renderError = (field) => {
        if (error[field]) {
            return <span className="text-red-500 text-xs">{error[field]}</span>
        }
    }

    const cancel = () => {
        refDialog.current.close()
        setData(defaultData)
        setError(defaultError)
        setActiveIndex(0)
    }

    const onUploadedImage = useCallback((val) => {
        onChangeData("image", val)
    }, [data])

    const onUploadedLogo = useCallback((val) => {
        onChangeData("logo", val)
    }, [data])

    const renderSetting = (item, index) => {
        return <div class={`field ${item.dataType == DATA_TYPE.COLOR ? 'col-12' : 'col-3'}`} key={item.id}>
            <label>{item.name}</label>
            <div>
                {item.dataType == DATA_TYPE.BOOLEAN
                    ? <Checkbox
                        onChange={e => onChangeData("setting", e.checked, "value", index)}
                        checked={!!item.value}
                        disabled={permission != USER_PERMISSION.ADMIN}
                    />
                    : item.dataType == DATA_TYPE.COLOR
                        ? <EColorPicker
                            value={item.value}
                            onChange={(e) => onChangeData("setting", `#${e.value}`, "value", index)}
                            disabled={permission != USER_PERMISSION.ADMIN}
                            applyColor={(val) => onChangeData("setting", `#${val}`, "value", index)}
                        />
                        : null
                }

            </div>

        </div>
    }

    const openRegister = useMemo(() => {
        return find(data.setting, { id: EVENT_SETTING.REGISTER })?.value
    }, [data.setting])

    return (
        <EDialog
            ref={refDialog}
            style={{ width: '90vw' }}
            title={t("event.event_information")}
            closable={false}
            hidebtnFooter={false}
            onShow={onShow}
            btnFooter={
                action === "view" ?
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        }
                    ] :
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        },
                        {
                            label: t("common.save"),
                            onClick: () => onSubmit(),
                            loading: loading
                        }
                    ]
            }
        >
            <div className="container">
                <div className="grid">
                    <div className="col-6 field">
                        <label className="require">{t("event.code")}</label>
                        <InputText
                            disabled={action === "view"}
                            value={data?.eventCode}
                            onChange={(e) => { onChangeData("eventCode", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["eventCode"] })}
                        ></InputText>
                        {renderError("eventCode")}
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("event.org")}</label>
                        <Dropdown
                            filter
                            filterBy="email,firstName,lastName,phone,userName"
                            disabled={action === "view"}
                            options={users}
                            optionLabel="userName"
                            optionValue="id"
                            className="w-full"
                            value={data?.userId}
                            onChange={(e) => { onChangeData("userId", e.target.value) }}
                        />
                        {renderError("userId")}
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("event.location")}</label>
                        <InputText
                            disabled={action === "view"}
                            value={data?.location}
                            onChange={(e) => { onChangeData("location", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["location"] })}
                        ></InputText>
                        {renderError("location")}
                    </div>

                    <div className="col-6 field">
                        <label className="require">{t("event.start_date")}</label>
                        <ECalendar
                            disabled={action === "view"}
                            value={data.startDate}
                            onChange={(value) => onChangeData("startDate", value)}
                            className={classNames({ "w-full": true, "p-invalid": error["startDate"] })}
                            showIcon
                            showDate
                            showTime
                            hourFormat="24"
                        />
                        {renderError("startDate")}
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("event.end_date")}</label>
                        <ECalendar
                            disabled={action === "view"}
                            value={data.endDate}
                            onChange={(value) => onChangeData("endDate", value)}
                            className={classNames({ "w-full": true, "p-invalid": error["endDate"] })}
                            showIcon
                            showDate
                            showTime
                            hourFormat="24"
                        />
                        {renderError("endDate")}
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("event.video")}</label>
                        <InputText
                            disabled={action === "view"}
                            value={data?.video}
                            onChange={(e) => { onChangeData("video", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["video"] })}
                        ></InputText>
                        {renderError("video")}
                    </div>
                    <div className="col-12 field">
                        <label className="require">{t("event.logo")}</label>
                        <S3FileUpload
                            ref={s3FileUploadRefLogo}
                            onChange={onUploadedLogo}
                            onLoading={setLoading}
                            accept="image/*"
                        />
                        {renderError("logo")}
                    </div>
                    <div className="col-12 field">
                        <label className="require">{t("event.image")}</label>
                        <S3FileUpload
                            ref={s3FileUploadRef}
                            onChange={onUploadedImage}
                            onLoading={setLoading}
                            accept="image/*"
                        />
                        {renderError("image")}
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("event.image_route")}</label>
                        <InputText
                            disabled={action === "view"}
                            value={data?.imageRouter}
                            onChange={(e) => { onChangeData("imageRouter", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["imageRouter"] })}
                        ></InputText>
                        {renderError("imageRouter")}
                    </div>
                    <div className="col-12">
                        <b className="mb-2">{t("event.setting")}</b>
                    </div>
                    {data?.setting?.map(renderSetting)}
                    {openRegister
                        ? <>
                            <div className="col-12">
                                <label className="require">{t("event.verify_registration")}</label>
                            </div>
                            <div className="col-12">
                                <Checkbox
                                    onChange={e => onChangeData("verifyRegistration", e.checked ? 1 : 0)}
                                    checked={!!data.verifyRegistration}
                                    disabled={permission != USER_PERMISSION.ADMIN}
                                />
                            </div>
                        </>
                        : null
                    }

                    <div className="col-12 flex flex-column">
                        <b className="mb-2">{t("event.attributes")}</b>
                        <div className="w-full">
                            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                {data?.attributes?.map((item) => {
                                    return (
                                        <TabPanel header={item?.languagesname}>
                                            <div className="grid">
                                                <div className="col-12 field">
                                                    <label>{t("event.name")}</label>
                                                    <InputText
                                                        disabled={action === "view"}
                                                        value={item?.name}
                                                        onChange={(e) => { onChangeData("attributes", e.target.value, "name", activeIndex) }}
                                                        className={classNames({ "w-full": true, "p-invalid": error["name"] })}
                                                    ></InputText>
                                                </div>
                                                <div className="col-12 field pb-4">
                                                    <label>{t("event.description")}</label>
                                                    <CKEditorDocumentEditor
                                                        initialData={item?.description}
                                                        onDataChanged={(e) => {
                                                            setData(prevData => {
                                                                // Tạo một bản sao của prevData
                                                                const newData = { ...prevData };
                                                                // Kiểm tra xem phần tử có index có tồn tại không
                                                                if (newData.attributes && newData.attributes[activeIndex]) {
                                                                    // Nếu tồn tại, cập nhật thuộc tính 'description'
                                                                    newData.attributes[activeIndex] = {
                                                                        ...newData.attributes[activeIndex],  // Kế thừa các thuộc tính khác của phần tử 
                                                                        description: e   // Cập nhật mô tả mới
                                                                    };
                                                                }
                                                                return newData;  // Trả về newData đã được cập nhật
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </TabPanel>
                                    )
                                })}
                            </TabView>
                        </div>
                    </div>


                </div>
            </div>
        </EDialog>
    )
}
EventDetail = forwardRef(EventDetail)
export default EventDetail;
