export const REQUEST_POST_LOGIN = `REQUEST_POST_LOGIN`
export const REQUEST_POST_LOGIN_SUCCESS = `REQUEST_POST_LOGIN_SUCCESS`
export function login(params, callBack) {
    return {
        type: REQUEST_POST_LOGIN,
        params,
        callBack
    };
}


export const REQUEST_POST_LOGOUT = `REQUEST_POST_LOGOUT`
export const REQUEST_POST_LOGOUT_SUCCESS = `REQUEST_POST_LOGOUT_SUCCESS`
export function logout(params, callBack) {
    return {
        type: REQUEST_POST_LOGOUT_SUCCESS,
        params,
        callBack
    };
}

export const REQUEST_POST_EVENT = `REQUEST_POST_EVENT`
export const REQUEST_POST_EVENT_SUCCESS = `REQUEST_POST_EVENT_SUCCESS`
export function postEvent(params, callBack) {
    return {
        type: REQUEST_POST_EVENT,
        params,
        callBack
    };
}

export const REQUEST_GET_EVENT_USER_TYPE = `REQUEST_GET_EVENT_USER_TYPE`
export const REQUEST_GET_EVENT_USER_TYPE_SUCCESS = `REQUEST_GET_EVENT_USER_TYPE_SUCCESS`
export function getEventUserType(params, callBack) {
    return {
        type: REQUEST_GET_EVENT_USER_TYPE,
        params,
        callBack
    };
}

export const REQUEST_POST_EVENT_USER_TYPE = `REQUEST_POST_EVENT_USER_TYPE`
export const REQUEST_POST_EVENT_USER_TYPE_SUCCESS = `REQUEST_POST_EVENT_USER_TYPE_SUCCESS`
export function postEventUserType(params, callBack) {
    return {
        type: REQUEST_POST_EVENT_USER_TYPE,
        params,
        callBack
    };
}

export const REQUEST_PUT_EVENT_USER_TYPE = `REQUEST_PUT_EVENT_USER_TYPE`
export const REQUEST_PUT_EVENT_USER_TYPE_SUCCESS = `REQUEST_PUT_EVENT_USER_TYPE_SUCCESS`
export function putEventUserType(params, callBack) {
    return {
        type: REQUEST_PUT_EVENT_USER_TYPE,
        params,
        callBack
    };
}

export const REQUEST_DELETE_EVENT_USER_TYPE = `REQUEST_DELETE_EVENT_USER_TYPE`
export const REQUEST_DELETE_EVENT_USER_TYPE_SUCCESS = `REQUEST_DELETE_EVENT_USER_TYPE_SUCCESS`
export function deleteEventUserType(params, callBack) {
    return {
        type: REQUEST_DELETE_EVENT_USER_TYPE,
        params,
        callBack
    };
}

export const SET_STATE = `SET_STATE`
export function setState(params) {
    return {
        type: SET_STATE,
        params
    };
}

export const REQUEST_GROUP_PERMISSION = `REQUEST_GROUP_PERMISSION`
export const REQUEST_GROUP_PERMISSION_SUCCESS = `REQUEST_GROUP_PERMISSION_SUCCESS`



