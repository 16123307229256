/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
export class ToastHelper {
    static ref;
    static setRef(ref) {
        this.ref = ref;
    }

    static show(state) {
        if (this.ref) {
            this.ref.show({ life: 3000, className: 'text-left', ...state })
        }
    }
}
