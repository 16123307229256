export const REQUEST_GET_COMPANY = `REQUEST_GET_COMPANY`
export const REQUEST_GET_COMPANY_SUCCESS = `REQUEST_GET_COMPANY_SUCCESS`
export function actionCompanyList(callBack) {
    return {
        type: REQUEST_GET_COMPANY,
        callBack
    };
}
export const REQUEST_POST_COMPANY = `REQUEST_POST_COMPANY`
export const REQUEST_POST_COMPANY_SUCCESS = `REQUEST_POST_COMPANY_SUCCESS`
export function actionCompanyCreate(params, callBack) {
    return {
        type: REQUEST_POST_COMPANY,
        params,
        callBack
    };
}
export const REQUEST_PUT_COMPANY = `REQUEST_PUT_COMPANY`
export const REQUEST_PUT_COMPANY_SUCCESS = `REQUEST_PUT_COMPANY_SUCCESS`
export function actionCompanyEdit(params, callBack) {
    return {
        type: REQUEST_PUT_COMPANY,
        params,
        callBack
    };
}
export const REQUEST_DELETE_COMPANY = `REQUEST_DELETE_COMPANY`
export const REQUEST_DELETE_COMPANY_SUCCESS = `REQUEST_DELETE_COMPANY_SUCCESS`
export function actionCompanyDelete(params, callBack) {
    return {
        type: REQUEST_DELETE_COMPANY,
        params,
        callBack
    };
}
export const REQUEST_GET_COMPANY_ID = `REQUEST_GET_COMPANY_ID`
export const REQUEST_GET_COMPANY_SUCCESS_ID = `REQUEST_GET_COMPANY_SUCCESS_ID`
export function actionCompanyById(params, callBack) {
    return {
        type: REQUEST_GET_COMPANY_ID,
        params,
        callBack
    };
}
