import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
// import "./styles.scss"
import { classNames } from "primereact/utils";
import { useDispatch } from "react-redux";
import EDialog from "../../../components/EDialog";
import { actionCompanyCreate, actionCompanyEdit, actionCompanyById } from "../../../core/actions/ActionCompany";
import CompanyServices from "../../../core/services/CompanyServices";
import S3FileUpload from "../../../components/S3FileUpload";
import { FILE_TYPE, PERMISSIONS, ROUTE_PERMISSION, UPLOAD_STATUS, USER_PERMISSION, USER_PERMISSIONS } from "../../../utils/constants";
import { useSelector } from "react-redux";
import UserServices from "../../../core/services/UserServices";
import CKEditorDocumentEditor from "../../../components/CKEditorDocumentEditor";
import { cloneDeep } from 'lodash'
import { Password } from 'primereact/password';
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { InputTextarea } from "primereact/inputtextarea";
import PermissionServices from "../../../core/services/PermissionServices";

function GroupDetail(props, ref) {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    const { countries = [] } = useSelector((state) => state.userReducer)

    const [nodes, setNodes] = useState(ROUTE_PERMISSION);

    const [options, setOptions] = useState([])

    const [permissions, setPermissions] = useState({});

    const s3FileUploadRef = useRef()

    const s3FileUploadBgRef = useRef()

    const { afterSubmit } = props
    const dispatch = useDispatch();
    const defaultData = {
        name: "",
        description: "",
        jsonAction: "",
    }
    const defaultError = {
        name: null
    }
    const [filter, setFilter] = useState({})
    const [data, setData] = useState(defaultData)
    const [error, setError] = useState(defaultError)
    const [action, setAction] = useState()
    const refDialog = useRef(null)

    const [companies, setCompanies] = useState([])

    useEffect(() => {
        setOptions(PERMISSIONS.map(o => ({
            ...o,
            name: t(o.name)
        })))
    }, [])

    useImperativeHandle(ref, () => ({
        create: () => {
            refDialog.current.create();
            setData(cloneDeep(defaultData))
            setAction("create")
        },
        edit: (_data) => {
            setAction("edit");

            let json = _data ? JSON.parse(_data.actionJson) : {}
            let _per = {}
            Object.keys(json).map(o => {
                _per[o] = json[o].map(x => ({
                    id: x,
                    name: t(`common.${x}`)
                }))
            })
            setData(_data)
            refDialog.current.create();
            setPermissions(_per)
        },
        view: (id) => {
            refDialog.current.create();
            setAction("view")
        }
    }));

    const onShow = () => {
        if (data?.avatar) {
            s3FileUploadRef.current.setFiles([data.avatar.name], FILE_TYPE.IMAGE)
        }
    }

    const onChangeData = (prop, value) => {
        let _data = cloneDeep(data)
        _data[prop] = value
        validateData([prop], _data)
        setData(_data)
    }

    const validateData = (prop, _data) => {
        let _error = cloneDeep(error)
        let requiredFields = prop.length > 0 ? prop : Object.keys(_data);
        let _prop = requiredFields
        for (const field of _prop) {
            switch (field) {
                case "name":

                    _error[field] = ""
                    if (!_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;

                default:
                    break;
            }
        }

        setError(_error)
        let count = 0;
        for (const key in _error) {
            if (_error[key]) {
                count++;
            }
        }
        return count;
    }

    const onSubmit = () => {
        let validate = validateData([], data)
        if (validate) return
        let _per = {}
            Object.keys(permissions).map(o => {
                _per[o] = permissions[o].map(x => x.id)
            })
        switch (action) {
            case "create":
                // console.log(JSON.stringify(_per))
                PermissionServices.create({
                    ...data,
                    jsonAction: JSON.stringify(_per)
                }).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            case "edit":
                PermissionServices.update(data.id, {
                    ...data,
                    jsonAction: JSON.stringify(_per)
                }).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            default:
                break;
        }

    }

    const renderError = (field) => {
        if (error[field]) {
            return <span className="text-red-500 text-xs">{error[field]}</span>
        }
    }

    const cancel = () => {
        refDialog.current.close()
        setData(defaultData)
        setError(defaultError)
    }

    const onUploadedImage = useCallback((val) => {
        onChangeData("avatar", val)
    }, [data])

    const renderCol0 = (row) => {
        return <span>{t(row.name)}</span>
    }

    const onChange = (code) => (e) => {
        let _per = cloneDeep(permissions)
        _per[code] = e.value
        setPermissions(_per)
    }

    const renderCol1 = (row) => {
        return <MultiSelect
            value={permissions[row.code]}
            onChange={onChange(row.code)}
            options={options}
            optionLabel="name"
            display="chip"
            // placeholder="Select Cities"
            // maxSelectedLabels={3}
            className="w-full"
        />
    }

    return (
        <EDialog
            ref={refDialog}
            style={{ width: '70vw' }}
            title={t("group.information")}
            closable={false}
            hidebtnFooter={false}
            onShow={onShow}
            btnFooter={
                action === "view" ?
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        }
                    ] :
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        },
                        {
                            label: t("common.save"),
                            onClick: () => onSubmit(),
                            loading
                        }
                    ]
            }
        >
            <div className="container e-group-detail">
                <div className="grid">
                    <div className="col-12 field">
                        <label className="require">{t("common.name")}</label>
                        <InputText
                            disabled={action === "view"}
                            value={data?.name}
                            onChange={(e) => { onChangeData("name", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["name"] })}
                        />
                        {renderError("name")}
                    </div>
                    <div className="col-12 field">
                        <label className="require">{t("common.description")}</label>
                        <InputTextarea
                            disabled={action === "view"}
                            value={data?.description}
                            onChange={(e) => { onChangeData("description", e.target.value) }}
                            className={classNames({ "w-full": true })}
                        />
                    </div>
                    <div className="col-12">
                        <TreeTable value={nodes} tableStyle={{ minWidth: '50rem' }}>
                            <Column
                                field="name"
                                header={t("common.name")}
                                className="py-1"
                                body={renderCol0}
                                expander
                            />
                            <Column
                                field="permission"
                                header={t("group.permission")}
                                className="py-1"
                                body={renderCol1}
                            />
                        </TreeTable>
                    </div>


                </div>
            </div>
        </EDialog>
    )
}
GroupDetail = forwardRef(GroupDetail)
export default GroupDetail;
