import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import EDialog from "../../../../components/EDialog";
import { Button } from "primereact/button";
import { ACTION, USER_COMPANY_PERMISSIONS, USER_TYPE } from "../../../../utils/constants";
import { cloneDeep } from 'lodash'
import EventsServices from "../../../../core/services/EventsServices";
import UserServices from "../../../../core/services/UserServices";
import { MultiSelect } from "primereact/multiselect";
import ChatSercive from "../../../../core/services/ChatService";

function UserCompanyDetail({ afterSubmit, refId, dictionary, isAdmin }, ref) {
    const { t } = useTranslation()
    const defaultData = {
        userId: null,
        type: null
    }
    const defaultError = {
        userId: "",
        type: "",
    }

    const [data, setData] = useState(defaultData)

    const [error, setError] = useState(defaultError)

    const [action, setAction] = useState()

    const [userPermission, setUserPermission] = useState([])

    const refDialog = useRef(null)

    useEffect(() => {
        setUserPermission(USER_COMPANY_PERMISSIONS.map(o => ({
            ...o,
            name: t(o.name)
        })))
    }, [])

    useImperativeHandle(ref, () => ({
        create: () => {
            refDialog.current.create();
            setAction(ACTION.CREATE)
        },
        edit: (item) => {
            refDialog.current.create();
            setAction(ACTION.UPDATE);
            setData(item)
        },
    }));

    const onChangeData = (prop, value) => {
        let _data = cloneDeep(data)
        _data[prop] = value
        validateData([prop], _data)
        setData(_data)
    }

    const validateData = (prop, _data) => {
        let _error = cloneDeep(error)
        let requiredFields = prop.length > 0 ? prop : Object.keys(_data);
        let _prop = requiredFields
        for (const field of _prop) {
            switch (field) {
                case "userId":
                    _error[field] = ""
                    if ((isAdmin && !_data[field]?.length) || (!isAdmin && !_data[field])) {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                case "type":
                    _error[field] = ""
                    if (!isAdmin && !_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                default:
                    break;
            }
        }

        setError(_error)
        let count = 0;
        for (const key in _error) {
            if (_error[key]) {
                count++;
            }
        }
        return count;
    }

    const onSubmit = () => {
        let validate = validateData([], data)
        if (validate) return
        let params = isAdmin ? data.userId.map(o => ({
            type: USER_TYPE.COMPANY,
            isAdmin,
            userId: o.id
        })) : [data]
        switch (action) {
            case ACTION.CREATE:
                UserServices.addUserCompany(refId, params, { code: dictionary?.event?.eventCode }).then((res) => {
                    if (res) {
                        ChatSercive.channel.update(refId, {
                            code: dictionary?.event?.eventCode
                        })
                        afterSubmit();
                        cancel();
                    }
                })
                break;
            case ACTION.UPDATE:

                break;
            default:
                break;
        }

    }

    const renderError = (field) => {
        if (error[field]) {
            return <span className="text-red-500 text-xs">{error[field]}</span>
        }
    }

    const cancel = () => {
        refDialog.current.close()
        setData(defaultData)
        setError(defaultError)
    }

    const renderItemTemplate = (item) => {
        if (item) {
            return <div>
                <div>{item.fullName}</div>
                <div className="text-xs">{item.email}</div>
            </div>
        }
        return <div>&nbsp;</div>
    }

    return (
        <EDialog
            ref={refDialog}
            style={{ width: '70vw' }}
            title={t("event.user")}
            closable={false}
            hidebtnFooter={false}
            btnFooter={
                action === ACTION.VIEW ?
                    [
                        {
                            text: true,
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        }
                    ] :
                    [
                        {
                            text: true,
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        },
                        {
                            label: t("common.save"),
                            onClick: () => onSubmit(),
                        }
                    ]
            }
        >
            <div className="container">
                <div className="grid">
                    <div className={`${isAdmin ? "col-12" : "col-6"} field`}>
                        <label>{t("event.user")}</label>
                        {isAdmin
                            ? <MultiSelect
                                value={data?.userId}
                                onChange={(e) => { onChangeData("userId", e.value) }}
                                options={dictionary?.userEvent || []}
                                showClear
                                filter
                                optionLabel={"userName"}
                                className={classNames({ "w-full": true, "p-invalid": error["userId"] })}
                            />
                            : <Dropdown
                                filter
                                disabled={action !== "create"}
                                options={dictionary?.userEvent || []}
                                optionLabel="fullName"
                                optionValue="id"
                                value={data?.userId}
                                itemTemplate={renderItemTemplate}
                                valueTemplate={renderItemTemplate}
                                onChange={(e) => { onChangeData("userId", e.target.value) }}
                                className={classNames({ "w-full": true, "p-invalid": error["userId"] })}
                            ></Dropdown>
                        }
                        {renderError("userId")}
                    </div>
                    {isAdmin
                        ? null
                        :
                        <div className="col-6 field">
                            <label>{t("common.user_permission.title")}</label>
                            <Dropdown
                                filter
                                disabled={action !== "create"}
                                options={userPermission}
                                optionLabel="name"
                                optionValue="id"
                                value={data?.type}
                                onChange={(e) => { onChangeData("type", e.target.value) }}
                                className={classNames({ "w-full": true, "p-invalid": error["type"] })}
                            ></Dropdown>
                            {renderError("type")}
                        </div>
                    }
                </div>
            </div>
        </EDialog>
    )
}
UserCompanyDetail = forwardRef(UserCompanyDetail)
export default UserCompanyDetail;
