import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { FileUpload } from "primereact/fileupload";
import S3Services from "../../core/services/S3Services";
import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { Image } from "primereact/image";
import { UPLOAD_STATUS } from "../../utils/constants";
import { findIndex, remove } from 'lodash'
import { BASE_S3_URL } from "../../config";

function S3FileUpload({ onChange, onLoading, accept, multiple = false, disabled, clearActive }, ref) {
    const { t } = useTranslation()
    const fileUploadRef = useRef()
    const processRef = useRef({})

    const [loading, setLoading] = useState(false)

    const [success, setSuccess] = useState(false)

    const [progress, setProgress] = useState({})

    const [list, setList] = useState([])

    useImperativeHandle(ref, () => ({
        setFiles: (files, type) => {
            files = files.map(o => ({
                name: o,
                url: `${BASE_S3_URL}${o}`,
                type: type
            }))
            fileUploadRef.current.setFiles(files)
            // setFile(file)
            // setSuccess(true)
        }
    }));

    const onSelect = (e) => {
        setList(e.files)
        // processRef.current = 
        e.files.map(o => {
            if (!processRef.current[o.name]) {
                processRef.current[o.name] = {
                    name: o.name,
                    type: o.type,
                    status: UPLOAD_STATUS.UPLOADING
                }
                upload(o)
            }
        })
    }

    const upload = (file) => {
        onLoading(true)
        S3Services.upload({
            file
        }).then(res => {
            setFileStatus(file, res)
            onLoading(false)
        }).catch(() => {
            setFileStatus(file, null)
            onLoading(false)
        })
    }

    const setFileStatus = (file, _data) => {
        let _files = fileUploadRef.current.getFiles()
        const index = findIndex(_files, { name: file.name })
        if (index != -1 && _data) {
            processRef.current[file.name] = {
                ...processRef.current[file.name],
                name: _data.fileName,
                status: UPLOAD_STATUS.UPLOADED
            }
            let result = []
            Object.keys(processRef.current).map(key => {
                result.push(processRef.current[key])
            })
            onChange(multiple ? result : result.length ? result[0] : null)
        }
    }

    const headerTemplate = (options) => {
        const { className, chooseButton, cancelButton } = options;
        // const value = totalSize / 10000;
        // const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className}>
                <Button
                    {...chooseButton.props}
                    disabled={(!multiple && list.length) || disabled}
                />
                {/* <Button
                    {...cancelButton.props}
                    onClick={(e) => {
                        cancelButton.props.onClick(e)
                        setFile(null)
                        onChange()
                    }}
                /> */}
                {/* {cancelButton} */}
                <div className="flex align-items-center gap-3 ml-auto">
                </div>
            </div>
        );
    };

    const onClear = (_file, onRemove) => () => {
        delete processRef.current[_file.name]
        let result = []
        Object.keys(processRef.current).map(key => {
            result.push(processRef.current[key])
        })
        onChange(multiple ? result : result.length ? result[0] : null)
        let _files = fileUploadRef.current.getFiles()
        remove(_files, { name: _file.name })
        setList(_files)
        onRemove()
    }

    const itemTemplate = (_file, _props) => {
        const { formatSize, onRemove } = _props
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center">
                    {_file.type.indexOf("image/") != -1
                        ? <Image
                            src={_file.url ?? _file.objectURL}
                            className="w-6rem"
                            imageClassName="w-6rem"
                        />
                        : <i className="pi pi-file text-3xl" />
                    }
                    <span className="flex flex-column text-left mx-2">
                        {_file.name}
                    </span>
                </div>
                {/* <Tag value={!progress[_file.name] || progress[_file.name]?.status == UPLOAD_STATUS.UPLOADING ? "..." : progress[_file.name]?.status == UPLOAD_STATUS.UPLOADED ? t("common.success") : t("common.error")} severity={!progress[_file.name] || progress[_file.name]?.status == UPLOAD_STATUS.UPLOADING ? "info" : progress[_file.name]?.status == UPLOAD_STATUS.UPLOADED ? "success" : "danger"} className="px-3 py-2 ml-2" /> */}
                <Button disabled={clearActive ? false : true} type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={onClear(_file, onRemove)} />
            </div>
        );
    };

    return (
        <FileUpload
            className="e-file-upload"
            ref={fileUploadRef}
            headerTemplate={headerTemplate}
            chooseLabel={t("common.choose")}
            // mode="basic"
            // name="demo[]"
            accept={accept}
            maxFileSize={10000000}
            onSelect={onSelect}
            disabled={loading}
            // multiple
            emptyTemplate={<p className="m-0">{t("common.file_drag")}</p>}
            itemTemplate={itemTemplate}
        />
    )
}
S3FileUpload = forwardRef(S3FileUpload)
export default S3FileUpload;
