import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
// import "./styles.scss"
import { classNames } from "primereact/utils";
import { useDispatch } from "react-redux";
import EDialog from "../../../components/EDialog";
import { actionCompanyCreate, actionCompanyEdit, actionCompanyById } from "../../../core/actions/ActionCompany";
import CompanyServices from "../../../core/services/CompanyServices";
import S3FileUpload from "../../../components/S3FileUpload";
import { FILE_TYPE, UPLOAD_STATUS, USER_PERMISSION, USER_PERMISSIONS } from "../../../utils/constants";
import { useSelector } from "react-redux";
import CKEditorDocumentEditor from "../../../components/CKEditorDocumentEditor";
import { cloneDeep } from 'lodash'
import ConfigServices from "../../../core/services/ConfigServices";

function ConfigDetail(props, ref) {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    const { countries = [] } = useSelector((state) => state.userReducer)

    const [permissions] = useState(USER_PERMISSIONS.map(o => ({
        ...o,
        name: t(o.name)
    })))

    const s3FileUploadRef = useRef()

    const s3FileUploadBgRef = useRef()

    const { afterSubmit } = props
    const dispatch = useDispatch();
    const defaultData = {
        key: "",
        value: "",
    }
    const defaultError = {
        key: null
    }
    const [filter, setFilter] = useState({})
    const [data, setData] = useState(defaultData)
    const [error, setError] = useState(defaultError)
    const [action, setAction] = useState()
    const refDialog = useRef(null)

    useImperativeHandle(ref, () => ({
        create: () => {
            refDialog.current.create();
            setAction("create")
        },
        edit: (_data) => {
            refDialog.current.create();
            setAction("edit");
            setData({
                ..._data
            })
        },
        view: (id) => {
            refDialog.current.create();
            setAction("view")
        }
    }));

    const onShow = () => {
        
    }

    const onChangeData = (prop, value) => {
        let _data = cloneDeep(data)
        _data[prop] = value
        validateData([prop], _data)
        setData(_data)
    }

    const validateData = (prop, _data) => {
        let _error = cloneDeep(error)
        let requiredFields = prop.length > 0 ? prop : Object.keys(_data);
        let _prop = requiredFields
        for (const field of _prop) {
            switch (field) {
                case "key":

                    _error[field] = ""
                    if (!_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                default:
                    break;
            }
        }

        setError(_error)
        let count = 0;
        for (const key in _error) {
            if (_error[key]) {
                count++;
            }
        }
        return count;
    }

    const onSubmit = () => {
        let validate = validateData([], data)
        if (validate) return
        switch (action) {
            case "create":
                ConfigServices.create({
                    ...data
                }).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            case "edit":
                ConfigServices.update({
                    ...data,
                }).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            default:
                break;
        }

    }

    const renderError = (field) => {
        if (error[field]) {
            return <span className="text-red-500 text-xs">{error[field]}</span>
        }
    }

    const cancel = () => {
        refDialog.current.close()
        setData(defaultData)
        setError(defaultError)
    }

    const onUploadedImage = useCallback((val) => {
        onChangeData("avatar", val)
    }, [data])

    return (
        <EDialog
            ref={refDialog}
            style={{ width: '70vw' }}
            title={t("user.config")}
            closable={false}
            hidebtnFooter={false}
            onShow={onShow}
            btnFooter={
                action === "view" ?
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        }
                    ] :
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        },
                        {
                            label: t("common.save"),
                            onClick: () => onSubmit(),
                            loading
                        }
                    ]
            }
        >
            <div className="container">
                <div className="grid">
                    <div className="col-6 field">
                        <label className="require">{t("config.key")}</label>
                        <InputText
                            disabled={action != "create"}
                            value={data?.key}
                            onChange={(e) => { onChangeData("key", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["key"] })}
                        />
                        {renderError("key")}
                    </div>
                    <div className="col-12 field pb-4">
                        <label>{t("config.value")}</label>
                        <CKEditorDocumentEditor
                            initialData={data?.value}
                            onDataChanged={(e) => {
                                setData(prevData => {
                                    // Tạo một bản sao của prevData
                                    let newData = { ...prevData };
                                    newData = {
                                        ...newData,
                                        value: e
                                    };
                                    return newData;  // Trả về newData đã được cập nhật
                                });
                            }}
                        />
                    </div>

                </div>
            </div>
        </EDialog>
    )
}
ConfigDetail = forwardRef(ConfigDetail)
export default ConfigDetail;
