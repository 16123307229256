import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import EventsServices from "../../../core/services/EventsServices";
import { ToastHelper } from "../../../helper/ToastHelper";
import { confirmDialog } from "primereact/confirmdialog";
import { Link, useParams } from "react-router-dom";
import UserServices from "../../../core/services/UserServices";
import { Badge } from 'primereact/badge';
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import UserDetail from "../../User/UserDetail";
import GroupUserDetail from "./GroupUserDetail";
import { cloneDeep } from "lodash"
import { useSelector } from "react-redux";
import { checkPermission } from "../../../utils";
import { PERMISSION } from "../../../utils/constants";
import PermissionServices from "../../../core/services/PermissionServices";
import { ProgressBar } from "primereact/progressbar";

const screenPermission = "eventInfoScreen_user"

function GroupUser() {
    const { t } = useTranslation();

    const { groupPermission } = useSelector((state) => state.userReducer)

    const { groupId } = useParams()
    const refDetail = useRef();
    const [event, setEvent] = useState()
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        page: 0,
        size: 10,
        total: 0,
        // status: 1
    })
    const [meta, setMeta] = useState(lazyParams)

    const [data, setData] = useState()

    const [loadingList, setLoadingList] = useState(false)

    // useEffect(() => {
    //     if (eventId) {
    //         loadList(lazyParams)
    //     }
    // }, [lazyParams])

    useEffect(() => {
        if (groupId) {
            loadList(groupId)
        }
    }, [])

    const loadList = (id) => {
        setLoadingList(true)
        PermissionServices.getById(id).then((res) => {
            setLoadingList(false)
            if (res) {
                setData(res)
            }
        })
    }

    const createData = () => {
        refDetail.current.edit(data?.users ?? [])
    }

    const editData = (row) => {
        refDetail.current.edit(data?.users ?? [])
    }

    const viewData = (id) => {
        refDetail.current.view(id)
    }

    const addUserEvent = (id) => {
        confirmDialog({
            message: t("event.status_change"),
            header: t("common.confirm"),
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            rejectLabel: t("common.cancel"),
            acceptLabel: t("common.confirm"),
            accept: () => {
                UserServices.activeUserEvent(id, {}, { code: event.eventCode }).then((res) => {
                    if (res) {
                        ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
                        loadList()
                    }
                })
            }
            ,
            reject: () => { }
        });
    }

    const deleteData = (id) => {
        confirmDialog({
            message: t("common.confirm_content"),
            header: t("common.confirm"),
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            rejectLabel: t("common.cancel"),
            acceptLabel: t("common.confirm"),
            accept: () => {
            }
            ,
            reject: () => { }
        });

    }

    const headerTable = () => {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-plus"
                    className="mr-2"
                    label={t("common.add")}
                    outlined
                    size="small"
                    onClick={() => createData()}
                />
            </React.Fragment>
        )
    }

    const renderColEnd = (row) => {
        return <span className="flex">
            <Button icon="pi pi-pencil" rounded text onClick={() => editData(row)}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.UPDATE)}
            />
            {/* <Button icon={`pi pi-${row?.status == "1" ? "lock-open" : "lock"} ${row?.status == "1" ? "text-primary" : "text-red-500"}`} rounded text aria-label="Filter" onClick={() => addUserEvent(row.id, {}, { code: event.eventCode })}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.UPDATE)}
            /> */}
            {/* <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" onClick={() => deleteData(row.id)} /> */}
        </span>
    }

    const startContent = (
        <React.Fragment>
            <Button
                icon="pi pi-plus"
                className="mr-2"
                label={t("common.add")}
                outlined
                size="small"
                onClick={() => createData()}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.CREATE)}
            />
        </React.Fragment>
    );

    const endContent = (
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText placeholder="Search" />
        </span>
    );

    const afterSubmit = () => {
        loadList(groupId)
        ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
    }

    // const onPage = (event) => {
    //     let _filter = cloneDeep(lazyParams)
    //     _filter.size = event.rows;
    //     _filter.page = event.page;
    //     setLazyParams(_filter)
    // };

    if (!checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)) {
        return null
    }

    return (
        <div className="container-fluid">
            <div className="grid">
                <div className="col-12 ">
                    <Toolbar start={startContent} end={endContent} />
                </div>
                <div className="col-12">
                    <div className="e-progress-bar">
                        {loadingList
                            ? <ProgressBar className="e-progress-bar" mode="indeterminate"></ProgressBar>
                            : null
                        }
                    </div>
                    <DataTable
                        value={data?.users ?? []}
                        // header={headerTable}
                        dataKey="id"
                        className="p-datatable-gridlines border-all"
                        resizableColumns
                        columnResizeMode="expand"
                        emptyMessage={t("common.no_data")}
                        scrollable
                        scrollDirection="both"
                        scrollHeight="flex"
                    // lazy
                    // paginator
                    // first={meta?.first || 0}
                    // rows={lazyParams?.size || 20}
                    // totalRecords={meta.total}
                    // rowsPerPageOptions={[10, 20, 25, 50, 100]}
                    // paginatorTemplate="RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink"
                    // currentPageReportTemplate="{first} - {last} of {totalRecords}"
                    // onPage={onPage}
                    // reorderableColumns
                    // reorderableRows
                    >
                        <Column
                            style={{ width: 100 }}
                            field="STT"
                            body={(index, value) => value?.rowIndex + 1}
                            header={t("common.no")}
                        />
                        <Column
                            header={t("common.fullname")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {`${d?.firstName} ${d?.lastName}`}
                            </div>}
                        />
                        <Column
                            header={t("user.user_name")}
                            style={{ minWidth: 250 }}
                            field="userName"
                        />
                        {/* <Column
                            header={t("common.email")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {d?.email}
                            </div>}
                        />
                        <Column
                            header={t("common.phonenumber")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {d?.phoneNumber}
                            </div>}
                        /> */}
                        {/* <Column
                            header={t("event.companyname")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {d?.companyName}
                            </div>}
                        />
                        <Column
                            header={t("common.status")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {d?.status == "1" ? (
                                    <Badge value={t("common.active")} size="small" severity="success"></Badge>
                                ) : (<Badge value={t("common.inactive")} size="small" severity="warning"></Badge>)}
                            </div>}
                        /> */}
                        <Column
                            columnKey="action"
                            alignFrozen="right"
                            frozen
                            header={t("company.action")}
                            className="col-frozen-table-end py-1"
                            style={{ width: 100 }}
                            body={renderColEnd}
                        />
                    </DataTable>
                </div>
            </div>
            <GroupUserDetail ref={refDetail} afterSubmit={afterSubmit} groupId={groupId} />
        </div>
    )
}

export default GroupUser;
