
import { useTranslation } from 'react-i18next';
import './styles.scss'
import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { classNames } from 'primereact/utils';
import { Link, NavLink } from 'react-router-dom';
import { Dropdown, Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { logout, setState } from '../../core/actions/ActionUser';
import { BreadCrumb } from 'primereact/breadcrumb';
import EBreadCrumb from '../EBreadCrumb';
import { Image } from 'primereact/image';
import images from '../../utils/images';

const HeaderApp = ({ isToggle }) => {

  const { t, i18n } = useTranslation()
  const { token, userInfo } = useSelector((state) => state.userReducer)
  const { language } = useSelector(state => state.userReducer)
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout(() => {

    }))
  }

  const onLang = (lan) => () => {
    i18n.changeLanguage(lan)
    dispatch(setState({
      language: lan
    }))
    setTimeout(() => {
      window.location.reload()
    }, 200)

  }

  return (
    // <Navbar collapseOnSelect expand="lg" className={classNames({"bg-body-tertiary e-menu-toggle": true, "active": isToggle})}>
    //   <Container>
    //     <Navbar.Brand href="#home">CMS</Navbar.Brand>
    //     <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    //     <Navbar.Collapse id="responsive-navbar-nav">
    //       <Nav className="me-auto">

    //       </Nav>
    //       <Nav>
    //         <Nav.Link eventKey={2} href="#memes">
    //           Login
    //         </Nav.Link>
    //       </Nav>
    //     </Navbar.Collapse> 
    //   </Container>
    // </Navbar>
    <>
      <Navbar
        className={classNames({ "event-layout-top sticky-top e-header bg-white": true, "e-menu-toggle": token, "active": isToggle })}
        collapseOnSelect expand="lg" bg="light" variant="light"
      >
        <Container fluid>
          <Navbar.Brand className='px-2 flex align-items-center py-0'>
            <Image
              src={images.logo}
              width='43.5'
              className='mr-3'
            />
            {token
              ? <EBreadCrumb />
              : "Expolander CMS"
            }

          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto flex-1 justify-content-center">

            </Nav>
            <Nav className="px-2">
              <Dropdown as={Nav.Item}
                align={{ lg: 'end' }}
                className='e-fake-dropdown'
              >
                <Dropdown.Toggle
                  as={Nav.Link}
                  className='p-0'
                >
                  {/* <div className='badge-container relative flex justify-content-center align-items-center event-bg-light-300 border-circle mr-2' style={{ width: "38px", height: "38px" }}>
                  {language == 'en' ? t("common.lang.en") : t("common.lang.vi")}
                  </div> */}
                  <Button
                    className='overflow-visible event-bg-light-300 e-size-40'
                    rounded text
                    label={language == 'en' ? t("common.lang.en") : t("common.lang.vi")}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className='pt-0 border-0 shadow-3'
                >
                  <Dropdown.Item
                    onClick={onLang('en')}
                  >
                    <div className='flex align-items-center p-2'>

                      <span className='flex-1 font-medium event-color-neutral-400 ml-2'>{t("common.lang.en")}</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={onLang('vi')}
                  >
                    <div className='flex align-items-center p-2'>

                      <span className='flex-1 font-medium event-color-neutral-400 ml-2'>{t("common.lang.vi")}</span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>

              </Dropdown>
              {userInfo
                ? <Dropdown as={Nav.Item}
                  align={{ lg: 'end' }}
                  className='e-fake-dropdown'
                >
                  <Dropdown.Toggle
                    as={Nav.Link}
                    className='p-0'
                  >
                    <Button
                      className='overflow-visible event-bg-light-300 e-size-40'
                      rounded text
                      label={userInfo?.fullName}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className='pt-0 border-0 shadow-3'
                  >
                    <Dropdown.Item
                      onClick={handleLogout}
                    >
                      <div className='flex align-items-center p-2'>

                        <span className='flex-1 font-medium event-color-neutral-400 ml-2'>{t("common.logout")}</span>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>

                </Dropdown>
                : null
              }

            </Nav>
          </Navbar.Collapse>

        </Container>
      </Navbar>
    </>
  );
}

export default HeaderApp;

