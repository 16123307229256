import React, { useMemo, useState } from "react";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";

import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText
} from "@trendmicro/react-sidenav";
import { NavLink } from "react-router-dom";
import { PERMISSION, ROUTE, USER_PERMISSION } from "../../utils/constants";
import './styles.scss'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { checkPermission } from "../../utils";

const SideNavBar = ({ onToggleCallback }) => {

  const { t } = useTranslation()

  const { userInfo, groupPermission } = useSelector((state) => state.userReducer)

  const permission = useMemo(() => {
    return parseInt(userInfo?.permission ?? "-1")
  }, [userInfo])

  const [isVisible, setIsVisible] = useState(true)

  const onToggle = () => {
    onToggleCallback(!isVisible)
    setIsVisible(!isVisible)
  }

  return (
    <SideNav className={"fixed"} expanded={isVisible} onToggle={onToggle}>
      <Toggle
      // onClick={onToggle}
      />
      <SideNav.Nav defaultSelected="home">
        {permission == USER_PERMISSION.ADMIN || checkPermission(groupPermission, "dashboard", PERMISSION.VIEW)
          ? <NavItem navitemClassName="flex" eventKey="home">
            <NavIcon>
              <NavLink className="flex align-items-center justify-content-center w-full h-full" to={"/"}>
                <i className="pi pi-chart-pie" style={{ fontSize: "1.75em" }} />
              </NavLink>
            </NavIcon>
            <NavText navTextClassName="flex-1">
              <NavLink className="block w-full h-full" to={"/"}>{t("route.dashboard")}</NavLink>
            </NavText>
          </NavItem>
          : null
        }
        {permission == USER_PERMISSION.ADMIN || checkPermission(groupPermission, "event", PERMISSION.VIEW)
          ? <NavItem navitemClassName="flex" eventKey={ROUTE.events}>
            <NavIcon>
              <NavLink className="flex align-items-center justify-content-center w-full h-full" to={ROUTE.events}>
                <i className="pi pi-flag" style={{ fontSize: "1.75em" }} />
              </NavLink>
            </NavIcon>
            <NavText navTextClassName="flex-1">
              <NavLink className="block w-full h-full" to={ROUTE.events}>{t("route.events")}</NavLink>
            </NavText>
          </NavItem>
          : null
        }
        {permission == USER_PERMISSION.ADMIN || checkPermission(groupPermission, "company", PERMISSION.VIEW)
          ? <NavItem navitemClassName="flex" eventKey={ROUTE.companies}>
            <NavIcon>
              <NavLink className="flex align-items-center justify-content-center w-full h-full" to={ROUTE.companies}>
                <i className="pi pi-th-large" style={{ fontSize: "1.75em" }} />
              </NavLink>
            </NavIcon>
            <NavText navTextClassName="flex-1">
              <NavLink className="block w-full h-full" to={ROUTE.companies}>{t("route.company")}</NavLink>
            </NavText>
          </NavItem>
          : null
        }
        {permission == USER_PERMISSION.ADMIN || checkPermission(groupPermission, "users", PERMISSION.VIEW)
          ? <NavItem navitemClassName="flex" eventKey={ROUTE.users}>
            <NavIcon>
              <NavLink className="flex align-items-center justify-content-center w-full h-full" to={ROUTE.users}>
                <i className="pi pi-user" style={{ fontSize: "1.75em" }} />
              </NavLink>
            </NavIcon>
            <NavText navTextClassName="flex-1">
              <NavLink className="block w-full h-full" to={ROUTE.users}>{t("route.account")}</NavLink>
            </NavText>
          </NavItem>
          : null
        }
        {permission == USER_PERMISSION.ADMIN || checkPermission(groupPermission, "ranks", PERMISSION.VIEW)
          ? <NavItem navitemClassName="flex" eventKey={ROUTE.ranks}>
            <NavIcon>
              <NavLink className="flex align-items-center justify-content-center w-full h-full" to={ROUTE.ranks}>
                <i className="pi pi-sitemap" style={{ fontSize: "1.75em" }} />
              </NavLink>
            </NavIcon>
            <NavText navTextClassName="flex-1">
              <NavLink className="block w-full h-full" to={ROUTE.ranks}>{t("route.ranks")}</NavLink>
            </NavText>
          </NavItem>
          : null
        }
        {permission == USER_PERMISSION.ADMIN || checkPermission(groupPermission, "company-type", PERMISSION.VIEW)
          ? <NavItem navitemClassName="flex" eventKey={ROUTE.company_type}>
            <NavIcon>
              <NavLink className="flex align-items-center justify-content-center w-full h-full" to={ROUTE.company_type}>
                <i className="pi pi-sitemap" style={{ fontSize: "1.75em" }} />
              </NavLink>
            </NavIcon>
            <NavText navTextClassName="flex-1">
              <NavLink className="block w-full h-full" to={ROUTE.company_type}>{t("route.company_type")}</NavLink>
            </NavText>
          </NavItem>
          : null
        }
        {/* {permission == USER_PERMISSION.ADMIN || checkPermission(groupPermission, "offer-attribute", PERMISSION.VIEW)
          ? <NavItem navitemClassName="flex" eventKey={ROUTE.offerAttributes}>
            <NavIcon>
              <NavLink className="flex align-items-center justify-content-center w-full h-full" to={ROUTE.offerAttributes}>
                <i className="pi pi-sliders-h" style={{ fontSize: "1.75em" }} />
              </NavLink>
            </NavIcon>
            <NavText navTextClassName="flex-1">
              <NavLink className="block w-full h-full" to={ROUTE.offerAttributes}>{t("route.offer_attribute")}</NavLink>
            </NavText>
          </NavItem>
          : null
        } */}
        {/* {permission == USER_PERMISSION.ADMIN || checkPermission(groupPermission, "maps", PERMISSION.VIEW)
          ? <NavItem navitemClassName="flex" eventKey={ROUTE.maps}>
            <NavIcon>
              <NavLink className="flex align-items-center justify-content-center w-full h-full" to={ROUTE.maps}>
                <i className="pi pi-map-marker" style={{ fontSize: "1.75em" }} />
              </NavLink>
            </NavIcon>
            <NavText navTextClassName="flex-1">
              <NavLink className="block w-full h-full" to={ROUTE.maps}>{t("route.maps")}</NavLink>
            </NavText>
          </NavItem>
          : null
        } */}
        {permission == USER_PERMISSION.ADMIN || checkPermission(groupPermission, "faqs", PERMISSION.VIEW)
          ? <NavItem navitemClassName="flex" eventKey={ROUTE.faqs}>
            <NavIcon>
              <NavLink className="flex align-items-center justify-content-center w-full h-full" to={ROUTE.faqs}>
                <i className="pi pi-question-circle" style={{ fontSize: "1.75em" }} />
              </NavLink>
            </NavIcon>
            <NavText navTextClassName="flex-1">
              <NavLink className="block w-full h-full" to={ROUTE.faqs}>{t("route.faqs")}</NavLink>
            </NavText>
          </NavItem>
          : null
        }
        {permission == USER_PERMISSION.ADMIN || checkPermission(groupPermission, "configs", PERMISSION.VIEW)
          ? <NavItem navitemClassName="flex" eventKey={ROUTE.configs}>
            <NavIcon>
              <NavLink className="flex align-items-center justify-content-center w-full h-full" to={ROUTE.configs}>
                <i className="pi pi-cog" style={{ fontSize: "1.75em" }} />
              </NavLink>
            </NavIcon>
            <NavText navTextClassName="flex-1">
              <NavLink className="block w-full h-full" to={ROUTE.configs}>{t("route.config")}</NavLink>
            </NavText>
          </NavItem>
          : null
        }
        {permission == USER_PERMISSION.ADMIN
          ? <NavItem navitemClassName="flex" eventKey={ROUTE.group}>
            <NavIcon>
              <NavLink className="flex align-items-center justify-content-center w-full h-full" to={ROUTE.group}>
                <i className="pi pi-unlock" style={{ fontSize: "1.75em" }} />
              </NavLink>
            </NavIcon>
            <NavText navTextClassName="flex-1">
              <NavLink className="block w-full h-full" to={ROUTE.group}>{t("route.group")}</NavLink>
            </NavText>
          </NavItem>
          : null
        }

      </SideNav.Nav>
    </SideNav>
  );
}

export default SideNavBar;