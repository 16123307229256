import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import EventsServices from "../../../core/services/EventsServices";
import LocationDetail from "./LocationDetail";
import { ToastHelper } from "../../../helper/ToastHelper";
import { confirmDialog } from "primereact/confirmdialog";
import { Link, useParams } from "react-router-dom";
import { EnumEventModule, PERMISSION } from "../../../utils/constants";
import { checkPermission, findKeyword } from "../../../utils";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { ProgressBar } from "primereact/progressbar";

function Locations({ type, screenPermission }) {
    const { t } = useTranslation();

    const { groupPermission } = useSelector((state) => state.userReducer)

    const [lazyParams, setLazyParams] = useState({
        keyword: ""
    })

    const { eventId, refId } = useParams()
    // const { event, dictionary } = props;
    const refDetail = useRef();

    const [data, setData] = useState([])

    const [loadingList, setLoadingList] = useState(false)

    useEffect(() => {
        if (eventId && refId) {
            loadList()
        }
    }, [])



    const createData = () => {
        refDetail.current.create()
    }

    const editData = (id) => {
        refDetail.current.edit(id)
    }

    const viewData = (id) => {
        refDetail.current.view(id)
    }

    const deleteData = (id) => {
        confirmDialog({
            message: t("common.confirm_content"),
            header: t("common.confirm"),
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            rejectLabel: t("common.cancel"),
            acceptLabel: t("common.confirm"),
            accept: () => {
                EventsServices.deleteMapLocation(id, {
                    eventId,
                    refId: refId,
                    type: type
                }).then((res) => {
                    if (res) {
                        ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
                        loadList()
                    }
                })
            }
            ,
            reject: () => { }
        });

    }

    const onChangeKeyword = (e) => {
        let _filter = cloneDeep(lazyParams)
        _filter.keyword = e.target.value
        setLazyParams(_filter)
    }

    const headerTable = () => {
        return (
            <div className="flex justify-content-between">
                <Button
                    icon="pi pi-plus"
                    className="mr-2"
                    label={t("common.add")}
                    outlined
                    size="small"
                    onClick={() => createData()}
                    disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.CREATE)}

                />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText placeholder={t("company.search")} value={lazyParams?.keyword} onChange={onChangeKeyword} />
                </span>
            </div>
        )
    }

    const loadList = () => {
        setLoadingList(true)
        EventsServices.searchMapLocation({
            eventId,
            type: type,
            refId: refId
        }).then((res) => {
            setLoadingList(false)
            if (res) {
                setData(res)
            }
        })
    }

    const renderColEnd = (row) => {
        return <span>
            <Button icon="pi pi-pencil" rounded text aria-label="Filter" onClick={() => editData(row.id)}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.UPDATE)}
            />
            <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" onClick={() => deleteData(row.id)}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.DELETE)}
            />
        </span>
    }

    const previewList = useMemo(() => {
        return findKeyword(data, lazyParams.keyword, ["location"], ["name"])
    }, [lazyParams, data])

    if (!checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)) {
        return null
    }

    return (
        <div className="container-fluid">
            <div className="grid">
                <div className="col-12">
                    <div className="e-progress-bar">
                        {loadingList
                            ? <ProgressBar className="e-progress-bar" mode="indeterminate"></ProgressBar>
                            : null
                        }
                    </div>

                    <DataTable
                        value={previewList}
                        header={headerTable}
                        dataKey="id"
                        className="p-datatable-gridlines border-all"
                        resizableColumns
                        columnResizeMode="expand"
                        emptyMessage={t("common.no_data")}
                        scrollable
                        scrollDirection="both"
                        scrollHeight="flex"
                        lazy
                    >
                        <Column
                            style={{ width: 100 }}
                            field="STT"
                            body={(index, value) => value?.rowIndex + 1}
                            header={t("common.no")}
                        />
                        <Column
                            header={t("company.map")}
                            style={{ minWidth: 250 }}
                            field="location"
                            body={(d, value) => <div>
                                {d.location?.maps.name}
                            </div>}
                        />
                        <Column
                            header={t("company.location")}
                            style={{ minWidth: 250 }}
                            field="location"
                            body={(d, value) => <div>
                                {d.location?.name}
                            </div>}
                        />
                        <Column
                            columnKey="action"
                            alignFrozen="right"
                            frozen
                            header={t("company.action")}
                            className="col-frozen-table-end py-1"
                            style={{ minWidth: 180, width: 180 }}
                            body={renderColEnd}
                        />
                    </DataTable>
                </div>
            </div>
            <LocationDetail
                ref={refDetail}
                eventId={eventId}
                refId={refId}
                type={type}
                afterSubmit={loadList}
            />
        </div>
    )
}

export default Locations;
