import axiosClient from "./axiosClient";

const PermissionServices = {
    search: (payload) => {
        return axiosClient.get(`/permission/group`, {
            params: payload
        });
    },

    getById: (id) => {
        return axiosClient.get(`/permission/group/${id}`);
    },

    create: (payload) => {
        return axiosClient.post(`/permission/group`, payload);
    },

    update: (id, payload) => {
        return axiosClient.put(`/permission/group/${id}`, payload);
    },

    delete: (id) => {
        return axiosClient.delete(`/permission/group/${id}`);
    },

    updateUser: (id, payload) => {
        return axiosClient.put(`/permission/group/${id}/user`, payload);
    },
}

export default PermissionServices