import axiosClient from "./axiosClient";

const MapsServices = {
    get: (eventId) => {
        return axiosClient.get(`event/${eventId}/maps`);
    },

    getById: (id, eventId) => {
        return axiosClient.get(`event/${eventId}/maps/${id}`);
    },

    create: (eventId, payload) => {
        return axiosClient.post(`event/${eventId}/maps`, payload);
    },

    update: (eventId, payload) => {
        return axiosClient.put(`event/${eventId}/maps/${payload.id}`, payload);
    },

    delete: (id, eventId) => {
        return axiosClient.delete(`event/${eventId}/maps/${id}`);
    },

    createLocations: (id, eventId, payload) => {
        return axiosClient.post(`event/${eventId}/maps/${id}/locations`, payload);
    },

    getLocationsByMapsId: (id, eventId) => {
        return axiosClient.get(`event/${eventId}/maps/${id}/locations`);
    },
}

export default MapsServices