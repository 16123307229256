import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SpeedDial } from 'primereact/speeddial';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ListLocationDetail from "./ListLocationDetail";
import MapsServices from "../../core/services/MapsServices";
import EBreadCrumb from "../../components/EBreadCrumb";
import { useSelector } from 'react-redux';
import { PERMISSION, USER_PERMISSION } from "../../utils/constants";
import { checkPermission, findKeyword } from "../../utils";
import { cloneDeep } from 'lodash'
import { useParams } from "react-router";
import { confirmDialog } from "primereact/confirmdialog";
import { ToastHelper } from "../../helper/ToastHelper";
import { ProgressBar } from "primereact/progressbar";

const screenPermission = "maps"

function ListLocation(props) {
    const { t } = useTranslation()

    const { eventId } = useParams()

    const [lazyParams, setLazyParams] = useState({
        keyword: ""
    })

    const { userInfo, groupPermission } = useSelector((state) => state.userReducer)

    const refDetail = useRef()

    const [data, setData] = useState([])

    const [loadingList, setLoadingList] = useState(false)

    useEffect(() => {
        loadList()
    }, [])

    const permission = useMemo(() => {
        return parseInt(userInfo?.permission ?? "-1")
    }, [userInfo])

    const loadList = () => {
        setLoadingList(true)
        MapsServices.get(eventId).then(res => {
            setLoadingList(false)
            if (res) {
                setData(res)
            }
        })
    }

    const createData = () => {
        refDetail.current.create()
    }

    const editData = (id) => () => {
        refDetail.current.edit(id)
    }

    const onDelete = (id) => () => {
        // refDetail.current.view()
        confirmDialog({
            message: t("common.confirm_content"),
            header: t("common.confirm"),
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            rejectLabel: t("common.cancel"),
            acceptLabel: t("common.confirm"),
            accept: () => {
                try {
                    MapsServices.delete(id, eventId).then(res => {
                        if (res) {
                            loadList()
                            ToastHelper.show({ severity: 'success', summary: t("common.success") });
                        }
                    })
                } catch (error) {
                    ToastHelper.show({ severity: 'error', summary: t("common.error") });
                }
            },
            reject: () => { }
        });
    }

    const startContent = (
        <React.Fragment>
            <Button
                icon="pi pi-plus"
                className="mr-2"
                label={t("common.add")}
                outlined
                size="small"
                onClick={() => createData()}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.CREATE)}

            />
        </React.Fragment>
    );

    const onChangeKeyword = (e) => {
        let _filter = cloneDeep(lazyParams)
        _filter.keyword = e.target.value
        setLazyParams(_filter)
    }

    const endContent = (
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText placeholder={t("company.search")} value={lazyParams?.keyword} onChange={onChangeKeyword} />
        </span>
    );

    const renderColEnd = (row) => {
        return <span>
            <Button icon="pi pi-pencil" rounded text aria-label="Filter" onClick={editData(row.id)}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.UPDATE)}
            />
            <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" onClick={onDelete(row.id)}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.DELETE)}
            />
        </span>
    }

    const previewList = useMemo(() => {
        return findKeyword(data, lazyParams.keyword, ["name"])
    }, [lazyParams, data])

    if (!checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)) {
        return null
    }

    return (
        <div className="event-layout">
            <div className="event-layout-center overflow-hidden">
                <div className="p-card">
                    <div className="p-card-body p-0">
                        <div className="grid">
                            <div className="col-12 ">
                                <Toolbar start={startContent} end={endContent} />
                            </div>
                            <div className="col-12">
                                <div className="e-progress-bar">
                                    {loadingList
                                        ? <ProgressBar className="e-progress-bar" mode="indeterminate"></ProgressBar>
                                        : null
                                    }
                                </div>
                                <DataTable
                                    value={previewList}
                                    dataKey="id"
                                    className="p-datatable-gridlines border-all"
                                    resizableColumns
                                    columnResizeMode="expand"
                                    emptyMessage={t("common.no_data")}
                                    scrollable
                                    scrollDirection="both"
                                    scrollHeight="flex"
                                    reorderableColumns
                                    reorderableRows
                                >
                                    <Column
                                        className="w-6rem"
                                        body={(index, value) => value?.rowIndex + 1}
                                        header={t("common.ordinal_number")}
                                    />
                                    <Column
                                        header={t("common.name")}
                                        style={{ minWidth: 250 }}
                                        field="name"
                                    />
                                    <Column
                                        columnKey="action"
                                        alignFrozen="right"
                                        frozen
                                        header={t("common.action")}
                                        style={{ minWidth: 180, width: 180 }}
                                        className="col-frozen-table-end py-1"
                                        body={renderColEnd}
                                    />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <ListLocationDetail ref={refDetail}
                        reload={loadList}
                        eventId={eventId}
                    />
                </div>
            </div>
        </div>
    )
}

export default ListLocation;
