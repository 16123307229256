import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import EventsServices from "../../../core/services/EventsServices";
import { ToastHelper } from "../../../helper/ToastHelper";
import { confirmDialog } from "primereact/confirmdialog";
import { Link, useParams } from "react-router-dom";
import UserServices from "../../../core/services/UserServices";
import { Badge } from 'primereact/badge';
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import UserDetail from "../../User/UserDetail";
import UserEventDetail from "./UserEventDetail";
import _, { cloneDeep } from "lodash"
import { useSelector } from "react-redux";
import { checkPermission, convertExcelData, exportXlsx } from "../../../utils";
import { PERMISSION, USER_TYPES_TAB } from "../../../utils/constants";
import { TabPanel, TabView } from "primereact/tabview";
import { FileUpload } from "primereact/fileupload";
import * as XLSX from 'xlsx';
import ImportServices from "../../../core/services/ImportServices";
import ImportDetailDetail from "./ImportDetailDetail";
import { ProgressSpinner } from "primereact/progressspinner";
import ExportServices from "../../../core/services/ExportServices";
import { ProgressBar } from "primereact/progressbar";
import RanksServices from "../../../core/services/RanksServices";

const screenPermission = "eventInfoScreen_user"

function UserEvent({ userType }) {
    const { t } = useTranslation();

    const { groupPermission, language } = useSelector((state) => state.userReducer)

    const { eventId } = useParams()
    const refDetail = useRef();
    const refImportDetail = useRef();

    const uploadRef = useRef()

    const [loading, setLoading] = useState(false)
    const [loadingExport, setLoadingExport] = useState(false)

    const [loadingList, setLoadingList] = useState(false)

    const [event, setEvent] = useState()
    const [lazyParams, setLazyParams] = useState({
        keyword: "",
        first: 0,
        page: 0,
        size: 10,
        total: 0,
        type: userType
        // status: 1
    })
    const [meta, setMeta] = useState(lazyParams)

    const [data, setData] = useState([])

    useEffect(() => {
        setLazyParams({
            ...lazyParams,
            type: userType
        })
    }, [userType])

    useEffect(() => {

        const delayDebounceFn = setTimeout(() => {
            if (eventId) {
                loadList(lazyParams)
            }
        }, 200)
        return () => clearTimeout(delayDebounceFn)
    }, [lazyParams])

    const loadList = async (_lazyParams) => {
        _lazyParams = cloneDeep(_lazyParams ? _lazyParams : lazyParams)
        _lazyParams.page = _lazyParams.page + 1
        let _event = cloneDeep(event)
        setLoadingList(true)
        if (!_event) {
            _event = await EventsServices.getById(eventId)
            setEvent(_event)
        }
        if (_event) {
            UserServices.searchUserEvent({
                code: _event.eventCode
            }, _lazyParams).then((res) => {
                setLoadingList(false)
                if (res) {
                    setData(res.data)
                    setMeta({
                        ...meta,
                        first: (res.pageNumber - 1) * res.pageSize,
                        total: res.totalRecord
                    })
                }
            })
        } else {
            setLoadingList(false)
        }
    }

    const createData = () => {
        refDetail.current.create()
    }

    const editData = (row) => {
        refDetail.current.edit(row)
    }

    const viewData = (id) => {
        refDetail.current.view(id)
    }

    const addUserEvent = (id) => {
        confirmDialog({
            message: t("event.status_change"),
            header: t("common.confirm"),
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            rejectLabel: t("common.cancel"),
            acceptLabel: t("common.confirm"),
            accept: () => {
                UserServices.activeUserEvent(id, {}, { code: event.eventCode }).then((res) => {
                    if (res) {
                        ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
                        loadList()
                    }
                })
            }
            ,
            reject: () => { }
        });
    }

    const deleteData = (id) => {
        confirmDialog({
            message: t("common.confirm_content"),
            header: t("common.confirm"),
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            rejectLabel: t("common.cancel"),
            acceptLabel: t("common.confirm"),
            accept: () => {
            }
            ,
            reject: () => { }
        });

    }

    const headerTable = () => {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-plus"
                    className="mr-2"
                    label={t("common.add")}
                    outlined
                    size="small"
                    onClick={() => createData()}
                />
            </React.Fragment>
        )
    }

    const renderColEnd = (row) => {
        return <span className="flex">
            <Button icon="pi pi-pencil" rounded text onClick={() => editData(row)}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.UPDATE)}
            />
            <Button icon={`pi pi-${row?.status == "1" ? "lock-open" : "lock"} ${row?.status == "1" ? "text-primary" : "text-red-500"}`} rounded text aria-label="Filter" onClick={() => addUserEvent(row.id, {}, { code: event.eventCode })}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.UPDATE)}
            />
            {/* <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" onClick={() => deleteData(row.id)} /> */}
        </span>
    }

    const onExport = async () => {
        setLoadingExport(true)
        let ranks = await RanksServices.get()
        ranks = ranks.map(o => ({
            ...o,
            name: _.find(o.languages, {languageCode: language})?.name
        }))
        ranks = _.filter(ranks, {isShowWeb: 1})
        ExportServices.get(eventId, {
            'Content-Language': language
        }).then(res => {
            setLoadingExport(false)
            if (res) {
                exportXlsx(res, ranks)
            }
        })
    }

    const onSelectFile = (e) => {
        if (e.files?.length) {
            upload(e.files[0])
        }
    }

    const upload = (f) => {
        console.log(f)
        var name = f.name;
        const reader = new FileReader();
        reader.onload = async (evt) => { // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            let ds = []
            wb.SheetNames.map(wsname => {
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                var XL_row_object = XLSX.utils.sheet_to_row_object_array(ws);
                // var json_object = JSON.stringify(XL_row_object);
                // console.log(json_object);

                // const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
                /* Update state */
                // console.log("Data>>>", data.split("\n"));
                ds.push({
                    name: wsname,
                    data: XL_row_object
                })
            })
            // console.log(ds);
            const maxNo = await EventsServices.getCompanyNo(eventId)
            const params = convertExcelData(ds, eventId, maxNo?.eventUser ?? 0)
            setLoading(true)
            ImportServices.create(eventId, params).then(res => {
                setLoading(false)
                if (res && !res?.failed?.length) {
                    ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
                } else {
                    // ToastHelper.show({ severity: 'error', summary: t("common.error"), detail: res?.failed?.map(o => o.value).join(", ") });
                    refImportDetail.current.create(res?.failed, ds)
                }
                loadList()

            })

        };
        reader.readAsBinaryString(f);
    }

    const onClear = () => {
        uploadRef.current.clear()
    }

    const startContent = (
        <React.Fragment>
            <Button
                icon="pi pi-plus"
                className="mr-2"
                label={t("common.add")}
                outlined
                // size="small"
                onClick={() => createData()}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.CREATE)}
            />
            {/* <Button
            icon="pi pi-plus"
            className="mr-2"
            label={t("common.add")}
            outlined
            size="small"
            onClick={() => createData()}
            disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.CREATE)}
        /> */}
            <FileUpload
                ref={uploadRef}
                mode="basic"
                accept="*"
                chooseLabel={t("common.import")}
                maxFileSize={1000000}
                onSelect={onSelectFile}
                disabled={loading || !checkPermission(groupPermission, screenPermission, PERMISSION.CREATE)}
                className="mr-2"
                customUpload
                uploadHandler={onClear}
            />
            {loading
                ? <ProgressSpinner style={{ width: '40px', height: '40px' }} />
                : null
            }
            <Button
                icon="pi pi-download"
                label={t("common.export")}
                outlined
                // size="small"
                onClick={onExport}
                disabled={loadingExport || !checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)}
                loading={loadingExport}
            />
        </React.Fragment>
    );

    const onChangeKeyword = (e) => {
        let _filter = cloneDeep(lazyParams)
        _filter.keyword = e.target.value
        setLazyParams(_filter)
    }

    const endContent = (
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText placeholder={t("company.search")} value={lazyParams?.keyword} onChange={onChangeKeyword} />
        </span>
    );

    const afterSubmit = () => {
        loadList()
        ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
    }

    const onPage = (event) => {
        let _filter = cloneDeep(lazyParams)
        _filter.size = event.rows;
        _filter.page = event.page;
        setLazyParams(_filter)
    };

    // const previewData = useMemo(() => {
    //     let _type = userTypes[activeIndex]
    //     return filter(data, o => o.rank?.id == rank.id)
    // }, [data, activeIndex])

    // const onChangeTab = (e) => {
    //     setActiveIndex(e.index)
    //     setLazyParams({
    //         ...lazyParams,
    //         type: userTypes[e.index].value
    //     })
    // }

    if (!checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)) {
        return null
    }

    return (
        <div className="container-fluid">
            <div className="grid">

                <div className="col-12 ">
                    <Toolbar start={startContent} end={endContent} />
                </div>
                <div className="col-12">
                    <div className="e-progress-bar">
                        {loadingList
                            ? <ProgressBar className="e-progress-bar" mode="indeterminate"></ProgressBar>
                            : null
                        }
                    </div>
                    <DataTable
                        value={data}
                        // header={headerTable}
                        dataKey="id"
                        className="p-datatable-gridlines border-all"
                        resizableColumns
                        columnResizeMode="expand"
                        emptyMessage={t("common.no_data")}
                        scrollable
                        scrollDirection="both"
                        scrollHeight="flex"
                        lazy
                        paginator
                        first={meta?.first || 0}
                        rows={lazyParams?.size || 20}
                        totalRecords={meta.total}
                        rowsPerPageOptions={[10, 20, 25, 50, 100]}
                        paginatorTemplate="RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink"
                        currentPageReportTemplate="{first} - {last} of {totalRecords}"
                        onPage={onPage}
                        reorderableColumns
                        reorderableRows
                    >
                        <Column
                            style={{ width: 100 }}
                            field="STT"
                            body={(index, value) => value?.rowIndex + 1}
                            header={t("common.no")}
                        />
                        <Column
                            header={t("common.fullname")}
                            style={{ minWidth: 250 }}

                            body={(d, value) => <div>
                                <Link
                                    to={`/event/${eventId}/user/${d.id}`}
                                    className="text-primary underline"
                                >
                                    {d?.fullName}
                                </Link>
                            </div>}
                        />
                        <Column
                            header={t("user.user_name")}
                            style={{ minWidth: 250 }}
                            field="userName"
                        />
                        <Column
                            header={t("common.email")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {d?.email}
                            </div>}
                        />
                        <Column
                            header={t("common.phonenumber")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {d?.phoneNumber}
                            </div>}
                        />
                        <Column
                            header={t("event.companyname")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {d?.companyName}
                            </div>}
                        />
                        <Column
                            header={t("common.status")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {d?.status == "1" ? (
                                    <Badge value={t("common.active")} size="small" severity="success"></Badge>
                                ) : (<Badge value={t("common.inactive")} size="small" severity="warning"></Badge>)}
                            </div>}
                        />
                        <Column
                            columnKey="action"
                            alignFrozen="right"
                            frozen
                            header={t("company.action")}
                            className="col-frozen-table-end py-1"
                            style={{ width: 100 }}
                            body={renderColEnd}
                        />
                    </DataTable>
                </div>
            </div>
            <UserEventDetail ref={refDetail} afterSubmit={afterSubmit} eventId={eventId} event={event} />
            <ImportDetailDetail ref={refImportDetail} />
        </div>
    )
}

export default UserEvent;
