import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import EDialog from "../../../../components/EDialog";
import EventsServices from "../../../../core/services/EventsServices";
import S3FileUpload from "../../../../components/S3FileUpload";
import { FILE_TYPE, PERMALINK_APPLICATION, PERMALINK_APPLICATIONS, PERMALINK_TYPE, PERMALINK_TYPES, UPLOAD_STATUS } from "../../../../utils/constants";
import { Editor } from 'primereact/editor';
import { TabPanel, TabView } from "primereact/tabview";
import { useSelector } from "react-redux";
import CKEditorDocumentEditor from "../../../../components/CKEditorDocumentEditor";
import { InputNumber } from "primereact/inputnumber";

function EventPermalinkDetail(props, ref) {
    const { t } = useTranslation()
    const { afterSubmit, dictionary, seletedData, eventId, refId, type } = props
    const { languages, language } = useSelector((state) => state.userReducer)
    const s3FileUploadRef = useRef()
    const [loading, setLoading] = useState(false)
    const [currentId, setCurrentId] = useState(null)

    const defaultData = {
        "application": PERMALINK_APPLICATION.WEB,
        "type": PERMALINK_TYPE.EXTERNAL,
        "image": "",
        "url": "",
        "sortOrder": null,
        "attributes": []
    }
    const defaultArrAttributes = {
        languageCode: "",
        name: "",
        description: "",
        isNew: true
    }
    const defaultError = {
        application: null,
        "type": "",
        "attributes": []
    }
    const [data, setData] = useState(defaultData)
    const [error, setError] = useState(defaultError)
    const [action, setAction] = useState()
    const [activeIndex, setActiveIndex] = useState(0);
    const refDialog = useRef(null)

    useImperativeHandle(ref, () => ({
        create: () => {
            refDialog.current.create();
            setAction("create")
            let _data = structuredClone(data)
            languages?.map((item) => {
                _data.attributes.push({
                    languageCode: item.code,
                    languagesname: item.name,
                    name: "",
                    description: "",
                    isNew: true
                })
            })
            setData(_data)
        },
        edit: (d) => {
            setAction("edit");
            if (d) {
                refDialog.current.create();

                setCurrentId(d.id)
                setAction("edit");
                d.attributes.map((item) => {
                    item.languagesname = languages?.find((i) => i.code === item.languageCode)?.name
                })
                let _data = {
                    ...d,
                    image: d?.image ? {
                        name: d?.image,
                        status: UPLOAD_STATUS.UPLOADED
                    } : null,
                    attributes: []
                }
                languages?.map((item) => {
                    let lan = d.attributes.find(o => item.code == o.languageCode)
                    _data.attributes.push({
                        languageCode: item?.code,
                        languagesname: item?.name,
                        name: lan?.name,
                        description: lan?.description
                    })
                })
                setData(_data)
                refDialog.current.create();
            }
        },
        view: (d) => {
            setAction("view")
            if (d) {
                setCurrentId(d.id)
                d.attributes.map((item) => {
                    item.languagesname = languages?.find((i) => i.code === item.languageCode)?.name
                })
                let _data = {
                    ...d,
                    image: d?.image ? {
                        name: d?.image,
                        status: UPLOAD_STATUS.UPLOADED
                    } : null,
                    attributes: []
                }
                languages?.map((item) => {
                    let lan = d.attributes.find(o => item.code == o.languageCode)
                    _data.attributes.push({
                        languageCode: item?.code,
                        languagesname: item?.name,
                        name: lan?.name,
                        description: lan?.description
                    })
                })
                setData(_data)
                refDialog.current.create();
            }

        }
    }));

    const onChangeData = (prop, value, propArr, index) => {
        let _data = structuredClone(data)
        switch (prop) {
            case "application":
                _data[prop] = value
                if (value != PERMALINK_APPLICATION.MOBILE) {
                    _data["type"] = PERMALINK_TYPE.EXTERNAL 
                }
                break;

            case "attributes":
                _data.attributes[index][propArr] = value;
                break;
            default:
                _data[prop] = value

                break;
        }

        validateData([prop], _data, propArr, index)
        setData(_data)
    }

    const validateData = (prop, _data, propArr, index) => {
        let _error = structuredClone(error)
        let requiredFields = prop.length > 0 ? prop : Object.keys(_data);
        let _prop = requiredFields
        for (const field of _prop) {
            switch (field) {
                case "application":
                case "type":
                    _error[field] = ""
                    if (!_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;

                default:
                    break;
            }
        }

        setError(_error)
        let count = 0;
        for (const key in _error) {
            if (_error[key] && key !== "attributes") {
                count++;
            }
        }
        return count;
    }

    const onSubmit = () => {
        let validate = validateData([], data)
        if (validate) return
        switch (action) {
            case "create":
                EventsServices.permalink.post({
                    ...data,
                    eventId: eventId,
                    refType: type,
                    refId: refId,
                    image: data?.image?.name,
                }).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            case "edit":
                EventsServices.permalink.put(currentId, {
                    ...data,
                    eventId: eventId,
                    refType: type,
                    refId: refId,
                    image: data?.image?.name,
                }).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            default:
                break;
        }

    }

    const renderError = (field) => {
        if (error[field]) {
            return <span className="text-red-500 text-xs">{error[field]}</span>
        }
    }

    const cancel = () => {
        refDialog.current.close()
        setData(defaultData)
        setError(defaultError)
        setActiveIndex(0)
    }
    const onUploadedImage = useCallback((val) => {
        onChangeData("image", val)
    }, [data])


    const onShow = () => {
        if (data?.image) {
            s3FileUploadRef.current.setFiles([data.image.name], FILE_TYPE.IMAGE)
        }
        // if (data?.background) {
        //     s3FileUploadBgRef.current.setFiles([data.background.name], FILE_TYPE.IMAGE)
        // }
    }

    const applications = useMemo(() => {
        return PERMALINK_APPLICATIONS.map(o => ({
            ...o,
            name: t(o.name)
        }))
    }, [language])

    const permalinkType = useMemo(() => {
        return PERMALINK_TYPES.map(o => ({
            ...o,
            name: t(o.name)
        }))
    }, [language])

    return (
        <EDialog
            ref={refDialog}
            // style={{ width: '90vw' }}
            title={t("event.permalink")}
            closable={false}
            onShow={onShow}
            hidebtnFooter={false}
            btnFooter={
                action === "view" ?
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                        }
                    ] :
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                        },
                        {
                            label: t("common.save"),
                            onClick: () => onSubmit(),
                        }
                    ]
            }
        >
            <div className="container px-4 py-3">
                <div className="grid">
                    <div className="col-6 field">
                        <label className="require">{t("permalink.application")}</label>
                        <Dropdown
                            filter
                            disabled={action === "view"}
                            options={applications}
                            optionLabel="name"
                            optionValue="id"
                            value={data?.application}
                            onChange={(e) => { onChangeData("application", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["application"] })}
                        ></Dropdown>
                        {renderError("application")}
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("common.type")}</label>
                        <Dropdown
                            filter
                            disabled={data?.application != PERMALINK_APPLICATION.MOBILE || action === "view"}
                            options={permalinkType}
                            optionLabel="name"
                            optionValue="id"
                            value={data?.type}
                            onChange={(e) => { onChangeData("type", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["type"] })}
                        ></Dropdown>
                        {renderError("type")}
                    </div>
                    <div className="col-6 field">
                        <label>{t("common.url")}</label>
                        <InputText
                            disabled={action === "view"}
                            className="w-full"
                            onChange={(e) => { onChangeData("url", e.target.value) }}
                            value={data?.url}
                        ></InputText>

                    </div>
                    <div className="col-6 field">
                        <label>{t("common.sortOrder")}</label>
                        <InputText
                            disabled={action === "view"}
                            className="w-full"
                            onChange={(e) => { onChangeData("sortOrder", e.target.value) }}
                            value={data?.sortOrder}
                            type="number"
                        ></InputText>
                    </div>
                    <div className="col-12 field">
                        <label >{t("event.image")}</label>
                        {/* <S3FileUpload
                            ref={s3FileUploadRef}
                            onChange={(val) => { onChangeData("image", val) }}
                            accept="image/*"
                        /> */}
                        <S3FileUpload
                            ref={s3FileUploadRef}
                            onChange={onUploadedImage}
                            accept="image/*"
                            onLoading={setLoading}
                        />
                        {/* {renderError("image")} */}
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 flex flex-column">
                        <b>{t("event.attributes")}</b>
                        <div className="w-full">
                            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                {data?.attributes?.map((item) => {
                                    return (
                                        <TabPanel header={item?.languagesname}>
                                            <div className="grid">
                                                <div className="col-12 field">
                                                    <label>{t("event.name")}</label>
                                                    <InputText
                                                        disabled={action === "view"}
                                                        value={item?.name}
                                                        onChange={(e) => { onChangeData("attributes", e.target.value, "name", activeIndex) }}
                                                        className={classNames({ "w-full": true, "p-invalid": error["name"] })}
                                                    ></InputText>
                                                </div>
                                                <div className="col-12 field pb-4">
                                                    <label>{t("event.description")}</label>
                                                    <CKEditorDocumentEditor
                                                        initialData={item?.description}
                                                        onDataChanged={(e) => {
                                                            setData(prevData => {
                                                                // Tạo một bản sao của prevData
                                                                const newData = { ...prevData };
                                                                // Kiểm tra xem phần tử có index có tồn tại không
                                                                if (newData.attributes && newData.attributes[activeIndex]) {
                                                                    // Nếu tồn tại, cập nhật thuộc tính 'description'
                                                                    newData.attributes[activeIndex] = {
                                                                        ...newData.attributes[activeIndex],  // Kế thừa các thuộc tính khác của phần tử 
                                                                        description: e   // Cập nhật mô tả mới
                                                                    };
                                                                }
                                                                return newData;  // Trả về newData đã được cập nhật
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </TabPanel>
                                    )
                                })}
                            </TabView>
                        </div>
                    </div>
                </div>
            </div>

        </EDialog>
    )
}
EventPermalinkDetail = forwardRef(EventPermalinkDetail)
export default EventPermalinkDetail;
