import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { useDispatch } from "react-redux";
import EDialog from "../../../../components/EDialog";
import CompanyServices from "../../../../core/services/CompanyServices";
import { InputNumber } from 'primereact/inputnumber';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "react-bootstrap";
import { ACTION } from "../../../../utils/constants";
import MapsServices from "../../../../core/services/MapsServices";
import { cloneDeep } from 'lodash'
import EventsServices from "../../../../core/services/EventsServices";
import { MultiSelect } from "primereact/multiselect";

function LocationDetail({ afterSubmit, eventId, type, refId }, ref) {
    const { t } = useTranslation()
    const defaultData = {
        mapsId: null,
        locationIds: null,
        locationId: null
    }
    const defaultError = {
        mapsId: null,
        locationIds: null,
        locationId: null
    }

    const [data, setData] = useState(defaultData)

    const [error, setError] = useState(defaultError)

    const [action, setAction] = useState(ACTION.CREATE)

    const [loading, setLoading] = useState(false)

    const [maps, setMaps] = useState([])

    const [locations, setLocations] = useState([])

    const refDialog = useRef(null)

    useEffect(() => {
        loadMaps()
    }, [])

    const loadMaps = () => {
        EventsServices.searchMap({
            eventId
        }).then(res => {
            if (res) {
                setMaps(res)
            }
        })
    }

    const loadLocations = (id) => {
        if (id) {
            MapsServices.getLocationsByMapsId(id, eventId).then(res => {
                if (res) {
                    setLocations(res)
                } else {
                    setLocations([])
                }
            })
        } else {
            setLocations([])
        }
    }

    useImperativeHandle(ref, () => ({
        create: () => {
            refDialog.current.create();
            setAction(ACTION.CREATE)
        },
        edit: (id) => {
            refDialog.current.create();
            setAction(ACTION.UPDATE);
            EventsServices.getMapLocationById(id, {
                eventId,
                type,
                refId,
            }).then((res) => {
                if (res) {
                    setData({
                        id: res.id,
                        mapsId: res.location.maps.id,
                        locationId: res.location.id
                    })
                    loadLocations(res.location.maps.id)
                }
            })
        },
        view: (id) => {
            refDialog.current.create();
            setAction(ACTION.VIEW)
            // CompanyServices.getById(id).then((res) => {
            //     if (res) {
            //         setData(res)
            //     }
            // })
        }
    }));

    const onChangeData = (prop, value) => {
        let _data = cloneDeep(data)
        switch (prop) {
            case "mapsId":
                _data.locationIds = null
                loadLocations(value)
                break;
        }
        _data[prop] = value
        validateData([prop], _data)
        setData(_data)
    }

    const validateData = (prop, _data) => {
        let _error = cloneDeep(error)
        let requiredFields = prop.length > 0 ? prop : Object.keys(_data);
        let _prop = requiredFields
        for (const field of _prop) {
            switch (field) {
                case "mapsId":
                    _error[field] = ""
                    if (!_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                case "locationId":
                    _error[field] = ""
                    if (action == ACTION.UPDATE && !_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                case "locationIds":
                    _error[field] = ""
                    if (action == ACTION.CREATE && (!_data[field] || !_data[field].length)) {
                        _error[field] = t("common.validate_content")
                    }
                    break;

                default:
                    break;
            }
        }

        setError(_error)
        let count = 0;
        for (const key in _error) {
            if (_error[key]) {
                count++;
            }
        }
        return count;
    }

    const onSubmit = () => {
        let validate = validateData([], data)
        if (validate) return
        switch (action) {
            case ACTION.CREATE:
                setLoading(true)
                EventsServices.createMapLocation({
                    eventId,
                    type,
                    refId,
                    mapsId: data.mapsId,
                    locationIds: data.locationIds.map(o => o.id)
                }).then((res) => {
                    setLoading(false)
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            case ACTION.UPDATE:
                setLoading(true)
                EventsServices.updateMapLocation(data.id, {
                    eventId,
                    type,
                    refId,
                    mapsId: data.mapsId,
                    locationId: data.locationId
                }).then((res) => {
                    setLoading(false)
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            default:
                break;
        }

    }

    const renderError = (field) => {
        if (error[field]) {
            return <span className="text-red-500 text-xs">{error[field]}</span>
        }
    }

    const cancel = () => {
        refDialog.current.close()
        setData(defaultData)
        setError(defaultError)
    }

    return (
        <EDialog
            ref={refDialog}
            style={{ width: '70vw' }}
            title={t("location.maps")}
            closable={false}
            hidebtnFooter={false}
            btnFooter={
                action === ACTION.VIEW ?
                    [
                        {
                            text: true,
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        }
                    ] :
                    [
                        {
                            text: true,
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        },
                        {
                            label: t("common.save"),
                            onClick: () => onSubmit(),
                            loading,
                            disabled: loading
                        }
                    ]
            }
        >
            <div className="container">
                <div className="grid">
                    <div className="col-6 field">
                        <label className="require">{t("company.map")}</label>
                        <Dropdown
                            disabled={action === ACTION.VIEW}
                            options={maps ?? []}
                            optionLabel="name"
                            optionValue="id"
                            value={data?.mapsId}
                            onChange={(e) => { onChangeData("mapsId", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["mapsId"] })}
                            filter
                        />
                        {renderError("mapsId")}
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("company.location")}</label>
                        {action === ACTION.UPDATE
                            ? <Dropdown
                                disabled={action === ACTION.VIEW}
                                options={locations ?? []}
                                optionLabel="name"
                                optionValue="id"
                                value={data?.locationId}
                                onChange={(e) => { onChangeData("locationId", e.target.value) }}
                                className={classNames({ "w-full": true, "p-invalid": error["locationId"] })}
                                filter
                            />
                            : <MultiSelect
                                value={data?.locationIds}
                                onChange={(e) => { onChangeData("locationIds", e.value) }}
                                options={locations ?? []}
                                showClear
                                optionLabel="name"
                                className={classNames({ "w-full": true, "p-invalid": error["locationIds"] })}
                                filter
                            />
                        }

                        {action === ACTION.UPDATE ? renderError("locationId") : renderError("locationIds")}
                    </div>
                </div>
            </div>
        </EDialog>
    )
}
LocationDetail = forwardRef(LocationDetail)
export default LocationDetail;
