import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import "./styles.scss"
import { classNames } from "primereact/utils";
import EDialog from "../../../components/EDialog";
import { useDispatch, useSelector } from "react-redux";
import { Calendar } from 'primereact/calendar';
import EventsServices from "../../../core/services/EventsServices";
import { FileUpload } from "primereact/fileupload";
import { checkPermission, getPermission, uploadToS3, urlToFile } from "../../../utils";
import S3Services from "../../../core/services/S3Services";
import S3FileUpload from "../../../components/S3FileUpload";
import { DATA_TYPE, EVENT_SETTING, EVENT_SETTINGS, FILE_TYPE, PERMISSION, UPLOAD_STATUS } from "../../../utils/constants";
import { BASE_S3_URL } from "../../../config";
import moment from "moment";
import { Editor } from 'primereact/editor';
import { TabPanel, TabView } from "primereact/tabview";
import UserServices from "../../../core/services/UserServices";
import { Dropdown } from "primereact/dropdown";
import CKEditorDocumentEditor from "../../../components/CKEditorDocumentEditor";
import { cloneDeep, find } from 'lodash'
import { ColorPicker } from "primereact/colorpicker";
import { Checkbox } from "primereact/checkbox";
import { Image } from "primereact/image";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import EventDetail from "../EventDetail";
import { ProgressBar } from "primereact/progressbar";

const screenPermission = "eventInfoScreen"

function EventPreview(props, ref) {
    const languages = useSelector((state) => state.userReducer.languages)

    const [loadingList, setLoadingList] = useState(false)

    const { groupPermission, permission } = useSelector((state) => state.userReducer)

    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    const { afterSubmit, eventId } = props
    const user = cloneDeep(useSelector((state) => state.userReducer.userInfo))
    const s3FileUploadRef = useRef()
    const fileUploadRef = useRef()
    const isInitialMount = useRef(true);
    const [users, setUsers] = useState([])

    const [filter, setFilter] = useState({})
    const [show, setShow] = useState(false)
    const [data, setData] = useState()
    const [action, setAction] = useState()
    const [activeIndex, setActiveIndex] = useState(0);
    const refDetail = useRef()

    useEffect(() => {
        loadUser()
        edit(eventId)
    }, [])

    const loadUser = () => {
        UserServices.get({
            first: 0,
            page: 0,
            size: 9999,
            status: 1
        }).then(res => {
            if (res) {
                setUsers(res.data)
            }
        })
    }

    const edit = (id) => {
        id = id ?? eventId
        setLoadingList(true)
        EventsServices.getById(id).then((res) => {
            setLoadingList(false)
            if (res) {
                res.attributes.map((item) => {
                    item.languagesname = languages?.find((i) => i.code === item.languageCode)?.name
                })
                let _data = {
                    ...res,
                    startDate: new Date(res.startDate),
                    endDate: new Date(res.endDate),
                    image: res?.image ? {
                        name: res?.image,
                        status: UPLOAD_STATUS.UPLOADED
                    } : null,
                    attributes: []
                }
                languages?.map((item) => {
                    let lan = res.attributes.find(o => item.code == o.languageCode)
                    _data.attributes.push({
                        languageCode: item?.code,
                        languagesname: item?.name,
                        name: lan?.name,
                        description: lan?.description
                    })
                })
                let _settingJson = {}

                try {
                    _settingJson = _data.setting ? JSON.parse(_data.setting) : {}
                } catch (e) { }

                _data.setting = EVENT_SETTINGS.map(o => ({
                    ...o,
                    name: t(o.name),
                    value: _settingJson[o.id] ?? o.value
                }))
                setData(_data)
            }
        })
    }

    const renderSetting = (item, index) => {
        return <div class="field col-3" key={item.id}>
            <label>{item.name}</label>
            <div>
                {item.dataType == DATA_TYPE.BOOLEAN
                    ? <Checkbox
                        disabled
                        checked={!!item.value}
                    />
                    : item.dataType == DATA_TYPE.COLOR
                        ? <ColorPicker
                            value={item.value}
                            disabled
                        />
                        : null
                }

            </div>

        </div>
    }

    const previewUserId = useMemo(() => {
        return find(users, { id: data?.userId })
    }, [data?.userId, users])



    const editData = () => {
        refDetail.current.edit(eventId)
    }

    const endContent = (
        <React.Fragment>
            <Button
                // icon="pi pi-plus"
                // className="mr-2"
                label={t("common.edit")}
                outlined
                size="small"
                onClick={editData}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.UPDATE)}
            />
        </React.Fragment>
    );

    const openRegister = useMemo(() => {
        return find((data?.setting ?? []), { id: EVENT_SETTING.REGISTER })?.value
    }, [data?.setting])

    if (!checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)) {
        return null
    }

    return (

        <div className="container-fluid">
            <div className="e-progress-bar">
                {loadingList
                    ? <ProgressBar className="e-progress-bar" mode="indeterminate"></ProgressBar>
                    : null
                }
            </div>
            <div className="grid">
                <div className="col-12">
                    <Toolbar end={endContent} />
                </div>
                <div className="col-6 field">
                    <label className="require">{t("event.code")}</label>
                    <div>{data?.eventCode}</div>
                </div>
                <div className="col-6 field">
                    <label className="require">{t("event.org")}</label>
                    <div>{previewUserId?.userName}</div>
                </div>
                <div className="col-6 field">
                    <label className="require">{t("event.location")}</label>
                    <div>{data?.location}</div>
                </div>

                <div className="col-6 field">
                    <label className="require">{t("event.start_date")}</label>
                    <div>{moment(data?.startDate).format("DD/MM/YYYY HH:mm")}</div>
                </div>
                <div className="col-6 field">
                    <label className="require">{t("event.end_date")}</label>
                    <div>{moment(data?.endDate).format("DD/MM/YYYY HH:mm")}</div>
                </div>
                <div className="col-6 field">
                    <label className="require">{t("event.video")}</label>
                    <div>{data?.video}</div>
                </div>
                <div className="col-12 field">
                    <label className="require">{t("event.image")}</label>
                    <Image
                        src={data?.image?.name ? `${BASE_S3_URL}${data?.image?.name}` : null}
                        className="w-full"
                        imageClassName="w-full"
                    />
                </div>
                <div className="col-6 field">
                    <label className="require">{t("event.image_route")}</label>
                    <div>{data?.imageRouter}</div>
                </div>
                <div className="col-12">
                    <b className="mb-2">{t("event.setting")}</b>
                </div>
                {data?.setting?.map(renderSetting)}
                {openRegister
                    ? <>
                        <div className="col-12">
                            <label className="require">{t("event.verify_registration")}</label>
                        </div>
                        <div className="col-12">
                            <Checkbox
                                checked={!!data.verifyRegistration}
                                disabled
                            />
                        </div>
                    </>
                    : null
                }
                <div className="col-12 flex flex-column">
                    <b className="mb-2">{t("event.attributes")}</b>
                    <div className="w-full">
                        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                            {data?.attributes?.map((item) => {
                                return (
                                    <TabPanel header={item?.languagesname}>
                                        <div className="grid">
                                            <div className="col-12 field">
                                                <label>{t("event.name")}</label>
                                                <div>{item?.name}</div>
                                            </div>
                                            <div className="col-12 field pb-4">
                                                <label>{t("event.description")}</label>
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: item?.description }}
                                                />
                                            </div>
                                        </div>
                                    </TabPanel>
                                )
                            })}
                        </TabView>
                    </div>
                </div>


            </div>
            <EventDetail afterSubmit={edit} ref={refDetail} permission={permission}></EventDetail>
        </div>
    )
}
EventPreview = forwardRef(EventPreview)
export default EventPreview;
