import { ColorPicker } from "primereact/colorpicker";
import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";

const EColorPicker = ({ applyColor, ...props }) => {

    const [textVal, setTextVal] = useState()

    useEffect(() => {
        setTextVal(props.value.replace("#", ""))
    }, [props.value])

    const onChange = (e) => {
        props.onChange(e)
        setTextVal(e.value)
    }

    const onChangeText = (e) => {
        setTextVal(e.target.value)
        if (isColor(e.target.value)) {
            applyColor(e.target.value.replace("#", ""))
        }
    }

    const isColor = (strColor) => {
        const s = new Option().style;
        s.color = `#${strColor.replace("#", "")}`;
        return s.color !== '';
      }

    return (
        <div>
            <ColorPicker
                {...props}
                onChange={onChange}
            />
            <InputText className="ml-3 w-6rem" placeholder="FF0000" value={textVal} onChange={onChangeText}></InputText>
        </div>
    )
}
export default EColorPicker;
