import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SpeedDial } from 'primereact/speeddial';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
// import UserDetail from "./UserDetail";
import * as _ from 'lodash';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { ToastHelper } from "../../../helper/ToastHelper";
import { cloneDeep, find } from 'lodash'
import { PERMISSION, USER_PERMISSION, USER_PERMISSIONS, USER_TYPE_NEW } from "../../../utils/constants";
import { useDispatch, useSelector } from 'react-redux';
import FaqsServices from "../../../core/services/FaqsServices";
import EventsUserTypeDetail from "./EventsUserTypeDetail";
import EventsServices from "../../../core/services/EventsServices";
import { useParams } from "react-router";
import { checkPermission } from "../../../utils";
import { ProgressBar } from "primereact/progressbar";
import { getEventUserType } from "../../../core/actions/ActionUser";

const screenPermission = "eventInfoScreen_user_type"

function EventsUserType(props) {
    const { t } = useTranslation()

    const { userInfo, groupPermission, language } = useSelector((state) => state.userReducer)
    const { eventId } = useParams()
    const [lazyParams, setLazyParams] = useState({
        keyword: "",
        first: 0,
        page: 0,
        size: 10,
        total: 0,
        // total: 0,
        // status: 1
    })

    const [meta, setMeta] = useState(lazyParams)

    const toast = useRef()
    const refDetail = useRef()
    const refDetailGuest = useRef()
    const refDetailEmail = useRef()

    const [data, setData] = useState([])
    const [event, setEvent] = useState()
    const [loadingList, setLoadingList] = useState(false)
    const dispatch = useDispatch()

    const [sessions, setSessions] = useState([])

    useEffect(() => {
        loadEvent()
        getList()
    }, [])

    const loadEvent = () => {
        EventsServices.getById(eventId).then(res => {
            if (res) {
                setEvent(res)
            }
        })
    }

    const permission = useMemo(() => {
        return parseInt(userInfo?.permission ?? "-1")
    }, [userInfo])

    const getList = () => {
        setLoadingList(true)
        dispatch(getEventUserType(eventId, (res) => {
            setLoadingList(false)
            if (res) {
                console.log(res)
                setData(res)
            }
        }))
    }

    const createData = () => {
        refDetail.current.create()
    }

    const editData = (_data) => {
        refDetail.current.edit(_data)
    }

    const deleteData = (id) => {
        confirmDialog({
            message: t("company.confirm_content"),
            header: t("company.confirm"),
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            rejectLabel: t("company.cancel"),
            acceptLabel: t("company.confirm"),
            accept: () => {
                EventsServices.userType.delete(id, eventId).then((res) => {
                    if (res) {
                        ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
                        getList()
                    }
                })
            }
            ,
            reject: () => { }
        });

    }
    const afterSubmit = () => {
        getList()
        // ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
    }

    const startContent = (
        <React.Fragment>
            <Button
                icon="pi pi-plus"
                className="mr-2"
                label={t("common.add")}
                outlined
                size="small"
                onClick={createData}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.CREATE)}

            />

        </React.Fragment>
    );

    const onChangeKeyword = (e) => {
        let _filter = cloneDeep(lazyParams)
        _filter.keyword = e.target.value
        setLazyParams(_filter)
    }

    const endContent = (
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText placeholder={t("company.search")} value={lazyParams?.keyword} onChange={onChangeKeyword} />
        </span>
    );

    const renderColEnd = (row) => {
        return <span className="flex">
            <Button icon="pi pi-pencil" rounded text onClick={() => editData(row)}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.UPDATE)}
            />
            <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" onClick={() => deleteData(row.id)}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.DELETE) || row.type == USER_TYPE_NEW.ORG || row.type == USER_TYPE_NEW.GUEST}
            />
        </span>
    }
    const renderCol0 = (d, value) => {
        let _info = find(d.languages, { languageCode: language })
        return (
            <span>
                {_info?.name}
            </span>
        )
    }

    const printData = useMemo(() => {
        return !lazyParams.keyword ? data : _.filter(data, o => _.find(o.languages, x => x.name.indexOf(lazyParams.keyword) != -1))
    }, [data, lazyParams])

    if (!checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)) {
        return null
    }

    return (
        <div className="event-layout">
            <div className="event-layout-center overflow-hidden">

                <div className="grid">
                    <div className="col-12">
                        <Toolbar start={startContent} end={endContent} />
                    </div>
                    <div className="col-12">
                        <div className="e-progress-bar">
                            {loadingList
                                ? <ProgressBar className="e-progress-bar" mode="indeterminate"></ProgressBar>
                                : null
                            }
                        </div>
                        <DataTable
                            value={printData}
                            dataKey="id"
                            className="p-datatable-gridlines border-all"
                            resizableColumns
                            columnResizeMode="expand"
                            emptyMessage={t("company.no_data")}
                            scrollable
                            scrollDirection="both"
                            scrollHeight="flex"
                            lazy

                            reorderableColumns
                            reorderableRows
                        >
                            <Column
                                style={{ width: 100 }}
                                field="STT"
                                body={(index, value) => value?.rowIndex + 1}
                                header={t("common.no")}
                            />
                            <Column
                                header={t("common.name")}
                                // style={{ width: 200 }}
                                className="flex-1"
                                field="type"

                                body={renderCol0}
                            />

                            <Column
                                columnKey="action"
                                alignFrozen="right"
                                frozen
                                header={t("common.action")}
                                style={{ minWidth: 120, width: 120 }}
                                className="col-frozen-table-end py-1"
                                body={renderColEnd}
                            />
                        </DataTable>
                    </div>
                </div>
            </div>
            <EventsUserTypeDetail
                eventId={eventId}
                ref={refDetail}
                afterSubmit={afterSubmit}
            />

        </div>
    )
}

export default EventsUserType;
