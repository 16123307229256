import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import EDialog from "../../../../components/EDialog";
import EventsServices from "../../../../core/services/EventsServices";
import S3FileUpload from "../../../../components/S3FileUpload";
import { FILE_TYPE, UPLOAD_STATUS } from "../../../../utils/constants";
import { Editor } from 'primereact/editor';
import { TabPanel, TabView } from "primereact/tabview";
import { useSelector } from "react-redux";
import CKEditorDocumentEditor from "../../../../components/CKEditorDocumentEditor";
import { cloneDeep } from 'lodash'
import { InputNumber } from "primereact/inputnumber";

function EventProductDetail(props, ref) {
    const { t } = useTranslation()
    const { afterSubmit, dictionary, eventId, sortOrder } = props
    const languages = useSelector((state) => state.userReducer.languages)
    const s3FileUploadRef = useRef()
    const [loading, setLoading] = useState(false)

    const defaultData = {
        eventCompanyId: "",
        image: "",
        video: "",
        tags: "",
        attributes: [],
        sortOrder: 1
    }
    const defaultArrAttributes = {
        languageCode: "",
        name: "",
        description: "",
        isNew: true
    }
    const defaultError = {
        eventCompanyId: "",
        image: "",
        video: "",
        tags: "",
    }
    const [data, setData] = useState(defaultData)
    const [error, setError] = useState(defaultError)
    const [action, setAction] = useState()
    const [activeIndex, setActiveIndex] = useState(0);
    const refDialog = useRef(null)

    useImperativeHandle(ref, () => ({
        create: () => {
            refDialog.current.create();
            setAction("create")
            let _data = cloneDeep(data)
            languages?.map((item) => {
                _data.attributes.push({
                    languageCode: item.code,
                    languagesname: item.name,
                    name: "",
                    description: "",
                    isNew: true
                })
            })
            _data.sortOrder = sortOrder
            setData(_data)
        },
        edit: (id) => {
            setAction("edit");
            EventsServices.getByIdProduct(eventId, id).then((res) => {
                if (res) {
                    res.eventCompanyId = res?.company?.id
                    res.offers = []
                    res.attributes.map((item) => {
                        item.languagesname = languages?.find((i) => i.code === item.languageCode)?.name
                    })
                    res?.productOffered?.map((item) => {
                        res.offers.push(item.key)
                    })
                    let _data = {
                        ...res,
                        image: res?.image ? {
                            name: res?.image,
                            status: UPLOAD_STATUS.UPLOADED
                        } : null,
                        attributes: []
                    }
                    languages?.map((item) => {
                        let lan = res.attributes.find(o => item.code == o.languageCode)
                        _data.attributes.push({
                            languageCode: item?.code,
                            languagesname: item?.name,
                            name: lan?.name,
                            description: lan?.description
                        })
                    })
                    setData(_data)
                    refDialog.current.create();
                }
            })
        },
        view: (id) => {
            setAction("view")
            EventsServices.getByIdProduct(eventId, id).then((res) => {
                if (res) {
                    res.eventCompanyId = res?.company?.id
                    res.offers = []
                    res?.productOffered?.map((item) => {
                        res.offers.push(item.key)
                    })
                    res.attributes.map((item) => {
                        item.languagesname = languages?.find((i) => i.code === item.languageCode)?.name
                    })
                    setData({
                        ...res,
                        image: res?.image ? {
                            name: res?.image,
                            status: UPLOAD_STATUS.UPLOADED
                        } : null
                    })
                    refDialog.current.create();
                }
            })
        }
    }));

    const onShow = () => {
        if (data?.image) {
            s3FileUploadRef.current.setFiles([data.image.name], FILE_TYPE.IMAGE)
        }
    }

    const onChangeData = (prop, value, propArr, index) => {
        let _data = cloneDeep(data)
        switch (prop) {
            case "attributes":
                _data.attributes[index][propArr] = value;
                break;
            default:
                _data[prop] = value
                break;
        }

        validateData([prop], _data, propArr, index)
        setData(_data)
    }

    const validateData = (prop, _data, propArr, index) => {
        let _error = cloneDeep(error)
        let requiredFields = prop.length > 0 ? prop : Object.keys(_data);
        let _prop = requiredFields
        for (const field of _prop) {
            switch (field) {
                case "eventCompanyId":
                case "image":
                    _error[field] = ""
                    if (!_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;

                default:
                    break;
            }
        }

        setError(_error)
        let count = 0;
        for (const key in _error) {
            if (_error[key] && key !== "attributes") {
                count++;
            }
        }
        return count;
    }

    const onSubmit = () => {
        let validate = validateData([], data)
        if (validate) return
        switch (action) {
            case "create":
                EventsServices.createProduct(eventId, {
                    ...data,
                    image: data?.image?.name,
                }).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            case "edit":
                EventsServices.updateProduct(eventId, {
                    ...data,
                    image: data?.image?.name,
                }).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            default:
                break;
        }

    }

    const renderError = (field) => {
        if (error[field]) {
            return <span className="text-red-500 text-xs">{error[field]}</span>
        }
    }

    const cancel = () => {
        refDialog.current.close()
        setData(defaultData)
        setError(defaultError)
        setActiveIndex(0)
    }

    const onUploadedImage = useCallback((val) => {
        onChangeData("image", val)
    }, [data])

    return (
        <EDialog
            ref={refDialog}
            style={{ width: '90vw' }}
            title={t("event.product_information")}
            closable={false}
            hidebtnFooter={false}
            onShow={onShow}
            btnFooter={
                action === "view" ?
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        }
                    ] :
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        },
                        {
                            label: t("common.save"),
                            onClick: () => onSubmit(),
                            loading: loading
                        }
                    ]
            }
        >
            <div className="container">
                <div className="grid">
                    <div className="col-6 field">
                        <label className="require">{t("company.choose")}</label>
                        <Dropdown
                            filter
                            disabled={action === "view"}
                            options={dictionary?.company}
                            optionLabel="name"
                            optionValue="id"
                            value={data?.eventCompanyId}
                            onChange={(e) => { onChangeData("eventCompanyId", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["eventCompanyId"] })}
                        ></Dropdown>
                        {renderError("eventCompanyId")}
                    </div>
                    <div className="col-6 field">
                        <label>{t("event.video")}</label>
                        <InputText
                            disabled={action === "view"}
                            className="w-full"
                            onChange={(e) => { onChangeData("video", e.target.value) }}
                            value={data?.video}
                        ></InputText>
                    </div>
                    <div className="col-6 field">
                        <label>{t("event.sort_order")}</label>
                        <InputNumber
                            disabled={action === "view"}
                            className="w-full"
                            onChange={(e) => { onChangeData("sortOrder", e.value) }}
                            value={data?.sortOrder}
                        />
                    </div>
                    <div className="col-12 field">
                        <label className="require">{t("event.image")}</label>
                        <S3FileUpload
                            ref={s3FileUploadRef}
                            onChange={onUploadedImage}
                            accept="image/*"
                            onLoading={setLoading}
                        />
                        {renderError("image")}
                    </div>
                    
                </div>
                <div className="grid">
                    <div className="col-12 flex flex-column">
                        <b>{t("event.attributes")}</b>
                        <div className="w-full">
                            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                {data?.attributes?.map((item) => {
                                    return (
                                        <TabPanel header={item?.languagesname}>
                                            <div className="grid">
                                                <div className="col-12 field">
                                                    <label>{t("event.name")}</label>
                                                    <InputText
                                                        disabled={action === "view"}
                                                        value={item?.name}
                                                        onChange={(e) => { onChangeData("attributes", e.target.value, "name", activeIndex) }}
                                                        className={classNames({ "w-full": true, "p-invalid": error["name"] })}
                                                    ></InputText>
                                                </div>
                                                <div className="col-12 field pb-4">
                                                    <label>{t("event.description")}</label>
                                                    <CKEditorDocumentEditor
                                                        initialData={item?.description}
                                                        onDataChanged={(e) => {
                                                            setData(prevData => {
                                                                // Tạo một bản sao của prevData
                                                                const newData = { ...prevData };
                                                                // Kiểm tra xem phần tử có index có tồn tại không
                                                                if (newData.attributes && newData.attributes[activeIndex]) {
                                                                    // Nếu tồn tại, cập nhật thuộc tính 'description'
                                                                    newData.attributes[activeIndex] = {
                                                                        ...newData.attributes[activeIndex],  // Kế thừa các thuộc tính khác của phần tử 
                                                                        description: e   // Cập nhật mô tả mới
                                                                    };
                                                                }
                                                                return newData;  // Trả về newData đã được cập nhật
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </TabPanel>
                                    )
                                })}
                            </TabView>
                        </div>
                    </div>
                </div>
            </div>

        </EDialog>
    )
}
EventProductDetail = forwardRef(EventProductDetail)
export default EventProductDetail;
