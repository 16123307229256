// import * as actions from "../actionTypes/demoActionTypes";

import { groupJson } from "../../utils";
import { REQUEST_GET_COUNTRIES_SUCCESS, REQUEST_GET_MASTERDATA_LANGUAGE_SUCCESS } from "../actions/ActionMasterData";
import { CREATE_ERROR, CREATE_SUCCESS } from "../actions/ActionToast";
import { REQUEST_DELETE_EVENT_USER_TYPE_SUCCESS, REQUEST_GET_EVENT_USER_TYPE_SUCCESS, REQUEST_GROUP_PERMISSION_SUCCESS, REQUEST_POST_EVENT_SUCCESS, REQUEST_POST_EVENT_USER_TYPE_SUCCESS, REQUEST_POST_LOGIN_SUCCESS, REQUEST_POST_LOGOUT_SUCCESS, REQUEST_PUT_EVENT_USER_TYPE_SUCCESS, SET_STATE } from "../actions/ActionUser";


const initialState = {
	error: "",
	success: "",
	token: "",
	languages: [],
	language: 'en',
	groupPermission: {},
	userTypes: []
};

export default function UserReducer(
	state = initialState,
	action
) {
	switch (action.type) {
		case CREATE_ERROR:
			return {
				...state,
				error: action.error
			};
		case CREATE_SUCCESS:
			return {
				...state,
				success: action.success
			};

		case REQUEST_POST_LOGIN_SUCCESS:
			return {
				...state,
				...action.params,
				permission: parseInt(action.params.permission)
			};

		case REQUEST_POST_EVENT_SUCCESS:
			return {
				...state,
				...action.params,
				groupPermission: groupJson(action.params?.groups?.map(o => o.actionJson))
			};
		case REQUEST_POST_LOGOUT_SUCCESS:
			return {
				...state,
				token: null,
				refreshToken: null,
				sessionId: null,
				userInfo: null
			};
		case REQUEST_POST_LOGOUT_SUCCESS:
			return {
				...state,
				token: null,
				refreshToken: null,
				sessionId: null,
				userInfo: null
			};

		case SET_STATE:
			return {
				...state,
				...action.params
			}

		case REQUEST_GET_MASTERDATA_LANGUAGE_SUCCESS:
			return {
				...state,
				languages: action.params
			};
		case REQUEST_GET_COUNTRIES_SUCCESS:
			return {
				...state,
				countries: action.params
			};
		// case REQUEST_GROUP_PERMISSION_SUCCESS: {

		// 	return {
		// 		...state,
		// 		groupPermission: groupJson([
		// 			`{"dashboard":["view","create"],"company":["delete"],"event":["update"]}`,
		// 			`{"dashboard":["create","delete"],"company":[],"event":["update","create"]}`
		// 		])
		// 	};
		// }
		case REQUEST_GET_EVENT_USER_TYPE_SUCCESS:
			console.log(action.params)
			return {
				...state,
				userTypes: action.params
			};

		// REQUEST_POST_EVENT_USER_TYPE_SUCCESS
		// REQUEST_PUT_EVENT_USER_TYPE_SUCCESS
		// REQUEST_DELETE_EVENT_USER_TYPE_SUCCESS
		default:
			return state;
	}
}
