import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import EDialog from "../../../../components/EDialog";
import { Button } from "primereact/button";
import { ACTION } from "../../../../utils/constants";
import { cloneDeep, find } from 'lodash'
import EventsServices from "../../../../core/services/EventsServices";
import { Checkbox } from 'primereact/checkbox';
import { EnumEventModule } from "../../../../utils/constants";

function OfferAttributeDetail({ afterSubmit, type, refType, refId, dictionary }, ref) {
    const { t } = useTranslation()
    const defaultData = {
        offerAttributeId: "",
        tags: "",
        type: "",
        isMain: false
    }
    const defaultError = {
        offerAttributeId: "",
        // tags: "",
        // type: "",
    }

    const optionType = [{
        name: "PRODUCT",
        type: "COMPANIES_PRODUCT"
    },
    {
        name: "INDUSTRY",
        type: "COMPANIES_INDUSTRY"
    }]

    const [data, setData] = useState(defaultData)

    const [error, setError] = useState(defaultError)

    const [action, setAction] = useState()

    const refDialog = useRef(null)


    useImperativeHandle(ref, () => ({
        create: () => {
            refDialog.current.create();
            setAction(ACTION.CREATE)
        },
        edit: (item) => {
            refDialog.current.create();
            setAction(ACTION.UPDATE);
            setData(item)
        },
    }));

    const onChangeData = (prop, value) => {
        let _data = cloneDeep(data)
        _data[prop] = value
        switch (prop) {
            case "offerAttributeId":
                const offer = find(dictionary?.offer, { id: value })
                _data["tags"] = offer?.name ?? ""
                break
        }
        validateData([prop], _data)
        setData(_data)
    }

    const validateData = (prop, _data) => {
        let _error = cloneDeep(error)
        let requiredFields = prop.length > 0 ? prop : Object.keys(_data);
        let _prop = requiredFields
        for (const field of _prop) {
            switch (field) {
                case "offerAttributeId":
                    _error[field] = ""
                    if (!_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                // case "type":
                //     _error[field] = ""
                //     if (!_data[field] && type === EnumEventModule.COMPANY) {
                //         _error[field] = t("common.validate_content")
                //     }
                //     break;
                default:
                    break;
            }
        }
        setError(_error)
        let count = 0;
        for (const key in _error) {
            if (_error[key]) {
                count++;
            }
        }
        return count;
    }

    const onSubmit = () => {
        let validate = validateData([], data)
        if (validate) return
        switch (action) {
            case ACTION.CREATE:
                EventsServices.createOfferAttribute(type == EnumEventModule.COMPANY ? refType == EnumEventModule.PRODUCT ? EnumEventModule.COMPANIES_PRODUCT : EnumEventModule.COMPANIES_INDUSTRY : type, refId, data).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            case ACTION.UPDATE:
                EventsServices.updateOfferAttribute(type == EnumEventModule.COMPANY ? refType == EnumEventModule.PRODUCT ? EnumEventModule.COMPANIES_PRODUCT : EnumEventModule.COMPANIES_INDUSTRY : type, refId, data).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            default:
                break;
        }

    }

    const renderError = (field) => {
        if (error[field]) {
            return <span className="text-red-500 text-xs">{error[field]}</span>
        }
    }

    const cancel = () => {
        refDialog.current.close()
        setData(defaultData)
        setError(defaultError)
    }

    return (
        <EDialog
            ref={refDialog}
            style={{ width: '70vw' }}
            title={t("event.offer_attribute_information")}
            closable={false}
            hidebtnFooter={false}
            btnFooter={
                action === ACTION.VIEW ?
                    [
                        {
                            text: true,
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        }
                    ] :
                    [
                        {
                            text: true,
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        },
                        {
                            label: t("common.save"),
                            onClick: () => onSubmit(),
                        }
                    ]
            }
        >
            <div className="container">
                <div className="grid">
                    <div className="col-12 field">
                        <label className="require">{t("event.offer_attribute")}</label>
                        <Dropdown
                            filter
                            disabled={action === "view"}
                            options={dictionary?.offer}
                            optionLabel="name"
                            optionValue="id"
                            value={data?.offerAttributeId}
                            onChange={(e) => { onChangeData("offerAttributeId", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["offerAttributeId"] })}
                        ></Dropdown>
                        {renderError("offerAttributeId")}
                    </div>
                    {/* {type === EnumEventModule.COMPANY &&
                        <div className="col-12 field">
                            <label className="require">{t("offer_at.type")}</label>
                            <Dropdown
                                filter
                                disabled={action === "view"}
                                options={optionType}
                                optionLabel="name"
                                optionValue="type"
                                value={data?.type}
                                onChange={(e) => { onChangeData("type", e.target.value) }}
                                className={classNames({ "w-full": true, "p-invalid": error["type"] })}
                                disabled
                            ></Dropdown>
                            {renderError("type")}
                        </div>
                    } */}
                    {/* <div className="col-12 field">
                        <label>{t("event.tags")}</label>
                        <InputText
                            disabled={true}
                            className="w-full"
                            onChange={(e) => { onChangeData("tags", e.target.value) }}
                            value={data?.tags}
                        ></InputText>
                    </div> */}
                    <div className="col-6 field flex align-items-center">
                        <div className="flex align-items-center">
                            <Checkbox disabled={action === "view"}
                                onChange={e => onChangeData("isMain", e.checked)}
                                checked={data.isMain} />
                            <label className="ml-2">{t("event.main_offer")}</label>
                        </div>
                    </div>
                </div>
            </div>
        </EDialog>
    )
}
OfferAttributeDetail = forwardRef(OfferAttributeDetail)
export default OfferAttributeDetail;
