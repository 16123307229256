
export const CREATE_ERROR = `CREATE_ERROR`
function createErorr(
    error
) {
    return {
        type: CREATE_ERROR,
        error
    };
}

export const CREATE_SUCCESS = `CREATE_SUCCESS`
function createSuccess(
    success
) {
    return {
        type: CREATE_SUCCESS,
        success
    };
}

export default {
    createErorr,
    createSuccess
}