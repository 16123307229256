import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
// import "./styles.scss"
import { classNames } from "primereact/utils";
import { useDispatch } from "react-redux";
import EDialog from "../../../../components/EDialog";
import { actionCompanyCreate, actionCompanyEdit, actionCompanyById } from "../../../../core/actions/ActionCompany";
import CompanyServices from "../../../../core/services/CompanyServices";
import S3FileUpload from "../../../../components/S3FileUpload";
import { ACTION, FILE_TYPE, UPLOAD_STATUS, USER_PERMISSION, USER_PERMISSIONS, USER_TYPE, USER_TYPES } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import UserServices from "../../../../core/services/UserServices";
import CKEditorDocumentEditor from "../../../../components/CKEditorDocumentEditor";
import { cloneDeep, find } from 'lodash'
import EventsServices from "../../../../core/services/EventsServices";
import { MultiSelect } from "primereact/multiselect";
import PermissionServices from "../../../../core/services/PermissionServices";

function GroupUserDetail({ groupId, afterSubmit }, ref) {
    const { t } = useTranslation()
    const defaultData = {
        userIds: [],
        groupId: groupId
    }
    const defaultError = {

    }

    const [data, setData] = useState(defaultData)

    const [error, setError] = useState(defaultError)

    const [action, setAction] = useState()

    const [users, setUsers] = useState([])

    const refDialog = useRef(null)

    useEffect(() => {
        UserServices.get({
            first: 0,
            page: 0,
            size: 1000,
            total: 0,
            status: 1
        }).then((res) => {
            if (res) {
                setUsers(res.data)
            }
        })
    }, [])


    useImperativeHandle(ref, () => ({
        // create: () => {
        //     refDialog.current.create();
        //     setAction(ACTION.CREATE)
        // },
        edit: (_users) => {
            refDialog.current.create();
            setAction(ACTION.UPDATE);

            let _list = []
            _users.map(o => {
                const _u = find(users, {id: o.userId})
                _list.push(_u)
            })
            setData({
                userIds: _list,
                groupId: groupId
            })
        },
    }));

    const onChangeData = (prop, value) => {
        let _data = cloneDeep(data)
        _data[prop] = value
        validateData([prop], _data)
        setData(_data)
    }

    const validateData = (prop, _data) => {
        let _error = cloneDeep(error)
        let requiredFields = prop.length > 0 ? prop : Object.keys(_data);
        let _prop = requiredFields
        // for (const field of _prop) {
        //     switch (field) {
        //         case "userId":
        //             _error[field] = ""
        //             if (!_data[field]?.length) {
        //                 _error[field] = t("common.validate_content")
        //             }
        //             break;
        //         default:
        //             break;
        //     }
        // }

        setError(_error)
        let count = 0;
        for (const key in _error) {
            if (_error[key]) {
                count++;
            }
        }
        return count;
    }

    const onSubmit = () => {
        let validate = validateData([], data)
        if (validate) return
        // let params = data.userId.map(o => ({
        //     type: USER_TYPE.COMPANY,
        //     userId: o.id
        // }))
        switch (action) {
            case ACTION.CREATE:
            // UserServices.addUserCompany(refId, params, { code: dictionary?.event?.eventCode }).then((res) => {
            //     if (res) {
            //         afterSubmit();
            //         cancel();
            //     }
            // })
            // break;
            case ACTION.UPDATE:
                PermissionServices.updateUser(groupId, {
                    ...data,
                    userIds: data.userIds.map(o => o.id)
                }).then((res) => {
                    if (res) {
                        afterSubmit();
                        cancel();
                    }
                })
                break;
            default:
                break;
        }

    }

    const renderError = (field) => {
        if (error[field]) {
            return <span className="text-red-500 text-xs">{error[field]}</span>
        }
    }

    const cancel = () => {
        refDialog.current.close()
        setData(defaultData)
        setError(defaultError)
    }

    return (
        <EDialog
            ref={refDialog}
            style={{ width: '70vw' }}
            title={t("event.user")}
            closable={false}
            hidebtnFooter={false}
            btnFooter={
                action === ACTION.VIEW ?
                    [
                        {
                            text: true,
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        }
                    ] :
                    [
                        {
                            text: true,
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        },
                        {
                            label: t("common.save"),
                            onClick: () => onSubmit(),
                        }
                    ]
            }
        >
            <div className="container">
                <div className="grid">
                    <div className={`col-12 field`}>
                        <label>{t("event.user")}</label>
                        <MultiSelect
                            value={data?.userIds}
                            onChange={(e) => { onChangeData("userIds", e.value) }}
                            options={users}
                            showClear
                            filter
                            optionLabel={"userName"}
                            className={classNames({ "w-full": true, "p-invalid": error["userId"] })}
                        />
                        {renderError("userId")}
                    </div>
                </div>
            </div>
        </EDialog>
    )
}
GroupUserDetail = forwardRef(GroupUserDetail)
export default GroupUserDetail;
