/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss"
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Col, Container, Row } from "react-bootstrap";
import EDialog from "../../components/EDialog";
import { Badge } from "primereact/badge";
import { fabric } from "fabric";
import { Toolbar } from "primereact/toolbar";
import { ColorPicker } from "primereact/colorpicker";
import { CANVAS_TYPE } from "../../utils/constants";
import { SpeedDial } from "primereact/speeddial";
import { v4 as uuidv4 } from 'uuid'
import { InputNumber } from "primereact/inputnumber";
import { cloneDeep, findIndex, maxBy, remove } from 'lodash'
// import _dataJson from './json.json'

const Maps = ({ json }, ref) => {

	const [color, setColor] = useState('ff0000');

	const [colorNode, setColorNode] = useState('000000');

	const colorRef = useRef();

	const [makeGroupEnable, setMakeGroupEnable] = useState(true);

	const [detail, setDetail] = useState(null);
	const [selections, setSelections] = useState(null);

	const [node, setNode] = useState();

	const nodeRef = useRef()

	const canvasRef = useRef()

	const zindexRef = useRef()

	const fabricRef = React.useRef(null);

	const { t } = useTranslation()

	const items = [
		{
			label: 'Add',
			icon: 'pi pi-pencil',
			// command: () => {
			//     toast.current.show({ severity: 'info', summary: 'Add', detail: 'Data Added' });
			// }
		},
		{
			label: 'Update',
			icon: 'pi pi-refresh',
			// command: () => {
			//     toast.current.show({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
			// }
		},
		{
			label: 'Delete',
			icon: 'pi pi-trash',
			// command: () => {
			//     toast.current.show({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
			// }
		},
		{
			label: 'Upload',
			icon: 'pi pi-upload',
			dragdrop: true
			// command: () => {
			//     router.push('/fileupload');
			// }
		},
		{
			label: 'React Website',
			icon: 'pi pi-external-link',
			// command: () => {
			//     window.location.href = 'https://react.dev/';
			// }
		}
	];

	useImperativeHandle(ref, () => ({
		onSave: onSave,
		addImage: onAddImage
	}))

	useEffect(() => {
		colorRef.current = node ? colorNode : color
	}, [color, colorNode, node])

	useEffect(() => {

		const initFabric = () => {
			fabricRef.current = new fabric.Canvas(canvasRef.current, { preserveObjectStacking: true });
			fabricRef.current.on({
				"selection:updated": onSelection,
				"selection:created": onSelection,
				"selection:cleared": onSelection,
				"mouse:down": onMousedown,
				"mouse:wheel": function (opt) {
					var delta = opt.e.deltaY;
					var zoom = fabricRef.current.getZoom();
					zoom *= 0.999 ** delta;
					if (zoom > 20) zoom = 20;
					if (zoom < 0.01) zoom = 0.01;
					fabricRef.current.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
					opt.e.preventDefault();
					opt.e.stopPropagation();
				}
			})
			// let _json = ``
			if (json) {
				let _dataJson = JSON.parse(json);
				zindexRef.current = maxBy(_dataJson.objects, 'id')?.id ?? 0
				fabricRef.current.loadFromJSON(JSON.stringify(_dataJson))
				setMakeGroupEnable(false)
			} else {
				zindexRef.current = 0
			}
		};
		// document.onkeydown = onKeyDown

		const disposeFabric = () => {
			fabricRef.current.dispose();
		};

		initFabric();

		return () => {
			disposeFabric();
		};
	}, []);

	const onMousedown = (e) => {
		console.log(nodeRef.current, e)
		// console.log(fabricRef.current.viewportTransform)
		console.log(fabricRef.current.getZoom())
		switch (nodeRef.current) {
			case CANVAS_TYPE.NODE:
				zindexRef.current++
				const __node = new fabric.Circle({
					id: zindexRef.current,
					_type: CANVAS_TYPE.NODE,
					radius: 6, fill: `blue`, left: e.absolutePointer.x - 6, top: e.absolutePointer.y - 6,
					// lockUniScaling: true
				});
				__node.setControlsVisibility({
					mt: false,
					mb: false,
					ml: false,
					mr: false,
					bl: false,
					br: false,
					tl: false,
					tr: false,
					mtr: false,
				});
				fabricRef.current.add(__node);
				fabricRef.current.setActiveObject(__node)
				break
			case CANVAS_TYPE.NODE_DIRECTION:
				zindexRef.current++
				const _node = new fabric.Circle({
					id: zindexRef.current,
					_type: CANVAS_TYPE.NODE_DIRECTION,
					radius: 6, fill: `#${colorRef.current}`, left: e.absolutePointer.x - 6, top: e.absolutePointer.y - 6,
					// lockUniScaling: true
				});
				_node.setControlsVisibility({
					mt: false,
					mb: false,
					ml: false,
					mr: false,
					bl: false,
					br: false,
					tl: false,
					tr: false,
					mtr: false,
				});
				fabricRef.current.add(_node);
				fabricRef.current.setActiveObject(_node)
				break
		}
	}

	const onDelete = (e) => {
		// console.log(e)
		// if (e.keyCode == 8) {
		var _select = fabricRef.current.getActiveObject()
		if (_select._type) {
			fabricRef.current.remove(_select)
		} else {
			fabricRef.current.getActiveObject().forEachObject(o => {
				fabricRef.current.remove(o)
			})
		}

		fabricRef.current.discardActiveObject()
		// }
	}

	const onSelection = (e) => {
		console.log(e)
		// setMakeGroupEnable(e.selected.length > 1)
		if (e.selected && e.selected.length == 1) {
			setDetail(e.selected[0])
		} else {
			setDetail()
		}
		setSelections(e.selected)
	}

	const onAddRectangle = () => {
		zindexRef.current++
		unSetNode()
		let viewTt = fabricRef.current.viewportTransform
		console.log(viewTt, fabricRef.current)
		const rect = new fabric.Rect({
			id: zindexRef.current,
			_type: CANVAS_TYPE.RECT,
			top: (50 - fabricRef.current.viewportTransform[5]) / fabricRef.current.viewportTransform[0],
			left: (50 - fabricRef.current.viewportTransform[4]) / fabricRef.current.viewportTransform[0],
			width: 50 / fabricRef.current.viewportTransform[0],
			height: 50 / fabricRef.current.viewportTransform[0],
			fill: `#${color}90`,
			perPixelTargetFind :true,
		});

		fabricRef.current.add(rect);
		fabricRef.current.setActiveObject(rect)
	}

	const onAddCircle = () => {
		zindexRef.current++
		unSetNode()
		const circle = new fabric.Circle({
			id: zindexRef.current,
			_type: CANVAS_TYPE.CIRCLE,
			radius: 20 / fabricRef.current.viewportTransform[0],
			fill: `#${color}90`,
			perPixelTargetFind :true,
			top: (50 - fabricRef.current.viewportTransform[5]) / fabricRef.current.viewportTransform[0],
			left: (50 - fabricRef.current.viewportTransform[4]) / fabricRef.current.viewportTransform[0],
		});
		fabricRef.current.add(circle);
		fabricRef.current.setActiveObject(circle)
	}

	const onAddNode = () => {
		setNode(CANVAS_TYPE.NODE)
		nodeRef.current = CANVAS_TYPE.NODE
		// fabricRef.current.freeDrawingCursor = 'crosshair'
	}

	const onAddNodeDirection = () => {
		setNode(CANVAS_TYPE.NODE_DIRECTION)
		nodeRef.current = CANVAS_TYPE.NODE_DIRECTION
		// fabricRef.current.freeDrawingCursor = 'crosshair'
	}

	const unSetNode = () => {
		setNode(null)
		nodeRef.current = null
		// fabricRef.current.freeDrawingCursor = 'default'
	}

	const onAddTriangle = () => {
		zindexRef.current++
		unSetNode()
		const triangle = new fabric.Triangle({
			id: zindexRef.current,
			_type: CANVAS_TYPE.TRIANGLE,
			width: 30 / fabricRef.current.viewportTransform[0],
			height: 30 / fabricRef.current.viewportTransform[0],
			fill: `#${color}90`,
			perPixelTargetFind :true,
			top: (50 - fabricRef.current.viewportTransform[5]) / fabricRef.current.viewportTransform[0],
			left: (50 - fabricRef.current.viewportTransform[4]) / fabricRef.current.viewportTransform[0],
		});
		// var triangle = new fabric.Path('M 0 0 L 200 100 L 170 200 z');
		// triangle.set({ left: 120, top: 120 });

		fabricRef.current.add(triangle);
		fabricRef.current.setActiveObject(triangle)
	}

	const onAddPoligon = () => {
		zindexRef.current++
		unSetNode()
		var points = [{
			x: 6, y: 20
		}, {
			x: 30, y: 20
		}, {
			x: 40, y: 2
		}, {
			x: 80, y: 2
		}, {
			x: 80, y: 60
		}, {
			x: 6, y: 60
		}]
		var polygon = new fabric.Polygon(points, {
			id: zindexRef.current,
			_type: CANVAS_TYPE.POLIGON,
			top: (50 - fabricRef.current.viewportTransform[5]) / fabricRef.current.viewportTransform[0],
			left: (50 - fabricRef.current.viewportTransform[4]) / fabricRef.current.viewportTransform[0],
			fill: `#${color}90`,
			perPixelTargetFind :true,
			// scaleX: 4,
			// scaleY: 4,
			objectCaching: false,
			transparentCorners: false,
			cornerColor: 'blue',
		});
		// fabricRef.current.viewportTransform = [0.7, 0, 0, 0.7, -50, 50];
		fabricRef.current.add(polygon);
	}

	const onEdit = () => {
		unSetNode()
		var poly = fabricRef.current.getActiveObject();
		poly.edit = !poly.edit;
		if (poly.edit) {
			var lastControl = poly.points.length - 1;
			poly.cornerStyle = 'circle';
			poly.cornerColor = 'rgba(0,0,255,0.5)';
			poly.controls = poly.points.reduce(function (acc, point, index) {
				acc['p' + index] = new fabric.Control({
					positionHandler: polygonPositionHandler,
					actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, actionHandler),
					actionName: 'modifyPolygon',
					pointIndex: index
				});
				return acc;
			}, {});
		} else {
			poly.cornerColor = 'blue';
			poly.cornerStyle = 'rect';
			poly.controls = fabric.Object.prototype.controls;
		}
		poly.hasBorders = !poly.edit;
		fabricRef.current.requestRenderAll();
	}

	const getObjectSizeWithStroke = (object) => {
		var stroke = new fabric.Point(
			object.strokeUniform ? 1 / object.scaleX : 1,
			object.strokeUniform ? 1 / object.scaleY : 1
		).multiply(object.strokeWidth);
		return new fabric.Point(object.width + stroke.x, object.height + stroke.y);
	}

	function polygonPositionHandler(dim, finalMatrix, fabricObject) {
		var x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x),
			y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y);
		return fabric.util.transformPoint(
			{ x: x, y: y },
			fabric.util.multiplyTransformMatrices(
				fabricObject.canvas.viewportTransform,
				fabricObject.calcTransformMatrix()
			)
		);
	}

	const actionHandler = (eventData, transform, x, y) => {
		var polygon = transform.target,
			currentControl = polygon.controls[polygon.__corner],
			mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
			polygonBaseSize = getObjectSizeWithStroke(polygon),
			size = polygon._getTransformedDimensions(0, 0),
			finalPointPosition = {
				x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
				y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
			};
		polygon.points[currentControl.pointIndex] = finalPointPosition;
		return true;
	}

	// define a function that can keep the polygon in the same position when we change its
	// width/height/top/left.
	const anchorWrapper = (anchorIndex, fn) => {
		return function (eventData, transform, x, y) {
			var fabricObject = transform.target,
				absolutePoint = fabric.util.transformPoint({
					x: (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x),
					y: (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y),
				}, fabricObject.calcTransformMatrix()),
				actionPerformed = fn(eventData, transform, x, y),
				newDim = fabricObject._setPositionDimensions({}),
				polygonBaseSize = getObjectSizeWithStroke(fabricObject),
				newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x,
				newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
			fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
			return actionPerformed;
		}
	}

	const onAddImage = (src) => {
		deleteImage()
		if (src) {
			zindexRef.current++
			unSetNode()
			const image = new Image();
			image.src = src;

			image.onload = () => {
				const ratio = image.width / image.height
				let scaleX = 1
				let scaleY = 1
				if (ratio > 1) {
					scaleX = 1000 / image.width
					scaleY = (1000 / ratio) / image.height
				} else {
					scaleY = 1000 / image.height
					scaleX = 1000 * ratio / image.width
				}

				// context.drawImage(image, (context.canvas.width - width) / 2, 0, width, height);
				var imgInstance = new fabric.Image(image, {
					left: ratio > 1 ? 0 : (1000 - scaleX * image.width) / 2,
					top: 0,
					scaleX,
					scaleY,
					_type: CANVAS_TYPE.IMAGE,
					selectable: false,
					id: zindexRef.current
				});
				fabricRef.current.add(imgInstance)
				setMakeGroupEnable(false)

				resetImage()
			};

		}

	}

	const deleteImage = () => {
		fabricRef.current.forEachObject(o => {
			if (o._type == CANVAS_TYPE.IMAGE) {
				fabricRef.current.remove(o)
			}
		})
		fabricRef.current.discardActiveObject()
		fabricRef.current.requestRenderAll();
	}

	const resetImage = () => {
		unSetNode()
		fabricRef.current.forEachObject(o => {
			if (o._type == CANVAS_TYPE.IMAGE) {
				o.moveTo(0)
			}
		})
		fabricRef.current.discardActiveObject()
		fabricRef.current.requestRenderAll();
	}

	const onShowImage = () => {
		unSetNode()
		fabricRef.current.forEachObject(o => {
			if (o._type == CANVAS_TYPE.IMAGE) {
				console.log(fabricRef.current.getObjects().indexOf(o))
				if (fabricRef.current.getObjects().indexOf(o) != 0) {
					o.moveTo(0)
				} else {
					o.moveTo(99999)
				}
			}
		})
		fabricRef.current.discardActiveObject()
		fabricRef.current.requestRenderAll();
	}

	const startContent = (
		<React.Fragment>
			<Button icon="pi pi-stop" onClick={onAddRectangle} className="mr-2" disabled={node || makeGroupEnable} />
			<Button icon="pi pi-circle" onClick={onAddCircle} className="mr-2" disabled={node || makeGroupEnable} />
			<Button icon="pi pi-sort-up" onClick={onAddTriangle} className="mr-2" disabled={node || makeGroupEnable} />
			<Button icon="pi pi-folder" onClick={onAddPoligon} className="mr-2" disabled={node || makeGroupEnable} />
			<Button label="Node" onClick={onAddNode} className="mr-2" disabled={node == CANVAS_TYPE.NODE || makeGroupEnable} />
			<Button label="Node Direction" onClick={onAddNodeDirection} className="mr-2" disabled={node == CANVAS_TYPE.NODE_DIRECTION || makeGroupEnable} />
			<Button label="Poligon Edit" onClick={onEdit} className="mr-2" disabled={!(detail?._type == CANVAS_TYPE.POLIGON) || node || makeGroupEnable} />
			{/* <Button icon="pi pi-image" onClick={onAddImage} className="mr-2" disabled={!makeGroupEnable || node} /> */}
			<ColorPicker value={node ? colorNode : color} className="mr-2" onChange={(e) => node ? setColorNode(e.value) : setColor(e.value)} />
			<Button icon="pi pi-arrows-alt" onClick={unSetNode} className="mr-2" disabled={!node} />
			<Button icon="pi pi-times" onClick={onDelete} className="mr-2" disabled={!selections} />
			<Button label="Image Layer" onClick={onShowImage} className="mr-2" disabled={makeGroupEnable || node} />
		</React.Fragment>
	);

	const centerContent = (
		<span className="p-input-icon-left">
			<i className="pi pi-search" />
			<InputText placeholder="Search" />
		</span>
	);

	const onSave = () => {
		var _json = fabricRef.current.toJSON(["id", "_type", "_title", "_labelNodeId", "_doorNodeId", "_controlsVisibility", "selectable", "_references"])
		console.log(JSON.stringify(_json))
		return _json
	}

	const endContent = (
		<React.Fragment>
			<Button icon="pi pi-check" label="Save" className="" onClick={onSave} />
		</React.Fragment>
	);


	const onChangeTitle = (e) => {
		setDetail({
			...detail,
			_title: e.target.value
		})
		const index = fabricRef.current._objects.findIndex(o => o.id == detail.id)
		if (index != -1) {
			fabricRef.current._objects[index]._title = e.target.value
		}
	}

	const onChangeLabelNodeId = (e) => {
		setDetail({
			...detail,
			_labelNodeId: e.value
		})
		const index = fabricRef.current._objects.findIndex(o => o.id == detail.id)
		if (index != -1) {
			fabricRef.current._objects[index]._labelNodeId = e.value
		}
	}

	const onChangeDoorNodeId = (e) => {
		setDetail({
			...detail,
			_doorNodeId: e.target.value
		})
		const index = fabricRef.current._objects.findIndex(o => o.id == detail.id)
		if (index != -1) {
			fabricRef.current._objects[index]._doorNodeId = e.target.value
		}
	}

	const tmpDetail = useMemo(() => {
		switch (detail?._type) {
			case CANVAS_TYPE.RECT:
			case CANVAS_TYPE.CIRCLE:
			case CANVAS_TYPE.TRIANGLE:
			case CANVAS_TYPE.POLIGON:
				return {
					...detail,
					_title: fabricRef.current._objects.find(o => o.id == detail._labelNodeId)?._title,
					_doorNodeId: fabricRef.current._objects.find(o => o.id == detail._labelNodeId)?._doorNodeId
				}

			case CANVAS_TYPE.NODE:
				return detail
			case CANVAS_TYPE.NODE_DIRECTION:
				return {
					...detail,
					_title: fabricRef.current._objects.find(o => o.id == detail._doorNodeId)?._title,
				}

			default:

				return null
		}
	}, [detail])

	const onAddreference = () => {
		let _list = detail._references ? cloneDeep(detail._references) : []
		_list.push({
			_rowId: uuidv4(),
			_nodeId: null,
			_distance: null,
			editing: true,
			twoWay: false
		})
		setDetail({
			...detail,
			_references: _list
		})
		const index = fabricRef.current._objects.findIndex(o => o.id == detail.id)
		if (index != -1) {
			fabricRef.current._objects[index]._references = _list
		}
	}

	const renderReference = (_item) => {
		return <div className="col-12" key={_item._rowId}>
			<div className="flex">
				<div className="field flex-1 p-fluid mb-0 mr-3">
					<InputNumber
						placeholder="ID"
						value={_item?._nodeId}
						onChange={onChangeReferenceNodeId(_item._rowId)}
						size='small'
					/>
				</div>
				<div className="field flex-1 p-fluid mb-0 mr-3">
					<InputNumber
						placeholder="Distance"
						value={_item?._distance}
						onChange={onChangeReferenceDistance(_item._rowId)}
						size='small'
					/>
				</div>

				<Button
					icon={"pi pi-check"}
					text
					onClick={onChangeReferenceSave(_item._rowId)}
					disabled={!_item.editing}
				/>
				<Button
					icon={_item.twoWay ? "pi pi-arrow-right-arrow-left" : "pi pi-arrow-right"}
					text
					// tooltip={_item.twoWay ? "Chuyển đổi thành đường 1 chiều" : "Chuyển đổi thành đường 2 chiều"}
					onClick={onChangeReferenceWay(_item._rowId)}
					disabled={_item.editing}
				/>
			</div>
		</div>
	}

	const onChangeReferenceNodeId = (_rowId) => (e) => {
		let _detail = cloneDeep(detail)
		const _index = findIndex(_detail?._references ?? [], { _rowId })
		if (_index != -1) {
			_detail._references[_index]._nodeId = e.value
			_detail._references[_index].editing = true
		}
		setDetail(_detail)
	}

	const onChangeReferenceDistance = (_rowId) => (e) => {
		let _detail = cloneDeep(detail)
		const _index = findIndex(_detail?._references ?? [], { _rowId })
		if (_index != -1) {
			_detail._references[_index]._distance = e.value
			_detail._references[_index].editing = true
		}
		setDetail(_detail)
	}

	const onChangeReferenceWay = (_rowId) => (e) => {
		let _detail = cloneDeep(detail)
		const _index = findIndex(_detail?._references ?? [], { _rowId })
		if (_index != -1) {
			_detail._references[_index].twoWay = !_detail._references[_index].twoWay
		}
		setDetail(_detail)
		const index = fabricRef.current._objects.findIndex(o => o.id == _detail.id)
		if (index != -1) {
			fabricRef.current._objects[index]._references = _detail._references
		}

		const indexWay = fabricRef.current._objects.findIndex(o => o.id == _detail._references[_index]._nodeId)
		if (indexWay != -1) {
			if (_detail._references[_index].twoWay) {
				fabricRef.current._objects[indexWay]._references = fabricRef.current._objects[indexWay]._references ?? []
				fabricRef.current._objects[indexWay]._references.push({
					_rowId: _detail._references[_index]._rowId,
					_nodeId: _detail.id,
					_distance: _detail._references[_index]._distance,
					editing: false,
					twoWay: true
				})
			} else {
				remove(fabricRef.current._objects[indexWay]._references, { _rowId: _detail._references[_index]._rowId })
			}
		}
	}

	const onChangeReferenceSave = (_rowId) => (e) => {

		let _detail = cloneDeep(detail)
		const _index = findIndex(_detail?._references ?? [], { _rowId })
		if (_index != -1) {
			if (_detail._references[_index]._nodeId == _detail.id) {
				return
			}
			_detail._references[_index].editing = false
		}
		setDetail(_detail)
		const index = fabricRef.current._objects.findIndex(o => o.id == _detail.id)
		if (index != -1) {
			if (_detail._references[_index].twoWay) {
				const _indexFabricRef = findIndex(fabricRef.current._objects[index]._references ?? [], { _rowId })
				if (_indexFabricRef != -1) {
					if (fabricRef.current._objects[index]._references[_indexFabricRef]._nodeId == _detail._references[_index]._nodeId) {
						fabricRef.current._objects[index]._references[_indexFabricRef]._distance = _detail._references[_index]._distance
					} else {
						const _indexOldNode = fabricRef.current._objects.findIndex(o => o.id == fabricRef.current._objects[index]._references[_indexFabricRef]._nodeId)
						if (_indexOldNode != -1) {
							remove(fabricRef.current._objects[_indexOldNode]._references, { _rowId })
							const indexWay = fabricRef.current._objects.findIndex(o => o.id == _detail._references[_index]._nodeId)
							if (indexWay != -1) {
								fabricRef.current._objects[indexWay]._references = fabricRef.current._objects[indexWay]._references ?? []
								fabricRef.current._objects[indexWay]._references.push({
									_rowId: _detail._references[_index]._rowId,
									_nodeId: _detail.id,
									_distance: _detail._references[_index]._distance,
									editing: false,
									twoWay: true
								})
							}
						}
					}
				}
			}
			fabricRef.current._objects[index]._references = _detail._references
		}
	}

	return (<>
		<div className="grid">
			<div className="col-12">
				<Toolbar start={startContent} />
			</div>
			<div className="col-7">

				<div className='e-maps overflow-x-scroll'>
					<div
						// ref={refTransform}
						className='absolute w-full h-full top-0 left-0'
					>
						<canvas
							className="border-1"
							ref={canvasRef}
							width={1000}
							height={1000}
						/>
						{/* <canvas ref={canvasRef} {...props} className='w-full h-full' /> */}
					</div>
				</div>

			</div>
			<div className="col-5">
				<div className="grid">

					<div className="col-12">
						<div className="flex flex-column gap-2">
							<label>ID</label>
							<InputText
								value={tmpDetail?.id}
								disabled={true}
								size='small'
							/>
						</div>
					</div>
					{!detail || (tmpDetail._type != CANVAS_TYPE.NODE && tmpDetail._type != CANVAS_TYPE.RECT && tmpDetail._type != CANVAS_TYPE.CIRCLE && tmpDetail._type != CANVAS_TYPE.TRIANGLE && tmpDetail._type != CANVAS_TYPE.POLIGON)
						? null
						: <div className="col-12">
							<div className="flex flex-column gap-2">
								<label>Title</label>
								<InputText
									value={tmpDetail?._title ?? ""}
									onChange={onChangeTitle}
									size='small'
									disabled={tmpDetail._type != CANVAS_TYPE.NODE}
								/>
							</div>
						</div>
					}
					{!detail || (tmpDetail._type != CANVAS_TYPE.RECT && tmpDetail._type != CANVAS_TYPE.CIRCLE && tmpDetail._type != CANVAS_TYPE.TRIANGLE && tmpDetail._type != CANVAS_TYPE.POLIGON)
						? null
						: <div className="col-12">
							<div className="flex flex-column gap-2">
								<label>Label node id</label>
								<InputNumber
									value={tmpDetail?._labelNodeId}
									onChange={onChangeLabelNodeId}
									size='small'
								/>
							</div>
						</div>
					}
					{!detail || tmpDetail._type != CANVAS_TYPE.NODE
						? null
						: <div className="col-12">
							<div className="flex flex-column gap-2">
								<label>Door node id</label>
								<InputText
									value={tmpDetail?._doorNodeId ?? ""}
									onChange={onChangeDoorNodeId}
									size='small'
								/>
							</div>
						</div>
					}
					{detail && tmpDetail._type == CANVAS_TYPE.NODE_DIRECTION
						? <>
							<div className="col-12">
								<div className="flex align-items-center">
									<label className="mr-3">Reference</label>
									<Button
										text
										icon="pi pi-plus"
										onClick={onAddreference}
									/>
								</div>
							</div>
							{(tmpDetail._references ?? []).map(renderReference)}


						</>
						: null
					}

				</div>


			</div>
		</div>
	</>

	)
}

export default forwardRef(Maps)