import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { useDispatch } from "react-redux";
import EDialog from "../../../components/EDialog";
import EventsServices from "../../../core/services/EventsServices";
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import "./styles.scss"
import EventCompanies from "../EventCompanies";
import EventsSession from "../EventsSession/index.js";
import EventProduct from "../EventsProduct";
import Banners from "../Banners";
import Maps from "../Maps";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import EBreadCrumb from "../../../components/EBreadCrumb";
import UserEvent from "../UserEvent";
import { useSelector } from 'react-redux';
import { EnumEventModule, PERMALINK_TYPE, PERMISSION, USER_PERMISSION, USER_TYPES_TAB } from "../../../utils/constants";
import EventPermalink from "../EventsPermalink";
import EventPreview from "../EventPreview";
import EventsNotification from "../EventsNotification";
import { checkPermission } from "../../../utils";
import OfferAttributeList from "../../OfferAttribute/OfferAttributeList";
import { OverlayPanel } from "primereact/overlaypanel";
import RanksServices from "../../../core/services/RanksServices";
import { orderBy, find } from 'lodash'
import { ListBox } from "primereact/listbox";
import { Menubar } from "primereact/menubar";
import ListLocation from "../../Location/ListLocation";
import BusinessMatching from "../../BusinessMatching";
import Sort from "../../Sort";
import EventsUserType from "../EventsUserType";
import { getEventUserType } from "../../../core/actions/ActionUser";

const screenPermission = "eventInfo"

export default function EventInfo(props) {
    const { t } = useTranslation()

    const { userTypes } = useSelector(state => state.userReducer)

    const { eventId, screenId } = useParams()

    const [searchParams, setSearchParams] = useSearchParams();

    const rank = searchParams.get("rank")

    const userType = searchParams.get("user_type")

    const refCompany = useRef()

    const { userInfo, groupPermission, language } = useSelector((state) => state.userReducer)

    const history = useNavigate()

    const [ranks, setRanks] = useState([]);

    const dispatch = useDispatch()

    const permission = useMemo(() => {
        return parseInt(userInfo?.permission ?? "-1")
    }, [userInfo])

    useEffect(() => {
        loadDictionary()
    }, [])

    /**
     * load dictionary
     */
    const loadDictionary = async () => {
        RanksServices.get().then(res => {
            if (res) {
                setRanks(orderBy(res.map(o => {
                    const lang = find(o.languages, { languageCode: language })
                    const name = lang ? lang?.name : ""
                    return {
                        ...o,
                        label: name,
                        screen: "companies",
                        template: tabHeaderTemplate,
                        url: `/event/${eventId}/companies?rank=${o.id}`,
                    }
                }), ['isDisplay', 'sortOrder'], ['desc', 'asc']))
            }

        })
        dispatch(getEventUserType(eventId))
        // EventsServices.userType.get(eventId).then(res => {
        //     if (res) {
        //         setUserTypes(res.map(o => {
        //             const lang = find(o.languages, { languageCode: language })
        //             const name = lang ? lang?.name : ""
        //             return {
        //                 ...o,
        //                 label: name,
        //                 screen: "user",
        //                 template: tabHeaderTemplate,
        //                 url: `/event/${eventId}/user?user_type=${o.id}`,
        //             }
        //         }))
        //     }

        // })
    };

    const tabHeaderTemplate = (item) => {
        return <>
            <div className={`p-3 font-bold flex cursor-pointer flex align-items-center ${item?.active ? "text-primary" : ""}`} onClick={onLink(item.url)}>
                {item.label}
                {item?.items
                    ? <i className="pi pi-angle-down ml-1" />
                    : null
                }
            </div>

        </>

    }

    const _userTypes = useMemo(() => {
        return (userTypes ?? []).map(o => {
            const lang = find(o.languages, { languageCode: language })
            const name = lang ? lang?.name : ""
            return {
                ...o,
                label: name,
                screen: "user",
                template: tabHeaderTemplate,
                url: `/event/${eventId}/user?user_type=${o.id}`,
            }
        })
    }, [userTypes, language])

    const activeIndex = useMemo(() => {
        console.log(USER_TYPES_TAB.map(o => ({
            ...o,
            label: t(o.name),
            active: userType?.toUpperCase() == o.value,
            screen: "user",
            template: tabHeaderTemplate,
            url: `/event/${eventId}/user?user_type=${o.value}`,
        })))
        let screens = [
            {
                screen: "info",
                label: t("event_info.tab.info"),
                template: tabHeaderTemplate,
                url: `/event/${eventId}`,
                active: !screenId
            },
            {
                screen: "sessions",
                label: t("event_info.tab.session"),
                template: tabHeaderTemplate,
                url: `/event/${eventId}/sessions`,
                active: screenId == "sessions"
            },
            {
                screen: "banners",
                label: t("event_info.tab.banner"),
                template: tabHeaderTemplate,
                url: `/event/${eventId}/banners`,
                active: screenId == "banners"
            },
            {
                screen: "maps",
                label: t("event_info.tab.maps"),
                template: tabHeaderTemplate,
                url: `/event/${eventId}/maps`,
                active: screenId == "maps"
            },
            {
                screen: "permalink",
                label: t("event.permalink"),
                template: tabHeaderTemplate,
                url: `/event/${eventId}/permalink`,
                active: screenId == "permalink"
            },
            {
                screen: "business-matching",
                label: t("common.route.event_info.business-matching"),
                template: tabHeaderTemplate,
                url: `/event/${eventId}/business-matching`,
                active: screenId == "business-matching"
            },
            {
                screen: "sort",
                label: t("common.route.event_info.sort"),
                template: tabHeaderTemplate,
                url: `/event/${eventId}/sort`,
                active: screenId == "sort"
            },

            {
                screen: "companies",
                label: t("event_info.tab.company"),
                template: tabHeaderTemplate,
                // url: `/event/${eventId}/companies`,
                active: screenId == "companies",
                items: ranks.map(o => ({
                    ...o,
                    active: rank == o.id
                }))
            },
            {
                screen: "user",
                label: t("event.user"),
                template: tabHeaderTemplate,
                // url: `/event/${eventId}/user`,
                active: screenId == "user",
                // items: USER_TYPES_TAB.map(o => ({
                //     ...o,
                //     label: t(o.name),
                //     active: userType?.toUpperCase() == o.value,
                //     screen: "user",
                //     template: tabHeaderTemplate,
                //     url: `/event/${eventId}/user?user_type=${o.value}`,
                // }))
                items: _userTypes.map(o => ({
                    ...o,
                    active: userType == o.id
                }))
            },
            {
                screen: "products",
                label: t("event_info.tab.product"),
                template: tabHeaderTemplate,
                url: `/event/${eventId}/products`,
                active: screenId == "products"
            },
            {
                screen: "notification",
                label: t("event.notification"),
                template: tabHeaderTemplate,
                url: `/event/${eventId}/notification`,
                active: screenId == "notification"
            },
            {
                screen: "offer-attribute-agenda",
                label: t("common.route.event_info.offer-attribute-agenda"),
                template: tabHeaderTemplate,
                url: `/event/${eventId}/offer-attribute-agenda`,
                active: screenId == "offer-attribute-agenda"
            },
            {
                screen: "user-type",
                label: t("event.user_type"),
                template: tabHeaderTemplate,
                url: `/event/${eventId}/user-type`,
                active: screenId == "user-type",
            },
            {
                screen: "offer-attribute-position",
                label: t("common.route.event_info.offer-attribute-position"),
                template: tabHeaderTemplate,
                url: `/event/${eventId}/offer-attribute-position`,
                active: screenId == "offer-attribute-position"
            },
            {
                screen: "offer-attribute-industry",
                label: t("common.offer_attribute_industry"),
                template: tabHeaderTemplate,
                url: `/event/${eventId}/offer-attribute-industry`,
                active: screenId == "offer-attribute-industry"
            },
            {
                screen: "offer-attribute-product",
                label: t("common.offer_attribute_product"),
                template: tabHeaderTemplate,
                url: `/event/${eventId}/offer-attribute-product`,
                active: screenId == "offer-attribute-product"
            },

            
        ]
        // if (permission == USER_PERMISSION.COMPANY) {
        //     screens = [
        //         "info",
        //         "sessions",
        //         "companies",
        //         "products"
        //     ]
        // }
        // let index = screens.indexOf(screenId)
        return {
            index: screenId,
            screens
        }
    }, [screenId, ranks, rank, userType, _userTypes])




    const onLink = (link) => (e) => {
        if (link) {
            history(link)
        }

    }

    const renderTab = (_screen) => {
        switch (_screen) {
            case "sessions":
                return <EventsSession
                />

            case "products":
                return <EventProduct
                />

            case "banners":
                return <Banners
                />

            case "maps":
                return <ListLocation />

            case "user":
                return <UserEvent
                    userType={userType}
                />

            case "permalink":
                return <EventPermalink
                    type={EnumEventModule.EVENT}
                    screenPermission={"eventInfoScreen_permalink"}
                />

            case "companies":
                return <EventCompanies rankId={rank} />

            case "notification":
                return <EventsNotification
                    type={EnumEventModule.EVENT}
                />

            case "offer-attribute-industry":
                return <OfferAttributeList refType={EnumEventModule.INDUSTRY} />

            case "offer-attribute-product":
                return <OfferAttributeList refType={EnumEventModule.PRODUCT} />

            case "offer-attribute-position":
                return <OfferAttributeList refType={EnumEventModule.POSITION} />

            case "offer-attribute-agenda":
                return <OfferAttributeList refType={EnumEventModule.AGENDA} />

            case "business-matching":
                return <BusinessMatching />
            case "sort":
                return <Sort />
            case "user-type":
                return <EventsUserType />

            default:
                return <EventPreview
                    eventId={eventId}
                />

        }
    }

    if (!checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)) {
        return null
    }

    return (
        <div className="event-layout">
            <div className="event-layout-center overflow-hidden">
                <div className="p-card">
                    <div className="p-card-body">
                        <div className="grid">
                            <div className="col-12">
                                {/* <TabView scrollable activeIndex={activeIndex.index} panelContainerClassName="px-0">

                                    {activeIndex.screens.map(renderTab)}

                                </TabView> */}
                                <Menubar model={activeIndex.screens} />
                                <div className="pt-2">
                                    {renderTab((screenId ?? "info"))}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
