// import {S3} from 'aws-sdk'
import { S3, PutObjectCommand } from "@aws-sdk/client-s3"
import { v4 as uuidv4 } from 'uuid'
import { BASE_S3_URL } from "../config";
import { mergeWith, isArray, find, filter, orderBy } from 'lodash'
import * as _ from 'lodash'
import store from "../core/store/configureStore";
import { EnumEventModule, EXCEL_BUSINESS_MATCHING_CONFIG_FIELD, EXCEL_CONFIG, EXCEL_CONFIG_FIELD, EXCEL_SHEET, MEET_STATUS, USER_PERMISSION, USER_TYPE } from "./constants";
import * as XLSX from 'xlsx';
import moment from "moment";
import i18n from "../i18n";
// import * as fs from 'fs';
import { TemplateHandler } from 'easy-template-x';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';
import expressionParser from 'docxtemplater/expressions';
// import AWS from 'aws-sdk';
// var ep = new AWS.Endpoint('dev-apis3.xdp.vn');
// AWS.config.update({
//     region: "eu-central-1",
//     accessKeyId: 'AKIAJA72LE3KMKNP2DYQ',
//     secretAccessKey: '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',
//     // endpoint: "http://dev-apis3.xdp.vn",
//     // endpoint: {
//     //     hostname: 'http://dev-apis3.xdp.vn/',
//     //     // protocol: 'http',
//     //     port: 80,
//     //     path: '/expolander',
//     // },
//     sslEnabled: false,
//     s3ForcePathStyle: true
// });
// const s3 = new AWS.S3()
// import S3 from 'react-aws-s3';
// const config = {
//     bucketName: 'myBucket',
//     dirName: 'media', /* optional */
//     region: 'eu-west-1',
//     accessKeyId: 'JAJHAFJFHJDFJSDHFSDHFJKDSF',
//     secretAccessKey: 'jhsdf99845fd98qwed42ebdyeqwd-3r98f373f=qwrq3rfr3rf',
//     s3Url: 'https:/your-custom-s3-url.com/', /* optional */
// }
// var fs = require('fs-js')
// import S3 from 'react-aws-s3'
// var config = {
//     accessKeyId: '2t8ZBg4wKVEB9OscFwfm',
//     secretAccessKey: '0INcyGFzuOjAhbl15vyEzZE4we1LDZxjgxkqbgPT',
//     // endpoint: "dev-s3.xdp.vn",
//     // sslEnabled: false,
//     // s3ForcePathStyle: true
//     s3Url: "dev-s3.xdp.vn",
//     bucketName: "expolander",
//     region: "ap-southeast-1",
//     dirName: 'media', /* optional */
//   };
// const ReactS3Client = new S3(config)
// var config = {
//         accessKeyId: '2t8ZBg4wKVEB9OscFwfm',
//         secretAccessKey: '0INcyGFzuOjAhbl15vyEzZE4we1LDZxjgxkqbgPT',
//         endpoint: "dev-s3.xdp.vn",
//         sslEnabled: false,
//         // s3ForcePathStyle: true
//       };
//       AWS.config.update(config);
//     //   const myBucket = new AWS.S3({
//     //     params: { Bucket: "expolander"},
//     //     // region: REGION,
//     // })
//     const s3 = new AWS.S3()
// const s3 = new S3({
//     credentials: {
//       accessKeyId: '2t8ZBg4wKVEB9OscFwfm',
//       secretAccessKey: '0INcyGFzuOjAhbl15vyEzZE4we1LDZxjgxkqbgPT',
//     },
//     endpoint: "http://dev-apis3.xdp.vn",
//     region: "ap-southeast-1",
//     forcePathStyle: true
//     // signatureVersion: 'v4',
//     // endpoint: {
//     //     hostname: 'dev-s3.xdp.vn',
//     //     protocol: 'http',
//     //     path: '/expolander',
//     // },
//     // bucketEndpoint: false,
//   });
// const ReactS3Client = new S3(config);
const s3 = new S3({
	region: "us-east-1",
	credentials: {
		accessKeyId: '2t8ZBg4wKVEB9OscFwfm',
		secretAccessKey: '0INcyGFzuOjAhbl15vyEzZE4we1LDZxjgxkqbgPT',
	},
	endpoint: "http://dev-s3.xdp.vn",
	forcePathStyle: true
	//     region: "ap-southeast-1",
});

export const uploadToS3 = async (file) => {
	// var config = {
	//     accessKeyId: '2t8ZBg4wKVEB9OscFwfm',
	//     secretAccessKey: '0INcyGFzuOjAhbl15vyEzZE4we1LDZxjgxkqbgPT',
	//     endpoint: "dev-s3.xdp.vn",
	//     sslEnabled: false,
	//     s3ForcePathStyle: true
	//   };
	//   AWS.config.update(config);
	//   const s3 = new AWS.S3()
	// const s3 = new AWS.S3({
	//     endpoint: 'http://dev-s3.xdp.vn',
	//     accessKeyId: '2t8ZBg4wKVEB9OscFwfm',
	//     secretAccessKey: '0INcyGFzuOjAhbl15vyEzZE4we1LDZxjgxkqbgPT'
	// })
	const str = file.name.split(".")
	// const { createReadStream, filename, mimetype, encoding } = await file
	// const params = {
	//     Bucket: "expolander",
	//     Key: `${uuidv4()}.${str[str.length - 1]}`,
	//     Body: file,
	// };
	// s3.upload(params, (err, data) => {
	//     if (err) {
	//         console.error('Error uploading file:', err);
	//     } else {
	//         console.log(`File uploaded successfully. ${data.Location}`);
	//     }
	// });
	// fs.readFile('del.txt', function (err, data) {
	//     console.log(err, data)
	// })
	// ReactS3Client
	// .uploadFile(file, `${uuidv4()}.${str[str.length - 1]}`)
	// .then(data => console.log(data))
	// .catch(err => console.error(err))
	const params = {
		ACL: 'public-read',
		Body: file,
		Bucket: "expolander",
		Key: `${uuidv4()}.${str[str.length - 1]}`,
		"CORSConfiguration": {
			"CORSRules": [
				{
					"AllowedHeaders": [
						"*"
					],
					"AllowedMethods": [
						"PUT",
						"POST",
						"DELETE"
					],
					"AllowedOrigins": [
						"http://localhost:3000"
					],
					"ExposeHeaders": [
						"x-amz-server-side-encryption"
					],
					"MaxAgeSeconds": 3000
				},
				{
					"AllowedHeaders": [
						"Authorization"
					],
					"AllowedMethods": [
						"GET"
					],
					"AllowedOrigins": [
						"*"
					],
					"MaxAgeSeconds": 3000
				}
			]
		}
	};

	// myBucket.putObject(params)
	//     .on('httpUploadProgress', (evt) => {
	//         console.log(Math.round((evt.loaded / evt.total) * 100))
	//         // setProgress(Math.round((evt.loaded / evt.total) * 100))
	//     })
	//     .send((err) => {
	//         if (err) console.log(err)
	//     })
	// s3.putObject(params, (err) => {
	//     console.log(err)
	// });
	// const command = new PutObjectCommand(params);
	// const command = new ListObjectsCommand(params);

	// const data = await s3.send(command);
	// console.log(data)
	//     ReactS3Client
	//     .uploadFile(file, `${uuidv4()}.${str[str.length - 1]}`)
	//     .then(data => console.log(data))
	//     .catch(err => console.error(err))
	// s3
	//   .listBuckets(params)
	//   .then((data) => {
	//     // process data.
	//     console.log(data)
	//   })
	//   .catch((error) => {
	//     // error handling.
	//     console.error(error)
	//   });
	// s3.listBuckets(function (err, data) {
	//     if (err) {
	//         console.log("Error", err);
	//     } else {
	//         console.log("Success", data.Buckets);
	//     }
	// });
	// const data = await s3.listBuckets(params);
	const command = new PutObjectCommand(params);
	const data = await s3.send(command);
	console.log(data)
}


export const urlToFile = (image, callBack) => {
	try {
		fetch(`${BASE_S3_URL}${image}`, {
			mode: 'no-cors',
		})
			.then(response => response.blob())
			.then(data => {
				let metadata = {
					type: 'image/jpeg'
				}
				callBack(new File([data], "test.jpg", metadata))
			})
	} catch {
		callBack()
	}

}

export const getImage = async (image) => {
	try {
		return await fetch(`${BASE_S3_URL}${image}`)
	} catch {
		return null
	}
}

export const groupJson = (data) => {
	let result = {}
	data.map(o => {
		const json = JSON.parse(o)
		mergeWith(result, json, (objValue, srcValue) => {
			if (isArray(objValue)) {
				return objValue.concat(srcValue);
			}
		})
	})
	let _per = {}
	Object.keys(result).map(o => {
		_per[o] = {}
		result[o].map(x => {
			_per[o][x] = true
		})
	})
	return _per
}

export const checkPermission = (permission, code, rule) => {
	const state = store.getState()
	const _permission = state.userReducer?.permission
	let per = (permission ?? {})[code]
	return _permission == USER_PERMISSION.ADMIN || (per ?? {})[rule]
}

export const getPermission = (permission, code) => {
	let per = (permission ?? {})[code]
	return (per ?? {})
}

export const exportBusinessMatchingXlsx = (data, language) => {
	if (data.length) {
		let _first = data[0]
		let _users = []
		// _users.push(item.eventMeeting.senderId)
		data.map(o => {
			if (!find(_users, { id: o.fromUser.id })) {
				_users.push(o.fromUser)
			}
			o.fromUsers.map(x => {
				if (!find(_users, { id: x.id })) {
					_users.push(x)
				}
			})
		})
		let rs = []
		let arr = []
		data?.map(o => {
			let item = {}
			const companyAttributes = find(o.toCompany?.attributes, { languageCode: language })
			let status = find(MEET_STATUS.list, { id: o.eventMeeting.status })
			item[EXCEL_BUSINESS_MATCHING_CONFIG_FIELD.no] = o.toCompany.code
			item[EXCEL_BUSINESS_MATCHING_CONFIG_FIELD.date] = moment(o.eventMeeting.date).format("DD/MM/YYYY")
			item[EXCEL_BUSINESS_MATCHING_CONFIG_FIELD.to] = companyAttributes?.name
			item[EXCEL_BUSINESS_MATCHING_CONFIG_FIELD.industry] = (o.toCompany.industry ?? [])?.map(x => x.value).join(", ")
			item[EXCEL_BUSINESS_MATCHING_CONFIG_FIELD.website] = o.toCompany.website
			item[EXCEL_BUSINESS_MATCHING_CONFIG_FIELD.location] = o.toCompany.location?.name
			item[EXCEL_BUSINESS_MATCHING_CONFIG_FIELD.status] = ""
			arr.push(item)
		})
		rs.push({
			name: "Business matching",
			data: arr
		})
		console.log(rs)
		exportDataBusinessMatchingXlsx(rs, find(_first.toCompany.attributes, { languageCode: language })?.name)
	}
}

export const exportDataBusinessMatchingXlsx = (rs, name) => {
	var ns = XLSX.utils.book_new();
	ns.props = {
		title: "new excell sheet",
		subject: "test",
		Ather: "Shan",
		createdDate: Date.now(),
	};

	rs.map(o => {
		ns.SheetNames.push(o.name);
		var nb_data = [];
		let header = [
			EXCEL_BUSINESS_MATCHING_CONFIG_FIELD.no,
			EXCEL_BUSINESS_MATCHING_CONFIG_FIELD.date,
			EXCEL_BUSINESS_MATCHING_CONFIG_FIELD.to,
			EXCEL_BUSINESS_MATCHING_CONFIG_FIELD.industry,
			EXCEL_BUSINESS_MATCHING_CONFIG_FIELD.website,
			EXCEL_BUSINESS_MATCHING_CONFIG_FIELD.location,
			EXCEL_BUSINESS_MATCHING_CONFIG_FIELD.status,
		]

		nb_data.push(header)
		o.data.map(x => {
			let item = [
				x[EXCEL_BUSINESS_MATCHING_CONFIG_FIELD.no],
				x[EXCEL_BUSINESS_MATCHING_CONFIG_FIELD.date],
				x[EXCEL_BUSINESS_MATCHING_CONFIG_FIELD.to],
				x[EXCEL_BUSINESS_MATCHING_CONFIG_FIELD.industry],
				x[EXCEL_BUSINESS_MATCHING_CONFIG_FIELD.website],
				x[EXCEL_BUSINESS_MATCHING_CONFIG_FIELD.location],
				x[EXCEL_BUSINESS_MATCHING_CONFIG_FIELD.status],
			]

			nb_data.push(item)
		})
		var nb = XLSX.utils.aoa_to_sheet(nb_data);
		ns.Sheets[o.name] = nb;
	})
	var nbOut = XLSX.write(ns, { bookType: "xlsx", type: "binary" });
	saveAs(
		new Blob([saveBook(nbOut)], { type: "application/octet-stream" }),
		`Expolander-${name}-${moment().format("DD-MM-YYYY-HH-mm")}.xlsx`
	)
}

export const exportXlsx = (data, ranks) => {
	let rs = []
	_.remove(data.eventUsers, o => !o.userTypeNameImport)

	ranks.map(o => {
		let arr = []
		let arrAll = []
		let coms = filter(data.eventCompanies, { rankImport: o.name })
		coms = orderBy(coms, ["no"], ["asc"])
		coms.map(x => {
			let _users = filter(data.eventUsers, { companyId: x.companyId })
			_users = orderBy(_users, ["no"], ["asc"])
			_users.map((u, ui) => {
				const item = {}
				let names = []
				if (u?.firstName) {
					names.push(u?.firstName)
				}
				if (u?.lastName) {
					names.push(u?.lastName)
				}
				item[EXCEL_CONFIG_FIELD.passCode] = u.userNameImport
				item[EXCEL_CONFIG_FIELD.userEmail] = u.email
				item[EXCEL_CONFIG_FIELD.userFullName] = names.join(" ")
				item[EXCEL_CONFIG_FIELD.companyCode] = x?.companyImport
				item[EXCEL_CONFIG_FIELD.companyImport] = find((x?.attributes ?? []), { languageCode: 'vi' })?.name ?? find((x?.attributes ?? []), { languageCode: 'en' })?.name

				item[EXCEL_CONFIG_FIELD.userPosition] = u?.position
				item[EXCEL_CONFIG_FIELD.userPhone] = u?.phone
				item[EXCEL_CONFIG_FIELD.countryImport] = u.countryImport
				if (ui == 0) {
					arr.push(item)
				}
				arrAll.push(item)
			})
		})
		if (arr.length) {
			rs.push({
				name: i18n.t("common.company_sheet", { name: o.name }),
				data: arr
			})
			rs.push({
				name: i18n.t("common.company_all_sheet", { name: o.name }),
				data: arrAll
			})
		}

	})
	let _data = _.groupBy(data.eventUsers, "userTypeNameImport")
	Object.keys(_data).map(key => {
		let arr = []
		_data[key].map(o => {
			const item = {}

			let names = []
			if (o?.firstName) {
				names.push(o?.firstName)
			}
			if (o?.lastName) {
				names.push(o?.lastName)
			}
			let company = find(data?.eventCompanies, { companyId: o.companyId })
			// let companyName = find((company?.attributes ?? []), { languageCode: 'vi' })?.name
			// let rootCompany = find(data?.companies, { name: companyName })
			// if (!companyName) {
			// 	companyName = find((company?.attributes ?? []), { languageCode: 'en' })?.name
			// 	if (!rootCompany) {
			// 		rootCompany = find(data?.companies, { name: companyName })
			// 	}
			// }
			// let comInfo = find((company?.attributes ?? []), { languageCode: 'vi' })
			// let comLocation = company?.locations?.length ? company?.locations[0] : null

			// let eventOffers = filter(data?.eventOfferAttributes, { emailImport: o.email })
			// let offerIndustry = filter(data?.offerAttributes, x => x.refType == EnumEventModule.INDUSTRY && find(eventOffers, { offerAttributeIdImport: x.name })).map(v => v.name)
			// let offerProduct = filter(data?.offerAttributes, x => x.refType == EnumEventModule.PRODUCT && find(eventOffers, { offerAttributeIdImport: x.name })).map(v => v.name)

			item[EXCEL_CONFIG_FIELD.passCode] = o.userNameImport
			item[EXCEL_CONFIG_FIELD.userEmail] = o.email
			item[EXCEL_CONFIG_FIELD.userFullName] = names.join(" ")
			item[EXCEL_CONFIG_FIELD.companyCode] = company?.companyImport
			item[EXCEL_CONFIG_FIELD.companyImport] = find((company?.attributes ?? []), { languageCode: 'vi' })?.name ?? find((company?.attributes ?? []), { languageCode: 'en' })?.name

			item[EXCEL_CONFIG_FIELD.userPosition] = o?.position
			item[EXCEL_CONFIG_FIELD.userPhone] = o?.phone
			item[EXCEL_CONFIG_FIELD.countryImport] = o.countryImport
			// item[EXCEL_CONFIG_FIELD.userIndustry] = offerIndustry.join(", ")
			// item[EXCEL_CONFIG_FIELD.userProduct] = offerProduct.join(", ")
			arr.push(item)
		})
		rs.push({
			name: key,
			data: arr
		})
	})

	console.log(rs)

	exportDataXlsx(rs)
}

export const exportCompanyDataXlsx = (rs) => {
	var ns = XLSX.utils.book_new();
	ns.props = {
		title: "new excell sheet",
		subject: "test",
		Ather: "Shan",
		createdDate: Date.now(),
	};

	rs.map(o => {
		ns.SheetNames.push(o.name);
		var nb_data = [];
		let header = []
		header = [
			EXCEL_CONFIG_FIELD.companyCode,
			EXCEL_CONFIG_FIELD.companyImportEn,
			EXCEL_CONFIG_FIELD.companyImportVi,
			EXCEL_CONFIG_FIELD.countryImport,
			EXCEL_CONFIG_FIELD.companyDescriptionEn,
			EXCEL_CONFIG_FIELD.companyDescriptionVi,
			EXCEL_CONFIG_FIELD.companyWebsite,
			EXCEL_CONFIG_FIELD.companyPhone,
			EXCEL_CONFIG_FIELD.companyEmail,
			EXCEL_CONFIG_FIELD.companyVideo,
			EXCEL_CONFIG_FIELD.mapsImport,
			EXCEL_CONFIG_FIELD.locationIdsImport,
			EXCEL_CONFIG_FIELD.companyRank,
			EXCEL_CONFIG_FIELD.companyIndustry,
			EXCEL_CONFIG_FIELD.companyIndustryEn,
			EXCEL_CONFIG_FIELD.companyProduct,
			EXCEL_CONFIG_FIELD.companyProductEn,
		]
		nb_data.push(header)
		o.data.map(x => {
			let item = []
			item = [
				x[EXCEL_CONFIG_FIELD.companyCode],
				x[EXCEL_CONFIG_FIELD.companyImportEn],
				x[EXCEL_CONFIG_FIELD.companyImportVi],
				x[EXCEL_CONFIG_FIELD.countryImport],
				x[EXCEL_CONFIG_FIELD.companyDescriptionEn],
				x[EXCEL_CONFIG_FIELD.companyDescriptionVi],
				x[EXCEL_CONFIG_FIELD.companyWebsite],
				x[EXCEL_CONFIG_FIELD.companyPhone],
				x[EXCEL_CONFIG_FIELD.companyEmail],
				x[EXCEL_CONFIG_FIELD.companyVideo],
				x[EXCEL_CONFIG_FIELD.mapsImport],
				x[EXCEL_CONFIG_FIELD.locationIdsImport],
				x[EXCEL_CONFIG_FIELD.companyRank],
				x[EXCEL_CONFIG_FIELD.companyIndustry],
				x[EXCEL_CONFIG_FIELD.companyIndustryEn],
				x[EXCEL_CONFIG_FIELD.companyProduct],
				x[EXCEL_CONFIG_FIELD.companyProductEn],
			]
			nb_data.push(item)
		})
		var nb = XLSX.utils.aoa_to_sheet(nb_data);
		ns.Sheets[o.name] = nb;
	})
	var nbOut = XLSX.write(ns, { bookType: "xlsx", type: "binary" });
	saveAs(
		new Blob([saveBook(nbOut)], { type: "application/octet-stream" }),
		`Expolander-${moment().format("DD-MM-YYYY-HH-mm")}.xlsx`
	)
}

export const exportDataXlsx = (rs) => {
	var ns = XLSX.utils.book_new();
	ns.props = {
		title: "new excell sheet",
		subject: "test",
		Ather: "Shan",
		createdDate: Date.now(),
	};

	rs.map(o => {
		ns.SheetNames.push(o.name);
		var nb_data = [];
		let header = [
			EXCEL_CONFIG_FIELD.passCode,
			EXCEL_CONFIG_FIELD.userEmail,
			EXCEL_CONFIG_FIELD.userFullName,
			EXCEL_CONFIG_FIELD.companyCode,
			EXCEL_CONFIG_FIELD.companyImport,
			EXCEL_CONFIG_FIELD.userPosition,
			EXCEL_CONFIG_FIELD.userPhone,
			EXCEL_CONFIG_FIELD.countryImport,
		]
		nb_data.push(header)
		o.data.map(x => {
			let item = [
				x[EXCEL_CONFIG_FIELD.passCode],
				x[EXCEL_CONFIG_FIELD.userEmail],
				x[EXCEL_CONFIG_FIELD.userFullName],
				x[EXCEL_CONFIG_FIELD.companyCode],
				x[EXCEL_CONFIG_FIELD.companyImport],
				x[EXCEL_CONFIG_FIELD.userPosition],
				x[EXCEL_CONFIG_FIELD.userPhone],
				x[EXCEL_CONFIG_FIELD.countryImport],

			]

			nb_data.push(item)
		})
		var nb = XLSX.utils.aoa_to_sheet(nb_data);
		ns.Sheets[o.name] = nb;
	})
	var nbOut = XLSX.write(ns, { bookType: "xlsx", type: "binary" });
	saveAs(
		new Blob([saveBook(nbOut)], { type: "application/octet-stream" }),
		`Expolander-${moment().format("DD-MM-YYYY-HH-mm")}.xlsx`
	)
}

const saveBook = (s) => {
	var buf = new ArrayBuffer(s.length);
	var view = new Uint8Array(buf);
	for (var i = 0; i < s.length; i++) {
		view[i] = s.charCodeAt(i) & 0xff;
	}
	return buf;
}

export const convertCompanyExcelData = (data, maxNo) => {
	let rs = {
		offerAttributes: [],
		eventCompanies: [],
		eventCompanyOfferAttributes: []
	}
	data.map(o => {
		// const type = find(EXCEL_CONFIG, { name: o.name })
		if (o.data.length) {
			let no = maxNo + 1
			o.data.map(item => {
				// 
				if (item[EXCEL_CONFIG_FIELD.companyIndustry] || item[EXCEL_CONFIG_FIELD.companyIndustryEn]) {
					let _industries = item[EXCEL_CONFIG_FIELD.companyIndustry].split(", ")
					let _industriesEn = item[EXCEL_CONFIG_FIELD.companyIndustryEn]?.split(", ")
					const _mapIn = (_industries?.length ?? 0) >= (_industriesEn?.length ?? 0) ? _industries : _industriesEn
					_mapIn.map((offer, i) => {
						if (!find(rs.offerAttributes, { refType: EnumEventModule.INDUSTRY, name: offer })) {
							rs.offerAttributes.push({
								refType: EnumEventModule.INDUSTRY,
								attributes: [{
									languageCode: "vi",
									name: _industries && _industries[i] ? _industries[i] : offer
								}, {
									languageCode: "en",
									name: _industriesEn && _industriesEn[i] ? _industriesEn[i] : offer
								}],
							})
						}
						if (!find(rs.eventCompanyOfferAttributes, { companyImport: item[EXCEL_CONFIG_FIELD.companyCode], tags: offer })) {
							rs.eventCompanyOfferAttributes.push({
								type: EnumEventModule.COMPANIES_INDUSTRY,
								tags: offer,
								companyImport: item[EXCEL_CONFIG_FIELD.companyCode],
								isMain: true,
								offerAttributeIdImport: offer
							})
						}
					})
				}

				// 
				if (item[EXCEL_CONFIG_FIELD.companyProduct] || item[EXCEL_CONFIG_FIELD.companyProductEn]) {
					let _products = item[EXCEL_CONFIG_FIELD.companyProduct].split(", ")
					let _productsEn = item[EXCEL_CONFIG_FIELD.companyProductEn]?.split(", ")
					const _mapPr = (_products?.length ?? 0) >= (_productsEn?.length ?? 0) ? _products : _productsEn
					_mapPr.map((offer, i) => {
						if (!find(rs.offerAttributes, { refType: EnumEventModule.PRODUCT, name: offer })) {
							rs.offerAttributes.push({
								refType: EnumEventModule.PRODUCT,
								attributes: [{
									languageCode: "vi",
									name: _products && _products[i] ? _products[i] : offer
								}, {
									languageCode: "en",
									name: _productsEn && _productsEn[i] ? _productsEn[i] : offer
								}],
							})
						}
						if (!find(rs.eventCompanyOfferAttributes, { companyImport: item[EXCEL_CONFIG_FIELD.companyCode], tags: offer })) {
							rs.eventCompanyOfferAttributes.push({
								type: EnumEventModule.COMPANIES_PRODUCT,
								tags: offer,
								companyImport: item[EXCEL_CONFIG_FIELD.companyCode],
								isMain: true,
								offerAttributeIdImport: offer
							})
						}
					})
				}


				//
				if (item[EXCEL_CONFIG_FIELD.companyCode] && !find(rs.eventCompanies, { code: item[EXCEL_CONFIG_FIELD.companyCode] })) {

					const _eventCompany = {
						no,
						// sortOrder: 100,
						website: item[EXCEL_CONFIG_FIELD.companyWebsite],
						limitedUsers: 5,
						rankImport: item[EXCEL_CONFIG_FIELD.companyRank],
						countryImport: item[EXCEL_CONFIG_FIELD.countryImport],
						attributes: [{
							languageCode: "vi",
							name: item[EXCEL_CONFIG_FIELD.companyImportVi] ? item[EXCEL_CONFIG_FIELD.companyImportVi] : item[EXCEL_CONFIG_FIELD.companyImportEn],
							description: item[EXCEL_CONFIG_FIELD.companyDescriptionVi] ? item[EXCEL_CONFIG_FIELD.companyDescriptionVi] : item[EXCEL_CONFIG_FIELD.companyDescriptionEn]
						}, {
							languageCode: "en",
							name: item[EXCEL_CONFIG_FIELD.companyImportEn] ? item[EXCEL_CONFIG_FIELD.companyImportEn] : item[EXCEL_CONFIG_FIELD.companyImportVi],
							description: item[EXCEL_CONFIG_FIELD.companyDescriptionEn] ? item[EXCEL_CONFIG_FIELD.companyDescriptionEn] : item[EXCEL_CONFIG_FIELD.companyDescriptionVi]
						}],
						locations: [],
						code: item[EXCEL_CONFIG_FIELD.companyCode],
						phone: item[EXCEL_CONFIG_FIELD.companyPhone],
						email: item[EXCEL_CONFIG_FIELD.companyEmail]
					}
					if (item[EXCEL_CONFIG_FIELD.mapsImport] && item[EXCEL_CONFIG_FIELD.locationIdsImport]) {

						_eventCompany.locations.push({
							mapsImport: item[EXCEL_CONFIG_FIELD.mapsImport],
							locationIdsImport: item[EXCEL_CONFIG_FIELD.locationIdsImport].toString().split(", ")
						})
					}
					no++
					rs.eventCompanies.push(_eventCompany)
				}
			})

		}
	})
	console.log(rs)
	return rs
}

export const convertExcelData = (data, eventId, maxNo) => {
	let rs = {
		offerAttributes: [],
		// companies: [],
		// eventCompanies: [],
		eventUsers: [],
		// speakers: [],
		eventOfferAttributes: [],
		// eventCompanyOfferAttributes: []
	}
	let no = maxNo + 1
	data.map(o => {
		// const type = find(EXCEL_CONFIG, { name: o.name })
		if (o.data.length) {
			o.data.map(item => {
				// 
				if (item[EXCEL_CONFIG_FIELD.userIndustry]) {
					let _industries = item[EXCEL_CONFIG_FIELD.userIndustry].split(", ")
					let _industriesEn = item[EXCEL_CONFIG_FIELD.userIndustryEn]?.split(", ")
					const _mapIn = (_industries?.length ?? 0) >= (_industriesEn?.length ?? 0) ? _industries : _industriesEn
					_mapIn.map((offer, i) => {
						if (!find(rs.offerAttributes, { refType: EnumEventModule.INDUSTRY, name: offer })) {
							rs.offerAttributes.push({
								refType: EnumEventModule.INDUSTRY,
								attributes: [{
									languageCode: "vi",
									name: _industries && _industries[i] ? _industries[i] : offer
								}, {
									languageCode: "en",
									name: _industriesEn && _industriesEn[i] ? _industriesEn[i] : offer
								}],
							})
						}
						if (!find(rs.eventOfferAttributes, { emailImport: item[EXCEL_CONFIG_FIELD.userEmail], tags: offer })) {
							rs.eventOfferAttributes.push({
								type: EnumEventModule.USER,
								tags: offer,
								emailImport: item[EXCEL_CONFIG_FIELD.userEmail],
								isMain: true,
								offerAttributeIdImport: offer
							})
						}
					})
				}

				// 
				if (item[EXCEL_CONFIG_FIELD.userProduct]) {
					let _products = item[EXCEL_CONFIG_FIELD.userProduct].split(", ")
					let _productsEn = item[EXCEL_CONFIG_FIELD.userProductEn]?.split(", ")
					const _mapPr = (_products?.length ?? 0) >= (_productsEn?.length ?? 0) ? _products : _productsEn
					_mapPr.map((offer, i) => {
						if (!find(rs.offerAttributes, { refType: EnumEventModule.PRODUCT, name: offer })) {
							rs.offerAttributes.push({
								refType: EnumEventModule.PRODUCT,
								attributes: [{
									languageCode: "vi",
									name: _products && _products[i] ? _products[i] : offer
								}, {
									languageCode: "en",
									name: _productsEn && _productsEn[i] ? _productsEn[i] : offer
								}],
							})
						}
						if (!find(rs.eventOfferAttributes, { emailImport: item[EXCEL_CONFIG_FIELD.userEmail], tags: offer })) {
							rs.eventOfferAttributes.push({
								type: EnumEventModule.USER,
								tags: offer,
								emailImport: item[EXCEL_CONFIG_FIELD.userEmail],
								isMain: true,
								offerAttributeIdImport: offer
							})
						}
					})
				}

				//
				if (!find(rs.eventUsers, { email: item[EXCEL_CONFIG_FIELD.userEmail] })) {
					const names = item[EXCEL_CONFIG_FIELD.userFullName] ? item[EXCEL_CONFIG_FIELD.userFullName].split(" ") : ""
					const last = []
					names.map((n, ni) => {
						if (ni > 0) {
							last.push(n)
						}
					})
					rs.eventUsers.push({
						no,
						firstName: names?.length ? names[0] : "",
						lastName: last.join(" "),
						position: item[EXCEL_CONFIG_FIELD.userPosition],
						email: item[EXCEL_CONFIG_FIELD.userEmail],
						userType: item[EXCEL_CONFIG_FIELD.companyImport] ? USER_TYPE.COMPANY : USER_TYPE.GUEST,
						phone: item[EXCEL_CONFIG_FIELD.userPhone],
						eventId: eventId,
						// userNameImport: item[EXCEL_CONFIG_FIELD.userFullName],
						countryImport: item[EXCEL_CONFIG_FIELD.countryImport],
						companyImport: item[EXCEL_CONFIG_FIELD.companyCode],
						userTypeNameImport: o.name
					})
					no++
				}
			})

		}
	})
	console.log(rs)
	return rs
}

export const removeAccents = (str) => {
	var AccentsMap = [
		"aàảãáạăằẳẵắặâầẩẫấậ",
		"AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ",
		"dđ", "DĐ",
		"eèẻẽéẹêềểễếệ",
		"EÈẺẼÉẸÊỀỂỄẾỆ",
		"iìỉĩíị",
		"IÌỈĨÍỊ",
		"oòỏõóọôồổỗốộơờởỡớợ",
		"OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ",
		"uùủũúụưừửữứự",
		"UÙỦŨÚỤƯỪỬỮỨỰ",
		"yỳỷỹýỵ",
		"YỲỶỸÝỴ"
	];
	for (var i = 0; i < AccentsMap.length; i++) {

		var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
		var char = AccentsMap[i][0];

		str = str.replace(re, char);
	}
	str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "")
	str = str.replace(/\u02C6|\u0306|\u031B/g, "")
	return str.toLowerCase();
}

export const findAttribute = (list, _key, keys = [], attKey) => {
	let key = _key?.trim()
	if (key) {
		return list.filter(o => {

			let x = false
			if (keys) {
				for (let i = 0; i < keys.length; i++) {
					if (o[keys[i]]) {
						x = removeAccents(o[keys[i]]).toLowerCase().indexOf(removeAccents(key).toLowerCase()) != -1
						if (x) {
							return true
						}
					}
				}
			}
			if (o[attKey ?? "attributes"]) {
				for (let i = 0; i < o[attKey ?? "attributes"].length; i++) {
					x = removeAccents(o[attKey ?? "attributes"][i].name).toLowerCase().indexOf(removeAccents(key).toLowerCase()) != -1
					if (x) {
						return true
					}
				}
			}

			return false
		})
	}
	return list
}

export const findKeyword = (list, _key, keys = [], subKeys = []) => {
	let key = _key?.trim()
	if (key) {
		return list.filter(o => {

			let x = false
			if (keys) {
				for (let i = 0; i < keys.length; i++) {
					if (o[keys[i]]) {
						if (subKeys?.length) {
							for (let j = 0; j < subKeys.length; j++) {
								if (o[keys[i]][subKeys[j]]) {
									x = removeAccents(o[keys[i]][subKeys[j]]).toLowerCase().indexOf(removeAccents(key).toLowerCase()) != -1
									if (x) {
										return true
									}
								}
							}
						} else {
							x = removeAccents(o[keys[i]]).toLowerCase().indexOf(removeAccents(key).toLowerCase()) != -1
							if (x) {
								return true
							}
						}

					}
				}
			}
			return false
		})
	}
	return list
}

export const exportDoc = async (data, eventName, language) => {
	if (data.length) {
		let _first = data[0]
		let _users = []
		// _users.push(item.eventMeeting.senderId)
		data.map(o => {
			if (!find(_users, { id: o.fromUser.id })) {
				_users.push(o.fromUser)
			}
			o.fromUsers.map(x => {
				if (!find(_users, { id: x.id })) {
					_users.push(x)
				}
			})
		})
		const response = await fetch(`${process.env.REACT_APP_ROUTE_BASE_NAME}/template_expo.docx`);

		const templateFile = await response.blob();
		const dataMap = {
			names: _users.map(o => ({
				name: o.fullName
			})),
			eventName: eventName,
			companyName: find(_first.fromCompany.attributes, { languageCode: language })?.name,
			table1: data.map(o => ({
				code: o.toCompany.code,
				date: moment(o.eventMeeting.date).format("DD/MM/YYYY"),
				company: find(o.toCompany.attributes, { languageCode: language })?.name,
				industry: (o.toCompany.industry ?? [])?.map(x => x.value).join(", "),
				website: o.toCompany.website,
				location: o.toCompany.location?.name,
				status: ""
			}))

		};
		const handler = new TemplateHandler();
		const doc = await handler.process(templateFile, dataMap);
		saveAs(doc, 'output.docx');

	}

}