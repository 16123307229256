
export const REQUEST_GET_MASTERDATA_LANGUAGE = `REQUEST_GET_MASTERDATA_LANGUAGE`
export const REQUEST_GET_MASTERDATA_LANGUAGE_SUCCESS = `REQUEST_GET_MASTERDATA_LANGUAGE_SUCCESS`
export function getMasterDataLanguage(params, callBack) {
    return {
        type: REQUEST_GET_MASTERDATA_LANGUAGE,
        params,
        callBack
    };
}

export const REQUEST_GET_COUNTRIES = `REQUEST_GET_COUNTRIES`
export const REQUEST_GET_COUNTRIES_SUCCESS = `REQUEST_GET_COUNTRIES_SUCCESS`
export function getCountries(params, callBack) {
    return {
        type: REQUEST_GET_COUNTRIES,
        params,
        callBack
    };
}