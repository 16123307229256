import axiosClient from "./axiosClient";

const ChatSercive = {
  getToken: () => {
    return axiosClient.get(`/chat/token`);
  },
  channel: {
    create: (payload) => {
      return axiosClient.post(`/chat/channel`, payload);
    },
    update: (id, headers) => {
      return axiosClient.put(`/chat/channel/company/${id}`, null, {
        headers: headers
      });
    }
  }
}

export default ChatSercive