import axiosClient from "./axiosClient";

const EventsServices = {
    search: (payload) => {
        return axiosClient.get(`/event`, {
            params: payload
        });
    },

    getById: (id) => {
        return axiosClient.get(`/event/${id}`);
    },

    create: (payload) => {
        return axiosClient.post(`/event`, payload);
    },

    update: (payload) => {
        return axiosClient.put(`/event/${payload.id}`, payload);
    },

    delete: (id) => {
        return axiosClient.delete(`/event/${id}`);
    },

    changeState: (eventId, id) => {
        return axiosClient.put(`/event/${eventId}/state/${id}`);
    },

    //companyOfEvent
    //childId là id của Công ty
    searchCompany: (eventId, payload) => {
        return axiosClient.get(`/event/${eventId}/company`, {
            params: payload
        });
    },

    getByIdCompany: (eventId, childId) => {
        return axiosClient.get(`/event/${eventId}/company/${childId}`);
    },
    getCompanyNo: (eventId) => {
        return axiosClient.get(`/event/${eventId}/company/no`);
    },

    createCompany: (eventId, payload) => {
        return axiosClient.post(`/event/${eventId}/company`, payload);
    },

    updateCompany: (eventId, payload) => {
        return axiosClient.put(`/event/${eventId}/company/${payload.id}`, payload);
    },

    deleteCompany: (eventId, childId) => {
        return axiosClient.delete(`/event/${eventId}/company/${childId}`);
    },
    companyArrange: {
        get: (eventId, payload) => {
            return axiosClient.get(`/event/${eventId}/company/arrange`, {
                params: payload
            });
        },
        create: (eventId, payload) => {
            return axiosClient.post(`/event/${eventId}/company/arrange`, payload);
        },
        delete: (id, eventId) => {
            return axiosClient.delete(`/event/${eventId}/company/arrange/${id}`);
        },
    },

    //productOfEvent
    searchProduct: (eventId) => {
        return axiosClient.get(`/event/${eventId}/product`);
    },

    getByIdProduct: (eventId, childId) => {
        return axiosClient.get(`/event/${eventId}/product/${childId}`);
    },

    createProduct: (eventId, payload) => {
        return axiosClient.post(`/event/${eventId}/product`, payload);
    },

    updateProduct: (eventId, payload) => {
        return axiosClient.put(`/event/${eventId}/product/${payload.id}`, payload);
    },

    deleteProduct: (eventId, childId) => {
        return axiosClient.delete(`/event/${eventId}/product/${childId}`);
    },

    //Map
    searchMap: (payload) => {
        return axiosClient.get(`/event/${payload?.eventId}/maps`);
    },
    getMapById: (id, payload) => {
        return axiosClient.get(`/event/${payload?.eventId}/maps/${id}`);
    },
    createMap: (payload) => {
        return axiosClient.post(`/event/${payload?.eventId}/maps`, payload);
    },

    updateMap: (id, payload) => {
        return axiosClient.put(`/event/${payload?.eventId}/maps/${id}`, payload);
    },

    deleteMap: (id, payload) => {
        return axiosClient.delete(`/event/${payload?.eventId}/maps/${id}`);
    },

    searchMapLocation: (payload) => {
        return axiosClient.get(`/event/${payload?.eventId}/maps/${payload.type}/${payload.refId}/locations`);
    },
    getMapLocationById: (id, payload) => {
        return axiosClient.get(`/event/${payload?.eventId}/maps/${payload.type}/${payload.refId}/location/${id}`);
    },
    createMapLocation: (payload) => {
        return axiosClient.post(`/event/${payload?.eventId}/maps/${payload.type}/${payload.refId}/location`, payload);
    },

    updateMapLocation: (id, payload) => {
        return axiosClient.put(`/event/${payload?.eventId}/maps/${payload.type}/${payload.refId}/location/${id}`, payload);
    },

    deleteMapLocation: (id, payload) => {
        return axiosClient.delete(`/event/${payload?.eventId}/maps/${payload.type}/${payload.refId}/location/${id}`);
    },

    // Session 
    session: {
        get: (eventId) => {
            return axiosClient.get(`/event/${eventId}/session`);
        },

        getById: (eventId, id) => {
            return axiosClient.get(`/event/${eventId}/session/${id}`);
        },

        create: (eventId, payload) => {
            return axiosClient.post(`/event/${eventId}/session`, payload);
        },

        update: (eventId, id, payload) => {
            return axiosClient.put(`/event/${eventId}/session/${id}`, payload);
        },

        delete: (eventId, id) => {
            return axiosClient.delete(`/event/${eventId}/session/${id}`);
        },
    },
    //Permalink
    permalink: {
        get: (payload) => {
            return axiosClient.get(`event/${payload.eventId}/permalinks/${payload.refType}/${payload.refId}`);
        },
        post: (payload) => {
            return axiosClient.post(`event/${payload.eventId}/permalinks/${payload.refType}/${payload.refId}`, payload);
        },
        put: (id, payload) => {
            return axiosClient.put(`event/${payload.eventId}/permalinks/${payload.refType}/${payload.refId}/${id}`, payload);
        },
        delete: (id, payload) => {
            return axiosClient.delete(`event/${payload.eventId}/permalinks/${payload.refType}/${payload.refId}/${id}`);
        },
    },
    //Notification
    notification: {
        get: (payload) => {
            // return axiosClient.get(`/notifications?Keyword=${payload.keyword}&Page=${payload.page}&Size=${payload.size}`);
            return axiosClient.get(`/notifications`, {
                params: payload
            });
        },
        getById: (id) => {
            return axiosClient.get(`/notifications/${id}`);
        },
        post: (payload) => {
            return axiosClient.post(`/notifications`, payload);
        },
        put: (id, payload) => {
            return axiosClient.put(`/notifications/${id}`, payload);
        },
        delete: (id, payload) => {
            return axiosClient.delete(`/notifications/${id}`);
        },

        postGuest: (payload) => {
            return axiosClient.post(`/notifications/guest`, payload);
        },
        postEmail: (payload) => {
            return axiosClient.post(`/notifications/email`, payload);
        },
    },
    report: {
        getAudience: (id) => {
            return axiosClient.get(`reports/${id}/audience`);
        },
        getInteractive: (id,type) => {
            return axiosClient.get(`/reports/${id}/${type}/interactive`)
        },
        getSession: (id) => {
            return axiosClient.get(`reports/${id}/session`);
        },
        getScan: (id,type) => {
            return axiosClient.get(`/reports/${id}/${type}/scan`)
        },
        getMeeting: (id, headers) => {
            return axiosClient.get(`reports/${id}/meeting`, {
                headers: headers
            });
        },
        getMeetingDetail: (id, headers) => {
            return axiosClient.get(`reports/${id}/meeting/detail`, {
                headers: headers
            });
        },
        getCheckin: (id, headers) => {
            return axiosClient.get(`reports/${id}/checkin`, {
                headers: headers
            });
        },
        getCheckinByDate: (id, payload) => {
            return axiosClient.get(`reports/${id}/checkin/date`, {
                params: payload
            });
        },
        getCheckinByUser: (id, payload) => {
            return axiosClient.get(`reports/${id}/checkin/user`, {
                params: payload
            });
        },
        getLogin: (id) => {
            return axiosClient.get(`reports/${id}/login`);
        },
        getLoginUser: (id, eventId) => {
            return axiosClient.get(`reports/${eventId}/login/${id}`);
        },
        getSearch: (id) => {
            return axiosClient.get(`reports/${id}/search`);
        }

    },
    // offer-attribute
    offerAttribute: {
        filter: (eventId, payload) => {
            return axiosClient.post(`/event/${eventId}/offer_attribute/filter`, payload);
        },
        create: (eventId, payload) => {
            return axiosClient.post(`/event/${eventId}/offer_attribute`, payload);
        },
        update: (id, eventId, payload) => {
            return axiosClient.put(`/event/${eventId}/offer_attribute/${id}`, payload);
        },
        delete: (id, eventId) => {
            return axiosClient.delete(`/event/${eventId}/offer_attribute/${id}`);
        },
    },

    searchOfferAttribute: (type, refId) => {
        return axiosClient.get(`/event/offer_attribute/${type}/${refId}`);
    },

    getByIdOfferAttribute: (type, refId, id) => {
        return axiosClient.get(`/event/offer_attribute/${type}/${refId}/${id}`);
    },

    createOfferAttribute: (type, refId, payload) => {
        return axiosClient.post(`/event/offer_attribute/${type}/${refId}`, payload);
    },

    updateOfferAttribute: (type, refId, payload) => {
        return axiosClient.put(`/event/offer_attribute/${type}/${refId}/${payload.id}`, payload);
    },

    deleteOfferAttribute: (type, refId, id) => {
        return axiosClient.delete(`/event/offer_attribute/${type}/${refId}/${id}`);
    },

    // file
    searchFile: (type, refId) => {
        return axiosClient.get(`/event/files/${type}/${refId}`);
    },

    getByIdFile: (type, refId, id) => {
        return axiosClient.get(`/event/files/${type}/${refId}/${id}`);
    },

    createFile: (type, refId, payload) => {
        return axiosClient.post(`/event/files/${type}/${refId}`, payload);
    },

    updateFile: (type, refId, payload) => {
        return axiosClient.put(`/event/files/${type}/${refId}/${payload.id}`, payload);
    },

    deleteFile: (type, refId, id) => {
        return axiosClient.delete(`/event/files/${type}/${refId}/${id}`);
    },

    //banner
    searchBanner: (payload) => {
        return axiosClient.get(`/event/${payload?.eventId}/banners`);
    },
    getBannerById: (id, payload) => {
        return axiosClient.get(`/event/${payload?.eventId}/banner/${id}`);
    },
    createBanner: (payload) => {
        return axiosClient.post(`/event/${payload?.eventId}/banner`, payload);
    },

    updateBanner: (id, payload) => {
        return axiosClient.put(`/event/${payload?.eventId}/banner/${id}`, payload);
    },

    deleteBanner: (id, payload) => {
        return axiosClient.delete(`/event/${payload?.eventId}/banner/${id}`);
    },

    // sepaler
    searchEventSpeaker: (refId, idEvent) => {
        return axiosClient.get(`/event/${idEvent}/session/${refId}/speakers`);
    },

    getByIdEventSpeaker: (refId, id, idEvent) => {
        return axiosClient.get(`/event/${idEvent}/session/${refId}/speaker/${id}`);
    },

    createEventSpeaker: (refId, payload, idEvent) => {
        return axiosClient.post(`/event/${idEvent}/session/${refId}/speaker`, payload);
    },

    updateEventSpeaker: (refId, payload, idEvent) => {
        return axiosClient.put(`/event/${idEvent}/session/${refId}/speaker/${payload.id}`, payload);
    },

    deleteEventSpeaker: (refId, id, idEvent) => {
        return axiosClient.delete(`/event/${idEvent}/session/${refId}/speaker/${id}`);
    },
    userType: {
        get: (eventId) => {
            return axiosClient.get(`/event/${eventId}/user_type`);
        },

        create: (eventId, payload) => {
            return axiosClient.post(`/event/${eventId}/user_type`, payload);
        },

        update: (id, eventId, payload) => {
            return axiosClient.put(`/event/${eventId}/user_type/${id}`, payload);
        },

        delete: (id, eventId) => {
            return axiosClient.delete(`/event/${eventId}/user_type/${id}`);
        },
    }

}

export default EventsServices