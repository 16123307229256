import { all, fork } from "redux-saga/effects";
import UserSaga from "./userSaga";
import CompanySaga from "./companySaga";
import EventsSaga from "./eventsSaga";
import MasterDataSaga from "./masterDataSaga";

export default function* rootSaga() {
  yield all([fork(UserSaga)]);
  yield all([fork(CompanySaga)]);
  yield all([fork(EventsSaga)]);
  yield all([fork(MasterDataSaga)]);
}
