import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link } from "react-router-dom";
import EventsServices from "../../core/services/EventsServices";
import { ROUTE, ROUTE_SCREEN } from "../../utils/constants";
import { useLocation, matchPath, matchRoutes, useParams } from 'react-router';
import { useSelector } from "react-redux";
import { find } from 'lodash'

function EBreadCrumb({ }, ref) {
    const { t } = useTranslation()

    const { language } = useSelector((state) => state.userReducer)

    const location = useLocation();

    const logRef = useRef()

    const [event, setEvent] = useState()

    const [refName, setRefName] = useState()

    const routeX = useMemo(() => {
        const x = matchRoutes(Object.keys(ROUTE).map(k => ({
            path: ROUTE[k]
        })), location.pathname)
        return x?.length ? x[0] : null
    }, [location])

    const { eventId, refId, screenId } = (routeX?.params ?? {})

    useEffect(() => {
        if (eventId) {
            loadEvent()
        }
    }, [eventId])

    useEffect(() => {
        if (refId) {
            if (matchPath({ path: ROUTE.eventCompanyInfoScreen }, location.pathname) || matchPath({ path: ROUTE.eventCompanyInfo }, location.pathname)) {
                loadCompany(refId)
            }
            if (matchPath({ path: ROUTE.eventSessionInfo }, location.pathname) || matchPath({ path: ROUTE.eventSessionInfoScreen }, location.pathname)) {
                loadSession(refId)
            }
            if (matchPath({ path: ROUTE.eventProductInfo }, location.pathname) || matchPath({ path: ROUTE.eventProductInfoScreen }, location.pathname)) {
                loadProduct(refId)
            }
        }
    }, [refId])

    const loadEvent = () => {
        const logData = getLog("event", eventId)
        if (logData) {
            setEvent(logData)
            return
        }
        EventsServices.getById(eventId).then(res => {
            if (res) {
                setEvent(res)
                setLog("event", eventId, res)
            }
        })
    }

    const loadCompany = (_refId) => {
        const logData = getLog("company", _refId)
        if (logData) {
            setRefName(logData)
            return
        }
        EventsServices.getByIdCompany(eventId, _refId).then(res => {
            if (res?.attributes?.length) {
                let _info = find(res?.attributes, {languageCode: language})
                setRefName(_info.name)
                setLog("company", _refId, _info.name)
            }
        })
    }
    const loadSession = (_refId) => {
        const logData = getLog("session", _refId)
        if (logData) {
            setRefName(logData)
            return
        }
        EventsServices.session.getById(eventId, _refId).then(res => {
            if (res?.attributes?.length) {
                let _info = find(res?.attributes, {languageCode: language})
                setRefName(_info.name)
                setLog("session", _refId, _info.name)
            }
        })
    }
    const loadProduct = (_refId) => {
        const logData = getLog("product", _refId)
        if (logData) {
            setRefName(logData)
            return
        }
        EventsServices.getByIdProduct(eventId, _refId).then(res => {
            if (res?.attributes?.length) {
                let _info = find(res?.attributes, {languageCode: language})
                setRefName(_info.name)
                setLog("product", _refId, _info.name)
            }
        })
    }

    const setLog = (name, key, val) => {
        if (!logRef.current) {
            logRef.current = {}
        }
        if (!logRef.current[name]) {
            logRef.current[name] = {}
        }
        logRef.current[name][key] = val
    }
    const getLog = (name, key) => {
        if (logRef.current && logRef.current[name] && logRef.current[name][key]) {
            return logRef.current[name][key]
        }
        return null
    }

    const breadCrumbItems = useMemo(() => {
        if (matchPath({ path: ROUTE.events }, location.pathname)) {
            return [{ template: () => <Link to="/list-events" className="text-primary">{t("common.route.events")}</Link> }]
        }
        if (matchPath({ path: ROUTE.eventInfoScreen }, location.pathname)) {
            return [
                { template: () => <Link to="/list-events">{t("common.route.events")}</Link> },
                { template: () => <Link to={`/event/${eventId}`}>{event?.eventCode}</Link> },
                { template: () => <span className="text-primary">{t(ROUTE_SCREEN.eventInfoScreen[screenId])}</span> }]
        }
        if (matchPath({ path: ROUTE.eventInfo }, location.pathname)) {
            return [{ template: () => <Link to="/list-events">{t("common.route.events")}</Link> }, { template: () => <span className="text-primary">{event?.eventCode}</span> }]
        }
        if (matchPath({ path: ROUTE.companies }, location.pathname)) {
            return [{ template: () => <Link to="/list-company" className="text-primary">{t("common.route.companies")}</Link> }]
        }
        if (matchPath({ path: ROUTE.eventUserInfo }, location.pathname)) {
            return [
                { template: () => <Link to="/list-events">{t("common.route.events")}</Link> },
                { template: () => <Link to={`/event/${eventId}`}>{event?.eventCode}</Link> },
                { template: () => <Link to={`/event/${eventId}/user`}>{t("common.route.event_info.user")}</Link> },
                { template: () => <span className="text-primary">{refName ?? refId}</span> }
            ]
        }
        if (matchPath({ path: ROUTE.eventUserInfoScreen }, location.pathname)) {
            return [
                { template: () => <Link to="/list-events">{t("common.route.events")}</Link> },
                { template: () => <Link to={`/event/${eventId}`}>{event?.eventCode}</Link> },
                { template: () => <Link to={`/event/${eventId}/user`}>{t("common.route.event_info.user")}</Link> },
                { template: () => <Link to={`/event/${eventId}/user/${refId}`}>{refName ?? refId}</Link> },
                { template: () => <span className="text-primary">{t(ROUTE_SCREEN.eventUserInfoScreen[screenId])}</span> }
            ]
        }
        //company
        if (matchPath({ path: ROUTE.eventCompanyInfoScreen }, location.pathname)) {
            return [
                { template: () => <Link to="/list-events">{t("common.route.events")}</Link> },
                { template: () => <Link to={`/event/${eventId}`}>{event?.eventCode}</Link> },
                { template: () => <Link to={`/event/${eventId}/companies`}>{t("common.route.event_info.companies")}</Link> },
                { template: () => <Link to={`/event/${eventId}/company/${refId}`}>{refName}</Link> },
                { template: () => <span className="text-primary">{t(ROUTE_SCREEN.eventCompanyInfoScreen[screenId])}</span> }
            ]
        }
        if (matchPath({ path: ROUTE.eventCompanyInfo }, location.pathname)) {
            return [
                { template: () => <Link to="/list-events">{t("common.route.events")}</Link> },
                { template: () => <Link to={`/event/${eventId}`}>{event?.eventCode}</Link> },
                { template: () => <Link to={`/event/${eventId}/companies`}>{t("common.route.event_info.companies")}</Link> },
                { template: () => <span className="text-primary">{refName}</span> }
            ]
        }
        //session
        if (matchPath({ path: ROUTE.eventSessionInfoScreen }, location.pathname)) {
            return [
                { template: () => <Link to="/list-events">{t("common.route.events")}</Link> },
                { template: () => <Link to={`/event/${eventId}`}>{event?.eventCode}</Link> },
                { template: () => <Link to={`/event/${eventId}/sessions`}>{t("common.route.event_info.sessions")}</Link> },
                { template: () => <Link to={`/event/${eventId}/session/${refId}`}>{refName}</Link> },
                { template: () => <span className="text-primary">{t(ROUTE_SCREEN.eventSessionInfoScreen[screenId])}</span> }
            ]
        }
        if (matchPath({ path: ROUTE.eventSessionInfo }, location.pathname)) {
            return [
                { template: () => <Link to="/list-events">{t("common.route.events")}</Link> },
                { template: () => <Link to={`/event/${eventId}`}>{event?.eventCode}</Link> },
                { template: () => <Link to={`/event/${eventId}/sessions`}>{t("common.route.event_info.sessions")}</Link> },
                { template: () => <span className="text-primary">{refName}</span> }
            ]
        }
        //product
        if (matchPath({ path: ROUTE.eventProductInfoScreen }, location.pathname)) {
            return [
                { template: () => <Link to="/list-events">{t("common.route.events")}</Link> },
                { template: () => <Link to={`/event/${eventId}`}>{event?.eventCode}</Link> },
                { template: () => <Link to={`/event/${eventId}/products`}>{t("common.route.event_info.products")}</Link> },
                { template: () => <Link to={`/event/${eventId}/product/${refId}`}>{refName}</Link> },
                { template: () => <span className="text-primary">{t(ROUTE_SCREEN.eventProductInfoScreen[screenId])}</span> }
            ]
        }
        if (matchPath({ path: ROUTE.eventProductInfo }, location.pathname)) {
            return [
                { template: () => <Link to="/list-events">{t("common.route.events")}</Link> },
                { template: () => <Link to={`/event/${eventId}`}>{event?.eventCode}</Link> },
                { template: () => <Link to={`/event/${eventId}/products`}>{t("common.route.event_info.products")}</Link> },
                { template: () => <span className="text-primary">{refName}</span> }
            ]
        }
        if (matchPath({ path: ROUTE.ranks }, location.pathname)) {
            return [{ template: () => <Link to="/ranks" className="text-primary">{t("common.route.ranks")}</Link> }]
        }
        if (matchPath({ path: ROUTE.company_type }, location.pathname)) {
            return [{ template: () => <Link to="/company-type" className="text-primary">{t("common.route.company-type")}</Link> }]
        }
        if (matchPath({ path: ROUTE.faqs }, location.pathname)) {
            return [{ template: () => <Link to="/faqs" className="text-primary">{t("common.route.faqs")}</Link> }]
        }
        if (matchPath({ path: ROUTE.users }, location.pathname)) {
            return [{ template: () => <Link to="/users" className="text-primary">{t("common.route.accounts")}</Link> }]
        }
        
        
        if (matchPath({ path: ROUTE.maps }, location.pathname)) {
            return [{ template: () => <Link to="/maps" className="text-primary">{t("common.route.maps")}</Link> }]
        }
        // if (matchPath({ path: ROUTE.offerAttributes }, location.pathname)) {
        //     return [{ template: () => <Link to="/offer-attribute" className="text-primary">{t("common.route.offer_attributes")}</Link> }]
        // }
        if (matchPath({ path: ROUTE.configs }, location.pathname)) {
            return [{ template: () => <Link to="/configs" className="text-primary">{t("common.route.configs")}</Link> }]
        }
        if (matchPath({ path: ROUTE.group }, location.pathname)) {
            return [{ template: () => <Link to="/group" className="text-primary">{t("common.route.group")}</Link> }]
        }

        return []
    }, [location, event, refName])

    const breadCrumbHome = { template: () => <Link to="/"><i className="pi pi-home"></i></Link> }

    return (
        // <div className="grid">
        //     <div className="col-12">
                <BreadCrumb model={breadCrumbItems} home={breadCrumbHome} className="border-0 px-0 py-1"/>
        //     </div>
        // </div>
    )
}
EBreadCrumb = forwardRef(EBreadCrumb)
export default EBreadCrumb;
