import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import EDialog from "../../../components/EDialog";
import _, { cloneDeep, find } from 'lodash'
import EventsServices from "../../../core/services/EventsServices";
import { TabPanel, TabView } from "primereact/tabview";
import { useSelector } from "react-redux";
import UserServices from "../../../core/services/UserServices";
import { EnumEventModule, MEET_OPTION, MEET_OPTIONS, MEET_STATUS } from "../../../utils/constants";
import EventMeetingService from "../../../core/services/EventMeetingService";
import { InputTextarea } from "primereact/inputtextarea";
import { ECalendar } from "../../../components/ECalendar";
import moment from "moment";
import MapsServices from "../../../core/services/MapsServices";
import { Avatar } from "primereact/avatar";
import { BASE_S3_URL } from "../../../config";
import { MultiSelect } from "primereact/multiselect";
import { AutoComplete } from "primereact/autocomplete";
import RanksServices from "../../../core/services/RanksServices";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ToastHelper } from "../../../helper/ToastHelper";
import { v4 as uuidv4 } from 'uuid'
import { Tag } from "primereact/tag";
import { confirmDialog } from "primereact/confirmdialog";

function BusinessMatchingDetail(props, ref) {
    const { t } = useTranslation()
    const { afterSubmit, eventId, event } = props

    const language = useSelector((state) => state.userReducer.language)

    const defaultData = {
        type: MEET_OPTION.ONSITE,
        fromCompanyId: null,
        toCompanyId: null,
        createdBy: [],
        receiverId: null,
        businessMatching: true,
        date: null
    }
    const defaultError = {
        fromCompanyId: null,
        toCompanyId: null,
        createdBy: null,
        receiverId: null,
        date: null
    }

    const autoRef = useRef()

    const inputRef = useRef()

    const autoRef2 = useRef()

    const inputRef2 = useRef()

    const [data, setData] = useState(defaultData)
    const [error, setError] = useState(defaultError)
    const [action, setAction] = useState()
    const refDialog = useRef(null)
    const [activeIndex, setActiveIndex] = useState(0);
    const [usersFrom, setUsersFrom] = useState([])
    const [usersTo, setUsersTo] = useState([])
    const [companies, setCompanies] = useState([])
    const [maps, setMaps] = useState([])
    const [locations, setLocations] = useState([])
    const [ranks, setRanks] = useState([])
    const [industries, setIndustries] = useState([])
    const [rank, setRank] = useState()
    const [rank2, setRank2] = useState()

    const [keyword, setKeyword] = useState("")
    const [keyword2, setKeyword2] = useState("")
    const [items, setItems] = useState([])
    const [receiveItems, setReceiveItems] = useState([])

    useEffect(() => {
        if (event) {
            // loadCompany()
            loadRank()
        }

    }, [event])
    // useEffect(() => {
    //     if (data.fromCompanyId) {
    //         EventsServices.searchOfferAttribute(EnumEventModule.COMPANY, data.fromCompanyId).then((res) => {
    //             if (res) {
    //                 setIndustries(_.filter(res, { refType: EnumEventModule.INDUSTRY }))
    //             }
    //         })
    //     } else {
    //         setIndustries([])
    //     }

    // }, [data.fromCompanyId])



    const loadRank = () => {
        RanksServices.get().then(res => {
            if (res) {
                setRanks(res.map(o => ({
                    ...o,
                    name: find(o.languages, { languageCode: language })?.name
                })))
            }
        })
    }

    // const loadLocations = (id) => {
    //     if (id) {
    //         MapsServices.getLocationsByMapsId(id, eventId).then(res => {
    //             if (res) {
    //                 setLocations(res)
    //             } else {
    //                 setLocations([])
    //             }
    //         })
    //     } else {
    //         setLocations([])
    //     }
    // }

    const loadUser = (_companyId, callback) => {
        if (!_companyId) {
            callback([])
        }
        UserServices.searchUserCompany({
            code: event.eventCode
        }, {
            first: 0,
            page: 0,
            size: 9999,
            status: 1,
            companyId: _companyId,
            isAdmin: false
        }).then((res) => {
            if (res) {
                callback(res.data)
            } else {
                callback([])
            }
        }).catch(() => {
            callback([])
        })
    }

    const loadCompany = async () => {
        const res = await EventsServices.searchCompany(eventId)
        if (res) {
            let coms = res.map(o => {
                let _com = _.find(o.attributes, { languageCode: language })
                return {
                    ...o,
                    name: _com?.name,
                    description: _com?.description
                }
            })
            setCompanies(coms)
            return coms
        }
        return []
    }

    useImperativeHandle(ref, () => ({
        create: async () => {
            refDialog.current.create();
            let _companies = _.cloneDeep(companies)
            if (!_companies?.length) {
                _companies = await loadCompany()
            }
            let _data = _.cloneDeep(defaultData)

            setData(_data)
            setAction("create")
        },
        edit: async (_data) => {
            if (_data.length) {
                let item = _data[0]
                let _users = []
                let _companies = _.cloneDeep(companies)
                if (!_companies?.length) {
                    _companies = await loadCompany()
                }
                // _users.push(item.eventMeeting.senderId)
                _data.map(o => {
                    if (_users.indexOf(o.fromUser.id) == -1) {
                        _users.push(o.fromUser.id)
                    }
                    o.fromUsers.map(x => {
                        if (_users.indexOf(x.id) == -1) {
                            _users.push(x.id)
                        }
                    })
                })

                setData({
                    fromCompanyId: item.fromCompany.id,
                    createdBy: _users,
                    type: MEET_OPTION.ONSITE
                })
                if (item.fromCompany.id) {
                    loadUser(item.fromCompany.id, (_users) => {
                        setUsersFrom(_users)
                    })
                } else {
                    setUsersFrom([])
                }


                setItems(_data.map(o => {
                    let _lan = _.find(o.toCompany.attributes, { languageCode: language })
                    let _com = _.find(_companies, { id: o.toCompany.id })
                    return {
                        ...o.toCompany,
                        name: _lan?.name,
                        description: _lan?.description,
                        id: o.eventMeeting.id,
                        industry: _com?.industry ?? [],
                        location: _com?.location?.name,
                        logCompanyId: o.toCompany.id,
                        rowId: uuidv4(),
                        date: new Date(o.eventMeeting.date),
                        receiverId: o.toUser.id,
                        status: o.eventMeeting.status,
                        type: MEET_OPTION.ONSITE
                    }
                }))

                refDialog.current.create();
                setAction("edit");

            }
        },
        view: async (_data, id) => {

            let _sends = _.filter(_data, o => o.fromCompany.id == id)
            let _receives = _.filter(_data, o => o.toCompany.id == id)
            let _companies = _.cloneDeep(companies)
            if (!_companies?.length) {
                _companies = await loadCompany()
            }
            if (_sends.length) {
                let item = _sends[0]
                let _users = []
                // _users.push(item.eventMeeting.senderId)
                _sends.map(o => {
                    if (_users.indexOf(o.fromUser.id) == -1) {
                        _users.push(o.fromUser.id)
                    }
                    o.fromUsers.map(x => {
                        if (_users.indexOf(x.id) == -1) {
                            _users.push(x.id)
                        }
                    })
                })

                setData({
                    fromCompanyId: item.fromCompany.id,
                    createdBy: _users,
                    type: MEET_OPTION.ONSITE
                })
                if (item.fromCompany.id) {
                    loadUser(item.fromCompany.id, (_users) => {
                        setUsersFrom(_users)
                    })
                } else {
                    setUsersFrom([])
                }


                setItems(_sends.map(o => {
                    let _lan = _.find(o.toCompany.attributes, { languageCode: language })
                    let _com = _.find(_companies, { id: o.toCompany.id })
                    console.log(_com)
                    return {
                        ...o.toCompany,
                        name: _lan?.name,
                        description: _lan?.description,
                        id: o.eventMeeting.id,
                        industry: _com?.industry ?? [],
                        location: _com?.location?.name,
                        logCompanyId: o.toCompany.id,
                        rowId: uuidv4(),
                        date: new Date(o.eventMeeting.date),
                        receiverId: o.toUser.id,
                        status: o.eventMeeting.status,
                        type: MEET_OPTION.ONSITE
                    }
                }))
            }
            if (!_sends.length) {
                let item = _receives[0]
                setData({
                    fromCompanyId: item.toCompany.id,
                    createdBy: [item.toUser.id],
                    type: MEET_OPTION.ONSITE
                })
                if (item.toCompany.id) {
                    loadUser(item.toCompany.id, (_users) => {
                        setUsersFrom(_users)
                    })
                } else {
                    setUsersFrom([])
                }
            }
            setReceiveItems(_receives.map(o => {
                let _lan = _.find(o.fromCompany.attributes, { languageCode: language })
                let _com = _.find(_companies, { id: o.fromCompany.id })
                console.log(_com)
                return {
                    ...o.fromCompany,
                    name: _lan?.name,
                    description: _lan?.description,
                    id: o.eventMeeting.id,
                    industry: _com?.industry ?? [],
                    location: _com?.location?.name,
                    logCompanyId: o.fromCompany.id,
                    rowId: uuidv4(),
                    date: new Date(o.eventMeeting.date),
                    // receiverId: o.toUser.id,
                    status: o.eventMeeting.status,
                    type: MEET_OPTION.ONSITE
                }
            }))


            refDialog.current.create();
            setAction("view")
        }
    }));

    const applyChangeItem = (prop, value, rowId) => {
        console.log(prop, value, rowId)
        let _items = cloneDeep(items)
        let index = _.findIndex(_items, { rowId })
        // switch (prop) {

        //     default:
        //         _items[prop] = value;
        //         break
        // }
        // validateitems([prop], _items)
        if (index != -1) {
            _items[index][prop] = value;
            setItems(_items)
        }

    }

    const onChangeData = (prop, value, propArr, index) => {
        console.log(value)
        let _data = cloneDeep(data)
        switch (prop) {
            case "fromCompanyId":
                _data[prop] = value;
                _data["createdBy"] = null
                setUsersFrom([])
                loadUser(value, (_users) => {
                    setUsersFrom(_users)
                })
                break
            case "toCompanyId":
                _data[prop] = value;
                _data["receiverId"] = null
                setUsersTo([])
                loadUser(value, (_users) => {
                    setUsersTo(_users)
                })
                break;
            default:
                _data[prop] = value;
                break
        }
        validateData([prop], _data)
        setData(_data)
    }

    const validateData = (prop, _data) => {
        let _error = cloneDeep(error)
        let requiredFields = prop.length > 0 ? prop : Object.keys(_data);
        let _prop = requiredFields
        for (const field of _prop) {
            switch (field) {
                case "fromCompanyId":
                    _error[field] = ""
                    if (!_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;

                case "createdBy":
                    _error[field] = ""
                    if (!_data[field].length) {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                default:
                    break;
            }
        }

        setError(_error)
        let count = 0;
        for (const key in _error) {
            if (_error[key]) {
                count++;
            }
        }
        return count;
    }

    const onSubmit = () => {
        let _data = cloneDeep(data)
        _data.tags = _data.name
        let validate = validateData([], _data)
        if (validate) return
        EventMeetingService.create(eventId, items.map(o => ({
            id: o.id,
            type: o.type ?? data.type,
            // description: ,
            // industry: [

            // ],
            senderId: data.createdBy[0],
            senderIds: data.createdBy.slice(1),
            receiverId: o.receiverId,
            fromCompanyId: data.fromCompanyId,
            toCompanyId: o.logCompanyId,
            date: o.date ? moment.utc(o.date).format("HH:mm DD/MM/YYYY") : null,
            // mapId: ,
            // locationId: 
        })), {
            code: event.eventCode
        }).then((res) => {
            if (res) {
                afterSubmit()
                cancel()
            }
        })

    }

    const renderError = (field) => {
        if (error[field]) {
            return <span className="text-red-500 text-xs">{error[field]}</span>
        }
    }

    const cancel = () => {
        refDialog.current.close()
        setData(defaultData)
        setError(defaultError)
        setItems([])
    }

    const renderItemTemplate = (item) => {
        if (item) {
            return <div>
                <div>{item.fullName}</div>
                <div className="text-xs">{item.email}</div>
            </div>
        }
        return <div>&nbsp;</div>
    }

    const onDeleteItem = (key, item) => (e) => {
        console.log(data[key], item)
        e.stopPropagation()
        let _data = _.cloneDeep(data[key])
        _.remove(_data, o => o == item.id)
        onChangeData(key, _data)
    }

    const renderValueTemplate = (key) => (item) => {
        item = _.find(usersFrom, { id: item })
        if (item) {
            return <div className="flex align-items-center cursor-pointer" onClick={onDeleteItem(key, item)}>
                <Avatar
                    image={`${BASE_S3_URL}${item?.avatar}`}
                    label={item?.fullName?.length ? item?.fullName[0] : ""}
                    size='normal'
                    className="text-xs"
                    shape="circle"
                    style={{ width: 20, height: 20 }}
                />
                <div className="ml-2 mr-1 text-xs">{item.fullName}</div>
                <i className="pi pi-times text-xs" />
            </div>
        }
        return <div>&nbsp;</div>
    }

    const itemTemplate = (item) => {
        if (item) {
            return <div>
                <div className="text-base">{`${item.code ? `${item.code} - ` : ""}${item.name}`}</div>
            </div>
        }
        return <div>&nbsp;</div>
    }

    const onClear = (e) => {
        setKeyword("")
    }

    const onSearch = (e) => {
        let keyw = e?.query?.trim()
        setKeyword(keyw)
    }

    const onSearchChange = (e) => {
        if (e.value) {
            let _select = _.find(companies, {id: e.value})
            onChangeData("fromCompanyId", _select?.id)
        }
    }

    const onClear2 = (e) => {
        setKeyword2("")
    }

    const onSearch2 = (e) => {
        let keyw = e?.query?.trim()
        setKeyword2(keyw)
    }

    const onSearchChange2 = (e) => {
        if (e.value) {
            let _select = _.find(companies, {id: e.value})
            console.log(_select)
            // let _data = _.cloneDeep(data)
            // if (!find(_data, { id: _select.id })) {
            //     // _data.push(_select)
            //     // setData(_data)
            //     // refTableSort.current.add(_select)
            //     setEditing(true)
            // }
            // onChangeData("fromCompanyId", _select?.id)
            let _items = _.cloneDeep(items)
            if (!_.find(_items, { rowId: _select.id })) {

                loadUser(_select.id, (_users) => {
                    // setUsersFrom(_users)
                    if (_users?.length) {
                        _users = _.orderBy(_users, ["no"], ["asc"])
                        _items.push({
                            ..._select,
                            logCompanyId: _select.id,
                            rowId: uuidv4(),
                            receiverId: _users[0].id,
                            status: MEET_STATUS.WAITING,
                            id: null
                        })
                        console.log(_items)
                        setItems(_items)
                    } else {
                        ToastHelper.show({ severity: 'error', summary: t("common.error"), detail: t("error.meet_company_no_user") })
                    }

                })
            }
        }
    }

    const onDeleteItems = (row) => {
        if (row?.id) {
            confirmDialog({
                message: t("business_matching.confirm_content"),
                header: t("common.confirm"),
                icon: 'pi pi-exclamation-triangle',
                defaultFocus: 'accept',
                rejectLabel: t("common.cancel"),
                acceptLabel: t("common.confirm"),
                accept: () => {
                    EventMeetingService.delete(eventId, row?.id).then((res) => {
                        if (res) {
                            ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
                            let _items = _.cloneDeep(items)
                            _.remove(_items, { rowId: row.rowId })
                            setItems(_items)
                            afterSubmit()
                        }
                    })
                },
                reject: () => { }
            });
        } else {
            let _items = _.cloneDeep(items)
            _.remove(_items, { rowId: row.rowId })
            setItems(_items)
        }

    }

    const renderColO = (row) => {
        return <ECalendar
            // disabled={refEditMode.current == "view"}
            value={row.date}
            onChange={(value) => applyChangeItem("date", value, row.rowId)}
            className="w-full"
            showIcon
            showDate
            disabled={row.status != MEET_STATUS.WAITING || action == "view"}
        />
    }
    const renderCol2 = (row) => {
        return <span>{(row?.industry ?? []).map(o => o.value).join(", ")}</span>
    }
    const renderCol4 = (row) => {
        return <span>{row?.location?.name}</span>
    }

    const renderCol3 = (d, value) => {
        let status = find(MEET_STATUS.list, { id: d.status })
        return (
            <span>
                <Tag
                    severity={d.status == MEET_STATUS.ACCEPT
                        ? 'success'
                        : d.status == MEET_STATUS.NONE
                            ? 'info'
                            : d.status == MEET_STATUS.WAITING
                                ? 'warning'
                                : 'danger'}
                >
                    {t(status?.name)}
                </Tag>

            </span>
        )
    }

    const searchListSelection = useMemo(() => {
        const _companies = rank ? _.filter(companies, o => o.rank?.id == rank) : companies
        return _companies
    }, [companies, rank])

    const searchListSelection2 = useMemo(() => {
        const _companies = rank2 ? _.filter(companies, o => o.rank?.id == rank2) : companies

        return _companies
    }, [companies, rank2])

    const previewDescription = useMemo(() => {
        let com = _.find(companies, { id: data.fromCompanyId })
        return _.find((com?.attributes ?? []), { languageCode: language })?.description
    }, [data.fromCompanyId, companies])

    const previewIndustry = useMemo(() => {
        // let com = _.find(companies, {id: data.fromCompanyId})
        return (_.find(companies, { id: data.fromCompanyId })?.industry ?? []).map(o => o.value).join(", ")
    }, [data.fromCompanyId, companies])

    return (
        <EDialog
            ref={refDialog}
            style={{ width: '70vw' }}
            title={action === "view" ? t("business_matching.title") : action === "create" ? t("business_matching.title_create") : t("business_matching.title_edit")}
            closable={false}
            hidebtnFooter={false}
            btnFooter={
                action === "view" ?
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        }
                    ] :
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        },
                        {
                            label: t("common.save"),
                            onClick: () => onSubmit(),
                        }
                    ]
            }
        >
            <div className="container">
                <div className="grid">
                    <div className="col-12">
                        <div className="bg-blue-50 px-3 py-2 border-round">
                            <span className="text-blue-600">{t("business_matching.party_info")}</span>
                        </div>
                    </div>
                    {action === "create" && <>
                        <span className="col-6 p-input-icon-left">
                            <i className="pi pi-search pl-2" />
                            {/* <AutoComplete
                                ref={autoRef}
                                inputRef={inputRef}
                                placeholder={t("company.search")}
                                className='w-full'
                                field="name"
                                multiple
                                // value={keyword}
                                onClear={onClear}
                                completeMethod={onSearch}
                                suggestions={searchListSelection}
                                // onKeyPress={onSearch}
                                // onClear={() => console.log("ok")}
                                onChange={onSearchChange}
                                // dropdown
                                onFocus={() => autoRef.current.show()}
                            /> */}
                            <Dropdown
                                filter
                                disabled={action !== "create"}
                                options={searchListSelection}
                                optionLabel="name"
                                optionValue="id"
                                className="w-full"
                                value={null}
                                onChange={onSearchChange}
                                showClear

                            />
                        </span>
                        <div className="col-6">
                            <Dropdown
                                filter
                                disabled={action !== "create"}
                                options={ranks}
                                optionLabel="name"
                                optionValue="id"
                                className="w-full"
                                value={rank}
                                onChange={(e) => { setRank(e.target.value) }}
                                showClear

                            />
                        </div>
                    </>
                    }
                    <div className="col-6 field">
                        <label className="require">{t("business_matching.party_name")}</label>
                        <Dropdown
                            filter
                            disabled
                            options={companies}
                            optionLabel="name"
                            optionValue="id"
                            className="w-full"
                            value={data?.fromCompanyId}
                            itemTemplate={itemTemplate}
                            valueTemplate={itemTemplate}
                            onChange={(e) => { onChangeData("fromCompanyId", e.target.value) }}

                        />
                        {renderError("fromCompanyId")}
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("business_matching.party_participants")}</label>
                        <MultiSelect
                            filter
                            filterBy="email,firstName,lastName,phone,fullName"
                            disabled={action !== "create"}
                            options={usersFrom}
                            optionLabel="fullName"
                            optionValue="id"
                            className="w-full e-multi-select-user"
                            value={data?.createdBy}

                            itemTemplate={renderItemTemplate}
                            selectedItemTemplate={renderValueTemplate("createdBy")}

                            onChange={(e) => { onChangeData("createdBy", e.value) }}
                        />
                        {renderError("createdBy")}
                    </div>
                    <div className="col-6 field">
                        <label>{t("common.description")}</label>
                        <InputTextarea
                            className="w-full"
                            value={previewDescription}
                            onChange={(e) => { onChangeData("attributes", e.target.value, "description", activeIndex) }}
                            rows={5}
                            cols={30}
                            disabled
                        />
                    </div>
                    <div className="col-6 field">
                        <label>{t("common.industry")}</label>
                        <InputTextarea
                            className="w-full"
                            value={previewIndustry}
                            onChange={(e) => { onChangeData("attributes", e.target.value, "description", activeIndex) }}
                            rows={5}
                            cols={30}
                            disabled
                        />
                    </div>
                    <div className="col-12">
                        <div className="bg-blue-50 px-3 py-2 border-round">
                            <span className="text-blue-600">{t("business_matching.partners_info")}</span>
                        </div>
                    </div>
                    {action !== "view" && <>
                        <span className="col-6 p-input-icon-left">
                            <i className="pi pi-search pl-2" />
                            {/* <AutoComplete
                                ref={autoRef2}
                                inputRef={inputRef2}
                                placeholder={t("company.search")}
                                className='w-full'
                                field="name"
                                multiple
                                // value={keyword}
                                onClear={onClear2}
                                completeMethod={onSearch2}
                                suggestions={searchListSelection2}
                                // onKeyPress={onSearch}
                                // onClear={() => console.log("ok")}
                                onChange={onSearchChange2}
                                // dropdown
                                onFocus={() => autoRef2.current.show()}
                            /> */}
                            <Dropdown
                                filter
                                disabled={action !== "create"}
                                options={searchListSelection2}
                                optionLabel="name"
                                optionValue="id"
                                className="w-full"
                                value={null}
                                onChange={onSearchChange2}
                                showClear

                            />
                        </span>
                        <div className="col-6">
                            <Dropdown
                                filter
                                disabled={action !== "create"}
                                options={ranks}
                                optionLabel="name"
                                optionValue="id"
                                className="w-full"
                                value={rank2}
                                onChange={(e) => { setRank2(e.target.value) }}
                                showClear
                            />
                        </div>
                    </>
                    }

                    <div className="col-12">
                        <DataTable
                            value={items}
                            dataKey="id"
                            className="p-datatable-gridlines border-all"
                            resizableColumns
                            columnResizeMode="expand"
                            emptyMessage={t("common.no_data")}
                            scrollable
                            scrollDirection="both"
                            scrollHeight="flex"
                            lazy
                        >
                            <Column
                                style={{ width: 100 }}
                                field="code"
                                header={t("common.no")}
                            />
                            <Column
                                header={t("common.date")}
                                style={{ minWidth: 300 }}
                                body={renderColO}
                            />
                            <Column
                                header={t("common.company")}
                                style={{ minWidth: 300 }}
                                field="name"
                            />
                            <Column
                                header={t("common.description")}
                                style={{ minWidth: 300 }}
                                field="description"
                            />
                            <Column
                                header={t("common.website")}
                                style={{ minWidth: 250 }}
                                field="website"
                            />
                            <Column
                                header={t("common.locations")}
                                style={{ minWidth: 250 }}
                                // field="location"
                                body={renderCol4}
                            />
                            <Column
                                header={t("common.status")}
                                style={{ minWidth: 150 }}
                                body={renderCol3}
                            />
                            <Column
                                columnKey="action"
                                alignFrozen="right"
                                frozen
                                header={t("common.action")}
                                className="col-frozen-table-end py-1"
                                style={{ minWidth: 80, width: 80 }}
                                body={(d) => {
                                    return (
                                        <div className="flex">
                                            <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" onClick={() => onDeleteItems(d)}
                                                disabled={d.status != MEET_STATUS.WAITING || action === "view"}
                                            />
                                        </div>
                                    )
                                }}
                            />
                        </DataTable>
                    </div>

                    {action == "view" && !!receiveItems?.length
                        ? <>
                            <div className="col-12">
                                <div className="bg-blue-50 px-3 py-2 border-round">
                                    <span className="text-blue-600">{t("business_matching.schedute_info")}</span>
                                </div>
                            </div>
                            <div className="col-12">
                                <DataTable
                                    value={receiveItems}
                                    dataKey="id"
                                    className="p-datatable-gridlines border-all"
                                    resizableColumns
                                    columnResizeMode="expand"
                                    emptyMessage={t("common.no_data")}
                                    scrollable
                                    scrollDirection="both"
                                    scrollHeight="flex"
                                    lazy
                                >
                                    <Column
                                        style={{ width: 100 }}
                                        field="code"
                                        header={t("common.no")}
                                    />
                                    <Column
                                        header={t("common.date")}
                                        style={{ minWidth: 300 }}
                                        body={renderColO}
                                    />
                                    <Column
                                        header={t("common.company")}
                                        style={{ minWidth: 300 }}
                                        field="name"
                                    />
                                    <Column
                                        header={t("common.description")}
                                        style={{ minWidth: 300 }}
                                        field="description"
                                    />
                                    <Column
                                        header={t("common.industry")}
                                        style={{ minWidth: 250 }}
                                        body={renderCol2}
                                    />
                                    <Column
                                        header={t("common.status")}
                                        style={{ minWidth: 150 }}
                                        body={renderCol3}
                                        alignFrozen="right"
                                        frozen
                                    />

                                </DataTable>
                            </div>
                        </>
                        : null
                    }
                </div>
            </div>
        </EDialog>
    )
}
BusinessMatchingDetail = forwardRef(BusinessMatchingDetail)
export default BusinessMatchingDetail;
