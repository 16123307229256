import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SpeedDial } from 'primereact/speeddial';
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import "./ListAccounts.scss"

function ListAccountsDetail(props, ref) {
    const { t } = useTranslation()
    const [filter, setFilter] = useState({})
    const [show, setShow] = useState(false)

    useImperativeHandle(ref, () => ({
        create: () => {
            setShow(true);
        },
        edit: () => {
            setShow(true);
        },
        view: () => {
            setShow(true);
        }
    }));

    const cancel = () => {
        setShow(false)
    }

    return (
        <Dialog
            visible={show}
            style={{ width: '70vw' }}
            header={"Thông tin tài khoản"}
            onHide={() => cancel()}
            footer={() => {
                return (
                    <div>
                        <Button label="Hủy" size="small" onClick={() => cancel()}/>
                        <Button label="Lưu" size="small" className="ml-2" />
                    </div>
                )
            }}
        >
            <div className="container">
                <div className="grid">
                    <div className="col-6">
                        <label htmlFor="username" className="require">Username</label>
                        <InputText className="w-full"></InputText>
                    </div>
                    <div className="col-6">
                        <label htmlFor="Name" className="require">Họ và tên</label>
                        <InputText className="w-full"></InputText>
                    </div>
                    <div className="col-6">
                        <label htmlFor="Name" className="require">Vai trò</label>
                        <Dropdown
                            value={null}
                            // onChange={(e) => setSelectedCity(e.value)}
                            options={[
                                {
                                    name: "Admin", value: "Admin"
                                },
                                {
                                    name: "User", value: "User"
                                }
                            ]}
                            optionLabel="name"
                            optionValue="value"
                            className="w-full" />
                    </div>
                    <div className="col-6 flex align-items-center">
                        <Checkbox
                            // onChange={e => setChecked(e.checked)}
                            checked={true} />
                        <label htmlFor="kichhoat" className="ml-2">Kích hoạt</label>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
ListAccountsDetail = forwardRef(ListAccountsDetail)
export default ListAccountsDetail;
