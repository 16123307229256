import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from "primereact/dropdown";
import _ from "lodash";
import { Calendar } from "primereact/calendar";
import EDialog from "../../../../components/EDialog";
import { ToastHelper } from "../../../../helper/ToastHelper";
import { useSelector } from "react-redux";
import EventsServices from "../../../../core/services/EventsServices";
import { FILE_TYPE, NOTIFICATION_TYPE, UPLOAD_STATUS, USER_TYPE_NEW } from "../../../../utils/constants";
import S3FileUpload from "../../../../components/S3FileUpload";
import moment from "moment";
import { Editor } from 'primereact/editor';
import { TabPanel, TabView } from "primereact/tabview";
import { classNames } from "primereact/utils";
import CKEditorDocumentEditor from "../../../../components/CKEditorDocumentEditor";
import { cloneDeep } from 'lodash'
import { InputTextarea } from "primereact/inputtextarea";
import { ECalendar } from "../../../../components/ECalendar";

function EventsUserTypeDetail(props, ref) {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(false)

    const [saveLoading, setSaveLoading] = useState(false)
    const { eventId, sessions, afterSubmit } = props;
    let emptyValidate = {
        
    };
    let emptyData = {
        type: USER_TYPE_NEW.COMPANY,
        languages: [],
    };

    const [data, setData] = useState(emptyData)
    const [valid, setValid] = useState(emptyValidate)

    const s3FileUploadRef = useRef()

    const { languages } = useSelector((state) => state.userReducer)
    const refDialog = useRef(null)
    const refEditMode = useRef(null);
    const [currentId, setCurrentId] = useState(null)
    const [disabledInput, setDisabledInput] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0);
    const [types, setTypes] = useState([]);
    const [refIds, setRefIds] = useState([]);

    useImperativeHandle(ref, () => ({
        create: () => {
            refEditMode.current = "create"
            let _data = cloneDeep(emptyData)
            languages?.map((item) => {
                _data.languages.push({
                    languageCode: item.code,
                    languagesname: item.name,
                    name: "",
                    description: "",
                    isNew: true
                })
            })
            setData(_data)
            refDialog.current.create()
        },
        edit: (_data) => {
            refEditMode.current = "update"
            let lans = []
            languages?.map((item) => {
                let lan = _data.languages.find(o => item.code == o.languageCode)
                lans.push({
                    languageCode: item?.code,
                    languagesname: item?.name,
                    name: lan?.name
                })
            })
            _data.languages = lans
            setData(_data)
            setCurrentId(data.id)
            refDialog.current.create()
        }
    }))

    const onShow = () => {
        
    }

    const cancel = () => {
        refDialog.current.close();
        setData(emptyData)
        setValid(emptyValidate)
        setActiveIndex(0)
    }
    const save = async () => {
        let isValid = performValidate([]);
        if (isValid.isValid) {
            // call api
            try {
                if (refEditMode.current == "create") {
                    setSaveLoading(true)
                    await EventsServices.userType.create(eventId, {
                        ...data
                    }).then(res => {
                        refDialog.current.close();
                        ToastHelper.show({ severity: 'success', summary: t("common.success") });
                        afterSubmit()
                        setSaveLoading(false)
                    })

                } else {
                    setSaveLoading(true)
                    await EventsServices.userType.update(data.id, eventId, {
                        ...data
                    }).then(res => {
                        refDialog.current.close();
                        ToastHelper.show({ severity: 'success', summary: t("common.success") });
                        afterSubmit()
                        setSaveLoading(false)
                    })

                }

            } catch (error) {
                setSaveLoading(false)
                // ToastHelper.show({ severity: 'error', summary: t("common.error"), detail: 'Error' });
            }
        } else {
            setSaveLoading(false)
            ToastHelper.show({ severity: 'error', summary: t("common.error"), detail: t("error.required_full") });
        }
    }

    const applyChange = (prop, value, propArr, index) => {
        let _current = _.cloneDeep(data);
        switch (prop) {
            case "languages":
                _current[prop][index][propArr] = value;
                break

            default:
                _current[prop] = value
                break;
        }

        setData(_current);
        performValidate([prop], _current, propArr, index)
    }
    const performValidate = (props, _checkObject, propArr, index) => {
        let result = { ...valid }, isValid = true, _errors = [];
        let _object = _checkObject ? _checkObject : data;
        // validate all props
        if (props.length === 0) {
            for (const property in result) {
                props.push(property);
            }
        }

        // validate props
        props.forEach(prop => {
            switch (prop) {
                
                default:
                    break;
            }
        });

        // set state
        setValid(result);
        for (const property in result) {
            if (result[property]) {
                if (property != "languages") {
                    isValid = false;
                    _errors.push(result[property]);
                }
                break;
            }
        }
        return { isValid: isValid, errors: _errors };
    }


    const header = () => {
        let headerMode = refEditMode.current
        if (headerMode === 'create') {
            return t('common.create') + " " + (t("common.route.event_info.user_type").toLowerCase())
        }
        if (headerMode === 'update') {
            return t('common.update') + " " + (t("common.route.event_info.user_type").toLowerCase())
        }
        if (headerMode === 'view') {
            return t('common.view') + " " + (t("common.route.event_info.user_type").toLowerCase())
        }
    }

    const onUploadedImage = useCallback((val) => {
        applyChange("image", val)
    }, [data])

    return (
        <EDialog
            ref={refDialog}
            title={header()}
            closable={true}
            style={{ width: '90vw' }}
            hidebtnFooter={false}
            onShow={onShow}
            btnFooter={
                refEditMode.current == "view" ?
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        }
                    ] :
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        },
                        {
                            label: t("common.save"),
                            onClick: () => save(),
                            loading: loading || saveLoading
                        }
                    ]
            }
        >
            <div className="container">
                <div class="formgrid grid">
                    <div className="col-12 flex flex-column">
                        <div className="w-full">
                            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                {data?.languages?.map((item) => {
                                    return (
                                        <TabPanel header={item?.languagesname}>
                                            <div className="grid">
                                                <div className="col-12 field">
                                                    <label>{t("event.name")}</label>
                                                    <InputText
                                                        disabled={refEditMode.current === "view"}
                                                        value={item?.name}
                                                        onChange={(e) => { applyChange("languages", e.target.value, "name", activeIndex) }}
                                                        className={classNames({ "w-full": true })}
                                                    ></InputText>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    )
                                })}
                            </TabView>
                        </div>
                    </div>
                </div>

            </div>
        </EDialog>

    )
}
EventsUserTypeDetail = forwardRef(EventsUserTypeDetail)
export default EventsUserTypeDetail;
