import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import EventDetail from "./EventDetail";
import { Toast } from 'primereact/toast';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import EventsServices from "../../core/services/EventsServices";
import { ToastHelper } from '../../helper/ToastHelper';
import { Link, useNavigate } from "react-router-dom"
import { BreadCrumb } from "primereact/breadcrumb";
import EBreadCrumb from "../../components/EBreadCrumb";
import { Card } from "primereact/card";
import { useSelector } from "react-redux"
import { EVENT_STATUS, PERMISSION, USER_PERMISSION } from "../../utils/constants";
import { filter, find, cloneDeep } from "lodash"
import { CommonHelper } from "../../helper/CommonHelper";
import { checkPermission, findAttribute, getPermission } from "../../utils";
import { ProgressBar } from "primereact/progressbar";

const screenPermission = "event"

function Events(props) {
    const { t } = useTranslation()

    const { events, permission, eventCode, groupPermission } = useSelector((state) => state.userReducer)

    const [lazyParams, setLazyParams] = useState({
        keyword: ""
    })

    const [loadingList, setLoadingList] = useState(false)

    const history = useNavigate()
    const refDetail = useRef()

    const [data, setData] = useState([])

    useEffect(() => {
        if (permission == USER_PERMISSION.ADMIN) {
            getList(lazyParams)
        } else {
            let _events = filter(events, { eventCode: eventCode })
            setData(_events)
        }
    }, [])

    const getList = () => {
        setLoadingList(true)
        EventsServices.search().then((res) => {
            if (res) {
                setData(res)
            }
            setLoadingList(false)
        })
    }


    const createData = () => {
        refDetail.current.create()
    }

    const editData = (id) => {
        refDetail.current.edit(id)
    }

    const viewData = (id) => {
        refDetail.current.view(id)
    }

    const deleteData = (id) => {
        confirmDialog({
            message: t("common.confirm_content"),
            header: t("common.confirm"),
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            rejectLabel: t("common.cancel"),
            acceptLabel: t("common.confirm"),
            accept: () => {
                EventsServices.delete(id).then((res) => {
                    if (res) {
                        ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
                        getList()
                    }
                })
            }
            ,
            reject: () => { }
        });
    }

    const afterSubmit = () => {
        getList()
        ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
    }

    const startContent = (
        <React.Fragment>
            <Button
                icon="pi pi-plus"
                className="mr-2"
                label={t("common.add")}
                outlined
                size="small"
                onClick={() => createData()}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.CREATE)}
            />
        </React.Fragment>
    );

    const onChangeKeyword = (e) => {
        let _filter = cloneDeep(lazyParams)
        _filter.keyword = e.target.value
        setLazyParams(_filter)
    }

    const endContent = (
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText placeholder={t("company.search")} value={lazyParams?.keyword} onChange={onChangeKeyword} />
        </span>
    );

    const renderColEnd = (row) => {
        return <span>
            <Button icon="pi pi-eye" rounded text onClick={() => viewData(row.id)} />
            <Button icon="pi pi-pencil" rounded text onClick={() => editData(row.id)}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.UPDATE)}
            />
            <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" onClick={() => deleteData(row.id)}
                disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.DELETE)}
            />
        </span>
    }

    const previewList = useMemo(() => {
        return findAttribute(data, lazyParams.keyword, ["eventCode"])
    }, [lazyParams, data])

    if (!checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)) {
        return null
    }

    return (
        <div className="event-layout">
            <div className="event-layout-center overflow-hidden">
                <div className="e-progress-bar">
                    {loadingList
                        ? <ProgressBar className="e-progress-bar" mode="indeterminate"></ProgressBar>
                        : null
                    }
                </div>
                <div className="p-card">
                    <div className="p-card-body">
                        <div className="grid">
                            <div className="col-12 ">
                                <Toolbar start={startContent} end={endContent} />
                            </div>
                            <div className="col-12">
                                <DataTable
                                    value={previewList}
                                    dataKey="id"
                                    className="p-datatable-gridlines border-all"
                                    resizableColumns
                                    columnResizeMode="expand"
                                    emptyMessage={t("common.no_data")}
                                    scrollable
                                    scrollDirection="both"
                                    scrollHeight="flex"
                                    lazy
                                >
                                    <Column
                                        className="w-6rem"
                                        body={(index, value) => value?.rowIndex + 1}
                                        header={t("common.no")}
                                    />
                                    <Column
                                        header={t("common.code")}
                                        style={{ minWidth: 250 }}
                                        field="location"
                                        body={(d, value) => {
                                            return (
                                                <Link
                                                    to={`/event/${d.id}`}
                                                    className="text-primary underline"
                                                >
                                                    {d.eventCode}
                                                </Link>
                                            )
                                        }}
                                    />
                                    <Column
                                        header={t("common.state")}
                                        style={{ minWidth: 250 }}
                                        field="location"
                                        body={(d, value) => {
                                            const _state = find(EVENT_STATUS, { id: d?.state })
                                            return (
                                                <div>
                                                    {_state ? t(_state.name) : null}
                                                </div>
                                            )
                                        }}
                                    />
                                    <Column
                                        header={t("event.location")}
                                        style={{ minWidth: 250 }}
                                        field="location"
                                        body={(d, value) => {
                                            return (
                                                <div>
                                                    {d?.location}
                                                </div>
                                            )
                                        }}
                                    />
                                    <Column
                                        columnKey="action"
                                        alignFrozen="right"
                                        frozen
                                        header={t("common.action")}
                                        style={{ minWidth: 180, width: 180 }}
                                        className="col-frozen-table-end py-1"
                                        body={renderColEnd}
                                    />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
                <EventDetail afterSubmit={afterSubmit} ref={refDetail} permission={permission}></EventDetail>
            </div>
        </div>

    )
}

export default Events;
