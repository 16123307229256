import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { useDispatch } from "react-redux";
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import "./styles.scss";
import OfferAttribute from "../../OfferAttribute";
import EventFile from "../../EventFile";
import Maps from "../../Maps";
import Locations from "../../Locations";
import { EnumEventModule } from "../../../../utils/constants";
import EBreadCrumb from "../../../../components/EBreadCrumb";
import { useNavigate, useParams } from "react-router-dom";

export default function ProductInfo(props) {
    const { t } = useTranslation()
    
    const { eventId, refId, screenId } = useParams()
    
    const history = useNavigate()

    const activeIndex = useMemo(() => {
        switch (screenId) {
            case "offer-attributes":
                return 0
            case "files":
                return 1
            case "locations":
                return 2
            default:
                return 0
        }
    }, [screenId])


    const tabHeaderTemplate = (name, link) => ({ onClick }) => {
        return <div className="p-3 font-bold flex cursor-pointer text-primary" onClick={onLink(link, onClick)}>
            {name}
        </div>
    }

    const onLink = (link, onClick) => () => {
        onClick()
        history(link)
    }

    return (
        <div className="event-layout">
            <div className="event-layout-center overflow-hidden">
                <div className="p-card">
                    <div className="p-card-body">
                        <div className="grid">
                            <div className="col-12">
                                <TabView scrollable activeIndex={activeIndex} panelContainerClassName="px-0">
                                    <TabPanel headerTemplate={tabHeaderTemplate(t("common.offer_attribute_product"), `/event/${eventId}/product/${refId}/offer-attribute-product`)}>
                                        <OfferAttribute
                                            type={EnumEventModule.PRODUCT}
                                            refType={EnumEventModule.PRODUCT}
                                            screenPermission="eventInfoScreen_product_offer-attributes"
                                        />
                                    </TabPanel>
                                    <TabPanel headerTemplate={tabHeaderTemplate(t("event.file"), `/event/${eventId}/product/${refId}/files`)}>
                                        <EventFile
                                            type={EnumEventModule.PRODUCT}
                                            screenPermission={"eventInfoScreen_products_files"}
                                        />
                                    </TabPanel>
                                    {/* <TabPanel headerTemplate={tabHeaderTemplate(t("event.maps"), `/event/${eventId}/product/${refId}/locations`)}>
                                        <Locations
                                            type={EnumEventModule.PRODUCT}
                                        />
                                    </TabPanel> */}
                                </TabView>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
