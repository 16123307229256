import React, { CSSProperties, forwardRef, useCallback, useEffect, useImperativeHandle } from 'react'
import ReactDOM from 'react-dom/client'


import {
  ColumnDef,
  Row,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'

// needed for table body level scope DnD setup
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

// needed for row & cell level scope DnD setup
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import * as _ from 'lodash'
import { DataTable } from 'primereact/datatable'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

// Cell Component
const RowDragHandleCell = ({ rowId }) => {
  const { attributes, listeners, ref } = useSortable({
    id: rowId,
  })
  return (
    // Alternatively, you could set these attributes on the rows themselves
    <button className='p-button p-component p-button-icon-only p-button-text p-button-rounded ' {...attributes} {...listeners} ref={ref}>
      <i className='pi pi-bars' />
    </button>
  )
}

// Row Component
const DraggableRow = ({ row, index }) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.id,
  })

  const style = {
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative'
  }

  return (
    // connect row ref to dnd-kit, apply important styles
    <tr ref={setNodeRef} style={style}>
      {row.getVisibleCells().map(cell => (
        <td key={cell.id} style={{ width: row.index == 0 ? 80 : 'auto' }}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  )
}

// Table Component
function TableSort({ column, setEditing }, ref) {

  const { t } = useTranslation()

  const [data, setData] = React.useState([])

  useImperativeHandle(ref, () => ({
    init: (_data) => {
      setData(_data)
    },
    reset: () => {
      setData([])
    },
    add: (item) => {
      let _data = _.cloneDeep(data)
      if (!_.find(_data, { id: item.id })) {
        _data.push(item)
        setData(_data)
      }
    },
    get: () => {
      return data
    }
  }))

  const onDelete = useCallback((row) => {
    let _data = _.cloneDeep(data)
    _.remove(_data, { id: row.id })
    setData(_data)
  }, [data])

  const columns = React.useMemo(() => {

    return [
      {
        id: 'drag-handle',

        cell: ({ row }) => <RowDragHandleCell rowId={row.id} />,
        size: 60,
        width: 80
      },
      ...column,
      {
         id: 'drag-handle-end',
        cell: ({ row }) => <>
          <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" onClick={() => onDelete(row)}
          // disabled={!checkPermission(groupPermission, screenPermission, PERMISSION.DELETE)}
          />
        </>,
        size: 60,
        width: 80
      },
    ]
  }, [])

  const dataIds = React.useMemo(
    () => data?.map(({ id }) => id),
    [data]
  )

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => row.id, //required because row indexes will change
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
  })

  // reorder rows after drag & drop
  function handleDragEnd(event) {
    const { active, over } = event
    if (active && over && active.id !== over.id) {
      setData(data => {
        const oldIndex = dataIds.indexOf(active.id)
        const newIndex = dataIds.indexOf(over.id)
        return arrayMove(data, oldIndex, newIndex) //this is just a splice util
      })
      // setEditing(true)
    }
  }
  console.log(data, dataIds)

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  )

  return (
    // NOTE: This provider creates div elements, so don't nest inside of <table> elements
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <div className='p-datatable-gridlines border-all p-datatable p-component p-datatable-resizable p-datatable-scrollable p-datatable-flex-scrollable p-datatable-responsive-scroll'>
        <div className="p-datatable-wrapper">
          <table className='p-datatable-table p-datatable-scrollable-table p-datatable-resizable-table' style={{ tableLayout: 'fixed' }}>
            <thead className='p-datatable-thead'>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, index) => (
                    <th key={header.id} colSpan={header.colSpan} className='p-resizable-column' style={{ width: index == 0 || headerGroup.headers?.length == index + 1 ? 80 : 'auto' }}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className='p-datatable-tbody'>
              <SortableContext
                items={dataIds}
                strategy={verticalListSortingStrategy}
              >
                {table.getRowModel().rows.map(row => (
                  <DraggableRow key={row.id} row={row} />
                ))}
              </SortableContext>
            </tbody>
          </table>
        </div>
      </div>
      <DataTable
        className='hidden'
      />
    </DndContext>
  )
}

export default forwardRef(TableSort)