/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss"
import { useTranslation } from "react-i18next";
import { Card } from "primereact/card";
import { Chart } from "primereact/chart";
import { Link } from "react-router-dom";
import { Image } from "primereact/image";
import images from "../../utils/images";
import { Dropdown } from "primereact/dropdown";
import EventsServices from "../../core/services/EventsServices";
import { filter, find, remove, findIndex } from 'lodash'
import { EVENT_SETTING, EVENT_STATUS, PERMISSION, USER_PERMISSION } from "../../utils/constants";
import moment from "moment";
import { Steps } from "primereact/steps";
import ProgressEvent from "../../components/ProgressEvent";
import { ToastHelper } from "../../helper/ToastHelper";
import { TabMenu } from 'primereact/tabmenu';
import Interactions from "./chart/interactions";
import Audience from "./chart/audience";
import Scans from "./chart/scans";
import Conversations from "./chart/conversations";
import Sessions from "./chart/sessions";
import { checkPermission } from "../../utils";
import Checkin from "./chart/checkin";
import LoginReport from "./chart/login";
import TopSearchReport from "./chart/topSearch";

const screenPermission = "dashboard"

export default function Dashboard() {

  const { t } = useTranslation()

  const { events, permission, eventCode, language, groupPermission } = useSelector((state) => state.userReducer)

  const dispatch = useDispatch();

  const [list, setList] = useState([])

  const [activeMenuIndex, setActiveMenuIndex] = useState(0);


  const [event, setEvent] = useState()

  const [steps] = useState(EVENT_STATUS.map(o => ({
    ...o,
    name: t(o.name)
  })))

  useEffect(() => {
    // if (permission == USER_PERMISSION.ADMIN) {
    getList()
    // } else {
    //   let _events = events.map(o => ({
    //       ...o,
    //       name: find(o.attributes, { languageCode: language })?.name ?? o.attributes?.length ? o.attributes[0].name : null,
    //       setting: o.setting ? JSON.parse(o.setting) : {}
    //   }))
    //   setList(_events)
    //   setEvent(_events?.length ? _events[0] : null)
    // }
  }, [])

  const getList = () => {
    EventsServices.search().then((res) => {
      if (res) {
        let _events = res.map(o => ({
          ...o,
          name: find(o.attributes, { languageCode: language })?.name,
          setting: o.setting ? JSON.parse(o.setting) : {}
        }))
        if (permission != USER_PERMISSION.ADMIN) {
          remove(_events, o => !find(events, { id: o.id }))
        }
        setList(_events)
        if (event) {
          setEvent(find(_events, { id: event.id }))
        } else {
          setEvent(_events?.length ? _events[0] : null)
        }

      }
    })
  }

  const onChangeEvent = (e) => {
    let _event = find(list, { eventCode: e.value })
    setEvent(_event)
  }

  const renderTemplate = (e) => {
    if (e) {
      return <div>
        <div className="font-semibold text-2xl">
          {e.name}
        </div>
        <div className="flex align-items-center">
          <i className="pi pi-calendar text-xs mr-2"></i>
          <div className="text-xs">
            {moment(e.startDate).format("DD MMMM YYYY")} - {moment(e.endDate).format("DD MMMM YYYY")}
          </div>
        </div>
      </div>
    }
    return <div>&nbsp;</div>
  }

  const activeIndex = useMemo(() => {
    const num = findIndex(steps, { id: event?.state })
    return num == -1 ? 0 : num
  }, [event])

  const itemRenderer = (item, itemIndex) => {
    const isActiveItem = activeIndex >= itemIndex;
    const backgroundColor = isActiveItem ? 'var(--primary-color)' : 'var(--surface-b)';
    const textColor = isActiveItem ? 'var(--surface-b)' : 'var(--text-color-secondary)';

    return (
      <span
        className="inline-flex align-items-center justify-content-center align-items-center border-circle border-primary border-1 h-3rem w-3rem z-1 cursor-pointer"
        style={{ backgroundColor: backgroundColor, color: textColor, marginTop: '-25px' }}
      // onClick={() => setActiveIndex(itemIndex)}
      >
        <i className={`${item.icon} text-xl`} />
      </span>
    );
  };

  const onChangeState = (item) => {
    if (permission == USER_PERMISSION.ADMIN || checkPermission(groupPermission, screenPermission, PERMISSION.UPDATE)) {
      EventsServices.changeState(event?.id, item.id).then(res => {
        if (res) {
          getList()
          ToastHelper.show({ severity: 'success', summary: t("common.success"), detail: t("common.saved") });
        }
      })
    } else {
      ToastHelper.show({ severity: 'error', summary: t("common.error"), detail: t("common.permission.denied") })
    }

  }

  const items = useMemo(() => [
    { label: t("chart.general") },
    { label: t("chart.checkin") },
    { label: t("chart.login") },
    { label: t("chart.topSearch") }
  ], [language])

  if (!checkPermission(groupPermission, screenPermission, PERMISSION.VIEW)) {
    return null
  }


  return (<div className="event-layout">
    <div className="event-layout-center overflow-hidden">
      <div className="container-fluid">
        <div className="grid">
          <div className="col-12">
            <div className="p-card overflow-hidden">
              <div className="w-full p-5 relative" style={{ backgroundColor: event?.setting[EVENT_SETTING.COLOR] ? event?.setting[EVENT_SETTING.COLOR] : `#E6B65A` }}>
                <h1 className="mb-0 font-normal">{t("dashboard.welcome", { name: "" })}</h1>
                <p className="w-30rem">{t("dashboard.description")}</p>
                <div className="flex flex-wrap gap-3">
                  <Link
                    to={event ? `/event/${event?.id}/companies` : "/"}
                    className="bg-white border-round-3xl px-4 py-2"
                  >
                    {t("common.route.event_info.companies")}
                  </Link>
                  <Link
                    to={event ? `/event/${event?.id}/sessions` : "/"}
                    className="bg-white border-round-3xl px-4 py-2"
                  >
                    {t("common.route.event_info.sessions")}
                  </Link>
                  <Link
                    to={event ? `/event/${event?.id}/products` : "/"}
                    className="bg-white border-round-3xl px-4 py-2"
                  >
                    {t("common.route.event_info.products")}
                  </Link>
                  <Link
                    to={event ? `/event/${event?.id}/maps` : "/"}
                    className="bg-white border-round-3xl px-4 py-2"
                  >
                    {t("common.route.event_info.maps")}
                  </Link>
                </div>
                <div className="absolute top-0 right-0 h-full w-30rem">
                  <Image
                    src={images.banner}
                    className="w-full h-full"
                    imageClassName="e-object-fit-cover w-full h-full"
                  />
                </div>

              </div>
              <div className="w-full px-3 py-3">
                <Dropdown
                  className="w-full e-event-dropdown border-0 shadow-none"
                  filter
                  filterBy="name"
                  // disabled={action === "view"}
                  options={list}
                  optionLabel="name"
                  optionValue="eventCode"
                  value={event?.eventCode}
                  valueTemplate={renderTemplate}
                  onChange={onChangeEvent}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            {/* <Steps
              className="e-steps m-2 pt-4"
              model={items} activeIndex={activeIndex} readOnly={false}
            /> */}
            <ProgressEvent
              model={steps}
              activeIndex={activeIndex}
              onChangeState={onChangeState}
              disable={!checkPermission(groupPermission, screenPermission, PERMISSION.UPDATE)}
            />
          </div>
          <div className="col-12">
            <div className="menu-tab-css flex justify-content-center">
              <TabMenu model={items} activeIndex={activeMenuIndex} onTabChange={(e) => setActiveMenuIndex(e.index)} />
            </div>
          </div>

          {!activeMenuIndex && <Audience eventID={event?.id}></Audience>}
          {!activeMenuIndex && <Interactions eventID={event?.id}></Interactions>}
          {!activeMenuIndex && <Sessions eventID={event?.id}></Sessions>}
          {!activeMenuIndex && <Scans eventID={event?.id}></Scans>}
          {!activeMenuIndex && <Conversations eventID={event?.id} event={event}></Conversations>}
          {activeMenuIndex == 1 && <Checkin eventID={event?.id} event={event}></Checkin>}
          {activeMenuIndex == 2 && <LoginReport eventID={event?.id} event={event} />}
          {activeMenuIndex == 3 && <TopSearchReport eventID={event?.id} event={event} />}
        </div>
      </div>
    </div>
  </div>
  )
}

