/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss"
import { useTranslation } from "react-i18next";
import { Alert } from "react-bootstrap";
import BackgroundImage from "../../../assets/images/background.jpeg";
import Logo from "../../../assets/images/logo.jpg";
import { Image } from 'primereact/image'
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { login, postEvent, setState } from "../../../core/actions/ActionUser";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router";
import { CommonHelper } from "../../../helper/CommonHelper";
import { USER_PERMISSION } from "../../../utils/constants";
import { Card } from "primereact/card";

export default function Login() {

  const { t } = useTranslation()
  const dispatch = useDispatch();
  const history = useNavigate()
  const [visible, setVisible] = useState(false);
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [options, setOptions] = useState([]);
  const onLogin = () => {
    dispatch(login({
      "userName": userName,
      "password": password,
      // "eventCode": "SICIX_HP"
    }, (res) => {
      if (res) {
        setOptions(res.events)
        if (res.permission && res.permission == USER_PERMISSION.ADMIN.toString()) {
          dispatch(postEvent({
            "userName": userName,
            "password": password
          }, (res) => {
            if (res) {
              history('/')
            }
          }))
        } else {
          setVisible(true)
        }
        // setVisible(true)
      }
      // history(-1)
    }))
  }

  const handleEvent = () => {
    if (selectedOption != null) {
      dispatch(postEvent({
        "userName": userName,
        "password": password,
        "eventCode": selectedOption.eventCode
      }, (res) => {
        if (res) {
          // CommonHelper.setCommon({
          //   eventCode: selectedOption.eventCode
          // })
          dispatch(setState({
            eventCode: selectedOption.eventCode
          }))
          setVisible(false);
          history('/')
        }
      }))
    }
  }

  const [selectedOption, setSelectedOption] = useState(null);
  const [rowClick, setRowClick] = useState(true);

  return (
    <div className="flex mt-8 justify-content-center" style={{marginRight: "240px"}}>
      <Card className="px-5 w-30rem">
        <div className="grid " >
          <div className="col-12">
            <h3 className="">Login</h3>
          </div>
          <div className="col-12">
            <div className="flex flex-column gap-2">
              <label for="username">Name</label>
              <InputText value={userName} onChange={(e) => setUserName(e.target.value)} id="username" type="text" className="w-full" />
            </div>
          </div>
          <div className="col-12">
            <div className="flex flex-column gap-2">
              <label for="password">Password</label>
              <InputText id="password" type="password" className="w-full" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
          </div>
          <div className="col-12 ">
            <Button label="Login" icon="pi pi-user" className="w-10rem mx-auto" onClick={onLogin}></Button>
          </div>
        </div>
      </Card>
      <Dialog
        header="Header"
        visible={visible}
        style={{ width: '70vw' }}
        onHide={() => setVisible(false)}
        footer={
          <div>
            <Button label="Confirm" onClick={handleEvent}></Button>
          </div>
        }
      >
        <div>
          <DataTable
            className="w-full"
            value={options}
            selectionMode={rowClick ? null : 'radiobutton'}
            selection={selectedOption}
            onSelectionChange={(e) => setSelectedOption(e.value)}
            dataKey="eventCode"
          >
            <Column selectionMode="single" headerStyle={{ width: '3rem' }}></Column>
            <Column field="eventCode" header="Code"></Column>
            <Column field="name" header="Name"></Column>
            <Column field="location" header="Location"></Column>
          </DataTable>
        </div>
      </Dialog>
    </div>
  )
}

