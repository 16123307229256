import axiosClient from "./axiosClient";

const EventMeetingService = {
    get: (eventId, payload) => {
        return axiosClient.get(`/event/${eventId}/meeting`, {
            params: payload
        });
    },
    create: (eventId, payload) => {
        return axiosClient.post(`/event/${eventId}/meeting`, payload);
    },
    getByCompany: (eventId, eventCompanyId) => {
        return axiosClient.get(`/event/${eventId}/meeting/${eventCompanyId}`);
    },
    delete: (eventId, meetingId) => {
        return axiosClient.delete(`/event/${eventId}/meeting/${meetingId}`);
    },
}

export default EventMeetingService;