import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TabView, TabPanel } from 'primereact/tabview';
import "./styles.scss"
import { EnumEventModule, USER_PERMISSION } from "../../../../utils/constants";
import EBreadCrumb from "../../../../components/EBreadCrumb";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import OfferAttribute from "../../../Events/OfferAttribute";

import { useSelector } from 'react-redux';

export default function UserInfo(props) {
    const { t } = useTranslation()

    const { userInfo } = useSelector((state) => state.userReducer)

    const { eventId, refId, screenId } = useParams()

    const history = useNavigate()

    const permission = useMemo(() => {
        return parseInt(userInfo?.permission ?? "-1")
    }, [userInfo])

    const activeIndex = useMemo(() => {
        switch (screenId) {
            case "offer-attributes":
                return 0
            default:
                return 0
        }
    }, [screenId])

    const tabHeaderTemplate = (name, link) => ({ onClick }) => {
        return <div className="p-3 font-bold flex cursor-pointer text-primary" onClick={onLink(link, onClick)}>
            {name}
        </div>
    }

    const onLink = (link, onClick) => () => {
        onClick()
        history(link)
    }

    return (
        <div className="event-layout">
            <div className="event-layout-center overflow-hidden">
                <div className="p-card">
                    <div className="p-card-body">
                        <div className="grid">
                            <div className="col-12">
                                <TabView scrollable activeIndex={activeIndex} panelContainerClassName="px-0">
                                    {/* <TabPanel headerTemplate={tabHeaderTemplate(t("event.offer_attribute"), `/event/${eventId}/user/${refId}/offer-attributes`)}>
                                        <OfferAttribute
                                            type={EnumEventModule.USER}
                                            screenPermission={"eventInfoScreen_user_offer-attributes"}
                                        />
                                    </TabPanel> */}
                                    <TabPanel headerTemplate={tabHeaderTemplate(t("common.offer_attribute_industry"), `/event/${eventId}/user/${refId}/offer-attribute-industry`)}>
                                        <OfferAttribute
                                            type={EnumEventModule.USER}
                                            refType={EnumEventModule.INDUSTRY}
                                            screenPermission="eventInfoScreen_user_offer-attributes"
                                        />
                                    </TabPanel>

                                    <TabPanel headerTemplate={tabHeaderTemplate(t("common.offer_attribute_product"), `/event/${eventId}/user/${refId}/offer-attribute-product`)}>
                                        <OfferAttribute
                                            type={EnumEventModule.USER}
                                            refType={EnumEventModule.PRODUCT}
                                            screenPermission="eventInfoScreen_user_offer-attributes"
                                        />
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
