import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
// import "./styles.scss"
import { classNames } from "primereact/utils";
import { useDispatch } from "react-redux";
import EDialog from "../../../../components/EDialog";
import { actionCompanyCreate, actionCompanyEdit, actionCompanyById } from "../../../../core/actions/ActionCompany";
import CompanyServices from "../../../../core/services/CompanyServices";
import S3FileUpload from "../../../../components/S3FileUpload";
import { EXCEL_CONFIG_FIELD, FILE_TYPE, UPLOAD_STATUS, USER_PERMISSION, USER_PERMISSIONS, USER_TYPE, USER_TYPES } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import UserServices from "../../../../core/services/UserServices";
import CKEditorDocumentEditor from "../../../../components/CKEditorDocumentEditor";
import { cloneDeep, find, filter } from 'lodash'
import EventsServices from "../../../../core/services/EventsServices";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Badge } from "primereact/badge";
import { exportDataXlsx } from "../../../../utils";

function ImportDetailDetail(props, ref) {
    const { t } = useTranslation()

    const [data, setData] = useState([])
    const [exportData, setExportData] = useState()
    const refDialog = useRef(null)

    useImperativeHandle(ref, () => ({
        create: (_data, ds) => {
            refDialog.current.create();
            setData(_data)
            ds.map(o => {
                o.data = filter(o.data, x => find(_data, {key: x[EXCEL_CONFIG_FIELD.userEmail]}))
            })
            console.log(ds)
            setExportData(ds)
        },
    }));

    const cancel = () => {
        refDialog.current.close()
        setData([])
    }

    const onExport = () => {
        exportDataXlsx(exportData)
    }

    return (
        <EDialog
            ref={refDialog}
            style={{ width: '70vw' }}
            title={t("user.import_result")}
            closable={false}
            hidebtnFooter={false}
            // onShow={onShow}
            btnFooter={[
                {
                    label: t("common.cancel"),
                    onClick: () => cancel(),
                    text: true
                },
                {
                    label: t("common.export"),
                    onClick: onExport,
                    text: true
                }
            ]
            }
        >
            <div className="container">
                <div className="grid">
                    <div className="col-12">
                    <DataTable
                        value={data}
                        // header={headerTable}
                        dataKey="id"
                        className="p-datatable-gridlines border-all"
                        resizableColumns
                        columnResizeMode="expand"
                        emptyMessage={t("common.no_data")}
                        scrollable
                        scrollDirection="both"
                        scrollHeight="flex"
                        
                    >
                        <Column
                            style={{ width: 100 }}
                            field="STT"
                            body={(index, value) => value?.rowIndex + 1}
                            header={t("common.no")}
                        />
                        <Column
                            header={t("common.email")}
                            style={{ minWidth: 250 }}
                            body={(d, value) => <div>
                                {d?.key}
                            </div>}
                        />
                        
                        <Column
                            header={t("common.status")}
                            style={{ minWidth: 250, width: 250 }}
                            body={(d, value) => <Badge value={d?.value} size="small" severity="warning"></Badge>}
                        />
                        
                    </DataTable>
                    </div>
                </div>
            </div>
        </EDialog>
    )
}
ImportDetailDetail = forwardRef(ImportDetailDetail)
export default ImportDetailDetail;
