import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import EDialog from "../../../../components/EDialog";
import EventsServices from "../../../../core/services/EventsServices";
import S3FileUpload from "../../../../components/S3FileUpload";
import { FILE_TYPE, PERMALINK_APPLICATION, PERMALINK_APPLICATIONS, PERMALINK_TYPE, PERMALINK_TYPES, REFERENCE_TYPE, UPLOAD_STATUS } from "../../../../utils/constants";
import { Editor } from 'primereact/editor';
import { TabPanel, TabView } from "primereact/tabview";
import { useSelector } from "react-redux";
import CKEditorDocumentEditor from "../../../../components/CKEditorDocumentEditor";
import { InputNumber } from "primereact/inputnumber";
import ReferenceServices from "../../../../core/services/ReferenceServices";

function EventsReferencesDetail(props, ref) {
    const { t } = useTranslation()
    const { afterSubmit, eventId, refId, type } = props
    const { languages, language } = useSelector((state) => state.userReducer)
    const s3FileUploadRef = useRef()
    const [loading, setLoading] = useState(false)
    const [currentId, setCurrentId] = useState(null)

    const defaultData = {
        refType: null,
        sortOrder: 1
    }
    const defaultError = {
        refType: null,
        sortOrder: null
    }
    const [data, setData] = useState(defaultData)
    const [error, setError] = useState(defaultError)
    const [action, setAction] = useState()
    const [activeIndex, setActiveIndex] = useState(0);
    const refDialog = useRef(null)
    const [options, setOptions] = useState([])

    useEffect(() => {
        setOptions(REFERENCE_TYPE.list.map(o => ({
            ...o,
            name: t(o.name)
        })))
    }, [])

    useImperativeHandle(ref, () => ({
        create: () => {
            refDialog.current.create();
            setAction("create")
            let _data = structuredClone(data)

            setData(_data)
        },
        edit: (d) => {
            setAction("edit");
            if (d) {
                refDialog.current.create();

                setCurrentId(d.id)
                setAction("edit");

                setData(d)
                refDialog.current.create();
            }
        },
        view: (d) => {
            setAction("view")
            if (d) {
                setCurrentId(d.id)

                setData(d)
                refDialog.current.create();
            }

        }
    }));

    const onChangeData = (prop, value, propArr, index) => {
        let _data = structuredClone(data)
        switch (prop) {

            default:
                _data[prop] = value

                break;
        }

        validateData([prop], _data, propArr, index)
        setData(_data)
    }

    const validateData = (prop, _data, propArr, index) => {
        let _error = structuredClone(error)
        let requiredFields = prop.length > 0 ? prop : Object.keys(_data);
        let _prop = requiredFields
        for (const field of _prop) {
            switch (field) {
                case "refType":
                    _error[field] = ""
                    if (!_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;
                    case "sortOrder":
                    _error[field] = ""
                    if (!_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;

                default:
                    break;
            }
        }

        setError(_error)
        let count = 0;
        for (const key in _error) {
            if (_error[key] && key !== "attributes") {
                count++;
            }
        }
        return count;
    }

    const onSubmit = () => {
        let validate = validateData([], data)
        if (validate) return
        switch (action) {
            case "create":
                setLoading(true)
                ReferenceServices.create(eventId, type, refId, data).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                    setLoading(false)
                })
                break;
            case "edit":
                setLoading(true)
                ReferenceServices.update(currentId, eventId, type, refId, data).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                    setLoading(false)
                })
                break;
            default:
                break;
        }

    }

    const renderError = (field) => {
        if (error[field]) {
            return <span className="text-red-500 text-xs">{error[field]}</span>
        }
    }

    const cancel = () => {
        refDialog.current.close()
        setData(defaultData)
        setError(defaultError)
        setActiveIndex(0)
    }
    const onUploadedImage = useCallback((val) => {
        onChangeData("image", val)
    }, [data])


    const onShow = () => {
        if (data?.image) {
            s3FileUploadRef.current.setFiles([data.image.name], FILE_TYPE.IMAGE)
        }
        // if (data?.background) {
        //     s3FileUploadBgRef.current.setFiles([data.background.name], FILE_TYPE.IMAGE)
        // }
    }

    return (
        <EDialog
            ref={refDialog}
            // style={{ width: '90vw' }}
            title={t("references.title")}
            closable={false}
            onShow={onShow}
            hidebtnFooter={false}
            btnFooter={
                action === "view" ?
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                        }
                    ] :
                    [
                        {
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                        },
                        {
                            label: t("common.save"),
                            onClick: () => onSubmit(),
                            loading: loading
                        }
                    ]
            }
        >
            <div className="container px-4 py-3">
                <div className="grid">
                    <div className="col-6 field">
                        <label className="require">{t("references.type")}</label>
                        <Dropdown
                            filter
                            disabled={action === "view"}
                            options={options}
                            optionLabel="name"
                            optionValue="id"
                            value={data?.refType}
                            onChange={(e) => { onChangeData("refType", e.target.value) }}
                            className={classNames({ "w-full": true })}
                        ></Dropdown>
                        {renderError("refType")}
                    </div>
                    <div className="col-6 field">
                        <label className="require">{t("references.sortOrder")}</label>
                        <InputNumber
                            disabled={action === "view"}
                            className="w-full"
                            onChange={(e) => { onChangeData("sortOrder", e.value) }}
                            value={data?.sortOrder}
                        />
                        {renderError("sortOrder")}
                    </div>

                </div>

            </div>

        </EDialog>
    )
}
EventsReferencesDetail = forwardRef(EventsReferencesDetail)
export default EventsReferencesDetail;
