import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { useDispatch } from "react-redux";
import EDialog from "../../../../components/EDialog";
import CompanyServices from "../../../../core/services/CompanyServices";
import { InputNumber } from 'primereact/inputnumber';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "react-bootstrap";
import { ACTION } from "../../../../utils/constants";
import MapsServices from "../../../../core/services/MapsServices";
import { cloneDeep } from 'lodash'
import EventsServices from "../../../../core/services/EventsServices";
import { MultiSelect } from "primereact/multiselect";

function MapsDetail({ afterSubmit, eventId, type, refId }, ref) {
    const { t } = useTranslation()
    const defaultData = {
        mapsId: null,
    }
    const defaultError = {
        mapsId: null,
    }

    const [data, setData] = useState(defaultData)

    const [error, setError] = useState(defaultError)

    const [action, setAction] = useState(ACTION.CREATE)

    const [maps, setMaps] = useState([])

    const [locations, setLocations] = useState([])

    const refDialog = useRef(null)

    useEffect(() => {
        loadMaps()
    }, [])

    const loadMaps = () => {
        MapsServices.get(eventId).then(res => {
            if (res) {
                setMaps(res)
            }
        })
    }

    useImperativeHandle(ref, () => ({
        create: () => {
            refDialog.current.create();
            setAction(ACTION.CREATE)
        },
        edit: (id) => {
            refDialog.current.create();
            setAction(ACTION.UPDATE);
            EventsServices.getMapById(id, {
                eventId,
            }).then((res) => {
                if (res) {
                    setData({
                        id: res.id,
                        mapsId: res.maps.id
                    })
                }
            })
        },
        view: (id) => {
            refDialog.current.create();
            setAction(ACTION.VIEW)
            // CompanyServices.getById(id).then((res) => {
            //     if (res) {
            //         setData(res)
            //     }
            // })
        }
    }));

    const onChangeData = (prop, value) => {
        let _data = cloneDeep(data)
        _data[prop] = value
        validateData([prop], _data)
        setData(_data)
    }

    const validateData = (prop, _data) => {
        let _error = cloneDeep(error)
        let requiredFields = prop.length > 0 ? prop : Object.keys(_data);
        let _prop = requiredFields
        for (const field of _prop) {
            switch (field) {
                case "mapsId":
                    _error[field] = ""
                    if (!_data[field]) {
                        _error[field] = t("common.validate_content")
                    }
                    break;

                default:
                    break;
            }
        }

        setError(_error)
        let count = 0;
        for (const key in _error) {
            if (_error[key]) {
                count++;
            }
        }
        return count;
    }

    const onSubmit = () => {
        let validate = validateData([], data)
        if (validate) return
        switch (action) {
            case ACTION.CREATE:
                EventsServices.createMap({
                    eventId,
                    ...data
                }).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            case ACTION.UPDATE:
                EventsServices.updateMap(data.id, {
                    eventId,
                    ...data
                }).then((res) => {
                    if (res) {
                        afterSubmit()
                        cancel()
                    }
                })
                break;
            default:
                break;
        }

    }

    const renderError = (field) => {
        if (error[field]) {
            return <span className="text-red-500 text-xs">{error[field]}</span>
        }
    }

    const cancel = () => {
        refDialog.current.close()
        setData(defaultData)
        setError(defaultError)
    }

    return (
        <EDialog
            ref={refDialog}
            style={{ width: '70vw' }}
            title={t("location.maps")}
            closable={false}
            hidebtnFooter={false}
            btnFooter={
                action === ACTION.VIEW ?
                    [
                        {
                            text: true,
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        }
                    ] :
                    [
                        {
                            text: true,
                            label: t("common.cancel"),
                            onClick: () => cancel(),
                            text: true
                        },
                        {
                            label: t("common.save"),
                            onClick: () => onSubmit(),
                        }
                    ]
            }
        >
            <div className="container">
                <div className="grid">
                    <div className="col-6 field">
                        <label className="require">{t("company.map")}</label>
                        <Dropdown
                            disabled={action === ACTION.VIEW}
                            options={maps}
                            optionLabel="name"
                            optionValue="id"
                            value={data?.mapsId}
                            onChange={(e) => { onChangeData("mapsId", e.target.value) }}
                            className={classNames({ "w-full": true, "p-invalid": error["mapsId"] })}
                        />
                        {renderError("mapsId")}
                    </div>
                </div>
            </div>
        </EDialog>
    )
}
MapsDetail = forwardRef(MapsDetail)
export default MapsDetail;
