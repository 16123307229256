/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss"
import { useTranslation } from "react-i18next";
import { Card } from "primereact/card";
import { Chart } from "primereact/chart";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import EventsServices from "../../../core/services/EventsServices";
import { filter, find, remove, findIndex } from 'lodash'
import { EnumEventModule, EVENT_SETTING, EVENT_STATUS, USER_PERMISSION, USER_TYPES_TAB } from "../../../utils/constants";
import moment from "moment";
import { Steps } from "primereact/steps";
import ProgressEvent from "../../../components/ProgressEvent";
import { ToastHelper } from "../../../helper/ToastHelper";
import { TabMenu } from 'primereact/tabmenu';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Divider } from "primereact/divider";
import _, { cloneDeep } from "lodash";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { OverlayPanel } from "primereact/overlaypanel";

function LoginReport(props, ref) {

    const { t } = useTranslation()

    const { countries = [], language } = useSelector((state) => state.userReducer)

    const { eventID, event } = props;

    const [lazyParams, setLazyParams] = useState({

    })
    const [lazyParamsDate, setLazyParamsDate] = useState({

    })
    // const [selectedDay, setselectedDay] = useState()

    const [selectedUser, setSelectedUser] = useState()
    const [login, setLogin] = useState([])
    const [checkInDate, setCheckInDate] = useState([])
    const [loginUser, setLoginUser] = useState([])
    const [types, setTypes] = useState([])
    const [offers, setOffers] = useState([])

    const filterRef = useRef()

    useEffect(() => {
        setTypes(USER_TYPES_TAB.map(o => ({
            ...o,
            name: t(o.name)
        })))
    }, [])

    useEffect(() => {
        if (event) {
            load()
            // setLazyParamsDate({})
            // setSelectedUser()
            // loadOffer()
        }
    }, [event])

    // useEffect(() => {
    //     loadDate(lazyParamsDate?.date)
    // }, [lazyParamsDate])

    useEffect(() => {
        loadUser(selectedUser?.userId)
    }, [selectedUser])

    // const loadOffer = () => {
    //     EventsServices.offerAttribute.filter(eventID, {
    //         page: 0,
    //         size: 9999,
    //         first: 0
    //     }).then((res) => {
    //         if (res) {
    //             setOffers(res.data.map(o => ({
    //                 ...o,
    //                 name: _.find(o.languages, { languageCode: language })?.name
    //             })))
    //         }
    //     })
    // }

    const load = () => {
        EventsServices.report.getLogin(eventID).then(res => {
            if (res) {
                setLogin(res)
            }
        })
    }

    // const loadDate = (date) => {
    //     if (date) {
    //         EventsServices.report.getCheckinByDate(eventID, {
    //             date,
    //             type: lazyParamsDate.type,
    //             countryId: lazyParamsDate?.countryId,
    //             industryIds: lazyParamsDate?.industryIds?.map(o => o.id),
    //             offerIds: lazyParamsDate?.offerIds?.map(o => o.id)
    //         }).then(res => {
    //             if (res) {
    //                 setCheckInDate(res)
    //             }
    //         })
    //     } else {
    //         setCheckInDate([])
    //     }
    // }

    const loadUser = (userId) => {
        if (userId) {
            EventsServices.report.getLoginUser(userId, eventID).then(res => {
                if (res) {
                    setLoginUser(res)
                }
            })
        } else {
            setLoginUser([])
        }
    }

    // const onDay = (row) => () => {
    //     applyChange("date", row.title)
    // }

    const onUser = (row) => () => {
        setSelectedUser(row)
    }

    const onBack = () => {
        if (selectedUser) {
            setSelectedUser()
        } else if (lazyParamsDate?.date) {
            setLazyParamsDate({})
        }
    }

    const startContent = (
        <React.Fragment>
            {(selectedUser || lazyParamsDate?.date) && <Button
                icon="pi pi-chevron-left"
                text
                size="small"
                onClick={onBack}
            />}
            <span className="ml-3">{selectedUser?.fullName}</span>

        </React.Fragment>
    );

    const onChangeKeyword = (e) => {
        // let _filter = cloneDeep(lazyParams)
        // _filter.keyword = e.target.value
        // setLazyParams(_filter)
    }

    const applyChange = (prop, value) => {
        let _current = cloneDeep(lazyParamsDate)
        _current[prop] = value;
        setLazyParamsDate(_current)
    }

    const offerIndustry = useMemo(() => {
        return _.filter(offers, { refType: EnumEventModule.INDUSTRY })
    }, [offers])
    const offerProduct = useMemo(() => {
        return _.filter(offers, { refType: EnumEventModule.PRODUCT })
    }, [offers])

    const endContent = (
        <>
            <div>
                {/* <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText placeholder={t("company.search")} value={lazyParams?.keyword} onChange={onChangeKeyword} />
                </span> */}
                {(lazyParamsDate?.date && !selectedUser) && <Button
                    icon="pi pi-filter"
                    className="ml-2"
                    outlined
                    onClick={(e) => filterRef.current.toggle(e)}
                />
                }


            </div>

            <OverlayPanel ref={filterRef}
                style={{ width: "500px", maxHeight: "65vh" }}
            >
                <div
                    className="sx-layout"
                >
                    <div
                        className="sx-layout-center"
                    >
                        <div className="grid">
                            <div className="col-6 field">
                                <label className="require">{t("chart.type")}</label>
                                <Dropdown
                                    value={lazyParamsDate?.type}
                                    onChange={(e) => applyChange('type', e.value)}
                                    options={types}
                                    optionLabel="name"
                                    optionValue="value"
                                    className="w-full"
                                    filter
                                    showClear
                                />
                            </div>
                            <div className="col-6 field">
                                <label className="require">{t("chart.country")}</label>
                                <Dropdown
                                    filter
                                    options={countries}
                                    optionLabel="countryName"
                                    optionValue="countryId"
                                    className="w-full"
                                    value={lazyParamsDate?.countryId}
                                    onChange={(e) => { applyChange("countryId", e.value) }}
                                    showClear
                                />
                            </div>

                            <div className="col-6 field">
                                <label className="require">{t("chart.offer-attribute-industry")}</label>
                                <MultiSelect
                                    value={lazyParamsDate.industryIds}
                                    onChange={(e) => applyChange('industryIds', e.value)}
                                    options={offerIndustry}
                                    optionLabel="name"
                                    optionValue="id"
                                    className="w-full"
                                    showClear
                                    filter
                                />
                            </div>

                            <div className="col-6 field">
                                <label className="require">{t("chart.offer-attribute-product")}</label>
                                <MultiSelect
                                    value={lazyParamsDate.offerIds}
                                    onChange={(e) => applyChange('offerIds', e.value)}
                                    options={offerProduct}
                                    optionLabel="name"
                                    optionValue="id"
                                    className="w-full"
                                    showClear
                                    filter
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </OverlayPanel>
        </>
    )


    return (

        <>
            <div className="col-12">
                <Card className="border-round-2xl shadow-2">
                    <div className="flex justify-content-center">
                        <div className="text-center mt-2 mb-4">
                            <div className="text-5xl font-bold text-900">{t("chart.login")}</div>
                            {/* <div className="text-lg font-medium">{t("chart.understand_what_meeting")}</div> */}
                            <div className="menu-tab-css flex justify-content-center">
                                {/* <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} /> */}
                            </div>
                        </div>
                    </div>

                    <div className="grid">
                        {(selectedUser) && <div className="col-12">
                            <Toolbar start={startContent} end={endContent} />
                        </div>
                        }

                        <div className="col-12">
                            {selectedUser
                                ? <DataTable
                                    value={loginUser}
                                    dataKey="id"
                                    className="p-datatable-gridlines border-all"
                                    resizableColumns
                                    columnResizeMode="expand"
                                    emptyMessage={t("common.no_data")}
                                    // scrollable
                                    // scrollDirection="both"
                                    // scrollHeight="flex"
                                    // lazy
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    paginator
                                >
                                    <Column
                                        className="w-6rem"
                                        body={(index, value) => value?.rowIndex + 1}
                                        header={t("common.no")}
                                    />
                                    <Column
                                        header={t("common.time")}
                                        style={{ minWidth: 100 }}
                                        body={(d, value) => <div>
                                            {moment(d.time).format("DD/MM/YYYY HH:mm:ss")}
                                        </div>}
                                    />

                                </DataTable>
                                :
                                <DataTable
                                    value={login}
                                    dataKey="userId"
                                    className="p-datatable-gridlines border-all"
                                    resizableColumns
                                    columnResizeMode="expand"
                                    emptyMessage={t("common.no_data")}
                                    // scrollable
                                    // scrollDirection="both"
                                    // scrollHeight="flex"
                                    // lazy
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    paginator
                                >
                                    <Column
                                        className="w-6rem"
                                        body={(index, value) => value?.rowIndex + 1}
                                        header={t("common.no")}
                                    />
                                    <Column
                                        header={t("chart.fullName")}
                                        // field="fullName"
                                        style={{ minWidth: 250 }}
                                        body={(d, value) => <div className="text-primary underline cursor-pointer" onClick={onUser(d)}>
                                            {d.fullName}
                                        </div>}
                                    />
                                    <Column
                                        header={t("chart.companyName")}
                                        // field="companyName"
                                        style={{ minWidth: 250 }}
                                        body={(d, value) => <div>
                                            {d.companyName}
                                        </div>}
                                    />
                                    <Column
                                        header={t("common.total")}
                                        // field="total"
                                        style={{ minWidth: 100 }}
                                        body={(d, value) => <div>
                                            {d.total}
                                        </div>}
                                    />

                                </DataTable>
                            }
                            

                        </div>

                    </div>



                </Card>
            </div>



        </>

    )
}
LoginReport = forwardRef(LoginReport)
export default LoginReport;

